import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  AppBar,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import agent from 'agent'
import { UPDATE_NEWS } from 'constants/actionTypes'

const mapStateToProps = state => ({
  inProgress: state.newsandevents.inProgress,
  newsUpdated: state.newsandevents.newsUpdated
})

const mapDispatchToProps = dispatch => ({
  updateNews: (id, announcement) =>
    dispatch({ type: UPDATE_NEWS, payload: agent.NewsAndEvents.updateNews(id, announcement) })
})

class DialogEditArticle extends React.Component {
  state = { announcement: '', youtube_link: '', title: '', url: '', image: '', summary: '' }

  componentDidMount() {
    const { articleData } = this.props
    if (articleData?._id === '9T_9txdLQjCLLVI52qgfIA') {
      this.setState({ announcement: articleData?.detail.title })
    } else {
      this.setState({
        title: articleData?.detail.title,
        youtube_link: articleData?.detail.youtube_link,
        url: articleData?.detail.url,
        image: articleData?.detail.image,
        summary: articleData?.detail.summary
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inProgress && !this.props.inProgress && this.props.newsUpdated) {
      this.props.onSave()
    }
  }

  saveChanges = () => {
    const { articleData } = this.props
    const { announcement, youtube_link, url, image, title, summary } = this.state
    if (articleData._id) {
      if (articleData?._id === '9T_9txdLQjCLLVI52qgfIA') {
        this.props.updateNews('9T_9txdLQjCLLVI52qgfIA', {
          detail: {
            title: announcement
          }
        })
      }

      if (articleData?._id === 'urLC5YmXRXCKO17mj4z7vA') {
        this.props.updateNews('urLC5YmXRXCKO17mj4z7vA', {
          detail: {
            title: title === '' ? '' : title,
            youtube_link: youtube_link === '' ? '' : youtube_link,
            url: url === '' ? '' : url,
            image: image === '' ? '' : image,
            summary: summary === '' ? '' : summary
          }
        })
      }

      if (articleData?._id === 'r935be4rQHyTtYXUBPsj9A') {
        this.props.updateNews('r935be4rQHyTtYXUBPsj9A', {
          detail: {
            title: title === '' ? '' : title,
            youtube_link: youtube_link === '' ? '' : youtube_link,
            url: url === '' ? '' : url,
            image: image === '' ? '' : image,
            summary: summary === '' ? '' : summary
          }
        })
      }

      if (articleData?._id === 'mjCbHgJ4R1usYmXPfxxmKg') {
        this.props.updateNews('mjCbHgJ4R1usYmXPfxxmKg', {
          detail: {
            title: title === '' ? '' : title,
            youtube_link: youtube_link === '' ? '' : youtube_link,
            url: url === '' ? '' : url,
            image: image === '' ? '' : image,
            summary: summary === '' ? '' : summary
          }
        })
      }
    }
  }

  handleChange = event => {
    const type = event.target.name
    const value = event.target.value
    this.setState({ [type]: value })
  }

  render() {
    const { isOpen, toggle, articleData } = this.props
    const { announcement, youtube_link, url, image, title, summary } = this.state

    return (
      <div>
        <Dialog disableEnforceFocus open={isOpen} onClose={toggle}>
          <AppBar className="position-relative">
            <Toolbar>
              <IconButton onClick={toggle} aria-label="Close" className="text-white">
                <CloseIcon />
              </IconButton>
              <Typography
                type="title"
                color="inherit"
                style={{
                  flex: 1
                }}>
                {articleData?._id === '9T_9txdLQjCLLVI52qgfIA' ? 'Edit Announcement' : 'Edit Article'}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ padding: '24px' }}>
            {articleData?._id === '9T_9txdLQjCLLVI52qgfIA' && (
              <FormControl>
                <div className="col-12" style={{ width: '500px' }}>
                  <TextField
                    label={'Announcement'}
                    name={'announcement'}
                    value={announcement || ''}
                    onChange={this.handleChange}
                    multiline
                    fullWidth
                  />
                </div>
              </FormControl>
            )}
            {articleData?._id !== '9T_9txdLQjCLLVI52qgfIA' && (
              <FormControl>
                <div className="col-12">
                  <TextField
                    label={'Article Title'}
                    name={'title'}
                    value={title || ''}
                    onChange={this.handleChange}
                    placeholder="A Levels 2022"
                    multiline
                    fullWidth
                  />
                  <TextField
                    label={'Article URL'}
                    name={'url'}
                    value={url || ''}
                    onChange={this.handleChange}
                    placeholder="https://www.yorkpress.co.uk/news/20668642.a-level-results-day-2022-york-north-east-yorkshire/"
                    multiline
                    fullWidth
                  />

                  <TextField
                    label={'Article Summary'}
                    name={'summary'}
                    value={summary || ''}
                    onChange={this.handleChange}
                    placeholder="Kerry Smith, Head of Sixth Form College said: These results reflect our students’ positive ethos, determination and extraordinary resilience, not only over the last two years, but since they joined us in Year 7.  Our Year 13 students truly embody our school motto; that ‘everything you do should be worthy, of great merit, character and value’, and these excellent results are testament to this."
                    multiline
                    fullWidth
                  />
                  <TextField
                    label={'Article image (jpg, png, jpeg)'}
                    name={'image'}
                    value={image || ''}
                    onChange={this.handleChange}
                    placeholder="https://www.yorkpress.co.uk/resources/images/14496755.image?type=article-full"
                    multiline
                    fullWidth
                  />
                  <TextField
                    label={'Youtube URL file code'}
                    name={'youtube_link'}
                    value={youtube_link || ''}
                    placeholder="ZnuwB35GYMY"
                    onChange={this.handleChange}
                    fullWidth
                    helperText="e.g. ZnuwB35GYMY, 8YHYFPck3-Q"
                  />
                </div>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant={'text'} color={'default'} onClick={toggle}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={this.saveChanges}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

DialogEditArticle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  articleData: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogEditArticle)
