import React from 'react'
import ClassCell from 'pages/CourseManagement/ClassCell'

const ContactList = ({ classList, onSaveClass, onDeleteClass }) => {
  return (
    <div className="contact-main-content">
      {classList.map((tClass, index) => (
        <ClassCell key={index} tClass={tClass} onDeleteClass={onDeleteClass} onSaveClass={onSaveClass} />
      ))}
    </div>
  )
}

export default ContactList
