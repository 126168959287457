import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from 'reducers/auth'
import common from 'reducers/common'
import question from 'reducers/question'
import orders from 'reducers/orders'
import user from 'reducers/user'
import classroom from 'reducers/classroom'
import lessons from 'reducers/lessons'
import announcements from 'reducers/announcements'
import feedback from 'reducers/feedback'
import report from 'reducers/report'
import newsandevents from 'reducers/newsandevents'
import consultation from 'reducers/consultation'

export default history => {
  return combineReducers({
    router: connectRouter(history),
    auth,
    common,
    question,
    orders,
    user,
    classroom,
    lessons,
    announcements,
    feedback,
    report,
    newsandevents,
    consultation
  })
}
