import * as Type from 'constants/actionTypes'
import _ from 'lodash'

const defaultState = {
  agentList: [],
  loadingCredit: false,
  addingCredit: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Type.GET_USERS:
      return {
        ...state,
        isLoading: true,
        userList: null
      }

    case Type.GET_USERS_SUCCESS: {
      let userList = state.userList || []
      let tutorList = state.tutorList ? state.tutorList : []
      let taList = state.taList ? state.taList : []
      let agentList = state.agentList || []
      let csList = state.csList || []
      let saList = state.saList || []
      let userCount = state.userCount || 0
      let tutorCount = state.tutorCount || 0
      let taCount = state.taCount || 0
      let agentCount = state.agentCount || 0
      let csCount = state.csCount || 0
      let saCount = state.saCount || 0

      if (action.userType === 'Tutor') {
        tutorList = action.payload.data
        tutorCount = action.payload.count
      } else if (action.userType === 'TA') {
        taList = action.payload.data
        taCount = action.payload.count
      } else if (action.userType === 'Agent') {
        agentList = action.payload.data
        agentCount = action.payload.count
      } else if (action.userType === 'CustService') {
        csList = action.payload.data
        csCount = action.payload.count
      } else if (action.userType === 'SalesAdmin') {
        saList = action.payload.data
        saCount = action.payload.count
      } else {
        userList = action.payload.data
        userCount = action.payload.count
      }

      return {
        ...state,
        isLoading: false,
        userList,
        userCount,
        tutorList,
        tutorCount,
        taList,
        taCount,
        agentList,
        agentCount,
        csList,
        saList,
        csCount,
        saCount,
        userPage: action.page
      }
    }

    case Type.GET_USERS_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case Type.GET_STUDENTS:
      return {
        ...state,
        isLoading: true,
        userList: null
      }

    case Type.GET_STUDENTS_SUCCESS:
      let userList = state.userList || []

      userList = action.payload.data

      return {
        ...state,
        isLoading: false,
        userList
      }

    case Type.GET_STUDENTS_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case Type.GET_TUTORS:
      return {
        ...state,
        isLoading: true,
        tutorList: null
      }

    case Type.GET_TUTORS_SUCCESS:
      let tutorList = state.tutorList ? state.tutorList : []

      tutorList = action.payload.data

      return {
        ...state,
        isLoading: false,
        tutorList
      }

    case Type.GET_TUTORS_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case Type.CREATE_USER:
      return {
        ...state,
        isLoading: true,
        userCreated: false,
        error: false
      }
    case Type.CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userCreated: true
      }
    case Type.CREATE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case Type.UPDATE_USER:
      return {
        ...state,
        isLoading: true,
        userUpdated: false,
        error: false
      }
    case Type.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userUpdated: true
      }
    case Type.UPDATE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      }

    case Type.CREATE_PARENT:
      return {
        ...state,
        isLoading: true,
        userCreated: false,
        error: false
      }
    case Type.CREATE_PARENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userCreated: true,
        error: false
      }
    case Type.CREATE_PARENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case Type.UPDATE_PARENT:
      return {
        ...state,
        isLoading: true,
        userUpdated: false,
        error: false
      }
    case Type.UPDATE_PARENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userUpdated: true
      }
    case Type.UPDATE_PARENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      }

    case Type.GET_STUDENT_LIST:
      return {
        ...state,
        isLoading: true,
        students: null
      }

    case Type.GET_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        students: action.payload.data,
        studentCount: action.payload.count,
        studentPage: action.page ? action.page : 0
      }

    case Type.GET_STUDENT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        students: null
      }

    case Type.GET_STUDENT_LESSON_LIST:
      return {
        ...state,
        isLoading: true,
        lessons: null
      }

    case Type.GET_STUDENT_LESSON_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lessons: action.payload.data,
        lessonCount: action.payload.count,
        lessonPage: action.page ? action.page : 0
      }

    case Type.GET_STUDENT_LESSON_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        lessons: null
      }

    case Type.GET_STUDENT_CONSULTATION_LIST:
      return {
        ...state,
        isLoading: true,
        lessonsConsultation: null
      }

    case Type.GET_STUDENT_CONSULTATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lessonsConsultation: action.payload.data
      }

    case Type.GET_STUDENT_CONSULTATION_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        lessonsConsultation: null
      }
  
    default:
      return state
  }
}
