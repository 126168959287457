import * as Type from 'constants/actionTypes'

const initialState = {
  loadingBirthdayStudent: false,
  birthdayStudentList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_BIRTHDAY_STUDENT:
      return {
        ...state,
        loadingBirthdayStudent: true,
        birthdayStudentList: []
      }

    case Type.GET_BIRTHDAY_STUDENT_SUCCESS:
      return {
        ...state,
        loadingBirthdayStudent: false,
        birthdayStudentList: action.payload.data
      }

    case Type.GET_BIRTHDAY_STUDENT_FAILED:
      return {
        ...state,
        loadingBirthdayStudent: false
      }
    default:
      return {
        ...state
      }
  }
}
