import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import Proptypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import * as Type from 'constants/actionTypes'
import CKEditor from 'react-ckeditor-component'

const mapStateToProps = state => ({
  tutors: state.user.tutorList,
  TAs: state.user.taList
})

const mapDispatchToProps = dispatch => ({
  getUsers: (filter, offset, userType) =>
    dispatch({ type: Type.GET_USERS, payload: agent.User.getUsers({ ...filter, _type: userType }, offset), userType })
})

class LevelSelector extends React.Component {
  constructor(props) {
    super()

    let { level } = props

    let levelName
    if (level === 'beginner' || level === 'intermediate' || level === 'advanced') {
      levelName = level
    } else {
      levelName = level.substr(0, level.lastIndexOf('-'))
    }
    let levelNum = level.substr(level.lastIndexOf('-') + 1)
    let levelMin = 1
    let levelMax

    switch (levelName) {
      case 'primary':
        levelMax = 6
        break
      case 'secondary':
        levelMax = 4
        break
      case 'junior-college':
        levelMax = 2
        break
      case 'integrated-programme':
        levelMax = 6
        break
      case 'international-baccalaureate-diploma-programme':
        levelMax = 2
        break
      default:
        levelMax = 2
    }

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.state = {
      levelName,
      levelNum,
      levelMin,
      levelMax
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.levelName !== this.state.levelName) {
      const { levelNum, levelName } = this.state
      let levelMax = 1

      switch (levelName) {
        case 'primary':
          levelMax = 6
          break
        case 'secondary':
          levelMax = 4
          break
        case 'junior-college':
          levelMax = 2
          break
        case 'integrated-programme':
          levelMax = 6
          break
        case 'international-baccalaureate-diploma-programme':
          levelMax = 2
          break
        default:
          levelMax = 6
      }

      if (levelNum > levelMax) {
        this.setState({ levelNum: levelMax, levelMax })
      } else {
        this.setState({ levelMax })
      }
    }

    if (prevState.levelName !== this.state.levelName || prevState.levelNum !== this.state.levelNum) {
      this.onChange()
    }
  }

  onChange = () => {
    const { levelName, levelNum } = this.state
    if (levelName === 'beginner' || levelName === 'intermediate' || levelName === 'advanced') {
      this.props.onChange(levelName)
    } else {
      this.props.onChange([levelName, levelNum].join('-'))
    }
  }

  render() {
    const { levelName, levelNum, levelMin, levelMax } = this.state

    return (
      <div className="row mt-3 border rounded py-2">
        <div className="col">
          <FormControl className="w-100">
            <InputLabel htmlFor="class_type">Level</InputLabel>
            <Select name={'levelName'} value={levelName} onChange={this.handleChange} input={<Input id="levelName" />}>

              <MenuItem value={'lower-secondary'}>Lower secondary</MenuItem>
              <MenuItem value={'upper-secondary'}>Upper secondary</MenuItem>
              <MenuItem value={'higher-education'}>Higher education</MenuItem>
              <MenuItem value={'others'}>Others</MenuItem>
              <MenuItem style={{display:'none'}} value={'primary'}>Primary</MenuItem>
              <MenuItem style={{display:'none'}} value={'secondary'}>Secondary</MenuItem>
              <MenuItem style={{display:'none'}} value={'junior-college'}>JC</MenuItem>
              <MenuItem style={{display:'none'}} value={'integrated-programme'}>IP</MenuItem>
              <MenuItem style={{display:'none'}} value={'international-baccalaureate-diploma-programme'}>IB</MenuItem>
              <MenuItem style={{display:'none'}} value={'beginner'}>Beginner</MenuItem>
              <MenuItem style={{display:'none'}} value={'intermediate'}>Intermediate</MenuItem>
              <MenuItem style={{display:'none'}} value={'advanced'}>Advanced</MenuItem>
            </Select>
          </FormControl>
        </div>
        {levelName !== 'beginner' && levelName !== 'intermediate' && levelName !== 'advanced' && (
          <div className="col-3">
            <TextField
              type={'number'}
              name={'levelNum'}
              value={levelNum}
              inputProps={{ min: levelMin, max: levelMax, step: 1 }}
              onChange={this.handleChange}
              label={'Year'}
              fullWidth
            />
          </div>
        )}
        {this.props.index !== 0 && (
          <div className="col-auto d-flex align-items-center">
            <Button variant={'text'} className={'text-danger text-capitalize'} onClick={this.props.onDelete}>
              Remove
            </Button>
          </div>
        )}
      </div>
    )
  }
}

LevelSelector.propTypes = {
  level: Proptypes.string.isRequired,
  onChange: Proptypes.func,
  onDelete: Proptypes.func
}

class AddClass extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value.trim() })
    }

    this.handleCKEditorChange = evt => {
      let newContent = evt.editor.getData()
      this.setState({ description: newContent })
    }

    this.handleArrayChange = index => e => {
      const original = this.state[e.target.name]
      let clone = _.cloneDeep(original)
      clone[index] = e.target.value
      this.setState({ [e.target.name]: clone })
    }

    this.handleCheckboxChange = (e, checked) => {
      this.setState({ [e.target.name]: checked })
    }

    this.handleLevelChange = index => levelStr => {
      const { level } = this.state

      let arrCopy = _.clone(level)
      arrCopy[index] = levelStr

      this.setState({ level: arrCopy })
    }

    this.editLevelTags = (e, index) => {
      const { level } = this.state

      let arrCopy = _.clone(level)
      if (typeof index !== 'undefined' && index !== null) {
        arrCopy.splice(index, 1)
      } else if (arrCopy.length > 0) {
        arrCopy.push(arrCopy[arrCopy.length - 1])
      } else {
        arrCopy.push('primary-1')
      }

      this.setState({ level: arrCopy })
    }

    this.toggleTutorSelectModal = index => () => {
      this.setState({
        tutorSelectModalOpen: !this.state.tutorSelectModalOpen,
        targetIndex: index
      })
    }

    this.toggleTASelectModal = index => () => {
      this.setState({
        taSelectModalOpen: !this.state.taSelectModalOpen,
        targetIndex: index
      })
    }

    this.selectTutor = selectedTutor => () => {
      let { tutor, targetIndex } = this.state
      let tutorCopy = [...tutor]

      if (typeof targetIndex === 'undefined') {
        tutorCopy.push(selectedTutor)
      } else {
        tutorCopy[targetIndex] = selectedTutor
      }

      this.setState({ tutor: tutorCopy })
      this.toggleTutorSelectModal()
      this.clearSearch()
    }

    this.removeTutor = index => () => {
      let { tutor } = this.state

      let tutorCopy = [...tutor]

      tutorCopy.splice(index, 1)

      this.setState({ tutor: tutorCopy })
    }

    this.selectTA = selectedTA => () => {
      let { ta, targetIndex } = this.state
      let taCopy = [...ta]

      if (typeof targetIndex === 'undefined') {
        taCopy.push(selectedTA)
      } else {
        taCopy[targetIndex] = selectedTA
      }

      this.setState({ ta: taCopy })
      this.toggleTASelectModal()
      this.clearSearch()
    }

    this.removeTA = index => () => {
      let { ta } = this.state

      let taCopy = [...ta]

      taCopy.splice(index, 1)

      this.setState({ ta: taCopy })
    }

    this.onChangeSearch = e => {
      this.setState({ searchTerm: e.target.value })
    }

    this.searchUser = e => {
      e.preventDefault()
      const { searchTerm, searchField } = this.state
      let filter = { [searchField]: { $regex: `${searchTerm}`, $options: 'i' } }
      this.props.getUsers(filter, 0, e.target.user_type.value)
    }

    this.clearSearch = () => {
      this.setState({ searchTerm: '' })
      this.props.getUsers({}, 0, 'Tutor')
    }

    const {
      _id,
      name,
      subject,
      country,
      class_type,
      desc,
      tutor,
      ta,
      start_date,
      end_date,
      schedule,
      level,
      selected,
      is_free,
      is_trial,
      is_featured,
      rate,
      currency,
      is_listed,
      is_sold_out,
      force_full_purchase,
      max_cap
    } = props.tClass

    this.state = {
      _id,
      name,
      subject,
      country: country ? country : 'SG',
      class_type: class_type ? class_type : 'Workshop',
      description: !!desc ? desc : '',
      start_date: !!start_date ? moment(start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      end_date: !!end_date ? moment(end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      start_time: !!schedule
        ? moment(schedule.start_time).format('YYYY-MM-DDTHH:mm')
        : moment().format('YYYY-MM-DDTHH:mm'),
      end_time: !!schedule
        ? moment(schedule.end_time).format('YYYY-MM-DDTHH:mm')
        : moment().add(1, 'h').format('YYYY-MM-DDTHH:mm'),
      date_of_week: !!schedule ? schedule?.date_of_week?.toString() : '',
      level: !!level ? level : [''],
      selected,
      is_free: !!is_free,
      is_trial: !!is_trial,
      is_featured: !!is_featured,
      is_listed: is_listed !== false ? true : is_listed,
      is_sold_out: !!is_sold_out,
      force_full_purchase: !!force_full_purchase,
      max_cap: max_cap ? max_cap : 20,
      rate: rate ? rate : 18,
      currency: currency ? currency : 'SGD',
      tutorSelectModalOpen: false,
      taSelectModalOpen: false,
      tutor: tutor ? tutor : [],
      ta: ta ? ta : [],
      searchTerm: '',
      searchField: 'name'
    }
  }

  formulateClassData = () => {
    const {
      _id,
      name,
      subject,
      country,
      class_type,
      description,
      start_date,
      end_date,
      start_time,
      end_time,
      date_of_week,
      level,
      is_free,
      is_trial,
      is_featured,
      is_listed,
      is_sold_out,
      max_cap,
      force_full_purchase,
      rate,
      currency,
      tutor,
      ta
    } = this.state

    let classData = {
      _id,
      name,
      subject,
      country,
      class_type,
      desc: description,
      start_date: moment(`${start_date} ${start_time}`, 'YYYY-MM-DD HH:mm').toISOString(),
      end_date: moment(`${end_date} ${end_time}`, 'YYYY-MM-DD HH:mm').toISOString(),
      schedule: {
        start_time: moment(start_time).toISOString(),
        end_time: moment(end_time).toISOString(),
        date_of_week
      },
      level,
      is_free,
      is_trial,
      is_featured,
      is_listed,
      is_sold_out,
      force_full_purchase,
      max_cap,
      rate,
      currency,
      tutor:
        tutor.length > 0
          ? tutor.map(t => {
              return t._id
            })
          : null,
      ta:
        ta.length > 0
          ? ta.map(t => {
              return t ? t._id : ''
            })
          : null
    }

    for (let key in classData) {
      if (classData.hasOwnProperty(key) && key !== '_id' && key !== 'desc' && key !== 'ta') {
        if (classData[key] === null || typeof classData[key] === 'undefined' || classData[key] === '') {
          return false
        }
      }
    }

    return classData
  }

  componentDidMount() {
    this.props.getUsers({}, 0, 'Tutor')
    this.props.getUsers({}, 0, 'TA')
  }

  render() {
    const { onSaveClass, onClassClose, open, tClass, tutors, TAs } = this.props
    const {
      name,
      subject,
      country,
      class_type,
      currency,
      rate,
      description,
      start_date,
      end_date,
      level,
      start_time,
      end_time,
      date_of_week,
      is_trial,
      is_featured,
      is_listed,
      is_sold_out,
      max_cap,
      tutorSelectModalOpen,
      taSelectModalOpen,
      tutor,
      ta,
      searchTerm,
      searchField,
      force_full_purchase
    } = this.state

    const toolbarGroups = [
      { name: 'basicstyles', groups: ['basicstyles', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'align', 'bidi', 'links'] }
    ]

    const CkConfig = {
      toolbarGroups
    }

    return (
      <Dialog disableEnforceFocus open={open} onClose={onClassClose} fullScreen={true}>
        <AppBar className="position-relative">
          <Toolbar>
            <IconButton onClick={onClassClose} aria-label="Close" className={'text-white'}>
              <CloseIcon />
            </IconButton>
            <Typography
              type="title"
              color="inherit"
              style={{
                flex: 1
              }}>
              {tClass.name === '' ? 'Add Class' : 'Save Class'}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '24px' }}>
          <div className="row">
            <div className="col-md-6">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2>Class Details</h2>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="Name"
                    name={'name'}
                    onChange={this.handleChange}
                    defaultValue={name}
                    margin="none"
                  />
                </FormControl>
                <FormControl className="w-100 mt-3">
                  <TextField
                    required
                    label="Subject"
                    name={'subject'}
                    onChange={this.handleChange}
                    value={subject}
                    margin="none"
                  />
                </FormControl>
                {/* <FormControl className="w-100 mt-3">
                  <InputLabel htmlFor="class_type">Country</InputLabel>
                  <Select name={'country'} value={country} onChange={this.handleChange} input={<Input id="country" />}>
                    <MenuItem value={'SG'}>Singapore</MenuItem>
                    <MenuItem value={'US'}>United State</MenuItem>
                    <MenuItem value={'ID'}>Indonesia</MenuItem>
                  </Select>
                </FormControl> */}
                {/* <FormControl className="w-100 mt-3">
                  <InputLabel htmlFor="class_type">Class Type</InputLabel>
                  <Select
                    name={'class_type'}
                    value={class_type}
                    onChange={this.handleChange}
                    input={<Input id="class_type" />}>
                    <MenuItem value={'Workshop'}>Workshop</MenuItem>
                    <MenuItem value={'Regular Classes'}>Regular Classes</MenuItem>
                  </Select>
                </FormControl> */}
                {level.map((levelStr, index) => {
                  return (
                    <LevelSelector
                      key={`${index}`}
                      index={index}
                      level={levelStr}
                      onChange={this.handleLevelChange(index)}
                      onDelete={this.editLevelTags.bind(this, index)}
                    />
                  )
                })}
                <Button color={'secondary'} variant={'text'} fullWidth onClick={this.editLevelTags}>
                  Add level tag
                </Button>
                <h3>Class Overview</h3>
                <FormControl className={'w-100 mt-3'}>
                  <CKEditor
                    name={'description'}
                    activeClass="p10"
                    content={description}
                    config={CkConfig}
                    events={{
                      change: this.handleCKEditorChange
                    }}
                  />
                </FormControl>
              </Paper>
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2>Assign Teacher to class</h2>
                {tutor.length > 0 &&
                  tutor.map((t, index) => {
                    return (
                      <div key={t._id} className="row">
                        <div className="col d-flex align-items-center">
                          <h3>
                            <span className={'text-capitalize'}>{t.name}</span>
                            <div className="text-muted">
                              <small>{t.email}</small>
                            </div>
                          </h3>
                        </div>
                        <div className="col-auto">
                          <Button variant={'contained'} color={'default'} onClick={this.toggleTutorSelectModal(index)}>
                            Change teacher
                          </Button>
                          <Button className={'ml-2'} onClick={this.removeTutor(index)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                <Button variant={'contained'} color={'primary'} onClick={this.toggleTutorSelectModal()}>
                  Add Teacher
                </Button>
                {/*Select Tutor Dialog*/}
                <Dialog onClose={this.toggleTutorSelectModal()} open={tutorSelectModalOpen} fullWidth={true}>
                  <DialogTitle>Select a teacher</DialogTitle>
                  <div className="app-wrapper pt-0">
                    <form onSubmit={this.searchUser}>
                      <input type="hidden" name="user_type" value="Tutor" />
                      <div className="row">
                        <div className="col">
                          <TextField
                            label={'Tutor Name/Email'}
                            value={searchTerm}
                            onChange={this.onChangeSearch}
                            fullWidth
                          />
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'submit'} variant={'contained'}>
                            Search
                          </Button>
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'button'} variant={'text'} onClick={this.clearSearch}>
                            Clear
                          </Button>
                        </div>
                        <div className="col-12">
                          <FormControl component="fieldset" required>
                            <RadioGroup
                              className="d-flex flex-row"
                              aria-label="searchField"
                              name="searchField"
                              value={searchField}
                              onChange={this.handleChange}>
                              <FormControlLabel value="name" control={<Radio color="primary" />} label="Name" />
                              <FormControlLabel value="email" control={<Radio color="primary" />} label="Email" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {!!tutors ? (
                      tutors.length > 0 ? (
                        <List disablePadding={true}>
                          {tutors.map(user => (
                            <ListItem button onClick={this.selectTutor(user)} key={user._id}>
                              <ListItemText primary={user.name} secondary={user.email} />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <div className="app-wrapper">
                          <h4 className={'font-weight-semibold'}>No tutors found</h4>
                        </div>
                      )
                    ) : (
                      <div className="my-5 text-center">
                        <CircularProgress color={'primary'} size={20} />
                      </div>
                    )}
                  </div>
                </Dialog>
                {/*Select Tutor Dialog END*/}
              </Paper>
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2>Assign TA to class</h2>
                {ta.length > 0 &&
                  ta.map((t, index) => {
                    if (!t) {
                      return null
                    }

                    return (
                      <div key={t._id} className="row">
                        <div className="col d-flex align-items-center">
                          <h3>
                            <span className={'text-capitalize'}>{t.name}</span>
                            <div className="text-muted">
                              <small>{t.email}</small>
                            </div>
                          </h3>
                        </div>
                        <div className="col-auto">
                          <Button variant={'contained'} color={'default'} onClick={this.toggleTASelectModal(index)}>
                            Change TA
                          </Button>
                          <Button className={'ml-2'} onClick={this.removeTA(index)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                <Button variant={'contained'} color={'primary'} onClick={this.toggleTASelectModal()}>
                  Add TA
                </Button>
                {/*Select TA Dialog*/}
                <Dialog onClose={this.toggleTASelectModal()} open={taSelectModalOpen} fullWidth={true}>
                  <DialogTitle>Select a TA</DialogTitle>
                  <div className="app-wrapper pt-0">
                    <form onSubmit={this.searchUser}>
                      <input type="hidden" name="user_type" value="TA" />
                      <div className="row">
                        <div className="col">
                          <TextField
                            label={'Tutor Name/Email'}
                            value={searchTerm}
                            onChange={this.onChangeSearch}
                            fullWidth
                          />
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'submit'} variant={'contained'}>
                            Search
                          </Button>
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'button'} variant={'text'} onClick={this.clearSearch}>
                            Clear
                          </Button>
                        </div>
                        <div className="col-12">
                          <FormControl component="fieldset" required>
                            <RadioGroup
                              className="d-flex flex-row"
                              aria-label="searchField"
                              name="searchField"
                              value={searchField}
                              onChange={this.handleChange}>
                              <FormControlLabel value="name" control={<Radio color="primary" />} label="Name" />
                              <FormControlLabel value="email" control={<Radio color="primary" />} label="Email" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {!!TAs ? (
                      TAs.length > 0 ? (
                        <List disablePadding={true}>
                          {TAs.map(user => (
                            <ListItem button onClick={this.selectTA(user)} key={user._id}>
                              <ListItemText primary={user.name} secondary={user.email} />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <div className="app-wrapper">
                          <h4 className={'font-weight-semibold'}>No TA found</h4>
                        </div>
                      )
                    ) : (
                      <div className="my-5 text-center">
                        <CircularProgress color={'primary'} size={20} />
                      </div>
                    )}
                  </div>
                </Dialog>
                {/*Select TA Dialog END*/}
              </Paper>
            </div>
            <div className="col-md-6">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2>Class Length</h2>
                <div className="row">
                  <div className="col-md-6">
                    <FormControl className="w-100 mb-3">
                      <TextField
                        fullWidth
                        label={'Start Date'}
                        value={start_date}
                        name={'start_date'}
                        type={'date'}
                        onChange={this.handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-6">
                    <FormControl className="w-100 mb-3">
                      <TextField
                        type={'date'}
                        fullWidth
                        label={'End Date'}
                        name={'end_date'}
                        value={end_date}
                        onChange={this.handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
              </Paper>
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2>General Lesson Schedule</h2>
                <TextField
                  label="Day of week (Example: Monday, Wednesday)"
                  name="date_of_week"
                  onChange={this.handleChange}
                  defaultValue={date_of_week}
                  fullWidth
                  className="mb-3"
                />

                <div className="row mb-3">
                  <div className="col-6">
                    <TextField
                      label="1st Lesson Start Time"
                      type="datetime-local"
                      name="start_time"
                      fullWidth
                      value={start_time}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      label="1st Lesson End Time"
                      type="datetime-local"
                      name="end_time"
                      fullWidth
                      value={end_time}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {/* <FormControl className={'w-100'}>
                  <div className="row">
                    <div className="col-auto d-flex">
                      <FormControl className="w-100">
                        <InputLabel htmlFor="class_type">Currency</InputLabel>
                        <Select
                          name="currency"
                          value={currency}
                          onChange={this.handleChange}
                          input={<Input id="currency" />}>
                          <MenuItem value="SGD">SGD ($)</MenuItem>
                          <MenuItem value="USD">USD ($)</MenuItem>
                          <MenuItem value="CNY">CNY (&#20803;)</MenuItem>
                          <MenuItem value="IDR">IDR</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col">
                      <TextField
                        name={'rate'}
                        label={'Cost per lesson'}
                        type={'number'}
                        value={rate}
                        inputProps={{ min: 1 }}
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </div>
                  </div>
                </FormControl> */}
              </Paper>
              {/* <Paper elevation={2} className={'app-wrapper'}> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={'is_trial'}
                      checked={is_trial}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Free trial available"
                /> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={'is_featured'}
                      checked={is_featured}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Featured class"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={'is_listed'}
                      checked={is_listed}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Listed on catalogue"
                /> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={'is_sold_out'}
                      checked={is_sold_out}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Sold Out"
                /> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={'force_full_purchase'}
                      checked={force_full_purchase}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Force Full Class Purchase"
                /> */}
              {/* </Paper> */}

              {/* <Paper elevation={2} className={'app-wrapper my-3'}>
                <h2>Enter max capacity for students (For Zoom)</h2>
                <TextField
                  label="For Zoom webinars, put above 100"
                  name="max_cap"
                  onChange={this.handleChange}
                  defaultValue={max_cap}
                  fullWidth
                  helperText={'*Leave at 20 for regular class'}
                  className="mb-3"
                />
              </Paper> */}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!this.formulateClassData()}
            variant="contained"
            color="primary"
            onClick={() => {
              onClassClose()
              onSaveClass(this.formulateClassData())
            }}>
            Save Class
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClass)
