import React from 'react'
import agent from 'agent'
import { connect } from 'react-redux'
import { store } from 'store'
import { push } from 'connected-react-router'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { UncontrolledAlert } from 'reactstrap'
import * as Type from 'constants/actionTypes'

const mapStateToProps = state => ({
  ...state.auth,
  redirectTo: state.common.redirectTo
})

const mapDispatchToProps = dispatch => ({
  onSubmit: (email, password) => dispatch({ type: Type.LOGIN, payload: agent.Auth.login(email, password) }),
  onRedirect: () => dispatch({ type: Type.REDIRECT })
})

class Login extends React.Component {
  constructor() {
    super()

    this.loginUser = e => {
      e.preventDefault()
      this.props.onSubmit(this.state.email, this.state.password)
    }

    this.state = {
      email: '',
      password: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.redirectTo !== this.props.redirectTo) {
      store.dispatch(push(this.props.redirectTo))
      this.props.onRedirect()
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <img src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/EPA+Extended+Logo+Dark+Background+(Transparent).png" width="225" height="65" alt="EPA" title="EPA" />
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-3">
              <h1>Login</h1>
            </div>
            {this.props.errors && (
              <UncontrolledAlert className="bg-danger text-white shadow-lg">
                You have entered the wrong email or password
              </UncontrolledAlert>
            )}
            <div className="app-login-form">
              <form onSubmit={this.loginUser}>
                <fieldset>
                  <TextField
                    label="Email"
                    name={'email'}
                    fullWidth
                    onChange={this.handleChange}
                    defaultValue={this.state.email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label="Password"
                    name={'password'}
                    fullWidth
                    onChange={this.handleChange}
                    defaultValue={this.state.password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button variant="contained" color="primary" type={'submit'}>
                      Sign In
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
