import React from 'react'
import agent from 'agent'
import { connect } from 'react-redux'
import * as Type from 'constants/actionTypes'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onSetPage: (page, payload) => dispatch({ type: Type.LOAD_BY_PAGE, page, payload })
})

const Pagination = props => {
  if (props.itemsCount <= 20) {
    return null
  }

  const range = []
  for (let i = 0; i < Math.ceil(props.itemsCount / 20); ++i) {
    range.push(i)
  }

  const setPage = page => {
    if (props.itemType === 'PaymentHistories') {
      props.onSetPage(page, agent.Payment.getAllHistories(page))
    } else if (props.itemType === 'DiscountCodes') {
      props.onSetPage(page, agent.DiscountCode.getAllOfType('general', page))
    } else if (props.itemType === 'Orders') {
      props.onSetPage(page, agent.Orders.getAll(page))
    } else if (props.itemType === 'Classes') {
      props.onSetPage(page, agent.Classroom.getClasses(props.itemFilter, 10, page))
    }
  }

  const onFirstClick = ev => {
    ev.preventDefault()
    setPage(0)
  }

  const onPrevClick = ev => {
    ev.preventDefault()
    if (props.currentPage > 0) {
      setPage(props.currentPage - 1)
    }
  }

  const onNextClick = ev => {
    ev.preventDefault()
    if (props.currentPage < range.length) {
      setPage(props.currentPage + 1)
    }
  }

  const onLastClick = ev => {
    ev.preventDefault()
    setPage(range.length - 1)
  }

  return (
    <ul className="pagination">
      {range.length > 7 && (
        <li className={'page-item'} onClick={onFirstClick}>
          <span className="page-link">First</span>
        </li>
      )}

      {range.length > 7 && (
        <li className={props.currentPage === 0 ? 'page-item disabled' : 'page-item'} onClick={onPrevClick}>
          <span className="page-link">Prev</span>
        </li>
      )}

      {range.map(v => {
        const isCurrent = v === props.currentPage
        const onClick = ev => {
          ev.preventDefault()
          setPage(v)
        }

        if (v < 7 && props.currentPage < 4) {
          return (
            <li className={isCurrent ? 'page-item active' : 'page-item'} onClick={onClick} key={v.toString()}>
              <span className="page-link">{v + 1}</span>
            </li>
          )
        } else if (props.currentPage > range.length - 4 && v > range.length - 8) {
          return (
            <li className={isCurrent ? 'page-item active' : 'page-item'} onClick={onClick} key={v.toString()}>
              <span className="page-link">{v + 1}</span>
            </li>
          )
        } else if (props.currentPage >= 4 && v > props.currentPage - 4 && v < props.currentPage + 4) {
          return (
            <li className={isCurrent ? 'page-item active' : 'page-item'} onClick={onClick} key={v.toString()}>
              <span className="page-link">{v + 1}</span>
            </li>
          )
        }
        return null
      })}

      {range.length > 7 && (
        <li
          className={props.currentPage === range.length - 1 ? 'page-item disabled' : 'page-item'}
          onClick={onNextClick}>
          <span className="page-link">Next</span>
        </li>
      )}

      {range.length > 7 && (
        <li className={'page-item'} onClick={onLastClick}>
          <span className="page-link">Last</span>
        </li>
      )}
    </ul>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
