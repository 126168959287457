import React from 'react'
import Pluralize from 'react-pluralize'
import { Button, Card } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

class OrderItem extends React.Component {
  render() {
    const { order, currentUser } = this.props

    if (!order) {
      return null
    }

    let lessonList = order.detail.lessonList
    let numberOfLessons = lessonList.length
    let billable = !!order.detail.billable_lesson ? order.detail.billable_lesson : []
    let unbilled = _.difference(order.detail.lesson, billable)
    let inCol = Math.ceil(numberOfLessons / 3)
    lessonList = _.orderBy(lessonList, ['scheduled_on'], ['asc'])

    let chunkedLessons = _.chunk(lessonList, inCol)

    return (
      <Card className="p-3 mb-2">
        <div className="row">
          <div className="col-md-4 d-flex flex-column">
            <div className="mb-3">
              {currentUser._id === 'MxzTuxI-TAeJi1Yg0_6drg' && <div>{order._id}</div>}
              <div className="font-weight-semibold">{moment(order.created).format('D MMM YYYY, ddd, h:mma')}</div>
              <div className="text-capitalize"></div>
              <div
                className={
                  order.status === 'PROCESSED' || order.status === 'PAID'
                    ? 'p-2 mt-3 text-center font-weight-semibold text-white bg-success'
                    : order.status === 'CANCELLED'
                    ? 'p-2 mt-3 text-center font-weight-semibold bg-light'
                    : 'p-2 mt-3 text-center font-weight-semibold text-white bg-danger'
                }>
                {order.status === 'PAID' || order.status === 'PROCESSED' ? 'ASSIGNED' : order.status}
              </div>
              {order.payment?.completed_on && (
                <div className="text-muted">Assigned on: {moment(order.payment.completed_on).format('D MMM YYYY')}</div>
              )}
            </div>

            {!!order.remark && <div className="border-top border-bottom py-2">{order.remark}</div>}

            <div className="text-center mt-auto">
              {order.status !== 'CANCELLED' && (
                <Button variant="outlined" onClick={this.props.triggerCancelOrder} fullWidth>
                  Cancel Allocation
                </Button>
              )}
            </div>
          </div>
          <div className="col-md-8">
            <Card className="p-3 mb-3 rounded-top-">
              <div className="text-muted">
                <small>Name</small>
              </div>
              <h3 className="font-weight-semibold mb-1">{order.created_by ? order.created_by?.name : 'N/A'}</h3>
              <div className="row">
                <div className="col">
                  <div className="text-muted">
                    <small>Email</small>
                  </div>
                  {order.created_by ? order.created_by?.email : 'NULL'}
                </div>
                <div className="col">
                  <div className="text-muted">
                    <small>Phone</small>
                  </div>
                  (+{order.created_by ? order.created_by?.phone_country_code : 'NULL'})&nbsp;
                  {order.created_by ? order.created_by?.phone : 'NULL'}
                </div>
              </div>
            </Card>

            <div>
              <Pluralize singular={'lesson'} count={numberOfLessons} />
            </div>
            <h3>{order.detail?.class_obj?.name}</h3>
            <div className="row text-muted">
              {chunkedLessons.map((chunk, index) => {
                return (
                  <div key={index} className="col-md-4 border-right">
                    {chunk.map(lesson => {
                      return (
                        <div key={lesson._id} className="mb-2">
                          {lesson.name}
                          {unbilled.includes(lesson._id) && (
                            <span className={'d-inline-block px-3 border rounded border-warning text-warning ml-2'}>
                              Trial
                            </span>
                          )}
                          <div>
                            <small>{moment(lesson.scheduled_on).format('ddd h:ma, D MMM YYYY')}</small>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

OrderItem.propTypes = {
  order: PropTypes.object.isRequired
}

export default OrderItem
