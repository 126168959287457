import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import {
  GET_LESSONS,
  CREATE_LESSON_POLL,
  EDIT_LESSON_POLL,
  DELETE_LESSON_POLL,
  SET_ACTIVE_LESSON
} from 'constants/actionTypes'
import moment from 'moment'
import { ROUTE_LESSON_DETAIL_ZOOM } from 'routes/paths'
import qs from 'qs'

const mapStateToProps = state => ({
  lessonList: state.lessons.lessons,
  isLoading: state.lessons.isLoading
})

const mapDispatchToProps = dispatch => ({
  getLessonList: day =>
    dispatch({
      type: GET_LESSONS,
      payload: agent.Lesson.getLessonList({
        is_active: true,
        ended_on: {
          $gte: moment(day).startOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]'),
          $lte: moment(day).endOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]')
        }
      })
    }),
  createPollQuestion: (lessonId, pollObj) =>
    dispatch({ type: CREATE_LESSON_POLL, payload: agent.Lesson.createPollQuestion(lessonId, pollObj) }),
  editPollQuestion: (lessonId, questionIndex, pollObj) =>
    dispatch({ type: EDIT_LESSON_POLL, payload: agent.Lesson.editPollQuestion(lessonId, questionIndex, pollObj) }),
  deletePollQuestion: (lessonId, questionIndex) =>
    dispatch({ type: DELETE_LESSON_POLL, payload: agent.Lesson.deletePollQuestion(lessonId, questionIndex) }),
  setActiveLesson: lesson => dispatch({ type: SET_ACTIVE_LESSON, lesson })
})

class LessonManagement extends React.Component {
  state = {
    day: moment().format('YYYY-MM-DD'),
    selectedLessonId: '',
    selectedQuestionIndex: '',
    selectedPoll: []
  }

  componentDidMount() {
    let { day } = this.state
    let searchParams = qs.parse(this.props.location.search.slice(1))
    if (searchParams.date) {
      day = searchParams.date
    }

    this.props.getLessonList(day)
    this.setState({ day })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.day !== this.state.day) {
      this.props.getLessonList(this.state.day)
    }
  }

  handleDateChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  searchLessons = day => () => {
    this.setState({ day })
  }

  goToDetailPage = lessonData => () => {
    this.props.setActiveLesson({ lesson: lessonData })
    if (lessonData.hosting_platform === 'Zoom') {
      this.props.history.push(ROUTE_LESSON_DETAIL_ZOOM.replace(':lessonId', lessonData._id))
    }
  }

  render() {
    let { lessonList, isLoading } = this.props
    let { day } = this.state

    return (
      <div className="app-wrapper">
        <h1>Quick Lesson Management</h1>

        <div className="row justify-content-center mb-3">
          <div className="col-md-auto col-12">
            <Button
              className="mr-4"
              variant="outlined"
              color="primary"
              onClick={this.searchLessons(moment(day).subtract(1, 'd').format('YYYY-MM-DD'))}
              disabled={isLoading}>
              Prev Day
            </Button>
            <TextField type={'date'} label={'Day'} name={'day'} value={day} onChange={this.handleDateChange} />
            <Button
              className="ml-4"
              variant="outlined"
              color="primary"
              onClick={this.searchLessons(moment(day).add(1, 'd').format('YYYY-MM-DD'))}
              disabled={isLoading}>
              Next Day
            </Button>
          </div>
        </div>

        {this.props.isLoading && (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        {lessonList && (
          <Paper elevation={2}>
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th scope="col">Student name</th>
                  <th scope="col">Teacher name</th>
                  <th scope="col">Class name</th>
                  <th scope="col">Lesson name</th>
                  <th scope="col">Time</th>
                  {/* <th scope="col">Poll</th>
                  <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {lessonList.map(p => (
                  <tr key={p._id}>
                    <td>{p.student[0]?.name}</td>
                    <td>{p.tutor[0]?.name}</td>
                    <td>{p.class?.name?.slice(6)}</td>
                    <td>{p.name}</td>
                    <td>{moment(p.scheduled_on).format('lll')}</td>
                    {/* <td>{p.poll_setup?.length} questions</td> */}
                    {/* <td>
                      <Button color="primary" variant="contained" onClick={this.goToDetailPage(p)}>
                        Edit
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </Paper>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonManagement)
