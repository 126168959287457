import React from 'react'
import moment from 'moment'
import agent from 'agent'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import {
  CREATE_CONSULTATION,
  GET_CONSULTATION,
  UPDATE_CONSULTATION
} from 'constants/actionTypes'
import { Link } from 'react-router-dom'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core'
import _ from 'lodash'
import DialogStudentProfile from 'components/shared/DialogStudentProfile'

const mapStateToProps = state => ({
  isLoading: state.common.isLoading,
  currentUser: state.common.currentUser,
  studentCount: state.consultation.studentCount,
  consultationData: state.consultation.consultationData,
  updateinProgress: state.consultation.updateinProgress
})

const mapDispatchToProps = dispatch => ({
  updateConsultation: (id, checked) =>
    dispatch({ type: UPDATE_CONSULTATION, payload: agent.Consultation.updateConsultation(id, { checked }) }),

  createConsultation: reqPayload =>
    dispatch({
      type: CREATE_CONSULTATION,
      payload: agent.Consultation.createConsultation(reqPayload)
    }),
  getConsultation: day =>
    dispatch({
      type: GET_CONSULTATION,
      payload: agent.Consultation.getConsultation({
        date: {
          $gte: moment(day).startOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]'),
          $lte: moment(day).endOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]')
        }
      })
    })
})

class ConsultationTab extends React.Component {
  state = {
    studentDetailsDialog: {},
    isStudentDetailsDialogOpen: false,
    day: moment().subtract(1, 'months').format('YYYY-MM-DD')
  }

  componentDidMount() {
    let { day } = this.state
    this.props.getConsultation(day)
    this.setState({ day })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.day !== this.state.day || prevProps.updateinProgress !== this.props.updateinProgress) {
      this.props.getConsultation(this.state.day)
    }
  }

  handleCheckboxChange = (e, checked) => {
    let id = e.target.name
    this.props.updateConsultation(id, checked)
  }

  openStudentDetails = user => {
    this.setState({ studentDetailsDialog: user, isStudentDetailsDialogOpen: !this.state.isStudentDetailsDialogOpen })
  }

  handleDateChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  searchUsers = day => () => {
    this.setState({ day })
  }

  render() {
    const { studentDetailsDialog, isStudentDetailsDialogOpen, day } = this.state

    const { activeTab, studentDetails, isLoading, studentList, studentCount, currentUser, consultationData } =
      this.props

    const isKruA = currentUser?._id === 'hhfu4lg4TBmPQ5FcLIt9lA'

    return (
      <>
        <div className="row">
          <div className="col-3"> </div>

          <div className="row justify-content-center col-6 my-3" hidden={activeTab !== 'consultation'}>
            <div className="col-md-auto col-12">
              <Button
                className="mr-4"
                variant="outlined"
                color="primary"
                onClick={this.searchUsers(moment(day).subtract(1, 'd').format('YYYY-MM-DD'))}
                disabled={isLoading}>
                Prev Day
              </Button>
              <TextField type={'date'} label={'Day'} name={'day'} value={day} onChange={this.handleDateChange} />
              <Button
                className="ml-4"
                variant="outlined"
                color="primary"
                onClick={this.searchUsers(moment(day).add(1, 'd').format('YYYY-MM-DD'))}
                disabled={isLoading}>
                Next Day
              </Button>
            </div>
          </div>
          <div className="col-3 my-3" hidden={activeTab !== 'consultation'}>
            <Button className="mr-4" variant="outlined" color="primary">
              <Link to={'/user'}>Create consultation</Link>
            </Button>
          </div>
        </div>
        {isLoading && (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        {studentCount === 0 && (
          <div className="my-5 text-center" hidden={activeTab !== 'consultation'}>
            No student consulted on this date ({moment(day).format('LL')})
          </div>
        )}

        {!isLoading && studentCount !== 0 && consultationData && (
          <Table className="mb-5" hidden={activeTab !== 'consultation'}>
            <TableHead>
              <TableRow>
                <TableCell>Student Name</TableCell>
                <TableCell>Student Nickname</TableCell>
                <TableCell>EPA Database ID</TableCell>
                <TableCell>Email of student</TableCell>
                {isKruA && <TableCell>Consultation action plan</TableCell>}{' '}
                <TableCell>Consultation Notes/comments</TableCell>
                <TableCell>Customer Service</TableCell>
                <TableCell>Sales Admin</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consultationData.map((studentsThatHadOneMonthConsultationAgo, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <i
                      className="zmdi zmdi-eye zmdi-hc-fw"
                      onClick={() => this.openStudentDetails(studentsThatHadOneMonthConsultationAgo?.student[0])}></i>
                    {studentsThatHadOneMonthConsultationAgo?.student[0]?.name}
                  </TableCell>
                  <TableCell>{studentsThatHadOneMonthConsultationAgo?.student[0]?.nickname}</TableCell>
                  <TableCell>{studentsThatHadOneMonthConsultationAgo?.student[0]?.dbid}</TableCell>
                  <TableCell>{studentsThatHadOneMonthConsultationAgo?.student[0]?.email}</TableCell>
                  {isKruA && <TableCell>{studentsThatHadOneMonthConsultationAgo?.action_plans}</TableCell>}{' '}
                  <TableCell>{studentsThatHadOneMonthConsultationAgo?.consultation_notes}</TableCell>
                  <TableCell>
                    {studentsThatHadOneMonthConsultationAgo?.student[0]?.staff_to_handle?.customer_service}
                  </TableCell>
                  <TableCell>
                    {studentsThatHadOneMonthConsultationAgo?.student[0]?.staff_to_handle?.sales_admin}
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`${studentsThatHadOneMonthConsultationAgo._id}`}
                            checked={studentsThatHadOneMonthConsultationAgo?.action}
                            color="primary"
                            onChange={this.handleCheckboxChange}
                          />
                        }
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {isStudentDetailsDialogOpen && (
          <DialogStudentProfile
            studentDetailsDialog={studentDetailsDialog}
            isOpen={isStudentDetailsDialogOpen}
            toggle={this.openStudentDetails}
          />
        )}
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultationTab)
