import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress, Tab, Tabs } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import { Rating } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import moment from 'moment'

import agent from 'agent'
import { GET_TUTORS, GET_STUDENTS, GET_USERS } from 'constants/actionTypes'
import permissions, { checkActionPermitted } from 'permissions'
import { ROUTE_USER_MANAGEMENT } from 'routes/paths'
import DialogUserEdit from 'pages/UserManagement/DialogUserEdit'
const superagent = require('superagent')
const NEWS_API = process.env.REACT_APP_NEWS_API_KEY

const mapStateToProps = state => ({
  userList: state.user.userList,
  tutorList: state.user.tutorList,
  isLoading: state.user.isLoading,
  userRole: state.auth.userRole,
  csList: state.user.csList,
  saList: state.user.saList,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  getStudents: reqPayload => dispatch({ type: GET_STUDENTS, payload: agent.User.getStudents(reqPayload) }),
  getTutors: reqPayload => dispatch({ type: GET_TUTORS, payload: agent.User.getTutors(reqPayload) }),
  getUsers: (filter, offset, userType) =>
    dispatch({ type: GET_USERS, payload: agent.User.getUsers({ ...filter, _type: userType }, offset), userType })
})

const StudentTable = props => {
  const { userList, renderEditButton, rating, isKruA } = props
  let columns
  if (userList && userList.length > 0) {
    if (isKruA) {
      columns = [
        {
          field: 'edit',
          headerName: 'Edit',
          renderCell: renderEditButton
        },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150, editable: false },
        { field: 'nickname', headerName: 'Nickname', flex: 1, minWidth: 150, editable: false },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
          minWidth: 220,
          align: 'left',
          headerAlign: 'left'
        },
        {
          field: 'phone',
          headerName: 'Phone',
          flex: 1,
          type: 'number',
          minWidth: 120,
          editable: false,
          align: 'left',
          headerAlign: 'left'
        },
        { field: 'parentName', headerName: 'Parent Name', flex: 1, minWidth: 150, editable: false },
        {
          field: 'parentEmail',
          headerName: 'Parent Email',
          flex: 1,
          minWidth: 220,
          align: 'left',
          headerAlign: 'left'
        },
        {
          field: 'parentPhone',
          headerName: 'Parent Phone',
          flex: 1,
          type: 'number',
          minWidth: 120,
          editable: false,
          align: 'left',
          headerAlign: 'left'
        },
        {
          field: 'school',
          headerName: 'School',
          flex: 1,
          minWidth: 220,
          editable: false
        },
        {
          field: 'examDate',
          headerName: 'Next exam date',
          width: 120,
          editable: false
        },
        {
          field: 'punctual',
          headerName: 'Late to class?',
          width: 120,
          editable: false
        },
        {
          field: 'cancelClass',
          headerName: 'Cancel classes often?',
          width: 120,
          editable: false
        },
        {
          field: 'questionEngagement',
          headerName: 'Does student answer questions?',
          width: 120,
          editable: false
        },
        {
          field: 'grades',
          headerName: 'GPA',
          type: 'number',
          flex: 1,
          minWidth: 70,
          editable: false,
          align: 'left',
          headerAlign: 'left'
        },
        {
          field: 'medium',
          headerName: 'Medium of study',
          flex: 1,
          minWidth: 150,
          editable: false
        },
        {
          field: 'courseType',
          headerName: 'Courses taken',
          flex: 1,
          minWidth: 150,
          editable: false
        },
        {
          field: 'examType',
          headerName: 'Exams taken',
          flex: 1,
          minWidth: 150,
          editable: false
        },
        {
          field: 'otherSchool',
          headerName: 'Currently studying with another school?',
          flex: 1,
          minWidth: 150,
          editable: false
        },
        {
          field: 'famous',
          headerName: 'Parents famous/influential?',
          flex: 1,
          minWidth: 130,
          renderCell: rating,
          type: 'number',
          editable: false,
          align: 'left',
          headerAlign: 'left'
        },
        {
          field: 'academicBackground',
          headerName: 'Academic Background',
          flex: 1,
          minWidth: 150,
          editable: true
        },
        {
          field: 'favSubject',
          headerName: 'Favorite Subject',
          flex: 1,
          minWidth: 150,
          editable: false
        },
        {
          field: 'weakestSubject',
          headerName: 'Weakest Subject',
          flex: 1,
          minWidth: 150,
          editable: false
        },
        {
          field: 'specialRequests',
          headerName: 'Special Requests',
          flex: 1,
          minWidth: 150,
          editable: true
        },
        {
          field: 'careerGoals',
          headerName: 'Career Goals',
          flex: 1,
          minWidth: 150,
          editable: true
        },
        {
          field: 'learningStyle',
          headerName: 'Learning Type/Style',
          flex: 1,
          minWidth: 150,
          editable: true
        },
        {
          field: 'amountPaid',
          headerName: 'Amount Paid',
          flex: 1,
          minWidth: 150,
          type: 'number',
          editable: true,
          align: 'left',
          headerAlign: 'left'
        },
        {
          field: 'amountFrequency',
          headerName: 'Amount Frequency',
          flex: 1,
          minWidth: 150,
          editable: true
        },
        { field: 'gender', headerName: 'Gender', flex: 1, minWidth: 80, editable: false },
        {
          field: 'birthday',
          headerName: 'Birthday',
          flex: 1,
          minWidth: 120,
          type: 'date',
          editable: false
        },
        {
          field: 'update',
          headerName: 'Last manual update',
          flex: 1,
          minWidth: 220,
          editable: false
        },
        {
          field: 'edit',
          headerName: 'Edit',
          renderCell: renderEditButton
        }
      ]
    } else {
      columns = [
        {
          field: 'edit',
          headerName: 'Edit',
          renderCell: renderEditButton
        },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150, editable: false },
        { field: 'nickname', headerName: 'Nickname', flex: 1, minWidth: 150, editable: false },
        { field: 'dbid', headerName: 'EPA Database ID', flex: 1, minWidth: 150, editable: false },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
          minWidth: 220,
          align: 'left',
          headerAlign: 'left',
          editable: false
        },
        {
          field: 'phone',
          headerName: 'Phone',
          flex: 1,
          type: 'number',
          minWidth: 120,
          editable: false,
          align: 'left',
          headerAlign: 'left'
        },
        { field: 'parentName', headerName: 'Parent Name', flex: 1, minWidth: 150, editable: false },
        {
          field: 'parentEmail',
          headerName: 'Parent Email',
          flex: 1,
          minWidth: 220,
          align: 'left',
          headerAlign: 'left'
        },
        {
          field: 'parentPhone',
          headerName: 'Parent Phone',
          flex: 1,
          type: 'number',
          minWidth: 120,
          editable: false,
          align: 'left',
          headerAlign: 'left'
        }
      ]
    }

    const rows = []

    {
      userList &&
        userList.map(studentData => {
          let studentGender = ''
          if (studentData.gender === 'm') {
            studentGender = 'male'
          } else if (studentData.gender === 'f') {
            studentGender = 'female'
          }

          let studentFame = ''
          if (studentData.famous === 'never') {
            studentFame = '1'
          } else if (studentData.famous === 'fair') {
            studentFame = '2'
          } else if (studentData.famous === 'famous') {
            studentFame = '3'
          } else if (studentData.famous === 'very-famous') {
            studentFame = '4'
          } else if (studentData.famous === 'vip') {
            studentFame = '5'
          }

          const rowsData = {
            id: studentData._id,
            name: studentData.name ? studentData.name : '',
            nickname: studentData.nickname ? studentData.nickname : '',
            dbid: studentData.dbid? studentData.dbid : '',
            email: studentData.email ? studentData.email : '',
            phone: studentData.phone ? studentData.phone : '',
            parentName: studentData.parent?.name ? studentData.parent?.name : '',
            parentEmail: studentData.parent?.email ? studentData.parent?.email : '',
            parentPhone: studentData.parent?.phone ? studentData.parent?.phone : '',
            school: studentData.school ?? '',
            // firstLanguage: studentData.first_language ?? 'N/A',
            examDate: studentData.examDate ? moment(studentData.examDate).format('ll') : '',
            punctual: studentData.punctual ?? '',
            cancelClass: studentData.cancelClass ?? '',
            questionEngagement: studentData.questionEngagement ?? '',
            grades: studentData.grades ?? '',
            medium:
              studentData.studyMediumEng === true && studentData.studyMediumThai === true
                ? 'English, Thai'
                : studentData.studyMediumEng === true
                ? 'English'
                : studentData.studyMediumThai === true
                ? 'Thai'
                : '',
            courseType: studentData.courseType ?? '',
            examType: studentData.examType ?? '',
            otherSchool:
              studentData.otherSchoolYes === true
                ? `Yes (${studentData.otherSchoolName ?? ''})`
                : studentData.otherSchoolNo === true
                ? 'No'
                : '',
            famous: studentFame ?? '',
            academicBackground: studentData.academicBackground ?? '',
            favSubject: studentData.favSubject ?? '',
            weakestSubject: studentData.weakestSubject ?? '',
            specialRequests: studentData.specialRequests ?? '',
            careerGoals: studentData.careerGoals?.student ?? '',
            studyGoals: studentData.studyGoals ?? '',
            learningStyle: studentData.learningStyle ?? '',
            amountPaid: studentData.amountPaid ?? '',
            amountFrequency: studentData.amountFrequency ?? '',
            gender: studentGender,
            birthday: studentData.date_of_birth ? moment(studentData.date_of_birth).format('ll') : '',
            update: studentData.updated ? moment(studentData.updated).format('llll') : ''
          }
          rows.push(rowsData)
        })
    }

    return (
      <div className="table-responsive-material bg-white">
        <div style={{ flexGrow: 1 }}>
          {
            <DataGrid
              autoHeight
              isRowSelectable={() => false}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
              initialState={{
                pagination: {
                  pageSize: 25
                },
                columns: {
                  columnVisibilityModel: {
                    // school: false,
                    // examDate: false,
                    // punctual: false,
                    // cancelClass: false,
                    // questionEngagement: false,
                    // grades: false,
                    // medium: false,
                    // courseType: false,
                    // examType: false,
                    // otherSchool: false,
                    // famous: false,
                    // academicBackground: false,
                    // favSubject: false,
                    // weakestSubject: false,
                    // specialRequests: false,
                    // careerGoals: false,
                    // studyGoals: false,
                    // learningStyle: false,
                    // amountPaid: false,
                    // amountFrequency: false,
                    // gender: false,
                    // birthday: false
                  }
                }
              }}
              rows={rows}
              columns={columns}
            />
          }
        </div>
      </div>
    )
  }

  return null
}

const TutorTable = props => {
  const { tutorList, renderEditTutorButton } = props

  if (tutorList && tutorList.length > 0) {
    const columns = [
      { field: 'name', headerName: 'Name', flex: 1, minWidth: 220, editable: false },
      { field: 'dbid', headerName: 'EPA Database ID', flex: 1, editable: false },
      { field: 'gender', headerName: 'Gender', width: 80, editable: false },
      { field: 'email', headerName: 'Email', flex: 1, minWidth: 220, editable: false },
      // { field: 'year_of_exp', type: 'number', headerName: 'Years of Experience', width: 180, editable: false },
      // { field: 'credential', headerName: 'Credentials', flex: 1, minWidth: 180, editable: true },
      // { field: 'subject', headerName: 'Subjects', flex: 1, minWidth: 180, editable: true },
      {
        field: 'edit',
        headerName: 'Edit',
        renderCell: renderEditTutorButton
      }
    ]

    const rows = []

    {
      tutorList &&
        tutorList.map(tutorData => {
          let tutorGender = ''
          if (tutorData.gender === 'm') {
            tutorGender = 'male'
          } else if (tutorData.gender === 'f') {
            tutorGender = 'female'
          }
          const rowsData = {
            id: tutorData._id,
            dbid: tutorData.dbid ? tutorData.dbid : '',
            gender: tutorGender ?? '',
            name: tutorData.name,
            email: tutorData.email,
            // year_of_exp: tutorData.credential?.year_of_exp?.length === 0 ? '' : tutorData.credential?.year_of_exp,
            // credential: tutorData.credential?.track_record?.length === 0 ? '' : tutorData.credential?.track_record,
            // subject: tutorData.subject?.length === 0 ? '' : tutorData.subject
          }

          rows.push(rowsData)
        })
    }

    return (
      <div className="table-responsive-material bg-white">
        <div style={{ flexGrow: 1 }}>
          {
            <DataGrid
              autoHeight
              initialState={{
                pagination: {
                  pageSize: 25
                }
              }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
              rows={rows}
              columns={columns}
            />
          }
        </div>
      </div>
    )
  }

  return null
}
class UserManagement extends React.Component {
  constructor(props) {
    super(props)

    this.changeTab = (e, value) => {
      this.setState({ tab: value })
    }

    this.toggleUserDialog = user => () => {
      this.setState({ userDialogOpen: !this.state.userDialogOpen, selectedUser: user })
    }

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.selectTarget = (e, checked) => {
      this.setState({ searchTarget: e.target.value })
    }

    this.state = {
      tab: 'Student',
      userDialogOpen: false,
      userDetailDialogOpen: false,
      selectedUser: null,
      value: 1
    }
  }

  componentDidMount() {
    this.searchUsers()
    this.props.getUsers({}, 0, 'SalesAdmin')
    this.props.getUsers({}, 0, 'CustService')
    this.canManageUser = checkActionPermitted(permissions, this.props.userRole, ROUTE_USER_MANAGEMENT, 'canManage')
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tab !== this.state.tab) {
      this.searchUsers()
    }
  }

  renderEditButton = params => {
    const { userList } = this.props

    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={this.toggleUserDialog(userList.filter(p => p._id === params.row.id)[0])}>
          Edit
        </Button>
      </strong>
    )
  }

  renderEditTutorButton = params => {
    const { tutorList } = this.props

    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={this.toggleUserDialog(tutorList.filter(p => p._id === params.row.id)[0])}>
          Edit
        </Button>
      </strong>
    )
  }

  rating = params => {
    const { userList } = this.props

    return (
      <Rating
        name="read-only"
        value={
          userList.filter(p => p._id === params.row.id)[0].famous === 'never'
            ? 1
            : userList.filter(p => p._id === params.row.id)[0].famous === 'fair'
            ? 2
            : userList.filter(p => p._id === params.row.id)[0].famous === 'famous'
            ? 3
            : userList.filter(p => p._id === params.row.id)[0].famous === 'very-famous'
            ? 4
            : userList.filter(p => p._id === params.row.id)[0].famous === 'vip'
            ? 5
            : 0
        }
        readOnly
      />
    )
  }

  searchUsers = () => {
    const { tab } = this.state

    if (tab === 'Student') {
      this.props.getStudents()
    }
    if (tab === 'Tutor') {
      this.props.getTutors()
    }
  }

  onSave = () => {
    this.toggleUserDialog(null)()
    this.searchUsers()
  }

  render() {
    const { userList, tutorList, currentUser } = this.props
    const { tab, userDialogOpen, selectedUser, value } = this.state
    const isKruA = currentUser?._id === 'hhfu4lg4TBmPQ5FcLIt9lA'
    return (
      <div className="app-wrapper">
        <h1>User Management</h1>
        <div className="row align-items-center my-3">
          <div className="col">
            <Tabs value={tab} onChange={this.changeTab}>
              {/* <Tab className="tab" label="All" value={'all'} /> */}
              <Tab className="tab" label="Student" value={'Student'} />
              <Tab className="tab" label="Tutor" value={'Tutor'} />
            </Tabs>
          </div>
          {this.canManageUser ? (
            <div className="col text-right">
              {tab === 'Tutor' && (
                <Button color={'primary'} variant={'contained'} onClick={this.toggleUserDialog()}>
                  Create New Tutor
                </Button>
              )}{' '}
              {userDialogOpen && (
                <DialogUserEdit
                  toggle={this.toggleUserDialog()}
                  isOpen={userDialogOpen}
                  userData={selectedUser}
                  onSave={this.onSave}
                />
              )}
            </div>
          ) : null}
        </div>
        {/* <div className="d-flex flex-row align-items-center mb-2">
          Click on the Menu icon <MoreVertIcon color="primary" style={{ height: '20px' }} /> to filter and search
        </div> */}
        {this.props.isLoading && (
          <div className="my-5 text-center">
            <CircularProgress color="primary" size={40} />
          </div>
        )}

        {tab === 'Student' && (
          <StudentTable
            userList={userList}
            renderEditButton={this.renderEditButton}
            rating={this.rating}
            isKruA={isKruA}
          />
        )}
        {tab === 'Tutor' && <TutorTable tutorList={tutorList} renderEditTutorButton={this.renderEditTutorButton} />}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
