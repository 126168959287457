/*Routes*/
export const ROUTE_ROOT = '/'
export const ROUTE_USER_MANAGEMENT = '/user'
export const ROUTE_LESSON_PAGE = '/course/:classId/lesson/:lessonId'
export const ROUTE_LESSONS = '/course/:classId'
export const ROUTE_COURSES = '/course'
export const ROUTE_ORDERS = '/orders'
export const ROUTE_FEEDBACK = '/feedback'
export const ROUTE_REPORT = '/report'
export const ROUTE_LESSON_MANAGEMENT = '/lessons'
export const ROUTE_LESSON_DETAIL_ZOOM = '/lessons/:lessonId/detail/zoom'
export const ROUTE_NUMBER_DASHBOARD = '/dashboard'
export const ROUTE_OPS_DASHBOARD = '/ops'
export const ROUTE_PROFILE = '/profile'
