import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import {
  ROUTE_COURSES,
  ROUTE_FEEDBACK,
  ROUTE_ORDERS,
  ROUTE_USER_MANAGEMENT,
  ROUTE_LESSON_MANAGEMENT,
  ROUTE_NUMBER_DASHBOARD,
  ROUTE_OPS_DASHBOARD,
} from 'routes/paths'
import { connect } from 'react-redux'
import { NavItem, NavSubMenu, NavSubmenuItem } from './_SideNavComponents'

const mapStateToProps = state => ({
  userRole: state.auth.userRole,
  currentUser: state.common.currentUser
})

class SideNavContent extends Component {
  goToPage = location => () => {
    this.props.history.push(location)
  }

  render() {
    const { userRole, currentUser } = this.props
    // KRU A id IS hhfu4lg4TBmPQ5FcLIt9lA, superadmin
    const isKruA = currentUser?._id === 'hhfu4lg4TBmPQ5FcLIt9lA'
    return (
      <ul className="nav-menu">
        <li className="nav-header">Main</li>
        <NavItem userRole={userRole} to={'/'}>
          <i className="zmdi zmdi-assignment zmdi-hc-fw" />
          <span className="nav-text">Home</span>
        </NavItem>
        
        <NavItem userRole={userRole} to={ROUTE_NUMBER_DASHBOARD}>
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">Dashboard</span>
        </NavItem>

        <NavItem userRole={userRole} to={ROUTE_USER_MANAGEMENT}>
          <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
          <span className="nav-text">User</span>
        </NavItem>

        <NavItem userRole={userRole} to={ROUTE_COURSES}>
          <i className="zmdi zmdi-desktop-mac zmdi-hc-fw" />
          <span className="nav-text">Class</span>
        </NavItem>

        <NavItem userRole={userRole} to={ROUTE_LESSON_MANAGEMENT}>
          <i className="zmdi zmdi-assignment zmdi-hc-fw" />
          <span className="nav-text">Lesson</span>
        </NavItem>

        <NavItem userRole={userRole} to={ROUTE_ORDERS}>
          <i className="zmdi zmdi-view-day zmdi-hc-fw" />
          <span className="nav-text">New Student/Class allocation</span>
        </NavItem>

        <NavItem userRole={userRole} to={ROUTE_FEEDBACK}>
          <i className="zmdi zmdi-comment-text zmdi-hc-fw" />
          <span className="nav-text">Feedback</span>
        </NavItem>

        <NavItem userRole={userRole} to={ROUTE_OPS_DASHBOARD}>
          <i className="zmdi zmdi-cake zmdi-hc-fw" />
          <span className="nav-text">Birthday Calendar</span>
        </NavItem>
      </ul>
    )
  }
}

export default connect(mapStateToProps)(withRouter(SideNavContent))
