export const LOAD_APP = 'LOAD_APP'
export const LOAD_APP_SUCCESS = 'LOAD_APP_SUCCESS'
export const LOAD_APP_FAILED = 'LOAD_APP_FAILED'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = `${CHANGE_PASSWORD}_SUCCESS`
export const CHANGE_PASSWORD_FAILED = `${CHANGE_PASSWORD}_FAILED`

export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED'

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = `${CREATE_ORDER}_SUCCESS`
export const CREATE_ORDER_FAILED = `${CREATE_ORDER}_FAILED`

export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = `${UPDATE_ORDER}_SUCCESS`
export const UPDATE_ORDER_FAILED = `${UPDATE_ORDER}_FAILED`

export const CANCEL_ORDER = `CANCEL_ORDER`
export const CANCEL_ORDER_SUCCESS = `${CANCEL_ORDER}_SUCCESS`
export const CANCEL_ORDER_FAILED = `${CANCEL_ORDER}_FAILED`

export const GET_USER_ORDERS = 'GET_USER_ORDERS'
export const GET_USER_ORDERS_SUCCESS = `${GET_USER_ORDERS}_SUCCESS`
export const GET_USER_ORDERS_FAILED = `${GET_USER_ORDERS}_FAILED`

export const LOAD_BY_PAGE = 'LOAD_BY_PAGE'
export const LOAD_BY_PAGE_SUCCESS = 'LOAD_BY_PAGE_SUCCESS'
export const LOAD_BY_PAGE_FAILED = 'LOAD_BY_PAGE_FAILED'

export const GET_CLASSES = 'GET_CLASSES'
export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS'
export const GET_CLASSES_FAILED = 'GET_CLASSES_FAILED'

export const CREATE_CLASS = 'CREATE_CLASS'
export const CREATE_CLASS_SUCCESS = `${CREATE_CLASS}_SUCCESS`
export const CREATE_CLASS_FAILED = `${CREATE_CLASS}_FAILED`

export const EDIT_CLASS = 'EDIT_CLASS'
export const EDIT_CLASS_SUCCESS = `${EDIT_CLASS}_SUCCESS`
export const EDIT_CLASS_FAILED = `${EDIT_CLASS}_FAILED`

export const CREATE_LESSON = 'CREATE_LESSON'
export const CREATE_LESSON_SUCCESS = `${CREATE_LESSON}_SUCCESS`
export const CREATE_LESSON_FAILED = `${CREATE_LESSON}_FAILED`

export const GET_LESSONS = 'GET_LESSONS'
export const GET_LESSONS_SUCCESS = `${GET_LESSONS}_SUCCESS`
export const GET_LESSONS_FAILED = `${GET_LESSONS}_FAILED`

export const GET_LESSON_COUNT = 'GET_LESSON_COUNT'
export const GET_LESSON_COUNT_SUCCESS = `${GET_LESSON_COUNT}_SUCCESS`
export const GET_LESSON_COUNT_FAILED = `${GET_LESSON_COUNT}_FAILED`

export const GET_LESSON_BATCH = 'GET_LESSON_BATCH'
export const GET_LESSON_BATCH_SUCCESS = `${GET_LESSON_BATCH}_SUCCESS`
export const GET_LESSON_BATCH_FAILED = `${GET_LESSON_BATCH}_FAILED`

export const GET_LESSON_STUDENT_DETAIL = 'GET_LESSON_STUDENT_DETAIL'
export const GET_LESSON_STUDENT_DETAIL_SUCCESS = `${GET_LESSON_STUDENT_DETAIL}_SUCCESS`
export const GET_LESSON_STUDENT_DETAIL_FAILED = `${GET_LESSON_STUDENT_DETAIL}_FAILED`

export const GET_LESSON_BY_ID = `GET_LESSON_BY_ID`
export const GET_LESSON_BY_ID_SUCCESS = `${GET_LESSON_BY_ID}_SUCCESS`
export const GET_LESSON_BY_ID_FAILED = `${GET_LESSON_BY_ID}_FAILED`

export const EDIT_LESSON = `EDIT_LESSON`
export const EDIT_LESSON_SUCCESS = `${EDIT_LESSON}_SUCCESS`
export const EDIT_LESSON_FAILED = `${EDIT_LESSON}_FAILED`

export const DELETE_LESSON = `DELETE_LESSON`
export const DELETE_LESSON_SUCCESS = `${DELETE_LESSON}_SUCCESS`
export const DELETE_LESSON_FAILED = `${DELETE_LESSON}_FAILED`

export const SAVE_NOTES = `SAVE_NOTES`
export const SAVE_NOTES_SUCCESS = `${SAVE_NOTES}_SUCCESS`
export const SAVE_NOTES_FAILED = `${SAVE_NOTES}_FAILED`

export const MAKE_ANNOUNCEMENT = `MAKE_ANNOUNCEMENT`
export const MAKE_ANNOUNCEMENT_SUCCESS = `${MAKE_ANNOUNCEMENT}_SUCCESS`
export const MAKE_ANNOUNCEMENT_FAILED = `${MAKE_ANNOUNCEMENT}_FAILED`

export const GET_NEWS = 'GET_NEWS'
export const GET_NEWS_SUCCESS = `${GET_NEWS}_SUCCESS`
export const GET_NEWS_FAILED = `${GET_NEWS}_FAILED`

export const UPDATE_NEWS = 'UPDATE_NEWS'
export const UPDATE_NEWS_SUCCESS = `${UPDATE_NEWS}_SUCCESS`
export const UPDATE_NEWS_FAILED = `${UPDATE_NEWS}_FAILED`

export const GET_CONSULTATION = 'GET_CONSULTATION'
export const GET_CONSULTATION_SUCCESS = `${GET_CONSULTATION}_SUCCESS`
export const GET_CONSULTATION_FAILED = `${GET_CONSULTATION}_FAILED`

export const UPDATE_CONSULTATION = 'UPDATE_CONSULTATION'
export const UPDATE_CONSULTATION_SUCCESS = `${UPDATE_CONSULTATION}_SUCCESS`
export const UPDATE_CONSULTATION_FAILED = `${UPDATE_CONSULTATION}_FAILED`

export const CREATE_CONSULTATION = 'CREATE_CONSULTATION'
export const CREATE_CONSULTATION_SUCCESS = `${CREATE_CONSULTATION}_SUCCESS`
export const CREATE_CONSULTATION_FAILED = `${CREATE_CONSULTATION}_FAILED`

export const GET_FEEDBACK = `GET_FEEDBACK`
export const GET_FEEDBACK_SUCCESS = `${GET_FEEDBACK}_SUCCESS`
export const GET_FEEDBACK_FAILED = `${GET_FEEDBACK}_FAILED`

export const GET_USER_FEEDBACK = 'GET_USER_FEEDBACK'
export const GET_USER_FEEDBACK_SUCCESS = `${GET_USER_FEEDBACK}_SUCCESS`
export const GET_USER_FEEDBACK_FAILED = `${GET_USER_FEEDBACK}_FAILED`

export const CREATE_LESSON_POLL = 'CREATE_LESSON_POLL'
export const CREATE_LESSON_POLL_SUCCESS = `${CREATE_LESSON_POLL}_SUCCESS`
export const CREATE_LESSON_POLL_FAILED = `${CREATE_LESSON_POLL}_FAILED`

export const EDIT_LESSON_POLL = 'EDIT_LESSON_POLL'
export const EDIT_LESSON_POLL_SUCCESS = `${EDIT_LESSON_POLL}_SUCCESS`
export const EDIT_LESSON_POLL_FAILED = `${EDIT_LESSON_POLL}_FAILED`

export const DELETE_LESSON_POLL = 'DELETE_LESSON_POLL'
export const DELETE_LESSON_POLL_SUCCESS = `${DELETE_LESSON_POLL}_SUCCESS`
export const DELETE_LESSON_POLL_FAILED = `${DELETE_LESSON_POLL}_FAILED`

export const REFRESH_LESSON = 'REFRESH_LESSON'
export const REFRESH_LESSON_SUCCESS = `${REFRESH_LESSON}_SUCCESS`
export const REFRESH_LESSON_FAILED = `${REFRESH_LESSON}_FAILED`

// NOTE: use on quick lesson management page, shall merge both lesson management
export const GET_LESSON_BY_ID_2 = 'GET_LESSON_BY_ID_2'
export const GET_LESSON_BY_ID_2_SUCCESS = `${GET_LESSON_BY_ID_2}_SUCCESS`
export const GET_LESSON_BY_ID_2_FAILED = `${GET_LESSON_BY_ID_2}_FAILED`

export const GET_USER_LIVE_LESSON_LINK = 'GET_USER_LIVE_LESSON_LINK'
export const GET_USER_LIVE_LESSON_LINK_SUCCESS = `${GET_USER_LIVE_LESSON_LINK}_SUCCESS`
export const GET_USER_LIVE_LESSON_LINK_FAILED = `${GET_USER_LIVE_LESSON_LINK}_FAILED`

export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const GET_STUDENTS = 'GET_STUDENTS'
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS'
export const GET_STUDENTS_FAILED = 'GET_STUDENTS_FAILED'

export const GET_TUTORS = 'GET_TUTORS'
export const GET_TUTORS_SUCCESS = 'GET_TUTORS_SUCCESS'
export const GET_TUTORS_FAILED = 'GET_TUTORS_FAILED'

export const CREATE_USER = `CREATE_USER`
export const CREATE_USER_SUCCESS = `${CREATE_USER}_SUCCESS`
export const CREATE_USER_FAILED = `${CREATE_USER}_FAILED`

export const UPDATE_USER = `UPDATE_USER`
export const UPDATE_USER_SUCCESS = `${UPDATE_USER}_SUCCESS`
export const UPDATE_USER_FAILED = `${UPDATE_USER}_FAILED`

export const CREATE_PARENT = `CREATE_PARENT`
export const CREATE_PARENT_SUCCESS = `${CREATE_PARENT}_SUCCESS`
export const CREATE_PARENT_FAILED = `${CREATE_PARENT}_FAILED`

export const UPDATE_PARENT = `UPDATE_PARENT`
export const UPDATE_PARENT_SUCCESS = `${UPDATE_PARENT}_SUCCESS`
export const UPDATE_PARENT_FAILED = `${UPDATE_PARENT}_FAILED`

// export const GET_PARENT_DETAILS = `GET_PARENT_DETAILS`
// export const GET_PARENT_DETAILS_SUCCESS = `${GET_PARENT_DETAILS}_SUCCESS`
// export const GET_PARENT_DETAILS_FAILED = `${GET_PARENT_DETAILS}_FAILED`

export const GET_USER_CLASS = 'GET_USER_CLASS'
export const GET_USER_CLASS_SUCCESS = `${GET_USER_CLASS}_SUCCESS`
export const GET_USER_CLASS_FAILED = `${GET_USER_CLASS}_FAILED`

export const GET_STUDENT_CLASS_LESSONS = `GET_STUDENT_CLASS_LESSONS`
export const GET_STUDENT_CLASS_LESSONS_SUCCESS = `${GET_STUDENT_CLASS_LESSONS}_SUCCESS`
export const GET_STUDENT_CLASS_LESSONS_FAILED = `${GET_STUDENT_CLASS_LESSONS}_FAILED`

export const GET_ALL_CLASSES = `GET_ALL_CLASSES`
export const GET_ALL_CLASSES_SUCCESS = `${GET_ALL_CLASSES}_SUCCESS`
export const GET_ALL_CLASSES_FAILED = `${GET_ALL_CLASSES}_FAILED`

export const IMPORT_CLASSES = `IMPORT_CLASSES`
export const IMPORT_CLASSES_SUCCESS = `${IMPORT_CLASSES}_SUCCESS`
export const IMPORT_CLASSES_FAILED = `${IMPORT_CLASSES}_FAILED`

export const UPDATE_ACTION = `UPDATE_ACTION`
export const UPDATE_ACTION_SUCCESS = `${UPDATE_ACTION}_SUCCESS`
export const UPDATE_ACTION_FAILED = `${UPDATE_ACTION}_FAILED`

export const GET_STUDENT_LIST = `GET_STUDENT_LIST`
export const GET_STUDENT_LIST_SUCCESS = `${GET_STUDENT_LIST}_SUCCESS`
export const GET_STUDENT_LIST_FAILED = `${GET_STUDENT_LIST}_FAILED`

export const GET_STUDENT_LESSON_LIST = `GET_STUDENT_LESSON_LIST`
export const GET_STUDENT_LESSON_LIST_SUCCESS = `${GET_STUDENT_LESSON_LIST}_SUCCESS`
export const GET_STUDENT_LESSON_LIST_FAILED = `${GET_STUDENT_LESSON_LIST}_FAILED`

export const GET_STUDENT_CONSULTATION_LIST = `GET_STUDENT_CONSULTATION_LIST`
export const GET_STUDENT_CONSULTATION_LIST_SUCCESS = `${GET_STUDENT_CONSULTATION_LIST}_SUCCESS`
export const GET_STUDENT_CONSULTATION_LIST_FAILED = `${GET_STUDENT_CONSULTATION_LIST}_FAILED`

export const UPDATE_ACTION_ONLY_LOADING = `UPDATE_ACTION_ONLY_LOADING`
export const UPDATE_ACTION_ONLY_LOADING_SUCCESS = `${UPDATE_ACTION_ONLY_LOADING}_SUCCESS`
export const UPDATE_ACTION_ONLY_LOADING_FAILED = `${UPDATE_ACTION_ONLY_LOADING}_FAILED`

export const GET_CLASS_ENROLMENT = 'GET_CLASS_ENROLMENT'
export const GET_CLASS_ENROLMENT_SUCCESS = `${GET_CLASS_ENROLMENT}_SUCCESS`
export const GET_CLASS_ENROLMENT_FAILED = `${GET_CLASS_ENROLMENT}_FAILED`

export const GET_BIRTHDAY_STUDENT = 'GET_BIRTHDAY_STUDENTS'
export const GET_BIRTHDAY_STUDENT_SUCCESS = `${GET_BIRTHDAY_STUDENT}_SUCCESS`
export const GET_BIRTHDAY_STUDENT_FAILED = `${GET_BIRTHDAY_STUDENT}_FAILED`

export const GET_SALES_STAT = 'GET_SALES_STAT'
export const GET_SALES_STAT_SUCCESS = `${GET_SALES_STAT}_SUCCESS`
export const GET_SALES_STAT_FAILED = `${GET_SALES_STAT}_FAILED`

export const GET_QUESTION_LIST = 'GET_QUESTION_LIST'
export const GET_QUESTION_LIST_SUCCESS = `${GET_QUESTION_LIST}_SUCCESS`
export const GET_QUESTION_LIST_FAILED = `${GET_QUESTION_LIST}_FAILED`

export const CREATE_QUESTION = 'CREATE_QUESTION'
export const CREATE_QUESTION_SUCCESS = `${CREATE_QUESTION}_SUCCESS`
export const CREATE_QUESTION_FAILED = `${CREATE_QUESTION}_FAILED`

export const TAG_QUESTION = 'TAG_QUESTION'
export const TAG_QUESTION_SUCCESS = `${TAG_QUESTION}_SUCCESS`
export const TAG_QUESTION_FAILED = `${TAG_QUESTION}_FAILED`

export const REDIRECT = 'REDIRECT'
export const REDIRECT_UNAUTHENTICATED_USER = 'REDIRECT_UNAUTHENTICATED_USER'
export const UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS'
export const LOGOUT = 'LOGOUT'
export const TOGGLE_COLLAPSE_SIDE_NAV = 'TOGGLE_COLLAPSE_SIDE_NAV'
export const UPDATE_WINDOW_WIDTH = 'UPDATE_WINDOW_WIDTH'
export const UNLOAD_LESSON = 'UNLOAD_LESSON'
export const SET_ACTIVE_LESSON = 'SET_ACTIVE_LESSON'

// NOTE: doesn't require API call
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'