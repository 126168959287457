import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { CREATE_LESSON_POLL, EDIT_LESSON_POLL } from 'constants/actionTypes'
import { Radio, RadioGroup } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import _ from 'lodash'

const mapStateToProps = state => ({
  creatingPoll: state.lessons.creatingPoll,
  edittingPoll: state.lessons.edittingPoll
})

const mapDispatchToProps = dispatch => ({
  createPollQuestion: (lessonId, pollObj) =>
    dispatch({ type: CREATE_LESSON_POLL, payload: agent.Lesson.createPollQuestion(lessonId, pollObj), lessonId }),
  editPollQuestion: (lessonId, questionIndex, pollObj) =>
    dispatch({
      type: EDIT_LESSON_POLL,
      payload: agent.Lesson.editPollQuestion(lessonId, questionIndex, pollObj),
      lessonId
    })
})

class LessonManagement extends React.Component {
  state = {
    question_type: 'question',
    answer_index: [],
    answer: [],
    rawOption: ['', ''],
    question: '',
    choice_type: 'single',
    question_index: 1,
    question_name: ''
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let { poll, pollCount } = this.props
      if (poll) {
        let question_index = poll ? poll.question_index : 1
        let rawOption = []
        for (let i = 0; i < poll.option.length; i++) {
          rawOption.push(poll.option[i].content)
        }

        let choice_type = poll.choice_type
        if (choice_type === 'Multiple Choice') {
          choice_type = 'multiple'
        } else if (choice_type === 'Single Choice') {
          choice_type = 'single'
        }

        this.setState({
          question_type: poll.question_type,
          answer_index: poll.answer_index,
          answer: poll.answer,
          rawOption,
          question: poll.question,
          choice_type,
          question_index: poll.question_index,
          question_name: poll.name ? poll.name : `Question ${question_index}`
        })
      } else {
        let question_index = pollCount + 1
        this.setState({
          question_index,
          question_name: `Question ${question_index}`
        })
      }
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.resetForm()
    }
  }

  resetForm = () => {
    this.setState({
      question_type: 'question',
      answer_index: [],
      answer: [],
      rawOption: ['', ''],
      question: '',
      choice_type: 'single',
      question_index: 1,
      question_name: ''
    })
  }

  onChangeFormElement = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })
  }

  onChangeAnswer = index => e => {
    let { rawOption } = this.state
    let newOption = rawOption.slice(0)
    newOption[index] = e.target.value
    this.setState({ rawOption: newOption })
  }

  setAnswer = index => e => {
    let { answer_index } = this.state
    let newAnswerIndex = answer_index
    if (answer_index.includes(index)) {
      newAnswerIndex = _.remove(answer_index, function (o) {
        return o !== index
      })
    } else {
      newAnswerIndex.push(index)
    }
    this.setState({ answer_index: newAnswerIndex })
  }

  addMoreAnswer = () => {
    let expandedRawOption = this.state.rawOption
    expandedRawOption.push('')
    this.setState({ answer: expandedRawOption })
  }

  addQuestion = () => {
    let { lessonId, poll } = this.props
    let { question_type, answer_index, question, rawOption, choice_type, question_index, question_name } = this.state
    let filteredOption = _.filter(rawOption, function (o) {
      return o !== ''
    })

    let processedAnswer = []
    for (let i = 0; i < answer_index.length; i++) {
      processedAnswer.push(rawOption[answer_index[i]])
    }

    let pollObj = {
      question_type,
      answer_index,
      answer: processedAnswer,
      option: filteredOption,
      question,
      choice_type,
      question_index,
      name: question_name
    }
    if (poll) {
      this.props.editPollQuestion(lessonId, poll.question_index, pollObj)
    } else {
      this.props.createPollQuestion(lessonId, pollObj)
    }
  }

  render() {
    let { isOpen, creatingPoll, edittingPoll } = this.props
    let { question_type, answer_index, rawOption, question, choice_type, question_name } = this.state

    return (
      <Dialog maxWidth="md" fullWidth={true} open={isOpen}>
        <DialogTitle>Create/Edit Poll Question</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <FormControl component="fieldset">
                <RadioGroup name="question_type" value={question_type} onChange={this.onChangeFormElement} row>
                  <FormControlLabel
                    name="question_type"
                    value="question"
                    control={<Radio color="primary" />}
                    label="Question"
                    labelPlacement="end"
                  />
                  {/* <FormControlLabel
                    name="question_type"
                    value="check_for_understanding"
                    control={<Radio color="primary" />}
                    label="Check for Understanding"
                    labelPlacement="end"
                  /> */}
                </RadioGroup>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                label="Question Name"
                name="question_name"
                value={question_name}
                onChange={this.onChangeFormElement}
                variant="outlined"
                margin="normal"
              />
            </div>
            <div className="ml-3"> {question_name === '' && 'Please give it a name'}</div>
            <div className="col-12">
              <FormControl component="fieldset">
                <RadioGroup name="choice_type" value={choice_type} onChange={this.onChangeFormElement} row>
                  <FormControlLabel
                    name="choice_type"
                    value="single"
                    control={<Radio color="primary" />}
                    label="Single choice"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    name="choice_type"
                    value="multiple"
                    control={<Radio color="primary" />}
                    label="Multiple choice"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                label="Question Context"
                name="question"
                value={question}
                onChange={this.onChangeFormElement}
                variant="outlined"
                fullWidth={true}
                margin="normal"
              />
            </div>
            <div className="ml-3 text-warning">{question === '' && 'Please give it a title'}</div>
            <div className="col-12">
              <h3 className="mt-2 mb-0">Answers</h3>
              {rawOption.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="d-flex flex-row">
                    <Checkbox checked={answer_index.includes(index)} onChange={this.setAnswer(index)} color="primary" />
                    <TextField
                      label={`Answer ${index + 1} ${index > 1 ? '(optional)' : ''}`}
                      value={item}
                      onChange={this.onChangeAnswer(index)}
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                    />
                  </div>
                  <div className='text-warning'>
                    {index === rawOption.length - 1 &&
                      item === '' &&
                      'At least 2 answers required'}
                  </div>
                </React.Fragment>
              ))}
              <Button onClick={this.addMoreAnswer} color="primary">
                Add more
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.addQuestion} color="primary" variant="outlined" disabled={creatingPoll || edittingPoll}>
            Save
          </Button>
          <Button onClick={this.props.toggleDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonManagement)
