import React from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { Card, CardBody } from 'reactstrap'
import moment from 'moment'

const mapStateToProps = state => ({
  newsState: state.newsandevents.newsState
})
class DisplayArticleCard extends React.Component {
  render() {
    const { newsState, toggleArticleDialog, articleSlot } = this.props

    return (
      <Card className={'mb-3 col-md-10 col-12'}>
        <CardBody>
          {articleSlot === 0 ? (
            <div className="row">
              <div className="col-3">
                <h4 className="font-weight-semibold">
                  <span>Announcement</span>
                </h4>
                <h5 className={'mb-0'}>
                  {newsState && newsState[articleSlot]?.detail?.title === '' ? (
                    <i className="zmdi zmdi-alert-circle mr-2"> No Announcements </i>
                  ) : (
                    'Leave blank to remove announcement'
                  )}
                </h5>{' '}
                <br />
                <h5 className={'mb-0'}>
                  {' '}
                  Last updated at: <br />{' '}
                  {moment(newsState && newsState[articleSlot]?.updated).format('DD-MM-YYYY h:mm A')}
                </h5>
              </div>
              <div className="col">
                <div className={'mb-3'} style={{ fontSize: 20, lineHeight: 1 }}>
                  {newsState && newsState[articleSlot]?.detail?.title}
                </div>
                <div className={'mb-1'}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={toggleArticleDialog(newsState && newsState[articleSlot])}>
                    Edit Announcement
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-3">
                <h4 className="font-weight-semibold">
                  <span>
                    Article {articleSlot} ({articleSlot === 1 ? 'Left' : articleSlot === 2 ? 'Middle' : 'Right'})
                  </span>
                </h4>
                <h5 className={'mb-0'}>
                  {newsState && newsState[articleSlot]?.detail?.title === '' ? (
                    <i className="zmdi zmdi-alert-circle mr-2"> No Title</i>
                  ) : (
                    ''
                  )}
                </h5>
                <h5 className={'mb-0'}>
                  {newsState && newsState[articleSlot]?.detail?.url === '' ? (
                    <i className="zmdi zmdi-alert-circle mr-2"> No URL</i>
                  ) : (
                    ''
                  )}
                </h5>
                <h5 className={'mb-0'}>
                  {newsState && newsState[articleSlot]?.detail?.summary === '' ? (
                    <i className="zmdi zmdi-alert-circle mr-2"> No Summary</i>
                  ) : (
                    ''
                  )}
                </h5>
                <h5 className={'mb-0'}>
                  {newsState && newsState[articleSlot]?.detail?.image === '' ? (
                    <i className="zmdi zmdi-alert-circle mr-2"> No Image</i>
                  ) : (
                    ''
                  )}
                </h5>
                <h5 className={'mb-0'}>
                  {newsState && newsState[articleSlot]?.detail?.youtube_link === '' ? (
                    <i className="zmdi zmdi-alert-circle mr-2"> No Youtube URL File Code</i>
                  ) : newsState && newsState[articleSlot]?.detail?.youtube_link?.startsWith('https:') ? (
                    <i className="zmdi zmdi-alert-circle mr-2">Wrong Youtube URL File Code</i>
                  ) : (
                    ''
                  )}
                </h5>

                <br />
                <h5 className={'mb-0'}>
                  Last updated at: <br />{' '}
                  {moment(newsState && newsState[articleSlot]?.updated).format('DD-MM-YYYY h:mm A')}
                </h5>
              </div>
              <div className="col">
                <div className={'mb-3'} style={{ fontSize: 15, lineHeight: 1 }}>
                  <div>
                    <u>
                      <h5> Title </h5>
                    </u>
                    {newsState && newsState[articleSlot]?.detail?.title}
                  </div>
                  <div>
                    <u>
                      <h5 className={'mt-4'}>Article URL </h5>
                    </u>
                    {newsState && newsState[articleSlot]?.detail?.url}
                  </div>
                  <div>
                    <u>
                      <h5 className={'mt-4'}>Article Summary</h5>
                    </u>
                    {newsState && newsState[articleSlot]?.detail?.summary}
                  </div>
                  <div>
                    <u>
                      <h5 className={'mt-4'}>Article image (jpg, png, jpeg) </h5>
                    </u>
                    {newsState && newsState[articleSlot]?.detail?.image}
                    <div>
                      {newsState && newsState[articleSlot]?.detail?.image !== '' ? (
                        <img
                          src={newsState && newsState[articleSlot]?.detail?.image}
                          alt={newsState && newsState[articleSlot]?.detail?.title}
                          width={'40%'}
                          height={'20%'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div>
                    <u>
                      <h5 className={'mt-4'}>Youtube URL File Code</h5>
                    </u>
                    {newsState && newsState[articleSlot]?.detail?.youtube_link}
                    <div>
                      {newsState && newsState[articleSlot]?.detail?.youtube_link !== '' ? (
                        <iframe
                          id="player"
                          width="40%"
                          height="20%"
                          src={`https://www.youtube.com/embed/${
                            newsState && newsState[articleSlot]?.detail?.youtube_link
                          }`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className={'mb-1'}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={toggleArticleDialog(newsState && newsState[articleSlot])}>
                    Edit Article
                  </Button>
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    )
  }
}

export default connect(mapStateToProps, {})(DisplayArticleCard)
