import React from 'react'
import { connect } from 'react-redux'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import { GET_STUDENTS } from 'constants/actionTypes'
import agent from 'agent'
import moment from 'moment'

import { ROUTE_NUMBER_DASHBOARD } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'
import RenewTab from './tab-renew'
import StudentInfoTab from './tab-student-info'
import ConsultationTab from './tab-consultation'

const mapStateToProps = state => ({
  // ...state.classroom,
  // selectedLessons: state.classroom.selectedLessons,
  userList: state.user.userList,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  getStudents: reqPayload => dispatch({ type: GET_STUDENTS, payload: agent.User.getStudents(reqPayload) })
})

class Dashboard extends React.Component {
  state = {
    activeTab: 'renew'
  }

  componentDidMount() {
    if (this.props.userRole === 'SuperAdmin') {
      this.setState({ activeTab: 'consultation' })
    }

    if (this.props.userRole === 'SalesAdmin') {
      this.setState({ activeTab: 'renew' })
    }

    if (this.props.userRole === 'CustService') {
      this.setState({ activeTab: 'student' })
    }
    this.props.getStudents()
    // this.props.getClassList()
  }

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value })
  }

  render() {
    const { userRole, userList, onlineClassList } = this.props
    const { activeTab } = this.state

    return (
      <>
        <AppBar position="static">
          <Tabs className="pt-2" value={activeTab} onChange={this.handleTabChange} centered>
            {/* {checkActionPermitted(permissions, userRole, ROUTE_NUMBER_DASHBOARD, 'canViewClassEnrolment') && (
            )}*/}
            {userRole === 'SuperAdmin' && <Tab label="Consultation" value="consultation" />}
            {/* SuperAdmin sees both */}
            {/* SalesAdmin only sees renew */}
            {userRole !== 'CustService' && <Tab label="Renew" value="renew" />}
            {/* CustService only sees student */}
            {userRole !== 'SalesAdmin' && <Tab label="Student" value="student" />}
          </Tabs>
        </AppBar>
        <ConsultationTab activeTab={activeTab} studentDetails={userList}/>
        <RenewTab activeTab={activeTab} studentDetails={userList} />
        <StudentInfoTab activeTab={activeTab} studentDetails={userList} userRole={userRole} />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
