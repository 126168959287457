import superagent from 'superagent'

const API_ROOT = process.env.REACT_APP_API_ROOT
const responseBody = res => res.body

let token = null
const tokenPlugin = req => {
  if (token) {
    req.set('Authorization', `Bearer ${token}`)
  }
}

let getHost = port => {
  let host = API_ROOT
  if (port && process.env.NODE_ENV !== 'production') {
    host = `http://localhost:${port}`
  }
  return host
}

const requests = {
  del: (url, port) =>
    superagent
      .del(`${getHost(port)}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: (url, body, port) =>
    superagent
      .get(`${getHost(port)}${url}`)
      .use(tokenPlugin)
      .query(body)
      .then(responseBody),
  put: (url, body, port) =>
    superagent
      .put(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body, port) =>
    superagent
      .post(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url, body, port) =>
    superagent
      .patch(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
}

const limit = (max, offset) => `max=${max}&offset=${offset ? offset * max : 0}`

const Auth = {
  current: () => requests.get('/me'),
  login: (email, password) => requests.post('/auth', { email, password }),
  refreshToken: token => requests.post('/refreshToken', {}),
  getUser: userId => requests.get(`/user?${userId}`),
  changePassword: reqPayload => requests.post('/changePassword', reqPayload)
}

const Orders = {
  getAll: (filter, perPage, page) => {
    let query = {
      sortBy: 'created',
      order: 'desc',
      ...filter,
      max: perPage,
      offset: perPage * (page - 1)
    }
    return requests.get(`/admin/order`, query)
  },
  createOrder: reqBody => {
    return requests.post('/admin/order', reqBody)
  },
  cancelOrder: (orderId, reqBody) => requests.patch(`/admin/order/${orderId}/cancel`, reqBody),
  updateOrder: (orderId, reqBody) => requests.patch(`/admin/order/${orderId}`, reqBody),
}

const User = {
  getUsers: (filter, perPage, page) => {
    let query = {
      sortBy: 'created',
      order: 'ASC',
      ...filter,
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get(`/admin/user`, query)
  },
  getStudents: req => requests.get('/admin/user/student', req),
  getTutors: req => requests.get('/admin/user/tutor', req),
  createUser: userObj => requests.post('/admin/user', userObj),
  updateUser: (userType, userId, userObj) => {
    return requests.patch(`/admin/user/${userType}/${userId}`, userObj)
  },
  createParent: userObj => requests.post('/admin/user/parent', userObj),
  updateParent: (parentEmail, userObj) => {
    return requests.patch(`/admin/user/parent/${parentEmail}`, userObj)
  },
  updateRenewalDate: (userId, reqPayload) => {
    return requests.patch(`/admin/user/renewaldate/${userId}`, reqPayload)
  },
  updateRenewalDateParent: (email, reqPayload) => {
    return requests.patch(`/admin/user/renewaldate/parent/${email}`, reqPayload)
  },
  doneFollowUpForCounsellor: (studentId, checked) =>
    requests.patch(`/admin/user/${studentId}/followup/counsellor`, checked),
  // getParent: parentEmail => requests.get(`/admin/user/parentdetails/${parentEmail}`),
  getStudentList: (filter, perPage, page) => {
    if (!perPage) perPage = 20
    if (!page) page = 1

    let query = {
      sortBy: 'date_of_consultation',
      order: 'asc',
      ...filter,
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get(`/admin/user/studentlist`, query)
  },
  getStudentLessonList: (filter, perPage, page) => {
    if (!perPage) perPage = 20
    if (!page) page = 1

    let query = {
      sortBy: 'comment',
      order: 'asc',
      ...filter,
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get(`/admin/user/studentlessonlist`, query)
  },
}

const Classroom = {
  getClasses: (filter, perPage, page) => {
    let query = {
      sortBy: 'updated',
      order: 'desc',
      ...filter,
      max: perPage,
      offset: page * perPage
    }
    return requests.get(`/admin/class`, query)
  },
  createClass: classroom => requests.post(`/admin/class`, classroom),
  editClass: classroom => requests.patch(`/admin/class/${classroom._id}`, classroom),
  getStudentClass: userId => requests.get(`/admin/class/${userId}/studentclass`),
  // reminder that server will use UTC, and localhost will save directly, so subtract 8 hours in backend. 
  importClass: classes => requests.post(`/admin/class/import`, classes),
  // getAllClasses: () => requests.get(`/admin/user/allclasses`)
  getAllClasses: (filter, perPage, page) => {
    if (!perPage) perPage = 20
    if (!page) page = 1

    let query = {
      // sortBy: 'comment',
      // order: 'asc',
      ...filter,
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get(`/admin/user/allclasses`, query)
  },
}

const Lesson = {
  getLessonList: (filter, perPage, page) => {
    if (!perPage) perPage = 20
    if (!page) page = 1

    let query = {
      sortBy: 'scheduled_on',
      order: 'asc',
      ...filter,
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get(`/admin/lesson`, query)
  },
  getLesson: lessonId => requests.get(`/admin/lesson/${lessonId}`),
  createLesson: lessonObj => requests.post(`/admin/lesson`, lessonObj),
  editLesson: lessonObj => requests.patch(`/admin/lesson/${lessonObj._id}`, lessonObj),
  addStudentToLesson: (lessonId, reqPayload) => requests.patch(`/admin/lesson/${lessonId}/add-student`, reqPayload),
  createPollQuestion: (lessonId, pollObj) => requests.post(`/admin/lesson/${lessonId}/poll`, pollObj),
  editPollQuestion: (lessonId, questionIndex, pollObj) =>
    requests.put(`/admin/lesson/${lessonId}/poll/${questionIndex}`, pollObj),
  deletePollQuestion: (lessonId, questionIndex) => requests.del(`/admin/lesson/${lessonId}/poll/${questionIndex}`),
  getUserLiveLessonLink: (lessonId, userId) => requests.get(`/admin/lesson/${lessonId}/link/${userId}`),
  getAllStudentClassLessons: () => requests.get(`/admin/user/allstudentclass`), // what on earth? cannot put to lesson endpoint
  doneFollowUp: (lessonId, checked) => requests.patch(`/admin/lesson/${lessonId}/followup`, checked),
  saveLessonNotes: (lessonId, reqPayload) => requests.post(`/admin/lesson/savelessonnotes/${lessonId}`, reqPayload),
  consultImmediately: (lessonId, reqPayload) => requests.patch(`/admin/lesson/${lessonId}/consultimmediately`, reqPayload),
}

const Announcement = {
  makeAnnouncement: (scope_type, scope_id, announcement_type, target_type, subject, content) => {
    return requests.post(`/admin/config/announcement/${scope_type}/${scope_id}/${announcement_type}/${target_type}`, {
      content,
      subject
    })
  }
}

const UserInput = {
  getFeedback: (filter, perPage, page) => {
    if (!perPage) perPage = 10
    if (!page) page = 1
    let query = {
      sortBy: 'created',
      order: 'desc',
      ...filter,
      max: perPage,
      offset: perPage * (page - 1)
    }
    return requests.get(`/user_input/feedback`, query)
  }
}

const Report = {
  getBirthdayStudent: reqPayload => requests.post('/admin/report/birthday-student', reqPayload),
}

const NewsAndEvents = {
  getNews: () => requests.get(`/v2/newsandevents`),
  updateNews: (id, reqPayload) => requests.patch(`/v2/newsandevents/${id}`, reqPayload)
  // updateAnnouncements
}

const Consultation = {
  getConsultation: (filter, perPage, page) => {
    if (!perPage) perPage = 20
    if (!page) page = 1

    let query = {
      sortBy: 'date',
      order: 'asc',
      ...filter,
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get('/admin/consultation', query)
  },

  updateConsultation: (id, reqPayload) => requests.patch(`/admin/consultation/${id}`, reqPayload),
  createConsultation: (userObj) => requests.post(`/admin/consultation`, userObj, )
}

export default {
  Auth,
  Orders,
  User,
  Lesson,
  Classroom,
  Announcement,
  UserInput,
  Report,
  NewsAndEvents,
  Consultation,
  setToken: _token => {
    token = _token
  }
}
