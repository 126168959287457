import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { ButtonGroup } from 'reactstrap'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/Menu'
import Select from '@material-ui/core/Select'
import { isMobile } from 'react-device-detect'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

class Pagination extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pager: {}
    }
  }

  componentDidMount() {
    // set page if items array isn't empty
    if (!!this.props.itemCount) {
      const { pager } = this.state
      const pageCount = pager.totalPages
      let initialPage = 1
      if (this.props.initialPage) {
        initialPage = this.props.initialPage
      }
      if (initialPage > pageCount) {
        initialPage = pageCount
      }
      this.setPage(initialPage)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.itemCount !== prevProps.itemCount) {
      const { pager } = this.state
      const pageCount = pager.totalPages
      let initialPage = 1
      if (this.props.initialPage) {
        initialPage = this.props.initialPage
      }
      if (initialPage > pageCount) {
        initialPage = pageCount
      }
      this.setPage(initialPage)
    }

    if (prevProps.currentPage !== this.props.currentPage) {
      this.setPage(this.props.currentPage)
    }
  }

  setPage(page) {
    let { itemCount, pageSize } = this.props
    let pager = this.state.pager

    if (page < 1 || page > pager.totalPages) {
      return
    }

    // get new pager object for specified page
    pager = this.getPager(itemCount, page, pageSize)

    // update state
    this.setState({ pager: pager })

    // call change page function in parent component
    this.props.onChangePage(page)
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1

    // default page size is 5
    pageSize = pageSize || 5

    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize)

    let startPage, endPage
    if (totalPages <= 5) {
      // less than 5 total pages so show all
      startPage = 1
      endPage = totalPages
    } else {
      // more than 5 total pages so calculate start and end pages
      if (currentPage <= 3) {
        startPage = 1
        endPage = 5
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4
        endPage = totalPages
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

    // create an array of pages to ng-repeat in the pager control
    let pages = [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i)

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    }
  }

  render() {
    const { className } = this.props
    let pager = this.state.pager

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null
    }

    if (isMobile) {
      return (
        <div className={`row mb-3`}>
          <div className="col"></div>
          <div className="col-auto">
            <div className="row">
              <div className="col">
                {pager.currentPage > 1 && (
                  <Button
                    variant="fab"
                    color={'primary'}
                    className="jr-fab-btn jr-btn-fab-xs mx-0"
                    onClick={() => this.setPage(pager.currentPage - 1)}>
                    <KeyboardArrowLeft />
                  </Button>
                )}
                <div className="d-inline-block mx-2">
                  <span className={'mr-2'}>Page</span>
                  <Select value={pager.currentPage} onChange={e => this.setPage(e.target.value)} input={<Input />}>
                    {pager.pages.map((page, index) => (
                      <MenuItem key={index} value={page}>
                        {page}
                      </MenuItem>
                    ))}
                  </Select>
                  <span className={'ml-2'}>of {pager.totalPages}</span>
                </div>
                {pager.currentPage < pager.totalPages && (
                  <Button
                    variant="fab"
                    color={'primary'}
                    className="jr-fab-btn jr-btn-fab-xs mx-0"
                    onClick={() => this.setPage(pager.currentPage + 1)}>
                    <KeyboardArrowRight />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <ButtonGroup className={className}>
          <Button
            variant={'contained'}
            disabled={pager.currentPage === 1}
            onClick={() => this.setPage(1)}
            color={'default'}
            className={'bg-white no-shadow text-capitalize'}>
            First
          </Button>
          <Button
            variant={'contained'}
            disabled={pager.currentPage === 1}
            onClick={() => this.setPage(pager.currentPage - 1)}
            color={'default'}
            className={'bg-white no-shadow text-capitalize'}>
            Previous
          </Button>
          {pager.pages.map((page, index) => (
            <Button
              variant={'contained'}
              key={index}
              color={pager.currentPage === page ? 'primary' : 'default'}
              onClick={() => this.setPage(page)}
              className={pager.currentPage === page ? '' : 'bg-white no-shadow'}>
              {page}
            </Button>
          ))}
          <Button
            variant={'contained'}
            disabled={pager.currentPage === pager.totalPages}
            onClick={() => this.setPage(pager.currentPage + 1)}
            color={'default'}
            className={'bg-white no-shadow text-capitalize'}>
            Next
          </Button>
          <Button
            variant={'contained'}
            disabled={pager.currentPage === pager.totalPages}
            onClick={() => this.setPage(pager.totalPages)}
            color={'default'}
            className={'bg-white no-shadow text-capitalize'}>
            Last
          </Button>
        </ButtonGroup>
      )
    }
  }
}

Pagination.propTypes = {
  className: PropTypes.string,
  itemCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number
}

export default Pagination
