import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import permissions from 'permissions'

import Home from 'pages/Home'
import UserManagement from 'pages/UserManagement'
import LessonPage from 'pages/CourseManagement/LessonManagement/LessonPage'
import LessonManagement from 'pages/CourseManagement/LessonManagement'
import CourseManagement from 'pages/CourseManagement'
import Orders from 'pages/Orders'
import Feedback from 'pages/Feedback/Feedback'
import LessonManagement2 from 'pages/LessonManagement'
import ZoomLessonDetail from 'pages/LessonManagement/details/zoom'
import NumberDashboard from 'pages/dashboard'
import OpsDashboard from 'pages/ops'
import ProfilePage from 'pages/profile'

import {
  ROUTE_ROOT,
  ROUTE_USER_MANAGEMENT,
  ROUTE_LESSON_PAGE,
  ROUTE_LESSONS,
  ROUTE_COURSES,
  ROUTE_ORDERS,
  ROUTE_FEEDBACK,
  ROUTE_LESSON_MANAGEMENT,
  ROUTE_LESSON_DETAIL_ZOOM,
  ROUTE_NUMBER_DASHBOARD,
  ROUTE_OPS_DASHBOARD,
  ROUTE_PROFILE,
} from 'routes/paths'

const mapStateToProps = state => ({
  userRole: state.auth.userRole
})

const GatedRoute = ({ userRole, path, component }) => {
  if (Object.keys(permissions).includes(userRole)) {
    const { allowedPaths } = permissions[userRole]
    if (Object.keys(allowedPaths).includes(path)) {
      return <Route path={path} component={component} />
    } else {
      return <Redirect to={ROUTE_ROOT} />
    }
  }
  return <Route path={path} component={component} />
}

class Routes extends React.Component {
  render() {
    const userRole = this.props.userRole

    return (
      <Switch>
        <Route exact path={ROUTE_ROOT} component={Home} />
        <GatedRoute userRole={userRole} path={ROUTE_USER_MANAGEMENT} component={UserManagement} />
        <GatedRoute userRole={userRole} path={ROUTE_LESSON_PAGE} component={LessonPage} />
        <GatedRoute userRole={userRole} path={ROUTE_LESSONS} component={LessonManagement} />
        <GatedRoute userRole={userRole} path={ROUTE_COURSES} component={CourseManagement} />
        <GatedRoute userRole={userRole} path={ROUTE_ORDERS} component={Orders} />
        <GatedRoute userRole={userRole} path={ROUTE_FEEDBACK} component={Feedback} />
        <GatedRoute userRole={userRole} path={ROUTE_LESSON_DETAIL_ZOOM} component={ZoomLessonDetail} />
        <GatedRoute userRole={userRole} path={ROUTE_LESSON_MANAGEMENT} component={LessonManagement2} />
        <GatedRoute userRole={userRole} path={ROUTE_NUMBER_DASHBOARD} component={NumberDashboard} />
        <GatedRoute userRole={userRole} path={ROUTE_OPS_DASHBOARD} component={OpsDashboard} />
        <Route path={ROUTE_PROFILE} component={ProfilePage} />
        <Route path={'*'} component={Home} />
      </Switch>
    )
  }
}

export default connect(mapStateToProps)(Routes)
