import React from 'react'
import { connect } from 'react-redux'
import { GET_NEWS, GET_STUDENTS } from 'constants/actionTypes'
import agent from 'agent'
import DialogEditArticle from 'pages/DialogEditArticle'
import DisplayArticleCard from './UserManagement/DisplayArticleCard'
import { Button, TableHead, TableCell, TableRow, Table, TableBody, CircularProgress } from '@material-ui/core'
import moment from 'moment'
const mapStateToProps = state => ({
  newsState: state.newsandevents.newsState,
  inProgress: state.newsandevents.inProgress,
  userRole: state.auth.userRole,
  currentUser: state.common.currentUser,
  userList: state.user.userList,
  isLoading: state.user.isLoading
})

const mapDispatchToProps = dispatch => ({
  getNews: () => dispatch({ type: GET_NEWS, payload: agent.NewsAndEvents.getNews() }),
  getStudents: reqPayload => dispatch({ type: GET_STUDENTS, payload: agent.User.getStudents(reqPayload) })
})

const StudentTable = props => {
  const { studentList, renderEditButton } = props

  if (studentList?.length > 0) {
    return (
      <div className="table-responsive-material bg-white">
        <Table>
          <TableHead style={{ backgroundColor: 'rgba(60, 72, 88, 0.1)' }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>School</TableCell>
              <TableCell>Birthday</TableCell>
              <TableCell>Gender</TableCell>

              {/* <TableCell>First Language</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {studentList?.map(studentData => {
              let studentGender = 'N/A'
              if (studentData.gender === 'm') {
                studentGender = 'male'
              } else if (studentData.gender === 'f') {
                studentGender = 'female'
              }

              return (
                <TableRow key={studentData._id}>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <span>{studentData.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>{studentData.phone}</TableCell>
                  <TableCell>{studentData.email}</TableCell>
                  <TableCell>{studentData.school ?? 'N/A'}</TableCell>
                  <TableCell>
                    {studentData.date_of_birth ? moment(studentData.date_of_birth).format('ll') : 'N/A'}
                  </TableCell>
                  <TableCell>{studentGender}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

class Home extends React.Component {
  state = {
    articleDialogOpen: false
  }

  componentDidMount() {
    this.props.getNews()
    this.props.getStudents()
  }

  toggleArticleDialog = article => () => {
    this.setState({ articleDialogOpen: !this.state.articleDialogOpen, selectedArticle: article })
  }

  onSave = () => {
    this.toggleArticleDialog(null)()
    this.props.getNews()
  }

  componentDidUpdate = () => {}

  render() {
    const { newsState, userRole, currentUser, userList, isLoading } = this.props
    const { articleDialogOpen, selectedArticle } = this.state
    const studentsThatRequireConsultationForAcademicCareer = userList?.filter(
      students => students?.careerGoals?.consultation
    )
    const isKruA = currentUser._id === 'hhfu4lg4TBmPQ5FcLIt9lA'
    return (
      <>
        <main role="main" className="col-sm-12 ml-sm-auto col-md-12 mb-5">
          <div className="mt-5">
            Students that require consultation for Academic Career
            <div className="table-responsive-material bg-white">
              <Table>
                <TableHead style={{ backgroundColor: 'rgba(60, 72, 88, 0.1)' }}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>School</TableCell>
                    <TableCell>Birthday</TableCell>
                    <TableCell>Gender</TableCell>

                    {/* <TableCell>First Language</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentsThatRequireConsultationForAcademicCareer?.map(studentData => {
                    let studentGender = 'N/A'
                    if (studentData.gender === 'm') {
                      studentGender = 'male'
                    } else if (studentData.gender === 'f') {
                      studentGender = 'female'
                    }

                    return (
                      <TableRow key={studentData._id}>
                        <TableCell>
                          <div className="d-flex align-items-center">
                            <span>{studentData.name}</span>
                          </div>
                        </TableCell>
                        <TableCell>{studentData.phone}</TableCell>
                        <TableCell>{studentData.email}</TableCell>
                        <TableCell>{studentData.school ?? 'N/A'}</TableCell>
                        <TableCell>
                          {studentData.date_of_birth ? moment(studentData.date_of_birth).format('ll') : 'N/A'}
                        </TableCell>
                        <TableCell>{studentGender}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </main>

        {userRole === 'SuperAdmin' && isKruA ? (
          <main role="main" className="col-sm-12 ml-sm-auto col-md-10 pt-3">
            <h1>Announcement</h1>

            {/* Announcement */}
            <DisplayArticleCard newsState={newsState} toggleArticleDialog={this.toggleArticleDialog} articleSlot={0} />

            {/* News and Events */}
            <DisplayArticleCard newsState={newsState} toggleArticleDialog={this.toggleArticleDialog} articleSlot={1} />

            <DisplayArticleCard newsState={newsState} toggleArticleDialog={this.toggleArticleDialog} articleSlot={2} />

            <DisplayArticleCard newsState={newsState} toggleArticleDialog={this.toggleArticleDialog} articleSlot={3} />

            <div className="mt-5 row">
              {articleDialogOpen && (
                <DialogEditArticle
                  toggle={this.toggleArticleDialog()}
                  isOpen={articleDialogOpen}
                  articleData={selectedArticle}
                  newsState={newsState}
                  onSave={this.onSave}
                />
              )}
            </div>
          </main>
        ) : (
          <main role="main" className="col-sm-12 ml-sm-auto col-md-12 pt-3 mt-3">
            <Button onClick={() => this.props.history.push('dashboard')}> Go to dashboard</Button>
          </main>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
