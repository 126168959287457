import * as Type from 'constants/actionTypes'

const defaultState = {
  recommendationConsult: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Type.GET_CONSULTATION:
      return {
        ...state,
        getinProgress: true
      }
    case Type.GET_CONSULTATION_SUCCESS:
      return {
        ...state,
        getinProgress: false,
        studentCount: action.payload.count,
        consultationData: action.payload.data
      }
    case Type.GET_CONSULTATION_FAILED:
      return {
        ...state,
        getinProgress: false
      }

    case Type.UPDATE_CONSULTATION:
      return {
        ...state,
        updateinProgress: true
      }
    case Type.UPDATE_CONSULTATION_SUCCESS:
      return {
        ...state,
        updateinProgress: false,
        consultationState: action.payload.data
      }
    case Type.UPDATE_CONSULTATION_FAILED:
      return {
        ...state,
        updateinProgress: false
      }

    case Type.CREATE_CONSULTATION:
      return {
        ...state,
        inProgress: true
      }
    case Type.CREATE_CONSULTATION_SUCCESS:
      return {
        ...state,
        inProgress: false,
        consultationState: action.payload.data
      }
    case Type.CREATE_CONSULTATION_FAILED:
      return {
        ...state,
        inProgress: false
      }

    case Type.UPDATE_NOTIFICATION:
      return {
        ...state,
        recommendationConsult: action.payload.recommendationConsult
      }

    default:
      return state
  }
}
