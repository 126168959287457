import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import agent from 'agent'
import * as Type from 'constants/actionTypes'

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import _ from 'lodash'
import DialogStudentProfile from 'components/shared/DialogStudentProfile'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  updateRenewalDate: (userId, dateObj) =>
    dispatch({ type: Type.UPDATE_ACTION, payload: agent.User.updateRenewalDate(userId, { dateObj }) }),
  updateRenewalDateParent: (email, dateObj) =>
    dispatch({ type: Type.UPDATE_ACTION, payload: agent.User.updateRenewalDateParent(email, { dateObj }) })
})

class RenewTab extends React.Component {
  constructor(props) {
    super()
    this.state = {
      renewalDate: '',
      showInput: false,
      selectedUser: null,
      newDate: '',
      studentDetailsDialog: {},
      isStudentDetailsDialogOpen: false
    }

    this.showCalendar = (user, newDate) => () => {
      this.setState({ showInput: !this.state.showInput, selectedUser: user, renewalDate: newDate })
      const { showInput, selectedUser } = this.state
      if (showInput && newDate !== user.parent?.meta?.date_of_renewal) {
        this.setState({ newDate: newDate })
        this.props.updateRenewalDate(user._id, newDate)
        this.props.updateRenewalDateParent(user.parent.email, newDate)
      }
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showInput !== this.state.showInput) {
      this.setState({ renewalDate: this.state.selectedUser.parent?.meta?.date_of_renewal })
    }
  }

  openStudentDetails = user => {
    this.setState({ studentDetailsDialog: user, isStudentDetailsDialogOpen: !this.state.isStudentDetailsDialogOpen })
  }

  render() {
    const { showInput, selectedUser, renewalDate, newDate, studentDetailsDialog, isStudentDetailsDialogOpen } =
      this.state

    const { studentDetails, activeTab } = this.props

    const filteredDates = studentDetails?.filter(students => students?.parent?.meta?.date_of_renewal)

    const sortedDate = _.orderBy(filteredDates, [
      item => {
        return item.parent.meta.date_of_renewal
      }
    ])
    const currentDate = moment()

    return (
      <>
        <Table className="mb-5" hidden={activeTab !== 'renew'}>
          <TableHead>
            <TableRow>
              <TableCell>Name of Student</TableCell>
              <TableCell>Nickname</TableCell>
              <TableCell>Name of Parent</TableCell>
              <TableCell>Parent Email</TableCell>
              <TableCell>Customer Service</TableCell>
              <TableCell>Sales Admin</TableCell>
              <TableCell>Renewal Date</TableCell>
              <TableCell>Update Renewal Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDate.map(
              (studentThatNeedsRenewal, index) =>
                moment(currentDate).isBetween(
                  moment(studentThatNeedsRenewal?.parent?.meta?.date_of_renewal).subtract(2, 'weeks'),
                  studentThatNeedsRenewal?.parent?.meta?.date_of_renewal
                ) && (
                  <TableRow key={index}>
                    <TableCell>
                      <i
                        className="zmdi zmdi-eye zmdi-hc-fw"
                        onClick={() => this.openStudentDetails(studentThatNeedsRenewal)}></i>
                      {studentThatNeedsRenewal?.name}
                    </TableCell>
                    <TableCell>{studentThatNeedsRenewal?.nickname}</TableCell>
                    <TableCell>{studentThatNeedsRenewal?.parent?.name}</TableCell>
                    <TableCell>{studentThatNeedsRenewal?.parent?.email}</TableCell>
                    <TableCell>{studentThatNeedsRenewal?.staff_to_handle?.customer_service}</TableCell>
                    <TableCell>{studentThatNeedsRenewal?.staff_to_handle?.sales_admin}</TableCell>
                    <TableCell>
                      {showInput && selectedUser?._id === studentThatNeedsRenewal?._id ? (
                        <TextField
                          name="renewalDate"
                          type="date"
                          defaultValue={studentThatNeedsRenewal?.parent?.meta?.date_of_renewal}
                          onChange={this.handleChange}
                        />
                      ) : (
                        moment(studentThatNeedsRenewal?.parent?.meta?.date_of_renewal).format('DD-MM-yyyy')
                      )}

                      {!showInput && selectedUser?._id === studentThatNeedsRenewal?._id ? (
                        <span style={{ color: 'blue' }}>
                          {newDate && ' -->'} {moment(newDate).format('DD-MM-yyyy')}
                        </span>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>
                      <Button onClick={this.showCalendar(studentThatNeedsRenewal, renewalDate)}>
                        {showInput && selectedUser?._id === studentThatNeedsRenewal?._id ? 'Done' : 'Update'}
                      </Button>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>

        {isStudentDetailsDialogOpen && (
          <DialogStudentProfile
            studentDetailsDialog={studentDetailsDialog}
            isOpen={isStudentDetailsDialogOpen}
            toggle={this.openStudentDetails}
          />
        )}
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RenewTab)
