import * as Type from 'constants/actionTypes'
import _ from 'lodash'

export default (state = {}, action) => {
  switch (action.type) {
    case Type.GET_ORDERS:
      return {
        ...state,
        isLoading: true,
        orderList: null
      }

    case Type.GET_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderList: action.payload.data,
        orderCount: action.payload.count,
        orderPage: action.page
      }

    case Type.GET_ORDERS_FAILED:
    case Type.UNAUTHORIZED_ACCESS:
      return {
        ...state,
        isLoading: false
      }

    case Type.GET_USER_ORDERS_SUCCESS: {
      let userOrdersCount = state.userOrdersCount ? state.userOrdersCount : 0
      let userOrders = state.userOrders ? state.userOrders : {}
      let order = action.payload.data
      userOrders[action.user_id] = order
      userOrdersCount += 1

      return {
        ...state,
        userOrders,
        userOrdersCount
      }
    }

    case Type.CREATE_ORDER:
      return {
        ...state,
        creatingOrder: true,
        error: false
      }

    case Type.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        creatingOrder: false
      }

    case Type.CREATE_ORDER_FAILED:
      return {
        ...state,
        creatingOrder: false,
        error: true
      }

    case Type.UPDATE_ORDER:
      return {
        ...state,
        updatingOrder: true
      }

    case Type.UPDATE_ORDER_SUCCESS: {
      let updatedOrderList = state.orderList
      let updatedOrderData = action.payload.data
      let existingOrderIndex = _.findIndex(state.orderList, o => {
        return o._id === updatedOrderData._id
      })

      if (existingOrderIndex > -1) {
        updatedOrderList[existingOrderIndex]['agent'] = updatedOrderData.agent
        updatedOrderList[existingOrderIndex]['meta'] = updatedOrderData.meta
      }

      return {
        ...state,
        updatingOrder: false
      }
    }

    case Type.UPDATE_ORDER_FAILED:
      return {
        ...state,
        updatingOrder: false
      }

    case Type.CANCEL_ORDER:
      return {
        ...state,
        cancellingOrder: true,
        orderCancelled: false
      }

    case Type.CANCEL_ORDER_SUCCESS: {
      let updatedOrderList = state.orderList
      let updatedOrderData = action.payload.data
      let existingOrderIndex = _.findIndex(state.orderList, o => {
        return o._id === updatedOrderData._id
      })

      if (existingOrderIndex > -1) {
        updatedOrderList[existingOrderIndex].status = updatedOrderData.status
        updatedOrderList[existingOrderIndex].remark = updatedOrderData.remark
        updatedOrderList[existingOrderIndex].histroy = updatedOrderData.histroy
      }

      return {
        ...state,
        cancellingOrder: false,
        orderList: updatedOrderList
      }
    }

    case Type.CANCEL_ORDER_FAILED:
      return {
        ...state,
        cancellingOrder: false
      }

    default:
      return state
  }
}
