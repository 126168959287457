import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Paper
} from '@material-ui/core'
import moment from 'moment'

import agent from 'agent'
import { GET_BIRTHDAY_STUDENT } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loadingBirthdayStudent: state.report.loadingBirthdayStudent,
  studentList: state.report.birthdayStudentList
})

const mapDispatchToProps = dispatch => ({
  getBirthdayStudent: reqPayload =>
    dispatch({ type: GET_BIRTHDAY_STUDENT, payload: agent.Report.getBirthdayStudent(reqPayload) })
})

class StudentBirthdayWidget extends React.Component {
  state = {
    selectedDate: moment().format('YYYY-MM-DD')
  }

  componentDidMount() {
    this.clickSearch()
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  clickSearch = () => {
    const { selectedDate } = this.state

    this.props.getBirthdayStudent({
      birthday_date: moment(selectedDate).startOf('day').toISOString()
    })
  }

  render() {
    const { loadingBirthdayStudent, studentList } = this.props
    const { selectedDate } = this.state

    return (
      <Paper elevation={2}>
        <h2 className="pt-3 px-3">Birthday Students</h2>
        <div className="d-flex align-items-center manage-margin p-3">
          <TextField
            variant="outlined"
            type="date"
            label="Birthday"
            name="selectedDate"
            value={selectedDate}
            onChange={this.handleChange}
          />

          <Button variant="contained" color="primary" onClick={this.clickSearch}>
            Search
          </Button>
        </div>

        {loadingBirthdayStudent && (
          <div className="text-center">
            <CircularProgress size={30} color="primary" />
          </div>
        )}

        {studentList.length === 0 && <div className="text-center pb-3">No birthday student today</div>}

        {studentList.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Birthday</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    {row.phone_country_code} {row.phone}
                  </TableCell>
                  <TableCell>{moment(row.date_of_birth).format('ll')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentBirthdayWidget)
