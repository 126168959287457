import React from 'react'
import moment from 'moment'
import agent from 'agent'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import {
  GET_STUDENT_CLASS_LESSONS,
  GET_STUDENT_LESSON_LIST,
  GET_STUDENT_CONSULTATION_LIST,
  UPDATE_ACTION_ONLY_LOADING,
  SAVE_NOTES,
  UPDATE_NOTIFICATION,
  GET_ALL_CLASSES
} from 'constants/actionTypes'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TextField
} from '@material-ui/core'
import _ from 'lodash'
import DialogStudentProfile from 'components/shared/DialogStudentProfile'

const mapStateToProps = state => ({
  allLessons: state.lessons.allLessons,
  isLoading: state.common.isLoading,
  lessons: state.user.lessons,
  lessonCount: state.user.lessonCount,
  currentUser: state.common.currentUser,
  allClasses: state.classroom.allClasses,
  lessonsConsultation: state.user.lessonsConsultation,
  recommendationConsult: state.consultation.recommendationConsult
})

const mapDispatchToProps = dispatch => ({
  getAllStudentClassLessons: () =>
    dispatch({ type: GET_STUDENT_CLASS_LESSONS, payload: agent.Lesson.getAllStudentClassLessons() }),
  doneFollowUp: (id, checked) =>
    dispatch({ type: UPDATE_ACTION_ONLY_LOADING, payload: agent.Lesson.doneFollowUp(id, { checked }) }),
  getStudentLessonList: day =>
    dispatch({
      type: GET_STUDENT_LESSON_LIST,
      payload: agent.User.getStudentLessonList({
        scheduled_on: {
          $gte: moment(day).startOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]'),
          $lte: moment(day).endOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]')
        }
      })
    }),
  getStudentConsultList: day =>
    dispatch({
      type: GET_STUDENT_CONSULTATION_LIST,
      payload: agent.User.getStudentLessonList({
        'lesson_meta.recommendation.recommendationConsultImmediatelyTimeStamp': {
          $gte: moment(day).startOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]'),
          $lte: moment(day).endOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]')
        }
      })
    }),

  getAllClasses: day =>
    dispatch({
      type: GET_ALL_CLASSES,
      payload: agent.Classroom.getAllClasses({
        scheduled_on: {
          $gte: moment(day).startOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]'),
          $lte: moment(day).endOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]')
        }
      })
    }),
  saveLessonNotes: (lessonId, studentNotes, timeStamp, editor) =>
    dispatch({
      type: SAVE_NOTES,
      payload: agent.Lesson.saveLessonNotes(lessonId, { studentNotes, timeStamp, editor })
    }),
  consultImmediately: (id, checked, resolvedTimeStamp, resolvedPerson) =>
    dispatch({
      type: UPDATE_ACTION_ONLY_LOADING,
      payload: agent.Lesson.consultImmediately(id, { checked, resolvedTimeStamp, resolvedPerson })
    }),
  updateNotification: recommendationConsult => dispatch({ type: UPDATE_NOTIFICATION, payload: recommendationConsult })
})
const OverallImage = props => {
  return (
    <img
      src={`https://epa-assets.s3.ap-southeast-1.amazonaws.com/lms/${props.props}`}
      width={isMobile ? '300' : '250'}
      height={isMobile ? '200' : '100'}></img>
  )
}

class StudentInfoTab extends React.Component {
  state = {
    classes: true,
    dates: false,
    comment: false,
    punctuality: false,
    homework: false,
    engagement: false,
    action: false,
    studentDetailsDialog: {},
    isStudentDetailsDialogOpen: false,
    day: moment().format('YYYY-MM-DD'),
    studentNotes: '',
    isSaved: false,
    selectedLesson: null
  }

  componentDidMount() {
    this.props.getAllStudentClassLessons()
    this.props.getStudentConsultList()
    let { day } = this.state

    this.props.getStudentLessonList(day)
    this.setState({ day })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.day !== this.state.day || prevProps.isLoading !== this.props.isLoading) {
      this.props.getStudentLessonList(this.state.day)
      this.props.getStudentConsultList(this.state.day)
      // this.props.getAllClasses(this.state.day)
      this.props.getAllStudentClassLessons()
      // this.props.getAllClasses()
    }

    if (prevProps.allLessons !== this.props.allLessons) {
      const recommendationConsult = this.props.allLessons
        ?.filter(lessons => lessons.lesson_meta)
        .filter(lessons => lessons.lesson_meta.recommendation?.recommendationConsultImmediately)
      this.props.updateNotification({ recommendationConsult })
    }
  }

  handleNotesChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  //cont here
  handleSaveChange = lesson => () => {
    this.setState({ isSaved: true, selectedLesson: lesson })
    const { currentUser } = this.props
    const { studentNotes } = this.state
    this.props.saveLessonNotes(lesson._id, studentNotes, moment(), currentUser) // call the save
  }

  handleDateChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  searchUsers = day => () => {
    this.setState({ day })
  }

  handleSort = event => {
    // this.setState({ [event]: true })
    if (event === 'classes') {
      this.setState({
        classes: true,
        dates: false,
        comment: false,
        punctuality: false,
        homework: false,
        engagement: false,
        action: false
      })
    }
    if (event === 'dates') {
      this.setState({
        classes: false,
        dates: true,
        comment: false,
        punctuality: false,
        homework: false,
        engagement: false,
        action: false
      })
    }
    if (event === 'comment') {
      this.setState({
        classes: false,
        dates: false,
        comment: true,
        punctuality: false,
        homework: false,
        engagement: false,
        action: false
      })
    }
    if (event === 'punctual') {
      this.setState({
        classes: false,
        dates: false,
        comment: false,
        punctuality: true,
        homework: false,
        engagement: false,
        action: false
      })
    }
    if (event === 'homework') {
      this.setState({
        classes: false,
        dates: false,
        comment: false,
        punctuality: false,
        homework: true,
        engagement: false,
        action: false
      })
    }
    if (event === 'engagement') {
      this.setState({
        classes: false,
        dates: false,
        comment: false,
        punctuality: false,
        homework: false,
        engagement: true,
        action: false
      })
    }
    if (event === 'action') {
      this.setState({
        classes: false,
        dates: false,
        comment: false,
        punctuality: false,
        homework: false,
        engagement: false,
        action: true
      })
    }
  }

  handleSortNAT = event => {
    if (event === 'classesNAT') {
      this.setState({
        classesNAT: true,
        datesNAT: false,
        commentNAT: false,
        punctualityNAT: false,
        homeworkNAT: false,
        engagement: false
      })
    }
    if (event === 'datesNAT') {
      this.setState({
        classesNAT: false,
        datesNAT: true,
        commentNAT: false,
        punctualityNAT: false,
        homeworkNAT: false,
        engagementNAT: false
      })
    }
    if (event === 'commentNAT') {
      this.setState({
        classesNAT: false,
        datesNAT: false,
        commentNAT: true,
        punctualityNAT: false,
        homeworkNAT: false,
        engagementNAT: false
      })
    }
    if (event === 'punctualNAT') {
      this.setState({
        classesNAT: false,
        datesNAT: false,
        commentNAT: false,
        punctualityNAT: true,
        homeworkNAT: false,
        engagementNAT: false
      })
    }
    if (event === 'homeworkNAT') {
      this.setState({
        classesNAT: false,
        datesNAT: false,
        commentNAT: false,
        punctualityNAT: false,
        homeworkNAT: true,
        engagementNAT: false
      })
    }
    if (event === 'engagementNAT') {
      this.setState({
        classesNAT: false,
        datesNAT: false,
        commentNAT: false,
        punctualityNAT: false,
        homeworkNAT: false,
        engagementNAT: true
      })
    }
  }

  handleConsultImmediately = (e, checked) => {
    let id = e.target.name
    this.props.consultImmediately(id, checked, moment(), this.props.currentUser)
  }

  handleCheckboxChange = (e, checked) => {
    let id = e.target.name
    this.props.doneFollowUp(id, checked)
  }

  openStudentDetails = user => {
    this.setState({ studentDetailsDialog: user, isStudentDetailsDialogOpen: !this.state.isStudentDetailsDialogOpen })
  }

  render() {
    const {
      classes,
      dates,
      comment,
      punctuality,
      homework,
      engagement,
      action,
      classesNAT,
      datesNAT,
      commentNAT,
      punctualityNAT,
      homeworkNAT,
      engagementNAT,
      studentDetailsDialog,
      isStudentDetailsDialogOpen,
      day,
      studentNotes,
      isSaved,
      selectedLesson
    } = this.state
    const { currentUser } = this.props

    const isKruA = currentUser?._id === 'hhfu4lg4TBmPQ5FcLIt9lA'
    const { allLessons, activeTab, userRole, lessonCount, lessons, isLoading, lessonsConsultation } = this.props
    let { recommendationConsult } = this.props
    // only on that day
    const filteredLessons = lessons?.filter(lessons => lessons.lesson_meta)

    // all lessons, regardless of day
    const filteredAllLessons = allLessons?.filter(lessons => lessons.lesson_meta)
    const filteredAllLessonsActionTaken = filteredAllLessons?.filter(
      lessons => lessons.lesson_meta.checked === 'n' || !lessons.lesson_meta.checked
    )

    const noTeacherReport = filteredAllLessons?.filter(
      lessons =>
        lessons.lesson_meta?.comment?.comment === '---' ||
        (lessons?.lesson_meta?.engagement?.engagementParticipation === '---' &&
          lessons?.lesson_meta?.engagement?.engagementDistracted === '---' &&
          lessons?.lesson_meta?.engagement?.engagementTiredBored === '---' &&
          lessons?.lesson_meta?.engagement?.engagementMotivate === '---' &&
          lessons?.lesson_meta?.engagement?.engagementAskQuestions === '---') ||
        lessons.lesson_meta.punctuality === '---' ||
        lessons.lesson_meta.homework?.homeworkSubmission === '---' ||
        lessons.lesson_meta.homework?.homeworkSubmission === '---'
    )

    const recommendationConsultImmediately = filteredAllLessons?.filter(
      lessons => lessons.lesson_meta.recommendation?.recommendationConsultImmediately
    )

    let filteredProblemStudentsForDisplay
    let filteredProblemStudentsForDisplayActionTaken

    if (userRole === 'SuperAdmin') {
      filteredProblemStudentsForDisplay = filteredLessons?.filter(
        lessons =>
          lessons.lesson_meta?.comment?.comment !== '' ||
          (lessons?.lesson_meta?.engagement?.engagementParticipation <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementDistracted <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementTiredBored <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementMotivate <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementAskQuestions <= 3) ||
          lessons.lesson_meta.punctuality === 'y' ||
          lessons.lesson_meta.homework?.homeworkSubmission === '2' ||
          lessons.lesson_meta.homework?.homeworkSubmission === '3'
      )
      filteredProblemStudentsForDisplayActionTaken = filteredAllLessonsActionTaken?.filter(
        lessons =>
          lessons.lesson_meta?.comment?.comment !== '' ||
          (lessons?.lesson_meta?.engagement?.engagementParticipation <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementDistracted <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementTiredBored <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementMotivate <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementAskQuestions <= 3) ||
          lessons.lesson_meta.punctuality === 'y' ||
          lessons.lesson_meta.homework?.homeworkSubmission === '2' ||
          lessons.lesson_meta.homework?.homeworkSubmission === '3'
      )
    } else {
      filteredProblemStudentsForDisplay = filteredLessons?.filter(
        lessons =>
          (lessons?.lesson_meta?.engagement?.engagementParticipation <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementDistracted <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementTiredBored <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementMotivate <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementAskQuestions <= 3) ||
          lessons.lesson_meta.punctuality === 'y' ||
          lessons.lesson_meta.homework?.homeworkSubmission === '2' ||
          lessons.lesson_meta.homework?.homeworkSubmission === '3'
      )
      filteredProblemStudentsForDisplayActionTaken = filteredLessons?.filter(
        lessons =>
          (lessons?.lesson_meta?.engagement?.engagementParticipation <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementDistracted <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementTiredBored <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementMotivate <= 3 &&
            lessons?.lesson_meta?.engagement?.engagementAskQuestions <= 3) ||
          lessons.lesson_meta.punctuality === 'y' ||
          lessons.lesson_meta.homework?.homeworkSubmission === '2' ||
          lessons.lesson_meta.homework?.homeworkSubmission === '3'
      )
    }

    const sortedLessons = _.orderBy(filteredProblemStudentsForDisplay, [
      item => {
        return item?.class?.name
      }
    ])

    const sortedDates = _.orderBy(filteredProblemStudentsForDisplay, [
      item => {
        return item?.scheduled_on
      }
    ])

    const sortedComments = _.orderBy(filteredProblemStudentsForDisplay, [
      item => {
        return item?.lesson_meta?.comment?.comment
      }
    ])

    const sortedPunctuality = _.orderBy(filteredProblemStudentsForDisplay, [
      item => {
        return item?.lesson_meta?.punctuality === 'y'
      }
    ])

    const sortedHomework = _.orderBy(filteredProblemStudentsForDisplay, [
      item => {
        return item?.lesson_meta?.homework?.homeworkSubmission === '2'
      }
    ])

    const sortedEngagement = _.orderBy(filteredProblemStudentsForDisplay, [
      item => {
        return (
          (parseInt(item.lesson_meta?.engagement?.engagementParticipation) +
            parseInt(item.lesson_meta?.engagement?.engagementDistracted) +
            parseInt(item.lesson_meta?.engagement?.engagementTiredBored) +
            parseInt(item.lesson_meta?.engagement?.engagementMotivate) +
            parseInt(item.lesson_meta?.engagement?.engagementAskQuestions)) /
          5
        )
      }
    ])

    const sortedAction = _.orderBy(filteredProblemStudentsForDisplay, [
      item => {
        return item?.lesson_meta?.checked
      }
    ])

    let sortedChoice = sortedLessons

    if (classes) {
      sortedChoice = sortedLessons
    }
    if (dates) {
      sortedChoice = [...sortedDates].reverse()
    }
    if (comment) {
      sortedChoice = [...sortedComments].reverse()
      // sortedChoice = [...filteredComments].reverse()
    }
    if (punctuality) {
      sortedChoice = [...sortedPunctuality].reverse()
      // sortedChoice = [...filteredPunctuality].reverse()
    }
    if (homework) {
      sortedChoice = [...sortedHomework].reverse()
      // sortedChoice = [...filteredHomework].reverse()
    }
    if (engagement) {
      sortedChoice = sortedEngagement
      // sortedChoice = filteredEngagement
    }
    if (action) {
      // sortedChoice = sortedAction
      sortedChoice = sortedAction
    }

    // sortedChoice = [...filteredComments].reverse()

    const sortedLessonsNAT = _.orderBy(filteredProblemStudentsForDisplayActionTaken, [
      item => {
        return item?.class?.name
      }
    ])

    const sortedDatesNAT = _.orderBy(filteredProblemStudentsForDisplayActionTaken, [
      item => {
        return item?.scheduled_on
      }
    ])

    const sortedCommentsNAT = _.orderBy(filteredProblemStudentsForDisplayActionTaken, [
      item => {
        return item?.lesson_meta?.comment?.comment
      }
    ])

    const sortedPunctualityNAT = _.orderBy(filteredProblemStudentsForDisplayActionTaken, [
      item => {
        return item?.lesson_meta?.punctuality === 'y'
      }
    ])

    const sortedHomeworkNAT = _.orderBy(filteredProblemStudentsForDisplayActionTaken, [
      item => {
        return item?.lesson_meta?.homework?.homeworkAssignment === 'y'
      }
    ])

    const sortedEngagementNAT = _.orderBy(filteredProblemStudentsForDisplayActionTaken, [
      item => {
        return (
          (parseInt(item.lesson_meta?.engagement?.engagementParticipation) +
            parseInt(item.lesson_meta?.engagement?.engagementDistracted) +
            parseInt(item.lesson_meta?.engagement?.engagementTiredBored) +
            parseInt(item.lesson_meta?.engagement?.engagementMotivate) +
            parseInt(item.lesson_meta?.engagement?.engagementAskQuestions)) /
          5
        )
      }
    ])

    let sortedChoiceNAT = sortedDatesNAT

    if (classesNAT) {
      sortedChoiceNAT = sortedLessonsNAT
    }
    if (datesNAT) {
      sortedChoiceNAT = sortedDatesNAT
    }
    if (commentNAT) {
      sortedChoiceNAT = [...sortedCommentsNAT].reverse()
    }
    if (punctualityNAT) {
      sortedChoiceNAT = [...sortedPunctualityNAT].reverse()
    }
    if (homeworkNAT) {
      sortedChoiceNAT = [...sortedHomeworkNAT].reverse()
    }
    if (engagementNAT) {
      sortedChoiceNAT = sortedEngagementNAT
    }

    return (
      <>
        <div className="row justify-content-center my-3" hidden={activeTab !== 'student'}>
          <div className="col-md-auto col-12">
            <Button
              className="mr-4"
              variant="outlined"
              color="primary"
              onClick={this.searchUsers(moment(day).subtract(1, 'd').format('YYYY-MM-DD'))}
              disabled={isLoading}>
              Prev Day
            </Button>
            <TextField type={'date'} label={'Day'} name={'day'} value={day} onChange={this.handleDateChange} />
            <Button
              className="ml-4"
              variant="outlined"
              color="primary"
              onClick={this.searchUsers(moment(day).add(1, 'd').format('YYYY-MM-DD'))}
              disabled={isLoading}>
              Next Day
            </Button>
          </div>

          <div className="col-md-auto col-2 justify-content-end">
            <Button
              className="ml-4"
              variant="outlined"
              color="primary"
              onClick={this.searchUsers(moment().startOf('day').format('YYYY-MM-DD'))}
              disabled={isLoading}>
              Today
            </Button>
          </div>
        </div>

        {sortedChoice.length === 0 && (
          <div className="my-5 text-center" hidden={activeTab !== 'student'}>
            No student lesson reported on this date ({moment(day).format('LL')})
          </div>
        )}

        {!isLoading && lessonCount !== 0 && sortedChoice.length > 0 && (
          <Table className="mb-5" hidden={activeTab !== 'student'}>
            <TableHead>
              <TableRow>
                <TableCell>Customer Service</TableCell>
                <TableCell>Name of student</TableCell>
                <TableCell className="pointer" onClick={() => this.handleSort('classes')}>
                  Class/Lesson name
                </TableCell>
                <TableCell className="pointer">Teacher name</TableCell>
                {/* <TableCell className="pointer" onClick={() => this.handleSort('dates')}>
                  Date of class
                </TableCell> */}
                {userRole === 'SuperAdmin' && (
                  <TableCell className="pointer" onClick={() => this.handleSort('comment')}>
                    Comment
                  </TableCell>
                )}
                <TableCell className="pointer" onClick={() => this.handleSort('punctual')}>
                  Punctuality
                </TableCell>
                <TableCell className="pointer" onClick={() => this.handleSort('homework')}>
                  Homework submission
                </TableCell>
                <TableCell className="pointer" onClick={() => this.handleSort('engagement')}>
                  Student engagement
                </TableCell>
                <TableCell>Sales Admin</TableCell>
                <TableCell className="pointer" onClick={() => this.handleSort('action')}>
                  Action
                </TableCell>
                {/* //check if action is ticked, then set state for text field */}
                <TableCell>Write a note</TableCell>
                <TableCell>Notes written</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* filter out lessons that have either comment, punct, enga, etc.  */}
              {/* if any left unchecked, bring down to the screen and show  */}
              {sortedChoice.map((lessons, index) => {
                let total =
                  (parseInt(lessons?.lesson_meta?.engagement?.engagementParticipation) +
                    parseInt(lessons?.lesson_meta?.engagement?.engagementDistracted) +
                    parseInt(lessons?.lesson_meta?.engagement?.engagementTiredBored) +
                    parseInt(lessons?.lesson_meta?.engagement?.engagementMotivate) +
                    parseInt(lessons?.lesson_meta?.engagement?.engagementAskQuestions)) /
                  5
                let overall = Math.round(total)
                return (
                  lessons && (
                    <TableRow key={index}>
                      <TableCell>{lessons?.student[0]?.staff_to_handle?.customer_service}</TableCell>
                      <TableCell>
                        <i
                          className="zmdi zmdi-eye zmdi-hc-fw"
                          onClick={() => this.openStudentDetails(lessons?.student[0])}></i>
                        {lessons?.student[0]?.name}
                      </TableCell>
                      <TableCell style={{ width: '10%' }}> {`${lessons?.class?.name}`}</TableCell>
                      <TableCell>{lessons?.tutor[0]?.name} </TableCell>
                      {/* <TableCell>{moment(lessons?.scheduled_on).format('LL')}</TableCell> */}
                      {userRole === 'SuperAdmin' && (
                        <TableCell style={{ width: '10%' }}>{lessons?.lesson_meta?.comment?.comment}</TableCell>
                      )}
                      <TableCell>{lessons?.lesson_meta?.punctuality === 'y' ? 'Late' : ''}</TableCell>
                      <TableCell>
                        {lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
                          ? 'Late'
                          : lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
                          ? 'No submission'
                          : ''}
                      </TableCell>
                      <TableCell>
                        {overall === 1 ? (
                          <>
                            <span style={{ fontSize: '25px' }}> 17% </span>
                            <span>
                              <br /> Bad
                            </span>
                          </>
                        ) : overall === 2 ? (
                          <>
                            <span style={{ fontSize: '20px' }}> 33% </span>
                            <span>
                              <br /> Not good
                            </span>
                          </>
                        ) : overall === 3 ? (
                          <>
                            <span style={{ fontSize: '17px' }}> 50% </span>
                            <span>
                              <br /> Can be better
                            </span>
                          </>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>{lessons?.student[0]?.staff_to_handle?.sales_admin} </TableCell>
                      <TableCell>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`${lessons._id}`}
                                checked={lessons?.lesson_meta?.checked}
                                color="primary"
                                onChange={this.handleCheckboxChange}
                              />
                            }
                          />
                        </FormControl>
                      </TableCell>
                      {lessons?.lesson_meta?.checked && (
                        <TableCell>
                          <div style={{ display: 'flex' }}>
                            <TextField
                              type={'text'}
                              multiline
                              label={`To ${lessons?.tutor[0]?.name}`}
                              name={'studentNotes'}
                              defaultValue={selectedLesson?._id === lessons._id ? studentNotes : ''}
                              onChange={this.handleNotesChange}
                            />

                            <button
                              style={{
                                borderRadius: '3px',
                                border: '2px solid blue',
                                backgroundColor: 'blue',
                                color: 'white'
                              }}
                              onClick={this.handleSaveChange(lessons, studentNotes)}>
                              {isSaved && selectedLesson?._id === lessons._id ? (
                                <i className="zmdi zmdi-check zmdi-hc-lg" />
                              ) : (
                                <i className="zmdi zmdi-floppy zmdi-hc-lg" />
                              )}
                            </button>
                          </div>
                          <div className="text-muted" style={{ fontSize: '9px' }}>
                            {lessons?.lesson_meta?.notes && `This will replace the notes written`}
                          </div>
                        </TableCell>
                      )}
                      {lessons?.lesson_meta?.checked && (
                        <TableCell>
                          {lessons?.lesson_meta?.notes}
                          <div className="text-muted" style={{ fontSize: '9px' }}>
                            {lessons?.lesson_meta?.notes &&
                              moment(lessons?.lesson_meta?.notes_timeStamp).format('llll')}
                          </div>
                          <div className="text-muted" style={{ fontSize: '9px' }}>
                            {lessons?.lesson_meta?.notes && `By ${lessons?.lesson_meta?.notes_editor}`}
                          </div>
                          {isSaved && selectedLesson?._id === lessons._id ? (
                            <span style={{ color: 'blue', fontSize: '10px' }}>
                              Changes will be seen when you refresh
                            </span>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                )
              })}
            </TableBody>
          </Table>
        )}

        {userRole === 'SuperAdmin' &&
          !isLoading &&
          recommendationConsultImmediately &&
          recommendationConsultImmediately.length > 0 && (
            <>
              <div
                style={{ marginTop: '100px', backgroundColor: 'red' }}
                className="row justify-content-center"
                hidden={activeTab !== 'student'}>
                <h3 style={{ paddingTop: '10px', color: 'white' }}>Consult with Kru A Immediately</h3>
              </div>
              <div style={{ overflow: 'auto', width: '100%', height: '300px' }}>
                <Table className="mb-5" hidden={activeTab !== 'student'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Customer Service</TableCell>
                      <TableCell>Name of student</TableCell>
                      <TableCell>Class/Lesson name</TableCell>
                      <TableCell>Date of class</TableCell>
                      {userRole === 'SuperAdmin' && <TableCell>Comment</TableCell>}
                      <TableCell>Punctuality</TableCell>
                      <TableCell>Homework submission</TableCell>
                      <TableCell>Student engagement</TableCell>
                      <TableCell>Sales Admin</TableCell>
                      <TableCell>Resolved?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recommendationConsultImmediately.map((lessons, index) => {
                      let total =
                        (parseInt(lessons?.lesson_meta?.engagement?.engagementParticipation) +
                          parseInt(lessons?.lesson_meta?.engagement?.engagementDistracted) +
                          parseInt(lessons?.lesson_meta?.engagement?.engagementTiredBored) +
                          parseInt(lessons?.lesson_meta?.engagement?.engagementMotivate) +
                          parseInt(lessons?.lesson_meta?.engagement?.engagementAskQuestions)) /
                        5
                      let overall = Math.round(total)
                      return (
                        lessons && (
                          <TableRow key={index}>
                            <TableCell>{lessons?.student[0]?.staff_to_handle?.customer_service} </TableCell>
                            <TableCell>
                              <i
                                className="zmdi zmdi-eye zmdi-hc-fw"
                                onClick={() => this.openStudentDetails(lessons?.student[0])}></i>
                              {lessons?.student[0]?.name}
                            </TableCell>
                            <TableCell> {`${lessons?.class?.name}`}</TableCell>
                            <TableCell>{moment(lessons?.scheduled_on).format('LL')}</TableCell>
                            {userRole === 'SuperAdmin' && (
                              <TableCell>{lessons?.lesson_meta?.comment?.comment}</TableCell>
                            )}
                            <TableCell>{lessons?.lesson_meta?.punctuality === 'y' ? 'Late' : ''}</TableCell>
                            <TableCell>
                              {lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
                                ? 'Late'
                                : lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
                                ? 'No submission'
                                : ''}
                            </TableCell>
                            <TableCell>
                              {overall === 1 ? (
                                <>
                                  <span style={{ fontSize: '25px' }}> 17% </span>
                                  <span>
                                    <br /> Bad
                                  </span>
                                </>
                              ) : overall === 2 ? (
                                <>
                                  <span style={{ fontSize: '20px' }}> 33% </span>
                                  <span>
                                    <br /> Not good
                                  </span>
                                </>
                              ) : overall === 3 ? (
                                <>
                                  <span style={{ fontSize: '17px' }}> 50% </span>
                                  <span>
                                    <br /> Can be better
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell>{lessons?.student[0]?.staff_to_handle?.sales_admin} </TableCell>
                            <TableCell>
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={`${lessons._id}`}
                                      checked={false}
                                      color="primary"
                                      onChange={this.handleConsultImmediately}
                                    />
                                  }
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </>
          )}

        {!isLoading && sortedChoiceNAT && sortedChoiceNAT.length > 0 && (
          <>
            <div
              style={{ marginTop: '100px', backgroundColor: 'orange' }}
              className="row justify-content-center"
              hidden={activeTab !== 'student'}>
              <h3 style={{ paddingTop: '10px' }}>No actions taken</h3>
            </div>
            <div style={{ overflow: 'auto', width: '100%', height: '300px' }}>
              <Table className="mb-5" hidden={activeTab !== 'student'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Customer Service</TableCell>
                    <TableCell>Name of student</TableCell>
                    <TableCell className="pointer" onClick={() => this.handleSortNAT('classesNAT')}>
                      Class/Lesson name
                    </TableCell>
                    <TableCell className="pointer" onClick={() => this.handleSortNAT('datesNAT')}>
                      Date of class
                    </TableCell>
                    {userRole === 'SuperAdmin' && (
                      <TableCell className="pointer" onClick={() => this.handleSortNAT('commentNAT')}>
                        Comment
                      </TableCell>
                    )}
                    <TableCell className="pointer" onClick={() => this.handleSortNAT('punctualNAT')}>
                      Punctuality
                    </TableCell>
                    <TableCell className="pointer" onClick={() => this.handleSortNAT('homeworkNAT')}>
                      Homework submission
                    </TableCell>
                    <TableCell className="pointer" onClick={() => this.handleSortNAT('engagementNAT')}>
                      Student engagement
                    </TableCell>
                    <TableCell>Sales Admin</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedChoiceNAT.map((lessons, index) => {
                    let total =
                      (parseInt(lessons?.lesson_meta?.engagement?.engagementParticipation) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementDistracted) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementTiredBored) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementMotivate) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementAskQuestions)) /
                      5
                    let overall = Math.round(total)
                    return (
                      lessons && (
                        <TableRow key={index}>
                          <TableCell>{lessons?.student[0]?.staff_to_handle?.customer_service} </TableCell>
                          <TableCell>
                            <i
                              className="zmdi zmdi-eye zmdi-hc-fw"
                              onClick={() => this.openStudentDetails(lessons?.student[0])}></i>
                            {lessons?.student[0]?.name}
                          </TableCell>
                          <TableCell> {`${lessons?.class?.name}`}</TableCell>
                          <TableCell>{moment(lessons?.scheduled_on).format('LL')}</TableCell>
                          {userRole === 'SuperAdmin' && <TableCell>{lessons?.lesson_meta?.comment?.comment}</TableCell>}
                          <TableCell>{lessons?.lesson_meta?.punctuality === 'y' ? 'Late' : ''}</TableCell>
                          <TableCell>
                            {lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
                              ? 'Late'
                              : lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
                              ? 'No submission'
                              : ''}
                          </TableCell>
                          <TableCell>
                            {overall === 1 ? (
                              <>
                                <span style={{ fontSize: '25px' }}> 17% </span>
                                <span>
                                  <br /> Bad
                                </span>
                              </>
                            ) : overall === 2 ? (
                              <>
                                <span style={{ fontSize: '20px' }}> 33% </span>
                                <span>
                                  <br /> Not good
                                </span>
                              </>
                            ) : overall === 3 ? (
                              <>
                                <span style={{ fontSize: '17px' }}> 50% </span>
                                <span>
                                  <br /> Can be better
                                </span>
                              </>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell>{lessons?.student[0]?.staff_to_handle?.sales_admin} </TableCell>
                          <TableCell>
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={`${lessons._id}`}
                                    checked={lessons?.lesson_meta?.checked}
                                    color="primary"
                                    onChange={this.handleCheckboxChange}
                                  />
                                }
                              />
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </>
        )}

        {userRole === 'SuperAdmin' && lessonsConsultation && lessonsConsultation.length > 0 && (
          <>
            <div
              style={{ marginTop: '100px', backgroundColor: '#BC5448' }}
              className="row justify-content-center"
              hidden={activeTab !== 'student'}>
              <h3 style={{ paddingTop: '10px', color: 'white' }}>
                Consult with Kru A Immediately (Saved on {moment().startOf('day').format('YYYY-MM-DD')})
              </h3>
            </div>
            <div style={{ overflow: 'auto', width: '100%', height: '300px' }}>
              <Table className="mb-5" hidden={activeTab !== 'student'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Customer Service</TableCell>
                    <TableCell>Name of student</TableCell>
                    <TableCell>Class/Lesson name</TableCell>
                    <TableCell>Date of class</TableCell>
                    {userRole === 'SuperAdmin' && <TableCell>Comment</TableCell>}
                    <TableCell>Punctuality</TableCell>
                    <TableCell>Homework submission</TableCell>
                    <TableCell>Student engagement</TableCell>
                    <TableCell>Sales Admin</TableCell>
                    <TableCell>Resolved?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lessonsConsultation.map((lessons, index) => {
                    let total =
                      (parseInt(lessons?.lesson_meta?.engagement?.engagementParticipation) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementDistracted) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementTiredBored) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementMotivate) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementAskQuestions)) /
                      5
                    let overall = Math.round(total)
                    return (
                      lessons && (
                        <TableRow key={index}>
                          <TableCell>{lessons?.student[0]?.staff_to_handle?.customer_service} </TableCell>
                          <TableCell>
                            <i
                              className="zmdi zmdi-eye zmdi-hc-fw"
                              onClick={() => this.openStudentDetails(lessons?.student[0])}></i>
                            {lessons?.student[0]?.name}
                          </TableCell>
                          <TableCell> {`${lessons?.class?.name}`}</TableCell>
                          <TableCell>{moment(lessons?.scheduled_on).format('LL')}</TableCell>
                          {userRole === 'SuperAdmin' && <TableCell>{lessons?.lesson_meta?.comment?.comment}</TableCell>}
                          <TableCell>{lessons?.lesson_meta?.punctuality === 'y' ? 'Late' : ''}</TableCell>
                          <TableCell>
                            {lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
                              ? 'Late'
                              : lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
                              ? 'No submission'
                              : ''}
                          </TableCell>
                          <TableCell>
                            {overall === 1 ? (
                              <>
                                <span style={{ fontSize: '25px' }}> 17% </span>
                                <span>
                                  <br /> Bad
                                </span>
                              </>
                            ) : overall === 2 ? (
                              <>
                                <span style={{ fontSize: '20px' }}> 33% </span>
                                <span>
                                  <br /> Not good
                                </span>
                              </>
                            ) : overall === 3 ? (
                              <>
                                <span style={{ fontSize: '17px' }}> 50% </span>
                                <span>
                                  <br /> Can be better
                                </span>
                              </>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell>{lessons?.student[0]?.staff_to_handle?.sales_admin} </TableCell>
                          <TableCell>Yes</TableCell>
                        </TableRow>
                      )
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </>
        )}
        {userRole === 'SuperAdmin' && noTeacherReport && noTeacherReport.length > 0 && (
          <>
            <div
              style={{ marginTop: '100px', backgroundColor: 'brown' }}
              className="row justify-content-center"
              hidden={activeTab !== 'student'}>
              <h3 style={{ paddingTop: '10px', color: 'white' }}>No Teacher Report</h3>
            </div>
            <div style={{ overflow: 'auto', width: '100%', height: '300px' }}>
              <Table className="mb-5" hidden={activeTab !== 'student'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Customer Service</TableCell>
                    <TableCell>Name of student</TableCell>
                    <TableCell>Class/Lesson name</TableCell>
                    <TableCell>Date of class</TableCell>
                    {userRole === 'SuperAdmin' && <TableCell>Comment</TableCell>}
                    <TableCell>Punctuality</TableCell>
                    <TableCell>Homework submission</TableCell>
                    <TableCell>Student engagement</TableCell>
                    <TableCell>Sales Admin</TableCell>
                    <TableCell>Resolved?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {noTeacherReport.map((lessons, index) => {
                    let total =
                      (parseInt(lessons?.lesson_meta?.engagement?.engagementParticipation) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementDistracted) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementTiredBored) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementMotivate) +
                        parseInt(lessons?.lesson_meta?.engagement?.engagementAskQuestions)) /
                      5
                    let overall = Math.round(total)
                    return (
                      lessons && (
                        <TableRow key={index}>
                          <TableCell>{lessons?.student[0]?.staff_to_handle?.customer_service} </TableCell>
                          <TableCell>
                            <i
                              className="zmdi zmdi-eye zmdi-hc-fw"
                              onClick={() => this.openStudentDetails(lessons?.student[0])}></i>
                            {lessons?.student[0]?.name}
                          </TableCell>
                          <TableCell> {`${lessons?.class?.name}`}</TableCell>
                          <TableCell>{moment(lessons?.scheduled_on).format('LL')}</TableCell>
                          {userRole === 'SuperAdmin' && <TableCell>{lessons?.lesson_meta?.comment?.comment}</TableCell>}
                          <TableCell>{lessons?.lesson_meta?.punctuality === 'y' ? 'Late' : ''}</TableCell>
                          <TableCell>
                            {lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
                              ? 'Late'
                              : lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
                              ? 'No submission'
                              : ''}
                          </TableCell>
                          <TableCell>
                            {overall === 1 ? (
                              <>
                                <span style={{ fontSize: '25px' }}> 17% </span>
                                <span>
                                  <br /> Bad
                                </span>
                              </>
                            ) : overall === 2 ? (
                              <>
                                <span style={{ fontSize: '20px' }}> 33% </span>
                                <span>
                                  <br /> Not good
                                </span>
                              </>
                            ) : overall === 3 ? (
                              <>
                                <span style={{ fontSize: '17px' }}> 50% </span>
                                <span>
                                  <br /> Can be better
                                </span>
                              </>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell>{lessons?.student[0]?.staff_to_handle?.sales_admin} </TableCell>
                          <TableCell>Yes</TableCell>
                        </TableRow>
                      )
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </>
        )}

        {isStudentDetailsDialogOpen && (
          <DialogStudentProfile
            studentDetailsDialog={studentDetailsDialog}
            isOpen={isStudentDetailsDialogOpen}
            toggle={this.openStudentDetails}
          />
        )}
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentInfoTab)
