import * as Type from 'constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case Type.MAKE_ANNOUNCEMENT:
      return {
        ...state,
        inProgress: true,
        announcementSent: false
      }
    case Type.MAKE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        inProgress: false,
        announcementSent: true
      }
    case Type.MAKE_ANNOUNCEMENT_FAILED:
      return {
        ...state,
        inProgress: false,
        announcementSent: false
      }
    default:
      return state
  }
}
