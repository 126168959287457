import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dialog, Card, CardContent, Paper, Popover, MenuItem, CircularProgress } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import agent from 'agent'
import educationLevels from 'assets/education-level.json'
import { GET_USER_CLASS } from 'constants/actionTypes'
import Chart from 'react-apexcharts'
// import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'

// const OverallImage = props => {
//   return (
//     <img
//       src={`https://epa-assets.s3.ap-southeast-1.amazonaws.com/lms/${props.props}`}
//       width={isMobile ? '300' : '400'}
//       height={isMobile ? '200' : '250'}></img>
//   )
// }

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updateUserInProgress: state.common.updateUserInProgress,
  chosenClass: state.classroom.chosenClass
})

const mapDispatchToProps = dispatch => ({
  getStudentClass: userId => dispatch({ type: GET_USER_CLASS, payload: agent.Classroom.getStudentClass(userId) })
})

class DialogStudentProfile extends React.Component {
  state = {
    showInviteLinkCopied: false,
    parent_report: false,
    news_letter: false,
    ontime: 0,
    late: 0,
    no: 0,
    totRNS: 0,
    totlateRNS: 0,
    totnoRNS: 0,
    user: null,
    options: {},
    series: [],
    optionsMastery: {},
    seriesMastery: [],
    indexClass: 0,
    classNow: [],
    showComments: false
  }

  componentDidMount() {
    this.setState({ user: this.props.studentDetailsDialog?._id })
    const { chosenClass } = this.props
    // this.props.getStudentClass(this.props.studentDetailsDialog?._id)
    this.setState({ classNow: chosenClass && chosenClass[0]?.lesson })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user !== this.state.user) {
      this.props.getStudentClass(this.props.studentDetailsDialog?._id)
    }

    const { indexClass } = this.state
    const { chosenClass } = this.props

    if (prevProps.chosenClass !== chosenClass || prevState.indexClass !== this.state.indexClass) {
      let chosenLessons = chosenClass[indexClass]?.lesson

      let filteredHomeworkSubmission = chosenLessons?.filter(
        lessons => lessons?.lesson_meta?.homework?.homeworkAssignment === 'y'
      )

      let filteredOnTimeHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '1'
      )

      let filteredLateHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
      )

      let filteredNoHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
      )

      let lateHWSLength = filteredLateHWS?.length
      let noHWSLength = filteredNoHWS?.length
      let onTimeHWSLength = filteredOnTimeHWS?.length

      let filteredHWSTotalLength = onTimeHWSLength + lateHWSLength + noHWSLength

      let tot = (onTimeHWSLength / filteredHWSTotalLength) * 100
      let totlate = (lateHWSLength / filteredHWSTotalLength) * 100
      let totno = (noHWSLength / filteredHWSTotalLength) * 100

      let filteredOnTimeRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '1')

      let filteredLateRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '2')

      let filteredNoRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '3')

      let totalRNS = filteredOnTimeRNS?.length + filteredLateRNS?.length + filteredNoRNS?.length

      let totRNS = (filteredOnTimeRNS?.length / totalRNS) * 100
      let totlateRNS = (filteredLateRNS?.length / totalRNS) * 100
      let totnoRNS = (filteredNoRNS?.length / totalRNS) * 100

      let filteredEngagementLessons = chosenLessons?.filter(
        lessons =>
          lessons?.lesson_meta?.engagement?.engagementParticipation > 0 &&
          lessons?.lesson_meta?.engagement?.engagementDistracted > 0 &&
          lessons?.lesson_meta?.engagement?.engagementTiredBored > 0 &&
          lessons?.lesson_meta?.engagement?.engagementMotivate > 0 &&
          lessons?.lesson_meta?.engagement?.engagementAskQuestions > 0
      )

      let filteredMasteryLessons = chosenLessons?.filter(
        lessons =>
          lessons?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryConfused > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryParticipative > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept > 0
      )

      const generateColors = data => {
        return data?.map((d, idx) => {
          let color = d >= 1 && d <= 2 ? '#ef4544' : d >= 3 && d <= 4 ? '#F28C28' : d >= 5 && d <= 6 ? '#50C878' : ''

          return {
            offset: (idx / data?.length) * 100,
            color,
            opacity: 1
          }
        })
      }

      this.setState({
        classNow: chosenLessons,
        ontime: tot,
        late: totlate,
        no: totno,
        totRNS: totRNS,
        totlateRNS: totlateRNS,
        totnoRNS: totnoRNS,
        options: {
          chart: {
            id: 'engagement'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              colorStops: generateColors(
                filteredEngagementLessons?.map(lesson =>
                  Math.round(
                    (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                      5
                  )
                )
              )
            }
          },
          xaxis: {
            categories: filteredEngagementLessons?.map(lessons => lessons.name)
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: `Engagement level over ${filteredEngagementLessons?.length} lessons`,
            align: 'left'
          }
        },
        series: [
          {
            name: 'Engagement',
            data:
              filteredEngagementLessons === undefined
                ? []
                : filteredEngagementLessons?.map(lesson =>
                    Math.round(
                      (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                        5
                    )
                  )
          }
        ],
        optionsMastery: {
          chart: {
            id: 'mastery'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              colorStops: generateColors(
                filteredMasteryLessons?.map(lesson =>
                  Math.round(
                    (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                      5
                  )
                )
              )
            }
          },
          xaxis: {
            categories: filteredMasteryLessons?.map(lessons => lessons.name)
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: `Mastery of skills over ${filteredMasteryLessons?.length} lessons`,
            align: 'left'
          }
        },
        seriesMastery: [
          {
            name: 'Mastery',
            data:
              filteredMasteryLessons === undefined
                ? []
                : filteredMasteryLessons?.map(lesson =>
                    Math.round(
                      (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                        5
                    )
                  )
          }
        ]
      })
    }
  }

  handleShowClass = index => {
    this.setState({
      indexClass: index
    })
  }

  handlePopoverOpen = (event, value) => {
    this.setState({ anchorEl: event.currentTarget })
    if (value === 'punctual') {
      this.setState({
        punctualityDialog: true,
        cancellationDialog: false,
        homeworkDialog: false,
        revisionNotesDialog: false,
        engagementDialog: false,
        masteryDialog: false
      })
    }
    if (value === 'cancelled') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: true,
        homeworkDialog: false,
        revisionNotesDialog: false,
        engagementDialog: false,
        masteryDialog: false
      })
    }
    if (value === 'homework') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: false,
        homeworkDialog: true,
        revisionNotesDialog: false,
        engagementDialog: false,
        masteryDialog: false
      })
    }
    if (value === 'revisionnotes') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: false,
        homeworkDialog: false,
        revisionNotesDialog: true,
        engagementDialog: false,
        masteryDialog: false
      })
    }
    if (value === 'engagement') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: false,
        homeworkDialog: false,
        revisionNotesDialog: false,
        engagementDialog: true,
        masteryDialog: false
      })
    }
    if (value === 'mastery') {
      this.setState({
        punctualityDialog: false,
        cancellationDialog: false,
        homeworkDialog: false,
        revisionNotesDialog: false,
        engagementDialog: false,
        masteryDialog: true
      })
    }
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  handleSwitchChange = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  render() {
    const openDialog = Boolean(this.state.anchorEl)
    const { currentUser, updateUserInProgress, chosenClass, studentDetailsDialog, isOpen, toggle } = this.props
    const {
      email,
      name,
      gender,
      date_of_birth,
      phone_country_code,
      phone,
      level,
      school,
      first_language,
      parent,
      referral_code,
      credit
    } = currentUser
    let {
      showInviteLinkCopied,
      parent_report,
      news_letter,
      ontime,
      late,
      no,
      totRNS,
      totlateRNS,
      totnoRNS,
      punctualityDialog,
      cancellationDialog,
      homeworkDialog,
      revisionNotesDialog,
      engagementDialog,
      masteryDialog,
      classNow,
      indexClass,
      showComments
    } = this.state
    let displayLevel = '---'
    if (level) {
      let levelObj = _.find(educationLevels, function (o) {
        return o.code === level
      })
      if (levelObj) {
        displayLevel = levelObj.name
      } else {
        displayLevel = level
      }
    }
    let creditBalance = 0
    let userCredit = credit?.credit
    if (userCredit) {
      let userCountry = currentUser.country
      if (userCountry === 'SG') {
        if (userCredit.SGD && userCredit.SGD.balance > 0) {
          creditBalance = userCredit.SGD.balance
        }
      } else if (userCountry === 'US') {
        if (userCredit.USD && userCredit.USD.balance > 0) {
          creditBalance = userCredit.USD.balance
        }
      }
    }

    let genderText = '---'
    if (gender === 'f') {
      genderText = 'Female'
    } else if (gender === 'm') {
      genderText = 'Male'
    }

    let chosenLessons = classNow

    let filteredAttendanceEarly = chosenLessons?.filter(lessons => lessons?.lesson_meta?.punctuality === 'n')
    let filteredAttendanceLate = chosenLessons?.filter(lessons => lessons?.lesson_meta?.punctuality === 'y')

    let filteredAttendanceTotal = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.punctuality === 'y' || lessons?.lesson_meta?.punctuality === 'n'
    )
    let attendanceEarly = (filteredAttendanceEarly?.length / filteredAttendanceTotal?.length) * 100
    let attendanceLate = (filteredAttendanceLate?.length / filteredAttendanceTotal?.length) * 100

    let sumEngagement = 0
    let sumMastery = 0
    let totalSumEngagement = 0
    let totalSumMastery = 0

    let filteredEngagementLessons = chosenLessons?.filter(
      lessons =>
        lessons?.lesson_meta?.engagement?.engagementParticipation > 0 &&
        lessons?.lesson_meta?.engagement?.engagementDistracted > 0 &&
        lessons?.lesson_meta?.engagement?.engagementTiredBored > 0 &&
        lessons?.lesson_meta?.engagement?.engagementMotivate > 0 &&
        lessons?.lesson_meta?.engagement?.engagementAskQuestions > 0
    )

    let filteredMasteryLessons = chosenLessons?.filter(
      lessons =>
        lessons?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryConfused > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryParticipative > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept > 0
    )

    let filteredLessonTopics = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.lesson_topic !== '' || lessons?.lesson_meta?.lesson_topic !== undefined
    )

    let filteredLessonComments = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.comment?.comment !== '' || lessons?.lesson_meta?.comment?.comment !== undefined
    )

    let punctualityColor = '#F44336'
    let cancellationColor = '#F44336'
    let engagementColor = '#F44336'
    let engagementPercentage
    let masteryColor = '#F44336'
    let masteryPercentage
    let homeworkColor = '#F44336'
    let revisionNotesColor = '#F44336'

    if (attendanceEarly >= 0 && attendanceEarly <= 49) {
      punctualityColor = '#F44336'
    }
    if (attendanceEarly >= 50 && attendanceEarly <= 80) {
      punctualityColor = '#F28C28'
    }
    if (attendanceEarly >= 81 && attendanceEarly <= 100) {
      punctualityColor = '#50C878'
    }

    let homeworkSubmissionTotal = ontime + late

    if (homeworkSubmissionTotal >= 0 && homeworkSubmissionTotal <= 49) {
      homeworkColor = '#F44336'
    }
    if (homeworkSubmissionTotal >= 50 && homeworkSubmissionTotal <= 80) {
      homeworkColor = '#F28C28'
    }
    if (homeworkSubmissionTotal >= 81 && homeworkSubmissionTotal <= 100) {
      homeworkColor = '#50C878'
    }

    let revisionSubmissionTotal = totRNS + totlateRNS

    if (revisionSubmissionTotal >= 0 && revisionSubmissionTotal <= 49) {
      revisionNotesColor = '#F44336'
    }
    if (revisionSubmissionTotal >= 50 && revisionSubmissionTotal <= 80) {
      revisionNotesColor = '#F28C28'
    }
    if (revisionSubmissionTotal >= 81 && revisionSubmissionTotal <= 100) {
      revisionNotesColor = '#50C878'
    }

    let filteredHomeworkSubmission = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.homework?.homeworkAssignment === 'y'
    )

    let filteredOnTimeHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '1'
    )

    let filteredOnTimeRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '1')

    let filteredLateHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '2'
    )
    let filteredNoHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework?.homeworkSubmission === '3'
    )
    let filteredLateRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '2')

    let filteredNoRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '3')

    return (
      <Dialog open={isOpen} onClose={toggle} fullWidth maxWidth="lg">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <Paper elevation={2} className="app-wrapper">
            <div className="row justify-content-between">
              <h2 className="mb-0">Profile</h2>
              <i className="zmdi zmdi-close zmdi-hc-2x mr-2" onClick={toggle} />
            </div>
          </Paper>
          <div className="app-wrapper">
            <div className="container">
              <div className="col-12 mt-3">
                <Card className="mb-3">
                  <CardContent>
                    <div className="row">
                      <div className="col-12 row justify-content-around">
                        {chosenClass ? (
                          chosenClass?.map((classes, index) => (
                            <div key={index} className="col-md-3 col-12">
                              <div
                                style={{
                                  fontSize: 15,
                                  minHeight: '80px',
                                  backgroundColor: index === indexClass ? 'lightgreen' : '',
                                  textAlign: 'center'
                                }}
                                className="col-md-12 col-12 my-2 py-4 box-dashboard"
                                onClick={() => this.handleShowClass(index)}>
                                {classes?.name.slice(6)}
                                {' ('}
                                {classes?.tutor[0]?.name}
                                {') '}
                              </div>
                            </div>
                          ))
                        ) : (
                          <CircularProgress />
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="row">
                <div className="col-lg-12 col-12">
                  <Card className="mb-3">
                    <CardContent>
                      <h2>Student Information</h2>
                      <div className="row align-items-center">
                        <div className="col-md-2 col-5 my-2 font-weight-medium">Email</div>
                        <div className="col-md-10 col-7 my-2">{studentDetailsDialog?.email}</div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Name</div>
                        <div className="col-md-10 col-7 my-2">{studentDetailsDialog?.name}</div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">School</div>
                        <div className="col-md-10 col-7 my-2">{studentDetailsDialog?.school}</div>
                        
                        <div className="col-md-2 col-5 my-2 font-weight-medium">Level</div>
                        <div className="col-md-10 col-7 my-2">
                          {studentDetailsDialog?.level}{' '}
                          {studentDetailsDialog?.gradeYearLevel?.grade
                            ? studentDetailsDialog?.gradeYearLevel?.grade
                            : studentDetailsDialog?.gradeYearLevel?.year}{' '}
                        </div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Date of birth</div>
                        <div className="col-md-10 col-7 my-2">
                          {studentDetailsDialog?.date_of_birth ? moment(date_of_birth).format('ll') : '---'}
                        </div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Gender</div>
                        <div className="col-md-10 col-7 my-2">{studentDetailsDialog?.genderText}</div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Phone number</div>
                        <div className="col-md-10 col-7 my-2">
                          {studentDetailsDialog?.phone ? (
                            <span>
                              (+{phone_country_code}) {phone}
                            </span>
                          ) : (
                            '---'
                          )}
                        </div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Social Media</div>
                        <div className="col-md-10 col-7 my-2">
                          {studentDetailsDialog?.facebook ?? '---'} / {studentDetailsDialog?.line ?? '---'}
                        </div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Contact Preference</div>
                        <div className="col-md-10 col-7 my-2">
                          {studentDetailsDialog?.contact_preference?.contact === 'nil'
                            ? '---'
                            : studentDetailsDialog?.contact_preference?.contact === 'others'
                            ? studentDetailsDialog?.contact_preference?.others_type
                            : studentDetailsDialog?.contact_preference?.contact === 'line'
                            ? 'Line'
                            : studentDetailsDialog?.contact_preference?.contact === 'whatsapp'
                            ? 'Whatsapp'
                            : ''}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <div className="col-12 mt-3">
                  <Card className="mb-3">
                    <CardContent>
                      {/* <h2>Lessons</h2> */}
                      <div className="row align-items-center">
                        <div className="col-12 row justify-content-around">
                          <div className="col-md-3 col-12">
                            <div className="col-md-12 col-12 my-2 py-4 font-weight-medium box-dashboard">
                              <div style={{ fontSize: 17 }}>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>
                                  <strong style={{ fontSize: 50, color: punctualityColor }}>
                                    {Math.round(attendanceEarly)}%
                                  </strong>
                                </div>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>punctuality </div>
                              </div>
                              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <span>
                                  late to {filteredAttendanceLate?.length} out of {filteredAttendanceTotal?.length}{' '}
                                  classes{' '}
                                </span>
                                {isMobile ? (
                                  <span
                                    className="pointer"
                                    onClick={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'punctual')
                                    }}>
                                    ▼
                                  </span>
                                ) : (
                                  <span
                                    className="pointer"
                                    onMouseEnter={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'punctual')
                                    }}>
                                    ▼
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="col-md-12 col-12 my-2 py-4 font-weight-medium box-dashboard">
                              {' '}
                              <div style={{ fontSize: 17 }}>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>
                                  <strong
                                    style={{
                                      fontSize: 50,
                                      color: punctualityColor
                                      // to change this color to cancellationColor
                                    }}>
                                    {}%
                                  </strong>
                                </div>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>cancellation rate </div>
                              </div>
                              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <span>
                                  cancelled {} out of {} classes{' '}
                                </span>
                                {isMobile ? (
                                  <span
                                    className="pointer"
                                    onClick={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'cancelled')
                                    }}>
                                    ▼
                                  </span>
                                ) : (
                                  <span
                                    className="pointer"
                                    onMouseEnter={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'cancelled')
                                    }}>
                                    ▼
                                  </span>
                                )}
                              </div>{' '}
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="col-md-12 col-12 my-2 py-4  font-weight-medium box-dashboard">
                              <div style={{ fontSize: 17 }}>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>
                                  <strong style={{ fontSize: 50, color: homeworkColor }}>
                                    {Math.round(homeworkSubmissionTotal)}%
                                  </strong>
                                </div>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>homework submission</div>
                              </div>
                              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <span>{filteredOnTimeHWS?.length} submitted on time</span>
                                {isMobile ? (
                                  <span
                                    className="pointer"
                                    onClick={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'homework')
                                    }}>
                                    ▼
                                  </span>
                                ) : (
                                  <span
                                    className="pointer"
                                    onMouseEnter={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'homework')
                                    }}>
                                    ▼
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="col-md-12 col-12 my-2 py-4  font-weight-medium box-dashboard">
                              <div style={{ fontSize: 17 }}>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>
                                  <strong style={{ fontSize: 50, color: revisionNotesColor }}>
                                    {Math.round(revisionSubmissionTotal)}%
                                  </strong>
                                </div>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>
                                  revision notes submission{' '}
                                </div>
                              </div>
                              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <span>{filteredOnTimeRNS?.length} submitted on time</span>
                                {isMobile ? (
                                  <span
                                    className="pointer"
                                    onClick={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'revisionnotes')
                                    }}>
                                    ▼
                                  </span>
                                ) : (
                                  <span
                                    className="pointer"
                                    onMouseEnter={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'revisionnotes')
                                    }}>
                                    ▼
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 row justify-content-around">
                          <div className="col-md-3 col-12">
                            <div
                              className="col-md-12 col-12 my-2 font-weight-medium box-dashboard"
                              style={{
                                paddingTop: isMobile ? '20px' : '85px',
                                paddingBottom: isMobile ? '20px' : '85px'
                              }}>
                              {filteredEngagementLessons?.map(lesson => {
                                {
                                  let total =
                                    (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                                      parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                                      parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                                      parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                                      parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                                    5
                                  let overall = Math.round(total)
                                  sumEngagement = sumEngagement + overall
                                  totalSumEngagement = sumEngagement / filteredEngagementLessons?.length
                                }

                                if (totalSumEngagement >= 1 && totalSumEngagement <= 2) {
                                  engagementColor = '#F44336'
                                }
                                if (totalSumEngagement >= 3 && totalSumEngagement <= 4) {
                                  engagementColor = '#F28C28'
                                }
                                if (totalSumEngagement >= 5 && totalSumEngagement <= 6) {
                                  engagementColor = '#50C878'
                                }

                                engagementPercentage = Math.round((totalSumEngagement / 6) * 100)
                              })}
                              <div style={{ fontSize: 17 }}>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>
                                  <strong style={{ fontSize: 50, color: engagementColor }}>
                                    {engagementPercentage}%
                                  </strong>
                                </div>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>engagement </div>
                              </div>
                              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <span>Show more</span>
                                {isMobile ? (
                                  <span
                                    className="pointer"
                                    onClick={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'engagement')
                                    }}>
                                    ▼
                                  </span>
                                ) : (
                                  <span
                                    className="pointer"
                                    onMouseEnter={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'engagement')
                                    }}>
                                    ▼
                                  </span>
                                )}
                              </div>
                            </div>{' '}
                          </div>

                          <div className="col-md-9">
                            <div className="col-md-12 my-2 font-weight-medium box-dashboard">
                              {' '}
                              <Chart
                                options={this.state.options}
                                series={this.state.series}
                                type="line"
                                width={isMobile ? '300' : '750'}
                                height="310"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 row justify-content-around">
                          <div className="col-md-3 col-12">
                            <div
                              className="col-md-12 col-12 my-2 font-weight-medium box-dashboard"
                              style={{
                                paddingTop: isMobile ? '20px' : '85px',
                                paddingBottom: isMobile ? '20px' : '85px'
                              }}>
                              {filteredMasteryLessons?.map(lesson => {
                                {
                                  let total =
                                    (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                                    5
                                  let overall = Math.round(total)
                                  sumMastery = sumMastery + overall
                                  totalSumMastery = sumMastery / filteredMasteryLessons?.length
                                }

                                if (totalSumMastery >= 1 && totalSumMastery <= 2) {
                                  masteryColor = '#F44336'
                                }
                                if (totalSumMastery >= 3 && totalSumMastery <= 4) {
                                  masteryColor = '#F28C28'
                                }
                                if (totalSumMastery >= 5 && totalSumMastery <= 6) {
                                  masteryColor = '#50C878'
                                }

                                masteryPercentage = Math.round((totalSumMastery / 6) * 100)
                              })}

                              <div style={{ fontSize: 17 }}>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>
                                  <strong style={{ fontSize: 50, color: masteryColor }}>{masteryPercentage}%</strong>
                                </div>
                                <div style={{ display: 'grid', justifyContent: 'center' }}>mastery of skills </div>
                              </div>
                              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <span>Show more</span>
                                {isMobile ? (
                                  <span
                                    className="pointer"
                                    onClick={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'mastery')
                                    }}>
                                    ▼
                                  </span>
                                ) : (
                                  <span
                                    className="pointer"
                                    onMouseEnter={(event, value) => {
                                      if (value !== null) this.handlePopoverOpen(event, 'mastery')
                                    }}>
                                    ▼
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9 col-12">
                            <div className="col-md-12 col-12 my-2 font-weight-medium box-dashboard">
                              <Chart
                                options={this.state.optionsMastery}
                                series={this.state.seriesMastery}
                                type="line"
                                width={isMobile ? '300' : '750'}
                                height="310"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="col-md-12 col-12 my-2 py-4  font-weight-medium box-dashboard">
                            <div style={{ fontSize: 17 }}>Lesson Topics</div>
                            {filteredLessonTopics &&
                              [...filteredLessonTopics].reverse().map((lesson, index) => (
                                <div key={index}>
                                  Lesson {index + 1}) {lesson?.lesson_meta?.lesson_topic}
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="col-md-12 col-12 my-2 py-4  font-weight-medium box-dashboard">
                            <div style={{ fontSize: 17 }}>Teacher Comments</div>
                            {showComments ? (
                              filteredLessonComments && (
                                <>
                                  {[...filteredLessonComments].reverse().map((lesson, index) => (
                                    <div key={index}>
                                      Lesson {index + 1}) {lesson?.lesson_meta?.comment?.comment}
                                    </div>
                                  ))}
                                  <div onClick={() => this.setState({ showComments: false })}> Hide content</div>
                                </>
                              )
                            ) : (
                              <div onClick={() => this.setState({ showComments: true })}> Show content</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <div className="col-lg-12 col-12">
                  <Card className="mb-3">
                    <CardContent>
                      <h2>School & Academic</h2>
                      <div className="row align-items-center">
                        <div className="col-md-2 col-5 my-2 font-weight-medium">Current School</div>
                        <div className="col-md-10 col-7 my-2">{studentDetailsDialog?.school ?? '---'}</div>

                        {studentDetailsDialog?.typeOfSchool && (
                          <>
                            <div className="col-md-2 col-5 my-2 font-weight-medium">Type of School</div>
                            <div className="col-md-10 col-7 my-2">
                              {studentDetailsDialog?.typeOfSchool === 'international'
                                ? 'International'
                                : studentDetailsDialog?.typeOfSchool === 'government'
                                ? 'Government'
                                : studentDetailsDialog?.typeOfSchool}
                            </div>
                          </>
                        )}

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Level: Year / Grade</div>
                        <div className="col-md-10 col-7 mt-2">
                          {studentDetailsDialog?.level}{' '}
                          {studentDetailsDialog?.gradeYearLevel?.grade
                            ? studentDetailsDialog?.gradeYearLevel?.grade
                            : studentDetailsDialog?.gradeYearLevel?.year}
                        </div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Medium of Study</div>
                        <div className="col-md-10 col-7 my-2">
                          {`${studentDetailsDialog?.studyMediumEng === true ? 'English' : '---'} ${
                            studentDetailsDialog?.studyMediumThai === true ? 'Thai' : ''
                          }`}
                        </div>

                        <div className="col-md-1 col-5 mt-md-5 my-2 font-weight-medium">Grades (GPA)</div>

                        <div className="row col-md-11 col-12 d-md-none d-flex">
                          {studentDetailsDialog?.grade?.gpa?.art && (
                            <div className="col-md-1 col-auto my-2">
                              Art <br /> {studentDetailsDialog?.grade?.gpa?.art}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.biology && (
                            <div className="col-md-1 col-auto my-2">
                              Biology <br />
                              {studentDetailsDialog?.grade?.gpa?.biology}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.chemistry && (
                            <div className="col-md-1 col-auto my-2">
                              Chemistry <br />
                              {studentDetailsDialog?.grade?.gpa?.chemistry}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.economics && (
                            <div className="col-md-1 col-auto my-2">
                              Economics <br />
                              {studentDetailsDialog?.grade?.gpa?.economics}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.english && (
                            <div className="col-md-1 col-auto my-2">
                              English {studentDetailsDialog?.grade?.gpa?.english}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.foreign_language && (
                            <div className="col-md-1 col-auto my-2">
                              Foreign Language <br />
                              {studentDetailsDialog?.grade?.gpa?.foreign_language}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.geography && (
                            <div className="col-md-1 col-auto my-2">
                              Geography <br />
                              {studentDetailsDialog?.grade?.gpa?.geography}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.history && (
                            <div className="col-md-1 col-auto my-2">
                              History <br />
                              {studentDetailsDialog?.grade?.gpa?.history}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.physics && (
                            <div className="col-md-1 col-auto my-2">
                              Physics <br />
                              {studentDetailsDialog?.grade?.gpa?.physics}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.politics && (
                            <div className="col-md-1 col-auto my-2">
                              Politics <br />
                              {studentDetailsDialog?.grade?.gpa?.politics}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.social_studies && (
                            <div className="col-md-1 col-auto my-2">
                              Social studies <br />
                              {studentDetailsDialog?.grade?.gpa?.social_studies}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.Thai && (
                            <div className="col-md-1 col-auto my-2">
                              Thai <br />
                              {studentDetailsDialog?.grade?.gpa?.Thai}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.others_type && (
                            <div className="col-md-1 col-auto my-2">
                              Others <br />
                              {studentDetailsDialog?.grade?.gpa?.others_type}
                            </div>
                          )}
                        </div>

                        <div className="row col-md-11 col-12 d-md-flex d-none bt-grades bb-grades">
                          {studentDetailsDialog?.grade?.gpa?.art && (
                            <div className="col-md-1 col-auto my-2 br-grades">Art</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.biology && (
                            <div className="col-md-1 col-auto my-2 br-grades">Biology </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.chemistry && (
                            <div className="col-md-1 col-auto my-2 br-grades">Chemistry</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.economics && (
                            <div className="col-md-1 col-auto my-2 br-grades">Economics</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.english && (
                            <div className="col-md-1 col-auto my-2 br-grades">English</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.foreign_language && (
                            <div className="col-md-1 col-auto my-2 br-grades">Foreign Language</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.geography && (
                            <div className="col-md-1 col-auto my-2 br-grades">Geography </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.history && (
                            <div className="col-md-1 col-auto my-2 br-grades">History </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.physics && (
                            <div className="col-md-1 col-auto my-2 br-grades">Physics</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.politics && (
                            <div className="col-md-1 col-auto my-2 br-grades">Politics</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.social_studies && (
                            <div className="col-md-1 col-auto my-2 br-grades">Social studies</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.thai && (
                            <div className="col-md-1 col-auto my-2 br-grades">Thai</div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.others_type && (
                            <div className="col-md-1 col-auto my-2 br-grades">Others</div>
                          )}
                        </div>
                        <div className="col-md-1 col-5 my-2 font-weight-medium d-md-flex d-none"></div>
                        <div className="row col-md-11 col-12 d-md-flex d-none bb-grades">
                          {studentDetailsDialog?.grade?.gpa?.art && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.art}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.biology && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.biology}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.chemistry && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.chemistry}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.economics && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.economics}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.english && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.english}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.history && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.history}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.foreign_language && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.foreign_language}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.geography && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.geography}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.physics && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.physics}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.politics && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.politics}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.social_studies && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.social_studies}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.thai && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.thai}
                            </div>
                          )}
                          {studentDetailsDialog?.grade?.gpa?.others_type && (
                            <div className="col-md-1 col-auto my-2 br-grades">
                              {studentDetailsDialog?.grade?.gpa?.others_type}
                            </div>
                          )}
                        </div>

                        <div className="col-md-2 col-5 mt-5 mb-2 font-weight-medium">Favorite Subject(s)</div>
                        <div className="row col-md-10 col-12">
                          {studentDetailsDialog?.favoriteSubjects?.art && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Art </div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.biology && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Biology </div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.chemistry && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Chemistry</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.economics && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Economics</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.english && (
                            <div className="col-md-1 col-auto mt-5 mb-2">English</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.history && (
                            <div className="col-md-1 col-auto mt-5 mb-2">History</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.foreign_language && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Foreign Language</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.geography && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Geography</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.physics && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Physics</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.politics && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Politics</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.social_studies && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Social studies</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.thai && (
                            <div className="col-md-1 col-auto mt-5 mb-2">Thai</div>
                          )}
                          {studentDetailsDialog?.favoriteSubjects?.others_type && (
                            <div className="col-md-1 col-auto mt-5 mb-2">
                              {studentDetailsDialog?.favoriteSubjects?.others_type}{' '}
                            </div>
                          )}
                        </div>
                        <div className="col-md-2 col-5 my-2 font-weight-medium">Weakest Subject(s)</div>
                        <div className="row col-md-10 col-12">
                          {studentDetailsDialog?.weakestSubjects?.art && (
                            <div className="col-md-1 col-auto my-2">Art </div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.biology && (
                            <div className="col-md-1 col-auto my-2">Biology </div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.chemistry && (
                            <div className="col-md-1 col-auto my-2">Chemistry </div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.economics && (
                            <div className="col-md-1 col-auto my-2">Economics</div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.english && (
                            <div className="col-md-1 col-auto my-2">English</div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.history && (
                            <div className="col-md-1 col-auto my-2">History</div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.foreign_language && (
                            <div className="col-md-1 col-auto my-2">Foreign Language</div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.geography && (
                            <div className="col-md-1 col-auto my-2">Geography</div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.physics && (
                            <div className="col-md-1 col-auto my-2">Physics </div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.politics && (
                            <div className="col-md-1 col-auto my-2">Politics </div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.social_studies && (
                            <div className="col-md-1 col-auto my-2">Social studies</div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.thai && (
                            <div className="col-md-1 col-auto my-2">Thai</div>
                          )}
                          {studentDetailsDialog?.weakestSubjects?.others_type && (
                            <div className="col-md-1 col-auto my-2">
                              {studentDetailsDialog?.weakestSubjects?.others_type}{' '}
                            </div>
                          )}
                        </div>
                        <div className="col-md-2 col-5 my-2 font-weight-medium">Type of external test taken</div>
                        <div className="row col-md-10 col-12">
                          {studentDetailsDialog?.externalExams?.gmat && (
                            <div className="col-md-1 col-auto my-2">GMAT</div>
                          )}
                          {studentDetailsDialog?.externalExams?.gre && (
                            <div className="col-md-1 col-auto my-2">GRE</div>
                          )}
                          {studentDetailsDialog?.externalExams?.ietls && (
                            <div className="col-md-1 col-auto my-2">IELTS</div>
                          )}
                          {studentDetailsDialog?.externalExams?.sat && (
                            <div className="col-md-1 col-auto my-2">SAT</div>
                          )}
                          {studentDetailsDialog?.externalExams?.toefl && (
                            <div className="col-md-1 col-auto my-2">TOEFL</div>
                          )}
                          {studentDetailsDialog?.externalExams?.others_type && (
                            <div className="col-md-1 col-auto my-2">
                              {studentDetailsDialog?.externalExams?.others_type}{' '}
                            </div>
                          )}
                        </div>
                        <div className="col-md-2 col-5 my-2 font-weight-medium">
                          Type of course taken at other tuition centre
                        </div>
                        <div className="row col-md-10 col-12">
                          {studentDetailsDialog?.coursesTuition?.art && (
                            <div className="col-md-1 col-auto my-2">
                              Art <br /> {studentDetailsDialog?.coursesTuition?.art}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.biology && (
                            <div className="col-md-1 col-auto my-2">
                              Biology <br />
                              {studentDetailsDialog?.coursesTuition?.biology}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.chemistry && (
                            <div className="col-md-1 col-auto my-2">
                              Chemistry <br />
                              {studentDetailsDialog?.coursesTuition?.chemistry}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.economics && (
                            <div className="col-md-1 col-auto my-2">
                              Economics <br />
                              {studentDetailsDialog?.coursesTuition?.economics}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.english && (
                            <div className="col-md-1 col-auto my-2">
                              English {studentDetailsDialog?.coursesTuition?.english}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.history && (
                            <div className="col-md-1 col-auto my-2">
                              History {studentDetailsDialog?.coursesTuition?.history}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.foreign_language && (
                            <div className="col-md-1 col-auto my-2">
                              Foreign Language <br />
                              {studentDetailsDialog?.coursesTuition?.foreign_language}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.geography && (
                            <div className="col-md-1 col-auto my-2">
                              Geography <br />
                              {studentDetailsDialog?.coursesTuition?.geography}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.physics && (
                            <div className="col-md-1 col-auto my-2">
                              Physics <br />
                              {studentDetailsDialog?.coursesTuition?.physics}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.politics && (
                            <div className="col-md-1 col-auto my-2">
                              Politics <br />
                              {studentDetailsDialog?.coursesTuition?.politics}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.social_studies && (
                            <div className="col-md-1 col-auto my-2">
                              Social studies <br />
                              {studentDetailsDialog?.coursesTuition?.social_studies}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.thai && (
                            <div className="col-md-1 col-auto my-2">
                              Thai {studentDetailsDialog?.coursesTuition?.thai}
                            </div>
                          )}
                          {studentDetailsDialog?.coursesTuition?.others_type && (
                            <div className="col-md-1 col-auto my-2">
                              Others <br />
                              {studentDetailsDialog?.coursesTuition?.others_type}
                            </div>
                          )}
                        </div>
                        <div className="col-md-2 col-5 my-2 font-weight-medium">Type of course taken at EPA</div>
                        <div className="row col-md-10 col-12">
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.art && (
                            <div className="col-md-1 col-auto my-2">
                              Art <br /> {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.art}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.biology && (
                            <div className="col-md-1 col-auto my-2">
                              Biology <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.biology}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.chemistry && (
                            <div className="col-md-1 col-auto my-2">
                              Chemistry <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.chemistry}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.economics && (
                            <div className="col-md-1 col-auto my-2">
                              Economics <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.economics}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.english && (
                            <div className="col-md-1 col-auto my-2">
                              English {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.english}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.history && (
                            <div className="col-md-1 col-auto my-2">
                              History {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.history}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.foreign_language && (
                            <div className="col-md-1 col-auto my-2">
                              Foreign Language <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.foreign_language}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.geography && (
                            <div className="col-md-1 col-auto my-2">
                              Geography <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.geography}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.physics && (
                            <div className="col-md-1 col-auto my-2">
                              Physics <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.physics}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.politics && (
                            <div className="col-md-1 col-auto my-2">
                              Politics <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.politics}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.social_studies && (
                            <div className="col-md-1 col-auto my-2">
                              Social studies <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.social_studies}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.thai && (
                            <div className="col-md-1 col-auto my-2">
                              Thai {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.thai}
                            </div>
                          )}
                          {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.others_type && (
                            <div className="col-md-1 col-auto my-2">
                              Others <br />
                              {studentDetailsDialog?.currentlyAtEPA?.coursesTakenAtEPA?.others_type}
                            </div>
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <div className="col-lg-12 col-12">
                  <Card className="mb-3">
                    <CardContent>
                      <h2>Academic & Career Goal</h2>
                      <div className="row align-items-center">
                        <div className="col-md-2 col-5 my-2 font-weight-medium">Career Goal</div>
                        <div className="col-md-10 col-7 my-2">
                          {studentDetailsDialog?.careerGoals?.student ?? '---'}
                        </div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Study Goal</div>
                        <div className="row col-md-10 col-12">
                          {studentDetailsDialog?.studyGoals?.student?.entrance && (
                            <div className="col-md-1 col-auto my-2">
                              {studentDetailsDialog?.studyGoals?.student?.entrance_type}
                            </div>
                          )}
                          {studentDetailsDialog?.studyGoals?.student?.gmat && (
                            <div className="col-md-1 col-auto my-2">GMAT</div>
                          )}
                          {studentDetailsDialog?.studyGoals?.student?.gre && (
                            <div className="col-md-1 col-auto my-2">GRE</div>
                          )}
                          {studentDetailsDialog?.studyGoals?.student?.ietls && (
                            <div className="col-md-1 col-auto my-2">IELTS</div>
                          )}
                          {studentDetailsDialog?.studyGoals?.student?.sat && (
                            <div className="col-md-1 col-auto my-2">SAT</div>
                          )}
                          {studentDetailsDialog?.studyGoals?.student?.toefl && (
                            <div className="col-md-1 col-auto my-2">TOEFL</div>
                          )}
                          {studentDetailsDialog?.studyGoals?.student?.others && (
                            <div className="col-md-1 col-auto my-2">
                              {studentDetailsDialog?.studyGoals?.student?.others_type}{' '}
                            </div>
                          )}
                        </div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Country of target university</div>
                        <div className="row col-md-10 col-12">
                          {studentDetailsDialog?.targetCountry?.student?.us && (
                            <div className="col-md-1 col-auto my-2">US</div>
                          )}
                          {studentDetailsDialog?.targetCountry?.student?.uk && (
                            <div className="col-md-1 col-auto my-2">UK</div>
                          )}
                          {studentDetailsDialog?.targetCountry?.student?.canada && (
                            <div className="col-md-1 col-auto my-2">Canada</div>
                          )}
                          {studentDetailsDialog?.targetCountry?.student?.others &&
                            studentDetailsDialog?.targetCountry?.student?.others_type && (
                              <div className="col-md-1 col-auto my-2">
                                {studentDetailsDialog?.targetCountry?.student?.others_type}
                              </div>
                            )}
                        </div>

                        <div className="col-md-2 col-5 my-2 font-weight-medium">Tentative area of study</div>
                        <div className="row col-md-10 col-12">
                          {studentDetailsDialog?.areaOfStudy?.area && (
                            <div className="col-md-12 col-auto my-2">
                              {studentDetailsDialog?.areaOfStudy?.area} - {studentDetailsDialog?.areaOfStudy?.specific}
                            </div>
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <Popover
            id="mouse-over-popover"
            style={{ borderRadius: '20px' }}
            open={openDialog}
            onClose={this.handlePopoverClose}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            disableRestoreFocus>
            {' '}
            <div>
              {punctualityDialog && (
                <>
                  <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                    late to these classes
                  </div>
                  {filteredAttendanceLate?.map((lesson, index) => (
                    <MenuItem key={index}>
                      {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                    </MenuItem>
                  ))}{' '}
                </>
              )}

              {cancellationDialog && 'canceled'}

              {homeworkDialog && (
                <>
                  <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                    {`${filteredLateHWS?.length !== 0 ? filteredLateHWS?.length : ' no'} late submission `}
                  </div>
                  {filteredLateHWS?.map((lesson, index) => (
                    <MenuItem key={index}>
                      {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                    </MenuItem>
                  ))}
                  <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                    {filteredNoHWS?.length !== 0 ? `${filteredNoHWS?.length} did not submit` : ''}
                  </div>
                  {filteredNoHWS?.map((lesson, index) => (
                    <MenuItem key={index}>
                      {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                    </MenuItem>
                  ))}
                </>
              )}

              {revisionNotesDialog && (
                <>
                  <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                    {`${filteredLateRNS?.length !== 0 ? filteredLateRNS?.length : ' no'} late submission `}
                  </div>
                  {filteredLateRNS?.map((lesson, index) => (
                    <MenuItem key={index}>
                      {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                    </MenuItem>
                  ))}
                  <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                    {filteredNoRNS?.length !== 0 ? `${filteredNoRNS?.length} did not submit` : ''}
                  </div>
                  {filteredNoRNS?.map((lesson, index) => (
                    <MenuItem key={index}>
                      {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                    </MenuItem>
                  ))}
                </>
              )}

              {engagementDialog && (
                <>
                  <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                    {/* {`${filteredEngagementLessons?.length !== 0 ? filteredEngagementLessons?.length : ''} `} */}
                  </div>
                  {`( `}
                  {filteredEngagementLessons?.map((lesson, index) => (
                    <span key={index}>
                      {Math.round(
                        (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                          parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                          parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                          parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                          parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                          5
                      )}{' '}
                      {index === filteredEngagementLessons?.length - 1 ? '' : '+ '}
                    </span>
                  ))}
                  {`)`} ÷ {filteredEngagementLessons?.length} (no. of lessons) ÷ 6 (score) * 100%
                  {/* <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                  {filteredNoRNS?.length !== 0 ? `${filteredNoRNS?.length} did not submit` : ''}
                </div>
               */}
                </>
              )}

              {masteryDialog && (
                <>
                  {/* <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {`${filteredLateRNS?.length !== 0 ? filteredLateRNS?.length : 'no'} late submission`}
                </div> */}
                  {`( `}
                  {filteredMasteryLessons?.map((lesson, index) => (
                    <span key={index}>
                      {Math.round(
                        (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                          parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                          parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                          parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                          parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                          5
                      )}{' '}
                      {index === filteredMasteryLessons?.length - 1 ? '' : '+ '}
                    </span>
                  ))}
                  {`)`} ÷ {filteredMasteryLessons?.length} (no. of lessons) ÷ 6 (score) * 100%
                </>
              )}
            </div>
          </Popover>
        </div>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogStudentProfile)
