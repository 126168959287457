import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  Checkbox
} from '@material-ui/core'

import Tooltip from '@mui/material/Tooltip'
import Autocomplete from '@mui/material/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import _, { truncate } from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import agent from 'agent'
import {
  CREATE_USER,
  UPDATE_USER,
  CREATE_PARENT,
  UPDATE_PARENT,
  GET_PARENT_DETAILS,
  GET_USERS,
  CREATE_CONSULTATION
} from 'constants/actionTypes'
import CKEditor from 'react-ckeditor-component'
import educationLevels from 'assets/education-level.json'
import schoolType from 'assets/type-of-school.json'
import famousLevel from 'assets/famous-level.json'
import schools from 'assets/schools.json'
import studyArea from 'assets/area-of-study.json'
import studyAreaSpecific from 'assets/area-of-study-specific.json'
import { HashLink as Link } from 'react-router-hash-link'
import { OthersField } from 'components/Fields/others'
import { InputText } from 'components/Fields/inputText'
import SchoolsAcademic from 'components/Fields/schoolsacademic'
import AcademicCareerGoal from 'components/Fields/academiccareergoal'
import PersonalityPreference from 'components/Fields/personalitypreference'
import ParentInfo from 'components/Fields/parentdetails'

const mapStateToProps = state => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  userCreated: state.user.userCreated,
  userUpdated: state.user.userUpdated,
  csList: state.user.csList,
  saList: state.user.saList,
  currentUser: state.common.currentUser
  // parentDetails: state.common.parentDetails
})

const mapDispatchToProps = dispatch => ({
  createUser: userObj => dispatch({ type: CREATE_USER, payload: agent.User.createUser(userObj) }),
  updateUser: (userType, userId, userObj) =>
    dispatch({ type: UPDATE_USER, payload: agent.User.updateUser(userType, userId, userObj) }),
  createParent: userObj => dispatch({ type: CREATE_PARENT, payload: agent.User.createParent(userObj) }),
  updateParent: (userId, userObj) =>
    dispatch({ type: UPDATE_PARENT, payload: agent.User.updateParent(userId, userObj) }),
  createConsultation: userObj =>
    dispatch({
      type: CREATE_CONSULTATION,
      payload: agent.Consultation.createConsultation(userObj)
    })
  // getParent: parentEmail => dispatch({ type: GET_PARENT_DETAILS, payload: agent.User.getParent(parentEmail) })
})

class DialogUserEdit extends React.Component {
  state = {
    dbid: '',
    email: '',
    name: '',
    nickname: '',
    password: '',
    dateOfBirth: '',
    gender: 'nil',
    phoneCountryCode: 65,
    phone: '',
    level: '',
    othersLevel: '',
    showOthersLevel: false,
    gradeLevel: '',
    yearLevel: '',
    school: '',
    othersSchool: '',
    showOthersSchool: false,
    typeOfSchool: '',
    firstLanguage: '',
    facebook: '',
    line: '',
    grades: '',
    dateOfConsultation: '',
    prevDateOfConsultation: '',
    csnameOfStaff: '',
    sanameOfStaff: '',
    actionPlans: '',
    consultationNotes: '',
    gpaEngBox: true,
    gpaThaiBox: true,
    gpaMathsBox: true,
    gpaPhysBox: true,
    gpaChemBox: true,
    gpaBioBox: true,
    gpaGeoBox: true, //geography
    gpaHistBox: true,
    gpaLitBox: true, //literature
    gpaSSBox: true, //social studies
    gpaPolBox: true, //politics
    gpaEcoBox: true, //economics
    gpaArtBox: true,
    gpaFLBox: true, //foreign languageg,
    gpaOthersBox: true,

    gpaEng: '',
    gpaThai: '',
    gpaMaths: '',
    gpaPhys: '',
    gpaChem: '',
    gpaBio: '',
    gpaGeo: '',
    gpaHist: '',
    gpaLit: '',
    gpaSS: '',
    gpaPol: '',
    gpaEco: '',
    gpaArt: '',
    gpaFL: '',
    gpaOthersField: '',

    favSubjEngBox: false,
    favSubjThaiBox: false,
    favSubjMathsBox: false,
    favSubjPhysBox: false,
    favSubjChemBox: false,
    favSubjBioBox: false,
    favSubjGeoBox: false, //geography
    favSubjHistBox: false,
    favSubjLitBox: false, //literature
    favSubjSSBox: false, //social studies
    favSubjPolBox: false, //politics
    favSubjEcoBox: false, //economics
    favSubjArtBox: false,
    favSubjFLBox: false, //foreign language
    favSubjOthersBox: false,
    favSubjOthersBoxField: '',

    weakestSubjEngBox: false,
    weakestSubjThaiBox: false,
    weakestSubjMathsBox: false,
    weakestSubjPhysBox: false,
    weakestSubjChemBox: false,
    weakestSubjBioBox: false,
    weakestSubjGeoBox: false, //geography
    weakestSubjHistBox: false,
    weakestSubjLitBox: false, //literature
    weakestSubjSSBox: false, //social studies
    weakestSubjPolBox: false, //politics
    weakestSubjEcoBox: false, //economics
    weakestSubjArtBox: false,
    weakestSubjFLBox: false, //foreign language
    weakestSubjOthersBox: false,
    weakestSubjOthersBoxField: '',

    extExamSAT: false,
    extExamGRE: false,
    extExamIELTS: false,
    extExamTOEFL: false,
    extExamGMAT: false,
    extExamOthers: false,
    extExamOthersField: '',

    otherTCcourseSAT: false,
    otherTCcourseGRE: false,
    otherTCcourseIELTS: false,
    otherTCcourseTOEFL: false,
    otherTCcourseGMAT: false,
    otherTCcourseEnglish: false,
    otherTCcourseThai: false,
    otherTCcourseMath: false,
    otherTCcoursePhysics: false,
    otherTCcourseBiology: false,
    otherTCcourseGeography: false,
    otherTCcourseChemistry: false,
    otherTCcourseHistory: false,
    otherTCcourseLiterature: false,
    otherTCcourseSocialStudies: false,
    otherTCcoursePolitics: false,
    otherTCcourseEconomics: false,
    otherTCcourseArt: false,
    otherTCcourseForeignLanguage: false,
    otherTCcourseOthers: false,
    otherTCcourseOthersField: '',

    studyingAtEPA: 'nil',
    coursesTakenAtEpaSAT: false,
    coursesTakenAtEpaGRE: false,
    coursesTakenAtEpaIELTS: false,
    coursesTakenAtEpaTOEFL: false,
    coursesTakenAtEpaGMAT: false,
    coursesTakenAtEpaEnglish: false,
    coursesTakenAtEpaThai: false,
    coursesTakenAtEpaMath: false,
    coursesTakenAtEpaPhysics: false,
    coursesTakenAtEpaBiology: false,
    coursesTakenAtEpaGeography: false,
    coursesTakenAtEpaChemistry: false,
    coursesTakenAtEpaHistory: false,
    coursesTakenAtEpaLiterature: false,
    coursesTakenAtEpaSocialStudies: false,
    coursesTakenAtEpaPolitics: false,
    coursesTakenAtEpaEconomics: false,
    coursesTakenAtEpaArt: false,
    coursesTakenAtEpaForeignLanguage: false,
    coursesTakenAtEpaOthers: false,
    coursesTakenAtEpaOthersField: '',

    careerGoalsStudentPerspective: '',
    careerGoalsParentPerspective: '',
    careerGoalsCounsellorPerspective: '',

    studyGoalsStudentPerspectiveEntranceExamBox: false,
    studyGoalsStudentPerspectiveEntranceExamField: '',
    studyGoalsStudentPerspectiveSchoolExamBox: false,
    studyGoalsStudentPerspectiveSATBox: false,
    studyGoalsStudentPerspectiveGREBox: false,
    studyGoalsStudentPerspectiveIELTSBox: false,
    studyGoalsStudentPerspectiveTOEFLBox: false,
    studyGoalsStudentPerspectiveGMATBox: false,
    studyGoalsStudentPerspectiveOthersBox: false,
    studyGoalsStudentPerspectiveOthersField: '',

    studyGoalsParentPerspectiveEntranceExamBox: false,
    studyGoalsParentPerspectiveEntranceExamField: '',
    studyGoalsParentPerspectiveSchoolExamBox: false,
    studyGoalsParentPerspectiveSATBox: false,
    studyGoalsParentPerspectiveGREBox: false,
    studyGoalsParentPerspectiveIELTSBox: false,
    studyGoalsParentPerspectiveTOEFLBox: false,
    studyGoalsParentPerspectiveGMATBox: false,
    studyGoalsParentPerspectiveOthersBox: false,
    studyGoalsParentPerspectiveOthersField: '',

    studyGoalsCounsellorPerspectiveEntranceExamBox: false,
    studyGoalsCounsellorPerspectiveEntranceExamField: '',
    studyGoalsCounsellorPerspectiveSchoolExamBox: false,
    studyGoalsCounsellorPerspectiveSATBox: false,
    studyGoalsCounsellorPerspectiveGREBox: false,
    studyGoalsCounsellorPerspectiveIELTSBox: false,
    studyGoalsCounsellorPerspectiveTOEFLBox: false,
    studyGoalsCounsellorPerspectiveGMATBox: false,
    studyGoalsCounsellorPerspectiveOthersBox: false,
    studyGoalsCounsellorPerspectiveOthersField: '',

    targetCountryStudentUS: false,
    targetCountryStudentCanada: false,
    targetCountryStudentUK: false,
    targetCountryStudentOthersBox: false,
    targetCountryStudentOthersField: '',

    targetCountryParentUS: false,
    targetCountryParentCanada: false,
    targetCountryParentUK: false,
    targetCountryParentOthersBox: false,
    targetCountryParentOthersField: '',

    areaOfStudy: '',
    areaOfStudySpecific: '',
    areaOfStudy1: '',
    areaOfStudySpecific1: '',
    areaOfStudy2: '',
    areaOfStudySpecific2: '',
    areaOfStudy3: '',
    areaOfStudySpecific3: '',
    areaOfStudy4: '',
    areaOfStudySpecific4: '',

    areaOfStudyParent: '',
    areaOfStudyParentSpecific: '',
    areaOfStudyParent1: '',
    areaOfStudyParentSpecific1: '',
    areaOfStudyParent2: '',
    areaOfStudyParentSpecific2: '',
    areaOfStudyParent3: '',
    areaOfStudyParentSpecific3: '',
    areaOfStudyParent4: '',
    areaOfStudyParentSpecific4: '',

    learningTypeVisual: false,
    learningTypeKinaesthetic: false,
    learningTypeAural: false,
    learningTypeSocial: false,
    learningTypeSolitary: false,
    learningTypeVerbal: false,
    learningTypeLogical: false,
    learningTypeOthersBox: false,
    learningTypeOthersField: '',

    personalityTestCheck: 'nil',
    personalityTestResult: '',
    personalityTestResultField: '',

    scDominanceDirect: false,
    scDominanceResultsOriented: false,
    scDominanceFirm: false,
    scDominanceStrongWilled: false,
    scDominanceForceful: false,

    scInfluenceOutgoing: false,
    scInfluenceEnthusiastic: false,
    scInfluenceOptimistic: false,
    scInfluenceHighSpirited: false,
    scInfluenceLively: false,
    scConscientiousnessAnalytical: false,
    scConscientiousnessReserved: false,
    scConscientiousnessPrecise: false,
    scConscientiousnessPrivate: false,
    scConscientiousnessSystematic: false,
    scSteadinessEvenTempered: false,
    scSteadinessAccommodating: false,
    scSteadinessPatient: false,
    scSteadinessHumble: false,
    scSteadinessTactful: false,

    healthConditionNA: false,
    healthConditionADHD: false,
    healthConditionAutism: false,
    healthConditionOthersBox: false,
    healthConditionOthersField: '',

    preferredTeacherStudentPerspectiveMale: false,
    preferredTeacherStudentPerspectiveFemale: false,
    preferredTeacherStudentPerspectiveNameBox: false,
    preferredTeacherStudentPerspectiveNameField: '',
    preferredTeacherStudentPerspectiveAuthority: false,
    preferredTeacherStudentPerspectiveDelegator: false,
    preferredTeacherStudentPerspectiveFacilitator: false,
    preferredTeacherStudentPerspectiveDemonstrator: false,
    preferredTeacherStudentPerspectiveHybrid: false,
    preferredTeacherStudentPerspectiveOthersBox: false,
    preferredTeacherStudentPerspectiveOthersField: '',

    preferredTeacherParentPerspectiveMale: false,
    preferredTeacherParentPerspectiveFemale: false,
    preferredTeacherParentPerspectiveNameBox: false,
    preferredTeacherParentPerspectiveNameField: '',
    preferredTeacherParentPerspectiveAuthority: false,
    preferredTeacherParentPerspectiveDelegator: false,
    preferredTeacherParentPerspectiveFacilitator: false,
    preferredTeacherParentPerspectiveDemonstrator: false,
    preferredTeacherParentPerspectiveHybrid: false,
    preferredTeacherParentPerspectiveOthersBox: false,
    preferredTeacherParentPerspectiveOthersField: '',

    interestedTopicsPublicSpeaking: false,
    interestedTopicsDebate: false,
    interestedTopicsCoding: false,
    interestedTopicsLeadership: false,
    interestedTopicsWriting: false,
    interestedTopicsCreativeWriting: false,
    interestedTopicsFormalWriting: false,
    interestedTopicsReadingComprehension: false,
    interestedTopicsLiterature: false,
    interestedTopicsOthersBox: false,
    interestedTopicsOthersField: '',

    studyMediumEng: false,
    studyMediumThai: false,
    courseType: '',
    examType: '',

    studyingAtOtherSchool: 'nil',
    studyingAtOtherSchoolName: '',

    famous: '',
    academicBackground: '',
    specialRequests: '',

    learningStyle: '',
    amountPaid: '',
    amountFrequency: '',
    parentGender: '',
    parentPassword: '',
    parentDOB: '',
    parentSalute: 'Mr',
    parentName: '',
    parentEmail: '',
    parentPhoneCountryCode: '',
    parentPhone: '',

    parentContactPreference: 'nil',
    parentContactPreferenceOther: '',
    parentFacebook: '',
    parentLine: '',
    parentOccupation: '',
    parentOccupationSpecific: '',
    parentFamilyBackground: '',
    parentNetworkingFB: false,
    parentNetworkingWebsite: false,
    parentNetworkingFriends: false,
    parentNetworkingOthersBox: false,
    parentNetworkingOthersField: '',

    pcDominanceDirect: false,
    pcDominanceResultsOriented: false,
    pcDominanceFirm: false,
    pcDominanceStrongWilled: false,
    pcDominanceForceful: false,
    pcInfluenceOutgoing: false,
    pcInfluenceEnthusiastic: false,
    pcInfluenceOptimistic: false,
    pcInfluenceHighSpirited: false,
    pcInfluenceLively: false,
    pcConscientiousnessAnalytical: false,
    pcConscientiousnessReserved: false,
    pcConscientiousnessPrecise: false,
    pcConscientiousnessPrivate: false,
    pcConscientiousnessSystematic: false,
    pcSteadinessEvenTempered: false,
    pcSteadinessAccommodating: false,
    pcSteadinessPatient: false,
    pcSteadinessHumble: false,
    pcSteadinessTactful: false,
    parentConflict: false,
    parentDecisionFather: false,
    parentDecisionMother: false,

    paymentMode: 'nil',
    parentInterestedTopicsPublicSpeaking: false,
    parentInterestedTopicsDebate: false,
    parentInterestedTopicsCoding: false,
    parentInterestedTopicsLeadership: false,
    parentInterestedTopicsWriting: false,
    parentInterestedTopicsCreativeWriting: false,
    parentInterestedTopicsFormalWriting: false,
    parentInterestedTopicsReadingComprehension: false,
    parentInterestedTopicsLiterature: false,
    parentInterestedTopicsOthersBox: false,
    parentInterestedTopicsOthersField: '',
    parentDateOfRenewal: '',

    contactPreference: 'nil',
    contactPreferenceOther: '',

    navigation: true,

    studentBackground: '',
    profile_pic: '',
    desc: '',
    subject: '',
    year_of_exp: '',
    track_record: ''
  }

  componentDidMount() {
    // this.props.getUsers({}, 0, 'SalesAdmin')
    // this.props.getUsers({}, 0, 'CustService')
    const { userData } = this.props
    // this.props.getParent(userData.parent.email)

    if (userData) {
      const { parent } = userData

      let displayLevel = '',
        showOthersLevel = false,
        othersLevel = '',
        displaySchool = '',
        showOthersSchool = false,
        othersSchool = '',
        displayOtherTypeOfSchool = '',
        showOthersTypeOfSchool = false,
        othersTypeOfSchool = '',
        displayFamousLevel = ''

      if (userData.level) {
        let levelObj = _.find(educationLevels, function (o) {
          // Check if code matches current user's code (lower-sec, upper-sec, higher-edu, others === lower-sec ?)
          return o.code === userData.level
        })
        if (levelObj) {
          //if there is, then displayLevel gets the object code inside levelObj { name : 'lower sec' , code : 'lower-sec'}
          displayLevel = levelObj.code
        } else {
          //if there isn't, then it will be others, show othersLevel, displayLevel is others
          showOthersLevel = true
          othersLevel = userData.level
          displayLevel = 'others'
        }
      }

      if (userData.typeOfSchool) {
        let schoolTypeObj = _.find(schoolType, function (o) {
          return o.code === userData.typeOfSchool
        })
        if (schoolTypeObj) {
          displayOtherTypeOfSchool = schoolTypeObj.code
        } else {
          showOthersTypeOfSchool = true
          othersTypeOfSchool = userData.typeOfSchool
          displayOtherTypeOfSchool = 'others'
        }
      }

      if (userData.school) {
        let schoolObj = _.find(schools, function (o) {
          return o.name === userData.school
        })
        if (schoolObj) {
          displaySchool = schoolObj.name
        } else {
          showOthersSchool = true
          othersSchool = userData.school
          displaySchool = 'others'
        }
      }

      if (userData.famous) {
        let levelFameObj = _.find(famousLevel, function (o) {
          return o.code === userData.famous
        })
        if (levelFameObj) {
          displayFamousLevel = levelFameObj.code
        } else {
          displayFamousLevel = ''
        }
      }

      let parentPhoneCountryCode = 66
      if (parent?.phone_country_code) {
        parentPhoneCountryCode = parent.phone_country_code
      } else {
        parentPhoneCountryCode = userData.phone_country_code
      }

      this.setState({
        dbid: userData.dbid,
        email: userData.email,
        name: userData.name ?? '',
        nickname: userData.nickname ?? '',
        dateOfBirth: userData.date_of_birth ? moment(userData.date_of_birth).startOf('day').format('YYYY-MM-DD') : '',
        gender: userData.gender ?? 'nil',
        phoneCountryCode: userData.phone_country_code,
        phone: userData.phone ?? '',
        level: displayLevel,
        othersLevel,
        showOthersLevel,
        typeOfSchool: displayOtherTypeOfSchool,
        othersTypeOfSchool,
        showOthersTypeOfSchool,
        // school: userData.school ?? '',
        school: displaySchool,
        othersSchool,
        showOthersSchool,
        dateOfConsultation: '',
        prevDateOfConsultation: '',
        csnameOfStaff: userData.staff_to_handle?.customer_service ?? '',
        sanameOfStaff: userData.staff_to_handle?.sales_admin ?? '',
        actionPlans: '',
        consultationNotes: '',

        gradeLevel: userData.gradeYearLevel?.grade ?? '',
        yearLevel: userData.gradeYearLevel?.year ?? '',

        gpaEngBox: userData.grade?.english ? true : false,
        gpaThaiBox: userData.grade?.thai ? true : false,
        gpaMathsBox: userData.grade?.math ? true : false,
        gpaPhysBox: userData.grade?.physics ? true : false,
        gpaChemBox: userData.grade?.chemistry ? true : false,
        gpaBioBox: userData.grade?.biology ? true : false,
        gpaGeoBox: userData.grade?.geography ? true : false, //geography
        gpaHistBox: userData.grade?.history ? true : false,
        gpaLitBox: userData.grade?.literature ? true : false, //literature
        gpaSSBox: userData.grade?.social_studies ? true : false, //social studies
        gpaPolBox: userData.grade?.politics ? true : false, //politics
        gpaEcoBox: userData.grade?.economics ? true : false, //economics
        gpaArtBox: userData.grade?.art ? true : false,
        gpaFLBox: userData.grade?.foreign_language ? true : false, //foreign language
        gpaOthersBox: userData.grade?.others ? true : false,

        gpaEng: userData.grade?.gpa?.english ?? '',
        gpaThai: userData.grade?.gpa?.thai ?? '',
        gpaMaths: userData.grade?.gpa?.math ?? '',
        gpaPhys: userData.grade?.gpa?.physics ?? '',
        gpaChem: userData.grade?.gpa?.chemistry ?? '',
        gpaBio: userData.grade?.gpa?.biology ?? '',
        gpaGeo: userData.grade?.gpa?.geography ?? '',
        gpaHist: userData.grade?.gpa?.history ?? '',
        gpaLit: userData.grade?.gpa?.literature ?? '',
        gpaSS: userData.grade?.gpa?.social_studies ?? '',
        gpaPol: userData.grade?.gpa?.politics ?? '',
        gpaEco: userData.grade?.gpa?.economics ?? '',
        gpaArt: userData.grade?.gpa?.art ?? '',
        gpaFL: userData.grade?.gpa?.foreign_language ?? '',
        gpaOthersField: userData.grade?.gpa?.others_type ?? '',

        favSubjEngBox: userData.favoriteSubjects?.english ? true : false,
        favSubjThaiBox: userData.favoriteSubjects?.thai ? true : false,
        favSubjMathsBox: userData.favoriteSubjects?.math ? true : false,
        favSubjPhysBox: userData.favoriteSubjects?.physics ? true : false,
        favSubjChemBox: userData.favoriteSubjects?.chemistry ? true : false,
        favSubjBioBox: userData.favoriteSubjects?.biology ? true : false,
        favSubjGeoBox: userData.favoriteSubjects?.geography ? true : false,
        favSubjHistBox: userData.favoriteSubjects?.history ? true : false,
        favSubjLitBox: userData.favoriteSubjects?.literature ? true : false,
        favSubjSSBox: userData.favoriteSubjects?.social_studies ? true : false,
        favSubjPolBox: userData.favoriteSubjects?.politics ? true : false,
        favSubjEcoBox: userData.favoriteSubjects?.economics ? true : false,
        favSubjArtBox: userData.favoriteSubjects?.art ? true : false,
        favSubjFLBox: userData.favoriteSubjects?.foreign_language ? true : false,
        favSubjOthersBox: userData.favoriteSubjects?.others ? true : false,
        favSubjOthersBoxField: userData.favoriteSubjects?.others_type ?? '',

        weakestSubjEngBox: userData.weakestSubjects?.english ? true : false,
        weakestSubjThaiBox: userData.weakestSubjects?.thai ? true : false,
        weakestSubjMathsBox: userData.weakestSubjects?.math ? true : false,
        weakestSubjPhysBox: userData.weakestSubjects?.physics ? true : false,
        weakestSubjChemBox: userData.weakestSubjects?.chemistry ? true : false,
        weakestSubjBioBox: userData.weakestSubjects?.biology ? true : false,
        weakestSubjGeoBox: userData.weakestSubjects?.geography ? true : false, //geography
        weakestSubjHistBox: userData.weakestSubjects?.history ? true : false,
        weakestSubjLitBox: userData.weakestSubjects?.literature ? true : false, //literature
        weakestSubjSSBox: userData.weakestSubjects?.social_studies ? true : false, //social studies
        weakestSubjPolBox: userData.weakestSubjects?.politics ? true : false, //politics
        weakestSubjEcoBox: userData.weakestSubjects?.economics ? true : false, //economics
        weakestSubjArtBox: userData.weakestSubjects?.art ? true : false,
        weakestSubjFLBox: userData.weakestSubjects?.foreign_language ? true : false, //foreign language
        weakestSubjOthersBox: userData.weakestSubjects?.others ? true : false,
        weakestSubjOthersBoxField: userData.weakestSubjects?.others_type ?? '',

        extExamSAT: userData.externalExams?.sat ? true : false,
        extExamGRE: userData.externalExams?.gre ? true : false,
        extExamIELTS: userData.externalExams?.ielts ? true : false,
        extExamTOEFL: userData.externalExams?.toefl ? true : false,
        extExamGMAT: userData.externalExams?.gmat ? true : false,
        extExamOthers: userData.externalExams?.others ? true : false,
        extExamOthersField: userData.externalExams?.others_type ?? '',

        otherTCcourseSAT: userData.coursesTuition?.sat ? true : false,
        otherTCcourseGRE: userData.coursesTuition?.gre ? true : false,
        otherTCcourseIELTS: userData.coursesTuition?.ielts ? true : false,
        otherTCcourseTOEFL: userData.coursesTuition?.toefl ? true : false,
        otherTCcourseGMAT: userData.coursesTuition?.gmat ? true : false,
        otherTCcourseEnglish: userData.coursesTuition?.english ? true : false,
        otherTCcourseThai: userData.coursesTuition?.thai ? true : false,
        otherTCcourseMath: userData.coursesTuition?.math ? true : false,
        otherTCcoursePhysics: userData.coursesTuition?.physics ? true : false,
        otherTCcourseBiology: userData.coursesTuition?.biology ? true : false,
        otherTCcourseGeography: userData.coursesTuition?.geography ? true : false,
        otherTCcourseChemistry: userData.coursesTuition?.chemistry ? true : false,
        otherTCcourseHistory: userData.coursesTuition?.history ? true : false,
        otherTCcourseLiterature: userData.coursesTuition?.literature ? true : false,
        otherTCcourseSocialStudies: userData.coursesTuition?.social_studies ? true : false,
        otherTCcoursePolitics: userData.coursesTuition?.politics ? true : false,
        otherTCcourseEconomics: userData.coursesTuition?.economics ? true : false,
        otherTCcourseArt: userData.coursesTuition?.art ? true : false,
        otherTCcourseForeignLanguage: userData.coursesTuition?.foreign_language ? true : false,
        otherTCcourseOthers: userData.coursesTuition?.others ? true : false,
        otherTCcourseOthersField: userData.coursesTuition?.others_type ?? '',

        studyingAtEPA: userData.currentlyAtEPA?.studyingAtEPA ?? 'n',
        coursesTakenAtEpaSAT: userData.currentlyAtEPA?.coursesTakenAtEPA?.sat ? true : false,
        coursesTakenAtEpaGRE: userData.currentlyAtEPA?.coursesTakenAtEPA?.gre ? true : false,
        coursesTakenAtEpaIELTS: userData.currentlyAtEPA?.coursesTakenAtEPA?.ielts ? true : false,
        coursesTakenAtEpaTOEFL: userData.currentlyAtEPA?.coursesTakenAtEPA?.toefl ? true : false,
        coursesTakenAtEpaGMAT: userData.currentlyAtEPA?.coursesTakenAtEPA?.gmat ? true : false,
        coursesTakenAtEpaEnglish: userData.currentlyAtEPA?.coursesTakenAtEPA?.english ? true : false,
        coursesTakenAtEpaThai: userData.currentlyAtEPA?.coursesTakenAtEPA?.thai ? true : false,
        coursesTakenAtEpaMath: userData.currentlyAtEPA?.coursesTakenAtEPA?.math ? true : false,
        coursesTakenAtEpaPhysics: userData.currentlyAtEPA?.coursesTakenAtEPA?.physics ? true : false,
        coursesTakenAtEpaBiology: userData.currentlyAtEPA?.coursesTakenAtEPA?.biology ? true : false,
        coursesTakenAtEpaGeography: userData.currentlyAtEPA?.coursesTakenAtEPA?.geography ? true : false,
        coursesTakenAtEpaChemistry: userData.currentlyAtEPA?.coursesTakenAtEPA?.chemistry ? true : false,
        coursesTakenAtEpaHistory: userData.currentlyAtEPA?.coursesTakenAtEPA?.history ? true : false,
        coursesTakenAtEpaLiterature: userData.currentlyAtEPA?.coursesTakenAtEPA?.literature ? true : false,
        coursesTakenAtEpaSocialStudies: userData.currentlyAtEPA?.coursesTakenAtEPA?.social_studies ? true : false,
        coursesTakenAtEpaPolitics: userData.currentlyAtEPA?.coursesTakenAtEPA?.politics ? true : false,
        coursesTakenAtEpaEconomics: userData.currentlyAtEPA?.coursesTakenAtEPA?.economics ? true : false,
        coursesTakenAtEpaArt: userData.currentlyAtEPA?.coursesTakenAtEPA?.art ? true : false,
        coursesTakenAtEpaForeignLanguage: userData.currentlyAtEPA?.coursesTakenAtEPA?.foreign_language ? true : false,
        coursesTakenAtEpaOthers: userData.currentlyAtEPA?.coursesTakenAtEPA?.others ? true : false,
        coursesTakenAtEpaOthersField: userData.currentlyAtEPA?.coursesTakenAtEPA?.others_type ?? '',

        careerGoalsStudentPerspective: userData.careerGoals?.student ?? '',
        careerGoalsParentPerspective: userData.careerGoals?.parent ?? '',
        careerGoalsCounsellorPerspective: userData.careerGoals?.counsellor ?? '',

        studyGoalsStudentPerspectiveEntranceExamBox: userData.studyGoals?.student?.entrance ?? false,
        studyGoalsStudentPerspectiveEntranceExamField: userData.studyGoals?.student?.entrance_type ?? '',
        studyGoalsStudentPerspectiveSchoolExamBox: userData.studyGoals?.student?.school_exam ?? false,
        studyGoalsStudentPerspectiveSATBox: userData.studyGoals?.student?.sat ?? false,
        studyGoalsStudentPerspectiveGREBox: userData.studyGoals?.student?.gre ?? false,
        studyGoalsStudentPerspectiveIELTSBox: userData.studyGoals?.student?.ielts ?? false,
        studyGoalsStudentPerspectiveTOEFLBox: userData.studyGoals?.student?.toefl ?? false,
        studyGoalsStudentPerspectiveGMATBox: userData.studyGoals?.student?.gmat ?? false,
        studyGoalsStudentPerspectiveOthersBox: userData.studyGoals?.student?.others ?? false,
        studyGoalsStudentPerspectiveOthersField: userData.studyGoals?.student?.others_type ?? '',

        studyGoalsParentPerspectiveEntranceExamBox: userData.studyGoals?.parent?.entrance ?? false,
        studyGoalsParentPerspectiveEntranceExamField: userData.studyGoals?.parent?.entrance_type ?? '',
        studyGoalsParentPerspectiveSchoolExamBox: userData.studyGoals?.parent?.school_exam ?? false,
        studyGoalsParentPerspectiveSATBox: userData.studyGoals?.parent?.sat ?? false,
        studyGoalsParentPerspectiveGREBox: userData.studyGoals?.parent?.gre ?? false,
        studyGoalsParentPerspectiveIELTSBox: userData.studyGoals?.parent?.ielts ?? false,
        studyGoalsParentPerspectiveTOEFLBox: userData.studyGoals?.parent?.toefl ?? false,
        studyGoalsParentPerspectiveGMATBox: userData.studyGoals?.parent?.gmat ?? false,
        studyGoalsParentPerspectiveOthersBox: userData.studyGoals?.parent?.others ?? false,
        studyGoalsParentPerspectiveOthersField: userData.studyGoals?.parent?.others_type ?? '',

        studyGoalsCounsellorPerspectiveEntranceExamBox: userData.studyGoals?.counsellor?.entrance ?? false,
        studyGoalsCounsellorPerspectiveEntranceExamField: userData.studyGoals?.counsellor?.entrance_type ?? '',
        studyGoalsCounsellorPerspectiveSchoolExamBox: userData.studyGoals?.counsellor?.school_exam ?? false,
        studyGoalsCounsellorPerspectiveSATBox: userData.studyGoals?.counsellor?.sat ?? false,
        studyGoalsCounsellorPerspectiveGREBox: userData.studyGoals?.counsellor?.gre ?? false,
        studyGoalsCounsellorPerspectiveIELTSBox: userData.studyGoals?.counsellor?.ielts ?? false,
        studyGoalsCounsellorPerspectiveTOEFLBox: userData.studyGoals?.counsellor?.toefl ?? false,
        studyGoalsCounsellorPerspectiveGMATBox: userData.studyGoals?.counsellor?.gmat ?? false,
        studyGoalsCounsellorPerspectiveOthersBox: userData.studyGoals?.counsellor?.others ?? false,
        studyGoalsCounsellorPerspectiveOthersField: userData.studyGoals?.counsellor?.others_type ?? '',

        targetCountryStudentUS: userData.targetCountry?.student?.us ?? false,
        targetCountryStudentCanada: userData.targetCountry?.student?.canada ?? false,
        targetCountryStudentUK: userData.targetCountry?.student?.uk ?? false,
        targetCountryStudentOthersBox: userData.targetCountry?.student?.others ?? false,
        targetCountryStudentOthersField: userData.targetCountry?.student?.others_type ?? '',

        targetCountryParentUS: userData.targetCountry?.parent?.us ?? false,
        targetCountryParentCanada: userData.targetCountry?.parent?.canada ?? false,
        targetCountryParentUK: userData.targetCountry?.parent?.uk ?? false,
        targetCountryParentOthersBox: userData.targetCountry?.parent?.others ?? false,
        targetCountryParentOthersField: userData.targetCountry?.parent?.others_type ?? '',

        areaOfStudy: userData.areaOfStudy?.area ?? '',
        areaOfStudySpecific: userData.areaOfStudy?.specific ?? '',
        areaOfStudy1: userData.areaOfStudy?.area1 ?? '',
        areaOfStudySpecific1: userData.areaOfStudy?.specific1 ?? '',
        areaOfStudy2: userData.areaOfStudy?.area2 ?? '',
        areaOfStudySpecific2: userData.areaOfStudy?.specific2 ?? '',
        areaOfStudy3: userData.areaOfStudy?.area3 ?? '',
        areaOfStudySpecific3: userData.areaOfStudy?.specific3 ?? '',
        areaOfStudy4: userData.areaOfStudy?.area4 ?? '',
        areaOfStudySpecific4: userData.areaOfStudy?.specific4 ?? '',

        areaOfStudyParent: userData.areaOfStudy?.parent?.area ?? '',
        areaOfStudyParentSpecific: userData.areaOfStudy?.parent?.specific ?? '',
        areaOfStudyParent1: userData.areaOfStudy?.parent?.area1 ?? '',
        areaOfStudyParentSpecific1: userData.areaOfStudy?.parent?.specific1 ?? '',
        areaOfStudyParent2: userData.areaOfStudy?.parent?.area2 ?? '',
        areaOfStudyParentSpecific2: userData.areaOfStudy?.parent?.specific2 ?? '',
        areaOfStudyParent3: userData.areaOfStudy?.parent?.area3 ?? '',
        areaOfStudyParentSpecific3: userData.areaOfStudy?.parent?.specific3 ?? '',
        areaOfStudyParent4: userData.areaOfStudy?.parent?.area4 ?? '',
        areaOfStudyParentSpecific4: userData.areaOfStudy?.parent?.specific4 ?? '',

        learningTypeVisual: userData.learningType?.visual ?? false,
        learningTypeKinaesthetic: userData.learningType?.kinaesthetic ?? false,
        learningTypeAural: userData.learningType?.aural ?? false,
        learningTypeSocial: userData.learningType?.social ?? false,
        learningTypeSolitary: userData.learningType?.solitary ?? false,
        learningTypeVerbal: userData.learningType?.verbal ?? false,
        learningTypeLogical: userData.learningType?.logical ?? false,
        learningTypeOthersBox: userData.learningType?.others ?? false,
        learningTypeOthersField: userData.learningType?.others_type ?? '',

        personalityTestCheck: userData.personalityTest?.attempted ?? 'nil',
        personalityTestResult: userData.personalityTest?.result ?? '',
        personalityTestResultField: userData.personalityTest?.result_field ?? '',

        scDominanceDirect: userData.studentCharacter?.dominance?.direct ?? false,
        scDominanceResultsOriented: userData.studentCharacter?.dominance?.result_oriented ?? false,
        scDominanceFirm: userData.studentCharacter?.dominance?.firm ?? false,
        scDominanceStrongWilled: userData.studentCharacter?.dominance?.strong_willed ?? false,
        scDominanceForceful: userData.studentCharacter?.dominance?.forceful ?? false,
        scInfluenceOutgoing: userData.studentCharacter?.influence?.outgoing ?? false,
        scInfluenceEnthusiastic: userData.studentCharacter?.influence?.enthusiastic ?? false,
        scInfluenceOptimistic: userData.studentCharacter?.influence?.optimistic ?? false,
        scInfluenceHighSpirited: userData.studentCharacter?.influence?.high_spirited ?? false,
        scInfluenceLively: userData.studentCharacter?.influence?.lively ?? false,
        scConscientiousnessAnalytical: userData.studentCharacter?.conscientiousness?.analytical ?? false,
        scConscientiousnessReserved: userData.studentCharacter?.conscientiousness?.reserved ?? false,
        scConscientiousnessPrecise: userData.studentCharacter?.conscientiousness?.precise ?? false,
        scConscientiousnessPrivate: userData.studentCharacter?.conscientiousness?.private ?? false,
        scConscientiousnessSystematic: userData.studentCharacter?.conscientiousness?.systematic ?? false,
        scSteadinessEvenTempered: userData.studentCharacter?.steadiness?.even_tempered ?? false,
        scSteadinessAccommodating: userData.studentCharacter?.steadiness?.accommodating ?? false,
        scSteadinessPatient: userData.studentCharacter?.steadiness?.patient ?? false,
        scSteadinessHumble: userData.studentCharacter?.steadiness?.humble ?? false,
        scSteadinessTactful: userData.studentCharacter?.steadiness?.tactful ?? false,

        healthConditionNA: userData.healthCondition?.na ?? false,
        healthConditionADHD: userData.healthCondition?.adhd ?? false,
        healthConditionAutism: userData.healthCondition?.autism ?? false,
        healthConditionOthersBox: userData.healthCondition?.others ?? false,
        healthConditionOthersField: userData.healthCondition?.others_type ?? '',

        preferredTeacherStudentPerspectiveMale: userData.preferredTeacher?.student?.male ?? false,
        preferredTeacherStudentPerspectiveFemale: userData.preferredTeacher?.student?.female ?? false,
        preferredTeacherStudentPerspectiveNameBox: userData.preferredTeacher?.student?.name ?? false,
        preferredTeacherStudentPerspectiveNameField: userData.preferredTeacher?.student?.name_type ?? '',
        preferredTeacherStudentPerspectiveAuthority: userData.preferredTeacher?.student?.authority ?? false,
        preferredTeacherStudentPerspectiveDelegator: userData.preferredTeacher?.student?.delegator ?? false,
        preferredTeacherStudentPerspectiveFacilitator: userData.preferredTeacher?.student?.facilitator ?? false,
        preferredTeacherStudentPerspectiveDemonstrator: userData.preferredTeacher?.student?.demonstrator ?? false,
        preferredTeacherStudentPerspectiveHybrid: userData.preferredTeacher?.student?.hybrid ?? false,
        preferredTeacherStudentPerspectiveOthersBox: userData.preferredTeacher?.student?.others ?? false,
        preferredTeacherStudentPerspectiveOthersField: userData.preferredTeacher?.student?.others_type ?? '',

        preferredTeacherParentPerspectiveMale: userData.preferredTeacher?.parent?.male ?? false,
        preferredTeacherParentPerspectiveFemale: userData.preferredTeacher?.parent?.female ?? false,
        preferredTeacherParentPerspectiveNameBox: userData.preferredTeacher?.parent?.name ?? false,
        preferredTeacherParentPerspectiveNameField: userData.preferredTeacher?.parent?.name_type ?? '',
        preferredTeacherParentPerspectiveAuthority: userData.preferredTeacher?.parent?.authority ?? false,
        preferredTeacherParentPerspectiveDelegator: userData.preferredTeacher?.parent?.delegator ?? false,
        preferredTeacherParentPerspectiveFacilitator: userData.preferredTeacher?.parent?.facilitator ?? false,
        preferredTeacherParentPerspectiveDemonstrator: userData.preferredTeacher?.parent?.demonstrator ?? false,
        preferredTeacherParentPerspectiveHybrid: userData.preferredTeacher?.parent?.hybrid ?? false,
        preferredTeacherParentPerspectiveOthersBox: userData.preferredTeacher?.parent?.others ?? false,
        preferredTeacherParentPerspectiveOthersField: userData.preferredTeacher?.parent?.others_type ?? '',

        interestedTopicsPublicSpeaking: userData.interestedTopics?.public_speaking ?? false,
        interestedTopicsDebate: userData.interestedTopics?.debate ?? false,
        interestedTopicsCoding: userData.interestedTopics?.coding ?? false,
        interestedTopicsLeadership: userData.interestedTopics?.leadership ?? false,
        interestedTopicsWriting: userData.interestedTopics?.writing ?? false,
        interestedTopicsCreativeWriting: userData.interestedTopics?.creative_writing ?? false,
        interestedTopicsFormalWriting: userData.interestedTopics?.formal_writing ?? false,
        interestedTopicsReadingComprehension: userData.interestedTopics?.reading_comprehension ?? false,
        interestedTopicsLiterature: userData.interestedTopics?.literature ?? false,
        interestedTopicsOthersBox: userData.interestedTopics?.others ?? false,
        interestedTopicsOthersField: userData.interestedTopics?.others_type ?? '',

        firstLanguage: userData.first_language ?? '',
        facebook: userData.facebook ?? '',
        line: userData.line ?? '',
        grades: userData.grades ?? '',
        studyMediumEng: userData.studyMediumEng ? true : false,
        studyMediumThai: userData.studyMediumThai ? true : false,
        courseType: userData.courseType ?? '',
        examType: userData.examType ?? '',

        studyingAtOtherSchool: userData.studyingAtOtherSchool?.at_other_school ?? 'nil',
        studyingAtOtherSchoolName: userData.studyingAtOtherSchool?.at_other_school_name ?? '',

        famous: displayFamousLevel,
        academicBackground: userData.academicBackground ?? '',
        specialRequests: userData.specialRequests ?? '',
        learningStyle: userData.learningStyle ?? '',
        amountPaid: userData.amountPaid ?? '',
        amountFrequency: userData.amountFrequency ?? '',

        contactPreference: userData.contact_preference?.contact ?? 'nil',
        contactPreferenceOther: userData.contact_preference?.others_type ?? '',

        parentSalute: parent?.salute ?? 'Mr',
        parentName: parent?.name ?? '',
        parentEmail: parent?.email ?? '',
        parentGender: parent?.gender ?? 'nil',
        parentDOB: parent?.dob ? moment(parent?.dob).startOf('day').format('YYYY-MM-DD') : '',
        parentPhoneCountryCode,
        parentPhone: parent?.phone ?? '',
        parentContactPreference: parent?.contact_preference?.contact ?? '',
        parentContactPreferenceOther: parent?.contact_preference?.others_type ?? '',
        parentFacebook: parent?.facebook ?? '',
        parentLine: parent?.line ?? '',

        // right now using student's meta which is wrong, have to use parent profile.
        parentOccupation: parent?.meta?.occupation?.area ?? '',
        parentOccupationSpecific: parent?.meta?.occupation?.specific ?? '',
        parentFamilyBackground: parent?.meta?.family_background ?? '',
        parentNetworkingFB: parent?.meta?.networking_circle?.fb ?? false,
        parentNetworkingWebsite: parent?.meta?.networking_circle?.website ?? false,
        parentNetworkingFriends: parent?.meta?.networking_circle?.friends ?? false,
        parentNetworkingOthersBox: parent?.meta?.networking_circle?.others ?? false,
        parentNetworkingOthersField: parent?.meta?.networking_circle?.others_type ?? '',

        pcDominanceDirect: parent?.meta?.parent_character?.dominance?.direct ?? false,
        pcDominanceResultsOriented: parent?.meta?.parent_character?.dominance?.result_oriented ?? false,
        pcDominanceFirm: parent?.meta?.parent_character?.dominance?.firm ?? false,
        pcDominanceStrongWilled: parent?.meta?.parent_character?.dominance?.strong_willed ?? false,
        pcDominanceForceful: parent?.meta?.parent_character?.dominance?.forceful ?? false,
        pcInfluenceOutgoing: parent?.meta?.parent_character?.influence?.outgoing ?? false,
        pcInfluenceEnthusiastic: parent?.meta?.parent_character?.influence?.enthusiastic ?? false,
        pcInfluenceOptimistic: parent?.meta?.parent_character?.influence?.optimistic ?? false,
        pcInfluenceHighSpirited: parent?.meta?.parent_character?.influence?.high_spirited ?? false,
        pcInfluenceLively: parent?.meta?.parent_character?.influence?.lively ?? false,
        pcConscientiousnessAnalytical: parent?.meta?.parent_character?.conscientiousness?.analytical ?? false,
        pcConscientiousnessReserved: parent?.meta?.parent_character?.conscientiousness?.reserved ?? false,
        pcConscientiousnessPrecise: parent?.meta?.parent_character?.conscientiousness?.precise ?? false,
        pcConscientiousnessPrivate: parent?.meta?.parent_character?.conscientiousness?.private ?? false,
        pcConscientiousnessSystematic: parent?.meta?.parent_character?.conscientiousness?.systematic ?? false,
        pcSteadinessEvenTempered: parent?.meta?.parent_character?.steadiness?.even_tempered ?? false,
        pcSteadinessAccommodating: parent?.meta?.parent_character?.steadiness?.accommodating ?? false,
        pcSteadinessPatient: parent?.meta?.parent_character?.steadiness?.patient ?? false,
        pcSteadinessHumble: parent?.meta?.parent_character?.steadiness?.humble ?? false,
        pcSteadinessTactful: parent?.meta?.parent_character?.steadiness?.tactful ?? false,
        parentConflict: parent?.meta?.conflict ?? '',
        parentDecisionFather: parent?.meta?.decision_maker?.father ?? false,
        parentDecisionMother: parent?.meta?.decision_maker?.mother ?? false,

        paymentMode: parent?.meta?.payment_mode ?? 'nil',
        parentInterestedTopicsPublicSpeaking: parent?.meta?.interested_topics?.public_speaking ?? false,
        parentInterestedTopicsDebate: parent?.meta?.interested_topics?.debate ?? false,
        parentInterestedTopicsCoding: parent?.meta?.interested_topics?.coding ?? false,
        parentInterestedTopicsLeadership: parent?.meta?.interested_topics?.leadership ?? false,
        parentInterestedTopicsWriting: parent?.meta?.interested_topics?.writing ?? false,
        parentInterestedTopicsCreativeWriting: parent?.meta?.interested_topics?.creative_writing ?? false,
        parentInterestedTopicsFormalWriting: parent?.meta?.interested_topics?.formal_writing ?? false,
        parentInterestedTopicsReadingComprehension: parent?.meta?.interested_topics?.reading_comprehension ?? false,
        parentInterestedTopicsLiterature: parent?.meta?.interested_topics?.literature ?? false,
        parentInterestedTopicsOthersBox: parent?.meta?.interested_topics?.others ?? false,
        parentInterestedTopicsOthersField: parent?.meta?.interested_topics?.others_type ?? '',
        parentDateOfRenewal: parent?.meta?.date_of_renewal
          ? moment(parent?.meta?.date_of_renewal).startOf('day').format('YYYY-MM-DD')
          : '',

        studentBackground: userData.meta?.background ?? '',
        profile_pic: userData.profile_pic ?? '',
        desc: userData.desc ?? '',
        subject: userData.subject ? userData.subject.join(', ') : '',
        year_of_exp: userData.credential?.year_of_exp ?? 0,
        track_record: userData.credential?.track_record ? userData.credential.track_record.join(', ') : ''
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading && (this.props.userCreated || this.props.userUpdated)) {
      this.props.onSave()
    }
  }

  handleNavigation = () => {
    this.setState({ navigation: !this.state.navigation })
  }

  handleChange = event => {
    const type = event.target.name
    const value = event.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }

    if (type === 'typeOfSchool') {
      if (value === 'others') {
        this.setState({ showOthersTypeOfSchool: true })
      } else {
        this.setState({ showOthersTypeOfSchool: false })
      }
    }
  }

  handleAutoCompleteCSStaff = (e, value) => {
    this.setState({ csnameOfStaff: value })
  }

  handleAutoCompleteSAStaff = (e, value) => {
    this.setState({ sanameOfStaff: value })
  }

  handleAutoCompleteChange = (e, value) => {
    this.setState({ school: value.name })

    if (value.code === 'others') {
      this.setState({ showOthersSchool: true })
    } else {
      this.setState({ showOthersSchool: false })
    }
  }

  handleAutoCompleteStudyAreaChange = (e, value) => {
    this.setState({ areaOfStudy: value })
  }

  handleAutoCompleteStudyAreaSpecificChange = (e, value) => {
    this.setState({ areaOfStudySpecific: value })
  }

  handleAutoCompleteStudyAreaChange1 = (e, value) => {
    this.setState({ areaOfStudy1: value })
  }

  handleAutoCompleteStudyAreaSpecificChange1 = (e, value) => {
    this.setState({ areaOfStudySpecific1: value })
  }

  handleAutoCompleteStudyAreaChange2 = (e, value) => {
    this.setState({ areaOfStudy2: value })
  }

  handleAutoCompleteStudyAreaSpecificChange2 = (e, value) => {
    this.setState({ areaOfStudySpecific2: value })
  }

  handleAutoCompleteStudyAreaChange3 = (e, value) => {
    this.setState({ areaOfStudy3: value })
  }

  handleAutoCompleteStudyAreaSpecificChange3 = (e, value) => {
    this.setState({ areaOfStudySpecific3: value })
  }

  handleAutoCompleteStudyAreaChange4 = (e, value) => {
    this.setState({ areaOfStudy4: value })
  }

  handleAutoCompleteStudyAreaSpecificChange4 = (e, value) => {
    this.setState({ areaOfStudySpecific4: value })
  }

  handleAutoCompleteParentStudyAreaChange = (e, value) => {
    this.setState({ areaOfStudyParent: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange = (e, value) => {
    this.setState({ areaOfStudyParentSpecific: value })
  }

  handleAutoCompleteParentStudyAreaChange1 = (e, value) => {
    this.setState({ areaOfStudyParent1: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange1 = (e, value) => {
    this.setState({ areaOfStudyParentSpecific1: value })
  }

  handleAutoCompleteParentStudyAreaChange2 = (e, value) => {
    this.setState({ areaOfStudyParent2: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange2 = (e, value) => {
    this.setState({ areaOfStudyParentSpecific2: value })
  }

  handleAutoCompleteParentStudyAreaChange3 = (e, value) => {
    this.setState({ areaOfStudyParent3: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange3 = (e, value) => {
    this.setState({ areaOfStudyParentSpecific3: value })
  }

  handleAutoCompleteParentStudyAreaChange4 = (e, value) => {
    this.setState({ areaOfStudyParent4: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange4 = (e, value) => {
    this.setState({ areaOfStudyParentSpecific4: value })
  }

  handleAutoCompleteOccupationChange = (e, value) => {
    this.setState({ parentOccupation: value })
  }

  handleAutoCompleteOccupationSpecificChange = (e, value) => {
    this.setState({ parentOccupationSpecific: value })
  }

  handleCheckboxChange = (e, checked) => {
    this.setState({ [e.target.name]: checked })
  }

  handleCKEditorChange = targetName => event => {
    this.setState({
      [targetName]: event.editor.getData()
    })
  }

  // https://stackoverflow.com/questions/33266156/react-redux-input-onchange-is-very-slow-when-typing-in-when-the-input-have-a
  handleBlurInput = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  saveChanges = () => {
    const { userData, currentUser } = this.props
    const {
      dbid,
      email,
      name,
      nickname,
      password,
      dateOfBirth,
      gender,
      phoneCountryCode,
      phone,
      level,
      othersLevel,
      gradeLevel,
      yearLevel,
      school,
      othersSchool,
      firstLanguage,
      facebook,
      line,
      grades,
      dateOfConsultation,
      prevDateOfConsultation,
      csnameOfStaff,
      sanameOfStaff,
      actionPlans,
      consultationNotes,
      typeOfSchool,
      othersTypeOfSchool,

      gpaEngBox,
      gpaThaiBox,
      gpaMathsBox,
      gpaPhysBox,
      gpaChemBox,
      gpaBioBox,
      gpaGeoBox,
      gpaHistBox,
      gpaLitBox,
      gpaSSBox,
      gpaPolBox,
      gpaEcoBox,
      gpaArtBox,
      gpaFLBox,
      gpaOthersBox,

      gpaEng,
      gpaThai,
      gpaMaths,
      gpaPhys,
      gpaChem,
      gpaBio,
      gpaGeo,
      gpaHist,
      gpaLit,
      gpaSS,
      gpaPol,
      gpaEco,
      gpaArt,
      gpaFL,
      gpaOthersField,

      favSubjEngBox,
      favSubjThaiBox,
      favSubjMathsBox,
      favSubjPhysBox,
      favSubjChemBox,
      favSubjBioBox,
      favSubjGeoBox, //geography
      favSubjHistBox,
      favSubjLitBox, //literature
      favSubjSSBox, //social studies
      favSubjPolBox, //politics
      favSubjEcoBox, //economics
      favSubjArtBox,
      favSubjFLBox, //foreign language,
      favSubjOthersBox,
      favSubjOthersBoxField,

      weakestSubjEngBox,
      weakestSubjThaiBox,
      weakestSubjMathsBox,
      weakestSubjPhysBox,
      weakestSubjChemBox,
      weakestSubjBioBox,
      weakestSubjGeoBox, //geography
      weakestSubjHistBox,
      weakestSubjLitBox, //literature
      weakestSubjSSBox, //social studies
      weakestSubjPolBox, //politics
      weakestSubjEcoBox, //economics
      weakestSubjArtBox,
      weakestSubjFLBox, //foreign ,
      weakestSubjOthersBox,
      weakestSubjOthersBoxField,

      extExamSAT,
      extExamGRE,
      extExamIELTS,
      extExamTOEFL,
      extExamGMAT,
      extExamOthers,
      extExamOthersField,

      otherTCcourseSAT,
      otherTCcourseGRE,
      otherTCcourseIELTS,
      otherTCcourseTOEFL,
      otherTCcourseGMAT,
      otherTCcourseEnglish,
      otherTCcourseThai,
      otherTCcourseMath,
      otherTCcoursePhysics,
      otherTCcourseBiology,
      otherTCcourseChemistry,
      otherTCcourseGeography,
      otherTCcourseHistory,
      otherTCcourseLiterature,
      otherTCcourseSocialStudies,
      otherTCcoursePolitics,
      otherTCcourseEconomics,
      otherTCcourseArt,
      otherTCcourseForeignLanguage,
      otherTCcourseOthers,
      otherTCcourseOthersField,

      studyingAtEPA,
      coursesTakenAtEpaSAT,
      coursesTakenAtEpaGRE,
      coursesTakenAtEpaIELTS,
      coursesTakenAtEpaTOEFL,
      coursesTakenAtEpaGMAT,
      coursesTakenAtEpaEnglish,
      coursesTakenAtEpaThai,
      coursesTakenAtEpaMath,
      coursesTakenAtEpaPhysics,
      coursesTakenAtEpaBiology,
      coursesTakenAtEpaGeography,
      coursesTakenAtEpaChemistry,
      coursesTakenAtEpaHistory,
      coursesTakenAtEpaLiterature,
      coursesTakenAtEpaSocialStudies,
      coursesTakenAtEpaPolitics,
      coursesTakenAtEpaEconomics,
      coursesTakenAtEpaArt,
      coursesTakenAtEpaForeignLanguage,
      coursesTakenAtEpaOthers,
      coursesTakenAtEpaOthersField,

      careerGoalsStudentPerspective,
      careerGoalsParentPerspective,
      careerGoalsCounsellorPerspective,

      studyGoalsStudentPerspectiveEntranceExamBox,
      studyGoalsStudentPerspectiveEntranceExamField,
      studyGoalsStudentPerspectiveSchoolExamBox,
      studyGoalsStudentPerspectiveSATBox,
      studyGoalsStudentPerspectiveGREBox,
      studyGoalsStudentPerspectiveIELTSBox,
      studyGoalsStudentPerspectiveTOEFLBox,
      studyGoalsStudentPerspectiveGMATBox,
      studyGoalsStudentPerspectiveOthersBox,
      studyGoalsStudentPerspectiveOthersField,

      studyGoalsParentPerspectiveEntranceExamBox,
      studyGoalsParentPerspectiveEntranceExamField,
      studyGoalsParentPerspectiveSchoolExamBox,
      studyGoalsParentPerspectiveSATBox,
      studyGoalsParentPerspectiveGREBox,
      studyGoalsParentPerspectiveIELTSBox,
      studyGoalsParentPerspectiveTOEFLBox,
      studyGoalsParentPerspectiveGMATBox,
      studyGoalsParentPerspectiveOthersBox,
      studyGoalsParentPerspectiveOthersField,

      studyGoalsCounsellorPerspectiveEntranceExamBox,
      studyGoalsCounsellorPerspectiveEntranceExamField,
      studyGoalsCounsellorPerspectiveSchoolExamBox,
      studyGoalsCounsellorPerspectiveSATBox,
      studyGoalsCounsellorPerspectiveGREBox,
      studyGoalsCounsellorPerspectiveIELTSBox,
      studyGoalsCounsellorPerspectiveTOEFLBox,
      studyGoalsCounsellorPerspectiveGMATBox,
      studyGoalsCounsellorPerspectiveOthersBox,
      studyGoalsCounsellorPerspectiveOthersField,

      targetCountryStudentUS,
      targetCountryStudentCanada,
      targetCountryStudentUK,
      targetCountryStudentOthersBox,
      targetCountryStudentOthersField,

      targetCountryParentUS,
      targetCountryParentCanada,
      targetCountryParentUK,
      targetCountryParentOthersBox,
      targetCountryParentOthersField,

      areaOfStudy,
      areaOfStudySpecific,
      areaOfStudy1,
      areaOfStudySpecific1,
      areaOfStudy2,
      areaOfStudySpecific2,
      areaOfStudy3,
      areaOfStudySpecific3,
      areaOfStudy4,
      areaOfStudySpecific4,

      areaOfStudyParent,
      areaOfStudyParentSpecific,
      areaOfStudyParent1,
      areaOfStudyParentSpecific1,
      areaOfStudyParent2,
      areaOfStudyParentSpecific2,
      areaOfStudyParent3,
      areaOfStudyParentSpecific3,
      areaOfStudyParent4,
      areaOfStudyParentSpecific4,

      learningTypeVisual,
      learningTypeKinaesthetic,
      learningTypeAural,
      learningTypeSocial,
      learningTypeSolitary,
      learningTypeVerbal,
      learningTypeLogical,
      learningTypeOthersBox,
      learningTypeOthersField,

      personalityTestCheck,
      personalityTestResult,
      personalityTestResultField,

      scDominanceDirect,
      scDominanceResultsOriented,
      scDominanceFirm,
      scDominanceStrongWilled,
      scDominanceForceful,
      scInfluenceOutgoing,
      scInfluenceEnthusiastic,
      scInfluenceOptimistic,
      scInfluenceHighSpirited,
      scInfluenceLively,
      scConscientiousnessAnalytical,
      scConscientiousnessReserved,
      scConscientiousnessPrecise,
      scConscientiousnessPrivate,
      scConscientiousnessSystematic,
      scSteadinessEvenTempered,
      scSteadinessAccommodating,
      scSteadinessPatient,
      scSteadinessHumble,
      scSteadinessTactful,

      healthConditionNA,
      healthConditionADHD,
      healthConditionAutism,
      healthConditionOthersBox,
      healthConditionOthersField,

      preferredTeacherStudentPerspectiveMale,
      preferredTeacherStudentPerspectiveFemale,
      preferredTeacherStudentPerspectiveNameBox,
      preferredTeacherStudentPerspectiveNameField,
      preferredTeacherStudentPerspectiveAuthority,
      preferredTeacherStudentPerspectiveDelegator,
      preferredTeacherStudentPerspectiveFacilitator,
      preferredTeacherStudentPerspectiveDemonstrator,
      preferredTeacherStudentPerspectiveHybrid,
      preferredTeacherStudentPerspectiveOthersBox,
      preferredTeacherStudentPerspectiveOthersField,

      preferredTeacherParentPerspectiveMale,
      preferredTeacherParentPerspectiveFemale,
      preferredTeacherParentPerspectiveNameBox,
      preferredTeacherParentPerspectiveNameField,
      preferredTeacherParentPerspectiveAuthority,
      preferredTeacherParentPerspectiveDelegator,
      preferredTeacherParentPerspectiveFacilitator,
      preferredTeacherParentPerspectiveDemonstrator,
      preferredTeacherParentPerspectiveHybrid,
      preferredTeacherParentPerspectiveOthersBox,
      preferredTeacherParentPerspectiveOthersField,

      interestedTopicsPublicSpeaking,
      interestedTopicsDebate,
      interestedTopicsCoding,
      interestedTopicsLeadership,
      interestedTopicsWriting,
      interestedTopicsCreativeWriting,
      interestedTopicsFormalWriting,
      interestedTopicsReadingComprehension,
      interestedTopicsLiterature,
      interestedTopicsOthersBox,
      interestedTopicsOthersField,

      contactPreference,
      contactPreferenceOther,

      studyMediumEng,
      studyMediumThai,
      courseType,
      examType,
      studyingAtOtherSchool,
      studyingAtOtherSchoolName,
      famous,
      academicBackground,
      specialRequests,
      learningStyle,
      amountPaid,
      amountFrequency,

      parentSalute,
      parentName,
      parentPassword,
      parentEmail,
      parentGender,
      parentDOB,
      parentPhoneCountryCode,
      parentPhone,
      parentContactPreference,
      parentContactPreferenceOther,
      parentFacebook,
      parentLine,
      parentOccupation,
      parentOccupationSpecific,
      parentFamilyBackground,
      parentNetworkingFB,
      parentNetworkingWebsite,
      parentNetworkingFriends,
      parentNetworkingOthersBox,
      parentNetworkingOthersField,

      pcDominanceDirect,
      pcDominanceResultsOriented,
      pcDominanceFirm,
      pcDominanceStrongWilled,
      pcDominanceForceful,
      pcInfluenceOutgoing,
      pcInfluenceEnthusiastic,
      pcInfluenceOptimistic,
      pcInfluenceHighSpirited,
      pcInfluenceLively,
      pcConscientiousnessAnalytical,
      pcConscientiousnessReserved,
      pcConscientiousnessPrecise,
      pcConscientiousnessPrivate,
      pcConscientiousnessSystematic,
      pcSteadinessEvenTempered,
      pcSteadinessAccommodating,
      pcSteadinessPatient,
      pcSteadinessHumble,
      pcSteadinessTactful,
      parentConflict,
      parentDecisionFather,
      parentDecisionMother,

      paymentMode,
      parentInterestedTopicsPublicSpeaking,
      parentInterestedTopicsDebate,
      parentInterestedTopicsCoding,
      parentInterestedTopicsLeadership,
      parentInterestedTopicsWriting,
      parentInterestedTopicsCreativeWriting,
      parentInterestedTopicsFormalWriting,
      parentInterestedTopicsReadingComprehension,
      parentInterestedTopicsLiterature,
      parentInterestedTopicsOthersBox,
      parentInterestedTopicsOthersField,
      parentDateOfRenewal,

      studentBackground,
      profile_pic,
      desc,
      subject,
      year_of_exp,
      track_record
    } = this.state

    let userDoc = {
      dbid,
      name,
      nickname,
      email,
      phone_country_code: phoneCountryCode,
      phone
    }

    if (password) {
      userDoc.password = password
    }

    let consultationDoc = {
      admin: currentUser._id,
      student_id: userData?._id,
      action_plans: actionPlans,
      consultation_notes: consultationNotes,
      date_of_consultation: moment(dateOfConsultation).endOf('day').toISOString()
    }

    if (userData?._type === 'Student') {
      userDoc.gender = gender
      userDoc.date_of_birth = moment(dateOfBirth).startOf('day').toISOString()
      userDoc.level = level === 'others' ? othersLevel : level
      userDoc.school = school === 'others' ? othersSchool : school === 'Others' ? othersSchool : school
      userDoc.typeOfSchool = typeOfSchool === 'others' ? othersTypeOfSchool : typeOfSchool
      userDoc.first_language = firstLanguage
      userDoc.date_of_consultation = moment(dateOfConsultation).endOf('day').toISOString()

      userDoc.gradeYearLevel = {
        grade: gradeLevel,
        year: yearLevel
      }
      userDoc.contact_preference = {
        contact: contactPreference,
        others_type: contactPreferenceOther
      }

      userDoc.facebook = facebook
      userDoc.line = line
      //edu background
      userDoc.grades = grades
      userDoc.studyMediumEng = studyMediumEng
      userDoc.studyMediumThai = studyMediumThai
      userDoc.courseType = courseType
      userDoc.examType = examType
      // userDoc.otherSchoolYes = otherSchoolYes
      // userDoc.otherSchoolNo = otherSchoolNo
      userDoc.studyingAtOtherSchool = {
        at_other_school: studyingAtOtherSchool,
        at_other_school_name: studyingAtOtherSchoolName
      }

      //acad background
      userDoc.famous = famous
      userDoc.academicBackground = academicBackground
      userDoc.specialRequests = specialRequests
      userDoc.learningStyle = learningStyle

      userDoc.amountPaid = amountPaid
      userDoc.amountFrequency = amountFrequency

      userDoc.staff_to_handle = {
        customer_service: csnameOfStaff,
        sales_admin: sanameOfStaff
      }

      userDoc.grade = {
        english: gpaEngBox,
        thai: gpaThaiBox,
        math: gpaMathsBox,
        physics: gpaPhysBox,
        chemistry: gpaChemBox,
        biology: gpaBioBox,
        geography: gpaGeoBox,
        history: gpaHistBox,
        literature: gpaLitBox,
        social_studies: gpaSSBox,
        politics: gpaPolBox,
        economics: gpaEcoBox,
        art: gpaArtBox,
        foreign_language: gpaFLBox,
        others: gpaOthersBox,

        gpa: {
          english: gpaEng,
          thai: gpaThai,
          math: gpaMaths,
          physics: gpaPhys,
          chemistry: gpaChem,
          biology: gpaBio,
          geography: gpaGeo,
          history: gpaHist,
          literature: gpaLit,
          social_studies: gpaSS,
          politics: gpaPol,
          economics: gpaEco,
          art: gpaArt,
          foreign_language: gpaFL,
          others_type: gpaOthersField
        }
      }

      userDoc.favoriteSubjects = {
        english: favSubjEngBox,
        thai: favSubjThaiBox,
        math: favSubjMathsBox,
        physics: favSubjPhysBox,
        chemistry: favSubjChemBox,
        biology: favSubjBioBox,
        geography: favSubjGeoBox,
        history: favSubjHistBox,
        literature: favSubjLitBox,
        social_studies: favSubjSSBox,
        politics: favSubjPolBox,
        economics: favSubjEcoBox,
        art: favSubjArtBox,
        foreign_language: favSubjFLBox,
        others: favSubjOthersBox,
        others_type: favSubjOthersBoxField
      }

      userDoc.weakestSubjects = {
        english: weakestSubjEngBox,
        thai: weakestSubjThaiBox,
        math: weakestSubjMathsBox,
        physics: weakestSubjPhysBox,
        chemistry: weakestSubjChemBox,
        biology: weakestSubjBioBox,
        geography: weakestSubjGeoBox,
        history: weakestSubjHistBox,
        literature: weakestSubjLitBox,
        social_studies: weakestSubjSSBox,
        politics: weakestSubjPolBox,
        economics: weakestSubjEcoBox,
        art: weakestSubjArtBox,
        foreign_language: weakestSubjFLBox,
        others: weakestSubjOthersBox,
        others_type: weakestSubjOthersBoxField
      }

      userDoc.externalExams = {
        gmat: extExamGMAT,
        gre: extExamGRE,
        ielts: extExamIELTS,
        sat: extExamSAT,
        toefl: extExamTOEFL,
        others: extExamOthers,
        others_type: extExamOthersField
      }

      userDoc.coursesTuition = {
        gmat: otherTCcourseGMAT,
        gre: otherTCcourseGRE,
        ielts: otherTCcourseIELTS,
        sat: otherTCcourseSAT,
        toefl: otherTCcourseTOEFL,
        english: otherTCcourseEnglish,
        thai: otherTCcourseThai,
        math: otherTCcourseMath,
        physics: otherTCcoursePhysics,
        biology: otherTCcourseBiology,
        chemistry: otherTCcourseChemistry,
        geography: otherTCcourseGeography,
        history: otherTCcourseHistory,
        literature: otherTCcourseLiterature,
        social_studies: otherTCcourseSocialStudies,
        politics: otherTCcoursePolitics,
        economics: otherTCcourseEconomics,
        art: otherTCcourseArt,
        foreign_language: otherTCcourseForeignLanguage,
        others: otherTCcourseOthers,
        others_type: otherTCcourseOthersField
      }

      userDoc.currentlyAtEPA = {
        studyingAtEPA: studyingAtEPA,
        coursesTakenAtEPA: {
          sat: coursesTakenAtEpaSAT,
          gre: coursesTakenAtEpaGRE,
          ielts: coursesTakenAtEpaIELTS,
          toefl: coursesTakenAtEpaTOEFL,
          gmat: coursesTakenAtEpaGMAT,
          english: coursesTakenAtEpaEnglish,
          thai: coursesTakenAtEpaThai,
          math: coursesTakenAtEpaMath,
          physics: coursesTakenAtEpaPhysics,
          biology: coursesTakenAtEpaBiology,
          geography: coursesTakenAtEpaGeography,
          chemistry: coursesTakenAtEpaChemistry,
          history: coursesTakenAtEpaHistory,
          literature: coursesTakenAtEpaLiterature,
          social_studies: coursesTakenAtEpaSocialStudies,
          politics: coursesTakenAtEpaPolitics,
          economics: coursesTakenAtEpaEconomics,
          art: coursesTakenAtEpaArt,
          foreign_language: coursesTakenAtEpaForeignLanguage,
          others: coursesTakenAtEpaOthers,
          others_type: coursesTakenAtEpaOthersField
        }
      }

      userDoc.careerGoals = {
        student: careerGoalsStudentPerspective,
        parent: careerGoalsParentPerspective,
        counsellor: careerGoalsCounsellorPerspective
      }

      userDoc.studyGoals = {
        student: {
          entrance: studyGoalsStudentPerspectiveEntranceExamBox,
          entrance_type: studyGoalsStudentPerspectiveEntranceExamField,
          school_exam: studyGoalsStudentPerspectiveSchoolExamBox,
          sat: studyGoalsStudentPerspectiveSATBox,
          gre: studyGoalsStudentPerspectiveGREBox,
          ielts: studyGoalsStudentPerspectiveIELTSBox,
          toefl: studyGoalsStudentPerspectiveTOEFLBox,
          gmat: studyGoalsStudentPerspectiveGMATBox,
          others: studyGoalsStudentPerspectiveOthersBox,
          others_type: studyGoalsStudentPerspectiveOthersField
        },

        parent: {
          entrance: studyGoalsParentPerspectiveEntranceExamBox,
          entrance_type: studyGoalsParentPerspectiveEntranceExamField,
          school_exam: studyGoalsParentPerspectiveSchoolExamBox,
          sat: studyGoalsParentPerspectiveSATBox,
          gre: studyGoalsParentPerspectiveGREBox,
          ielts: studyGoalsParentPerspectiveIELTSBox,
          toefl: studyGoalsParentPerspectiveTOEFLBox,
          gmat: studyGoalsParentPerspectiveGMATBox,
          others: studyGoalsParentPerspectiveOthersBox,
          others_type: studyGoalsParentPerspectiveOthersField
        },

        counsellor: {
          entrance: studyGoalsCounsellorPerspectiveEntranceExamBox,
          entrance_type: studyGoalsCounsellorPerspectiveEntranceExamField,
          school_exam: studyGoalsCounsellorPerspectiveSchoolExamBox,
          sat: studyGoalsCounsellorPerspectiveSATBox,
          gre: studyGoalsCounsellorPerspectiveGREBox,
          ielts: studyGoalsCounsellorPerspectiveIELTSBox,
          toefl: studyGoalsCounsellorPerspectiveTOEFLBox,
          gmat: studyGoalsCounsellorPerspectiveGMATBox,
          others: studyGoalsCounsellorPerspectiveOthersBox,
          others_type: studyGoalsCounsellorPerspectiveOthersField
        }
      }

      userDoc.targetCountry = {
        student: {
          us: targetCountryStudentUS,
          canada: targetCountryStudentCanada,
          uk: targetCountryStudentUK,
          others: targetCountryStudentOthersBox,
          others_type: targetCountryStudentOthersField
        },
        parent: {
          us: targetCountryParentUS,
          canada: targetCountryParentCanada,
          uk: targetCountryParentUK,
          others: targetCountryParentOthersBox,
          others_type: targetCountryParentOthersField
        }
      }

      userDoc.areaOfStudy = {
        area: areaOfStudy,
        specific: areaOfStudySpecific,
        area1: areaOfStudy1,
        specific1: areaOfStudySpecific1,
        area2: areaOfStudy2,
        specific2: areaOfStudySpecific2,
        area3: areaOfStudy3,
        specific3: areaOfStudySpecific3,
        area4: areaOfStudy4,
        specific4: areaOfStudySpecific4,
        parent: {
          area: areaOfStudyParent,
          specific: areaOfStudyParentSpecific,
          area1: areaOfStudyParent1,
          specific1: areaOfStudyParentSpecific1,
          area2: areaOfStudyParent2,
          specific2: areaOfStudyParentSpecific2,
          area3: areaOfStudyParent3,
          specific3: areaOfStudyParentSpecific3,
          area4: areaOfStudyParent4,
          specific4: areaOfStudyParentSpecific4
        }
      }

      userDoc.learningType = {
        visual: learningTypeVisual,
        kinaesthetic: learningTypeKinaesthetic,
        aural: learningTypeAural,
        social: learningTypeSocial,
        solitary: learningTypeSolitary,
        verbal: learningTypeVerbal,
        logical: learningTypeLogical,
        others: learningTypeOthersBox,
        others_type: learningTypeOthersField
      }

      userDoc.studentCharacter = {
        dominance: {
          direct: scDominanceDirect,
          result_oriented: scDominanceResultsOriented,
          firm: scDominanceFirm,
          strong_willed: scDominanceStrongWilled,
          forceful: scDominanceForceful
        },
        influence: {
          outgoing: scInfluenceOutgoing,
          enthusiastic: scInfluenceEnthusiastic,
          optimistic: scInfluenceOptimistic,
          high_spirited: scInfluenceHighSpirited,
          lively: scInfluenceLively
        },
        conscientiousness: {
          analytical: scConscientiousnessAnalytical,
          reserved: scConscientiousnessReserved,
          precise: scConscientiousnessPrecise,
          private: scConscientiousnessPrivate,
          systematic: scConscientiousnessSystematic
        },
        steadiness: {
          even_tempered: scSteadinessEvenTempered,
          accommodating: scSteadinessAccommodating,
          patient: scSteadinessPatient,
          humble: scSteadinessHumble,
          tactful: scSteadinessTactful
        }
      }

      userDoc.personalityTest = {
        attempted: personalityTestCheck,
        result: personalityTestResult,
        result_field: personalityTestResultField
      }

      userDoc.healthCondition = {
        na: healthConditionNA,
        adhd: healthConditionADHD,
        autism: healthConditionAutism,
        others: healthConditionOthersBox,
        others_type: healthConditionOthersField
      }

      userDoc.preferredTeacher = {
        student: {
          male: preferredTeacherStudentPerspectiveMale,
          female: preferredTeacherStudentPerspectiveFemale,
          name: preferredTeacherStudentPerspectiveNameBox,
          name_type: preferredTeacherStudentPerspectiveNameField,
          authority: preferredTeacherStudentPerspectiveAuthority,
          delegator: preferredTeacherStudentPerspectiveDelegator,
          facilitator: preferredTeacherStudentPerspectiveFacilitator,
          demonstrator: preferredTeacherStudentPerspectiveDemonstrator,
          hybrid: preferredTeacherStudentPerspectiveHybrid,
          others: preferredTeacherStudentPerspectiveOthersBox,
          others_type: preferredTeacherStudentPerspectiveOthersField
        },
        parent: {
          male: preferredTeacherParentPerspectiveMale,
          female: preferredTeacherParentPerspectiveFemale,
          name: preferredTeacherParentPerspectiveNameBox,
          name_type: preferredTeacherParentPerspectiveNameField,
          authority: preferredTeacherParentPerspectiveAuthority,
          delegator: preferredTeacherParentPerspectiveDelegator,
          facilitator: preferredTeacherParentPerspectiveFacilitator,
          demonstrator: preferredTeacherParentPerspectiveDemonstrator,
          hybrid: preferredTeacherParentPerspectiveHybrid,
          others: preferredTeacherParentPerspectiveOthersBox,
          others_type: preferredTeacherParentPerspectiveOthersField
        }
      }

      userDoc.interestedTopics = {
        public_speaking: interestedTopicsPublicSpeaking,
        debate: interestedTopicsDebate,
        coding: interestedTopicsCoding,
        writing: interestedTopicsWriting,
        leadership: interestedTopicsLeadership,
        creative_writing: interestedTopicsCreativeWriting,
        formal_writing: interestedTopicsFormalWriting,
        reading_comprehension: interestedTopicsReadingComprehension,
        literature: interestedTopicsLiterature,
        others: interestedTopicsOthersBox,
        others_type: interestedTopicsOthersField
      }

      userDoc.parent = {
        _type: 'Parent',
        salute: parentSalute,
        name: parentName,
        password: parentPassword && parentPassword,
        child: userData._id,
        child_email: userData.email,
        child_name: userDoc.name,
        email: parentEmail,
        gender: parentGender,
        dob: parentDOB,
        phone_country_code: parentPhoneCountryCode,
        phone: parentPhone,
        contact_preference: {
          contact: parentContactPreference,
          others_type: parentContactPreferenceOther
        },
        facebook: parentFacebook,
        line: parentLine,
        meta: {
          occupation: {
            area: parentOccupation,
            specific: parentOccupationSpecific
          },
          family_background: parentFamilyBackground,
          networking_circle: {
            fb: parentNetworkingFB,
            website: parentNetworkingWebsite,
            friends: parentNetworkingFriends,
            others: parentNetworkingOthersBox,
            others_type: parentNetworkingOthersField
          },
          conflict: parentConflict,
          decision_maker: {
            father: parentDecisionFather,
            mother: parentDecisionMother
          },
          parent_character: {
            dominance: {
              direct: pcDominanceDirect,
              result_oriented: pcDominanceResultsOriented,
              firm: pcDominanceFirm,
              strong_willed: pcDominanceStrongWilled,
              forceful: pcDominanceForceful
            },
            influence: {
              outgoing: pcInfluenceOutgoing,
              enthusiastic: pcInfluenceEnthusiastic,
              optimistic: pcInfluenceOptimistic,
              high_spirited: pcInfluenceHighSpirited,
              lively: pcInfluenceLively
            },
            conscientiousness: {
              analytical: pcConscientiousnessAnalytical,
              reserved: pcConscientiousnessReserved,
              precise: pcConscientiousnessPrecise,
              private: pcConscientiousnessPrivate,
              systematic: pcConscientiousnessSystematic
            },
            steadiness: {
              even_tempered: pcSteadinessEvenTempered,
              accommodating: pcSteadinessAccommodating,
              patient: pcSteadinessPatient,
              humble: pcSteadinessHumble,
              tactful: pcSteadinessTactful
            }
          },
          interested_topics: {
            public_speaking: parentInterestedTopicsPublicSpeaking,
            debate: parentInterestedTopicsDebate,
            coding: parentInterestedTopicsCoding,
            writing: parentInterestedTopicsWriting,
            leadership: parentInterestedTopicsLeadership,
            creative_writing: parentInterestedTopicsCreativeWriting,
            formal_writing: parentInterestedTopicsFormalWriting,
            reading_comprehension: parentInterestedTopicsReadingComprehension,
            literature: parentInterestedTopicsLiterature,
            others: parentInterestedTopicsOthersBox,
            others_type: parentInterestedTopicsOthersField
          },
          payment_mode: paymentMode,
          date_of_renewal: parentDateOfRenewal
        }
      }

      if (studentBackground) {
        userDoc['meta'] = {
          background: studentBackground
        }
      }
    }
    // TUTOR CREATE - if already exists, check if tutor, and update. else if userData is undefined, create tutor
    else if (userData?._type === 'Tutor' || !userData) {
      let subjectArray = !!subject.trim()
        ? subject.split(',').map(function (item) {
            return item.trim()
          })
        : []

      let trackArray = !!track_record.trim()
        ? track_record.split(',').map(function (item) {
            return item.trim()
          })
        : []

      userDoc.gender = gender
      userDoc.date_of_birth = moment(dateOfBirth).startOf('day').toISOString()
      userDoc.profile_pic = profile_pic
      userDoc.desc = desc
      userDoc.subject = subjectArray
      userDoc.credential = {
        year_of_exp,
        track_record: trackArray
      }
      userDoc._type = 'Tutor'
    }
    // if there is id, then update. if no id, then create
    if (userData?._id) {
      this.props.updateUser(userData._type, userData._id, userDoc)

      if (dateOfConsultation !== prevDateOfConsultation) {
        this.props.createConsultation(consultationDoc)
      }

      this.props.createParent(userDoc)
      // if (userData?.parent?.name !== '' && userData?.parent?.phone !== '') {
      if (userData?.parent?.email !== '') {
        this.props.updateParent(userData?.parent?.email, userDoc)
      }
      // }
    } else {
      this.props.createUser(userDoc)
    }
  }

  render() {
    const { isOpen, toggle, userData, isLoading, parentDetails, error, csList, saList, currentUser } = this.props
    const isKruA = currentUser?._id === 'hhfu4lg4TBmPQ5FcLIt9lA'

    // TODO: work on this parentDetails
    // console.log("🚀 alex: ~ file: DialogUserEdit.js ~ line 1924 ~ render ~ parentDetails", parentDetails)
    const {
      dbid,
      name,
      nickname,
      email,
      dateOfBirth,
      gender,
      password,
      phoneCountryCode,
      phone,
      level,
      othersLevel,
      gradeLevel,
      yearLevel,
      showOthersLevel,
      othersSchool,
      showOthersSchool,
      school,
      typeOfSchool,
      othersTypeOfSchool,
      showOthersTypeOfSchool,
      dateOfConsultation,
      csnameOfStaff,
      sanameOfStaff,
      actionPlans,
      consultationNotes,
      firstLanguage,
      facebook,
      line,

      gpaEngBox,
      gpaThaiBox,
      gpaMathsBox,
      gpaPhysBox,
      gpaChemBox,
      gpaBioBox,
      gpaGeoBox,
      gpaHistBox,
      gpaLitBox,
      gpaSSBox,
      gpaPolBox,
      gpaEcoBox,
      gpaArtBox,
      gpaFLBox,
      gpaOthersBox,

      gpaEng,
      gpaThai,
      gpaMaths,
      gpaPhys,
      gpaChem,
      gpaBio,
      gpaGeo,
      gpaHist,
      gpaLit,
      gpaSS,
      gpaPol,
      gpaEco,
      gpaArt,
      gpaFL,
      gpaOthersField,

      favSubjEngBox,
      favSubjThaiBox,
      favSubjMathsBox,
      favSubjPhysBox,
      favSubjChemBox,
      favSubjBioBox,
      favSubjGeoBox, //geography
      favSubjHistBox,
      favSubjLitBox, //literature
      favSubjSSBox, //social studies
      favSubjPolBox, //politics
      favSubjEcoBox, //economics
      favSubjArtBox,
      favSubjFLBox, //foreign language\
      favSubjOthersBox,
      favSubjOthersBoxField,

      weakestSubjEngBox,
      weakestSubjThaiBox,
      weakestSubjMathsBox,
      weakestSubjPhysBox,
      weakestSubjChemBox,
      weakestSubjBioBox,
      weakestSubjGeoBox, //geography
      weakestSubjHistBox,
      weakestSubjLitBox, //literature
      weakestSubjSSBox, //social studies
      weakestSubjPolBox, //politics
      weakestSubjEcoBox, //economics
      weakestSubjArtBox,
      weakestSubjFLBox, //foreign language
      weakestSubjOthersBox,
      weakestSubjOthersBoxField,

      extExamSAT,
      extExamGRE,
      extExamIELTS,
      extExamTOEFL,
      extExamGMAT,
      extExamOthers,
      extExamOthersField,

      //other tuition centre course
      otherTCcourseSAT,
      otherTCcourseGRE,
      otherTCcourseIELTS,
      otherTCcourseTOEFL,
      otherTCcourseGMAT,
      otherTCcourseEnglish,
      otherTCcourseThai,
      otherTCcourseMath,
      otherTCcoursePhysics,
      otherTCcourseBiology,
      otherTCcourseChemistry,
      otherTCcourseGeography,
      otherTCcourseHistory,
      otherTCcourseLiterature,
      otherTCcourseSocialStudies,
      otherTCcoursePolitics,
      otherTCcourseEconomics,
      otherTCcourseArt,
      otherTCcourseForeignLanguage,
      otherTCcourseOthers,
      otherTCcourseOthersField,

      studyingAtEPA,
      coursesTakenAtEpaSAT,
      coursesTakenAtEpaGRE,
      coursesTakenAtEpaIELTS,
      coursesTakenAtEpaTOEFL,
      coursesTakenAtEpaGMAT,
      coursesTakenAtEpaEnglish,
      coursesTakenAtEpaThai,
      coursesTakenAtEpaMath,
      coursesTakenAtEpaPhysics,
      coursesTakenAtEpaBiology,
      coursesTakenAtEpaGeography,
      coursesTakenAtEpaChemistry,
      coursesTakenAtEpaHistory,
      coursesTakenAtEpaLiterature,
      coursesTakenAtEpaSocialStudies,
      coursesTakenAtEpaPolitics,
      coursesTakenAtEpaEconomics,
      coursesTakenAtEpaArt,
      coursesTakenAtEpaForeignLanguage,
      coursesTakenAtEpaOthers,
      coursesTakenAtEpaOthersField,

      careerGoalsStudentPerspective,
      careerGoalsParentPerspective,
      careerGoalsCounsellorPerspective,

      areaOfStudy,
      areaOfStudySpecific,
      areaOfStudy1,
      areaOfStudySpecific1,
      areaOfStudy2,
      areaOfStudySpecific2,
      areaOfStudy3,
      areaOfStudySpecific3,
      areaOfStudy4,
      areaOfStudySpecific4,

      areaOfStudyParent,
      areaOfStudyParentSpecific,
      areaOfStudyParent1,
      areaOfStudyParentSpecific1,
      areaOfStudyParent2,
      areaOfStudyParentSpecific2,
      areaOfStudyParent3,
      areaOfStudyParentSpecific3,
      areaOfStudyParent4,
      areaOfStudyParentSpecific4,

      studyGoalsStudentPerspectiveEntranceExamBox,
      studyGoalsStudentPerspectiveEntranceExamField,
      studyGoalsStudentPerspectiveSchoolExamBox,
      studyGoalsStudentPerspectiveSATBox,
      studyGoalsStudentPerspectiveGREBox,
      studyGoalsStudentPerspectiveIELTSBox,
      studyGoalsStudentPerspectiveTOEFLBox,
      studyGoalsStudentPerspectiveGMATBox,
      studyGoalsStudentPerspectiveOthersBox,
      studyGoalsStudentPerspectiveOthersField,

      studyGoalsParentPerspectiveEntranceExamBox,
      studyGoalsParentPerspectiveEntranceExamField,
      studyGoalsParentPerspectiveSchoolExamBox,
      studyGoalsParentPerspectiveSATBox,
      studyGoalsParentPerspectiveGREBox,
      studyGoalsParentPerspectiveIELTSBox,
      studyGoalsParentPerspectiveTOEFLBox,
      studyGoalsParentPerspectiveGMATBox,
      studyGoalsParentPerspectiveOthersBox,
      studyGoalsParentPerspectiveOthersField,

      studyGoalsCounsellorPerspectiveEntranceExamBox,
      studyGoalsCounsellorPerspectiveEntranceExamField,
      studyGoalsCounsellorPerspectiveSchoolExamBox,
      studyGoalsCounsellorPerspectiveSATBox,
      studyGoalsCounsellorPerspectiveGREBox,
      studyGoalsCounsellorPerspectiveIELTSBox,
      studyGoalsCounsellorPerspectiveTOEFLBox,
      studyGoalsCounsellorPerspectiveGMATBox,
      studyGoalsCounsellorPerspectiveOthersBox,
      studyGoalsCounsellorPerspectiveOthersField,

      targetCountryStudentUS,
      targetCountryStudentCanada,
      targetCountryStudentUK,
      targetCountryStudentOthersBox,
      targetCountryStudentOthersField,

      targetCountryParentUS,
      targetCountryParentCanada,
      targetCountryParentUK,
      targetCountryParentOthersBox,
      targetCountryParentOthersField,

      learningTypeVisual,
      learningTypeKinaesthetic,
      learningTypeAural,
      learningTypeSocial,
      learningTypeSolitary,
      learningTypeVerbal,
      learningTypeLogical,
      learningTypeOthersBox,
      learningTypeOthersField,

      personalityTestCheck,
      personalityTestResult,
      personalityTestResultField,

      scDominanceDirect,
      scDominanceResultsOriented,
      scDominanceFirm,
      scDominanceStrongWilled,
      scDominanceForceful,
      scInfluenceOutgoing,
      scInfluenceEnthusiastic,
      scInfluenceOptimistic,
      scInfluenceHighSpirited,
      scInfluenceLively,
      scConscientiousnessAnalytical,
      scConscientiousnessReserved,
      scConscientiousnessPrecise,
      scConscientiousnessPrivate,
      scConscientiousnessSystematic,
      scSteadinessEvenTempered,
      scSteadinessAccommodating,
      scSteadinessPatient,
      scSteadinessHumble,
      scSteadinessTactful,

      healthConditionNA,
      healthConditionADHD,
      healthConditionAutism,
      healthConditionOthersBox,
      healthConditionOthersField,

      preferredTeacherStudentPerspectiveMale,
      preferredTeacherStudentPerspectiveFemale,
      preferredTeacherStudentPerspectiveNameBox,
      preferredTeacherStudentPerspectiveNameField,
      preferredTeacherStudentPerspectiveAuthority,
      preferredTeacherStudentPerspectiveDelegator,
      preferredTeacherStudentPerspectiveFacilitator,
      preferredTeacherStudentPerspectiveDemonstrator,
      preferredTeacherStudentPerspectiveHybrid,
      preferredTeacherStudentPerspectiveOthersBox,
      preferredTeacherStudentPerspectiveOthersField,

      preferredTeacherParentPerspectiveMale,
      preferredTeacherParentPerspectiveFemale,
      preferredTeacherParentPerspectiveNameBox,
      preferredTeacherParentPerspectiveNameField,
      preferredTeacherParentPerspectiveAuthority,
      preferredTeacherParentPerspectiveDelegator,
      preferredTeacherParentPerspectiveFacilitator,
      preferredTeacherParentPerspectiveDemonstrator,
      preferredTeacherParentPerspectiveHybrid,
      preferredTeacherParentPerspectiveOthersBox,
      preferredTeacherParentPerspectiveOthersField,

      interestedTopicsPublicSpeaking,
      interestedTopicsDebate,
      interestedTopicsCoding,
      interestedTopicsLeadership,
      interestedTopicsWriting,
      interestedTopicsCreativeWriting,
      interestedTopicsFormalWriting,
      interestedTopicsReadingComprehension,
      interestedTopicsLiterature,
      interestedTopicsOthersBox,
      interestedTopicsOthersField,

      studyMediumEng,
      studyMediumThai,
      courseType,
      examType,
      studyingAtOtherSchool,
      studyingAtOtherSchoolName,

      famous,
      academicBackground,
      specialRequests,
      learningStyle,
      amountPaid,
      amountFrequency,

      contactPreference,
      contactPreferenceOther,

      parentName,
      parentPassword,
      parentEmail,
      parentPhoneCountryCode,
      parentPhone,
      parentDOB,
      parentGender,
      parentContactPreference,
      parentContactPreferenceOther,
      parentFacebook,
      parentLine,
      parentOccupation,
      parentOccupationSpecific,
      parentFamilyBackground,
      parentNetworkingFB,
      parentNetworkingWebsite,
      parentNetworkingFriends,
      parentNetworkingOthersBox,
      parentNetworkingOthersField,

      pcDominanceDirect,
      pcDominanceResultsOriented,
      pcDominanceFirm,
      pcDominanceStrongWilled,
      pcDominanceForceful,
      pcInfluenceOutgoing,
      pcInfluenceEnthusiastic,
      pcInfluenceOptimistic,
      pcInfluenceHighSpirited,
      pcInfluenceLively,
      pcConscientiousnessAnalytical,
      pcConscientiousnessReserved,
      pcConscientiousnessPrecise,
      pcConscientiousnessPrivate,
      pcConscientiousnessSystematic,
      pcSteadinessEvenTempered,
      pcSteadinessAccommodating,
      pcSteadinessPatient,
      pcSteadinessHumble,
      pcSteadinessTactful,
      parentConflict,
      parentDecisionFather,
      parentDecisionMother,

      paymentMode,
      parentInterestedTopicsPublicSpeaking,
      parentInterestedTopicsDebate,
      parentInterestedTopicsCoding,
      parentInterestedTopicsLeadership,
      parentInterestedTopicsWriting,
      parentInterestedTopicsCreativeWriting,
      parentInterestedTopicsFormalWriting,
      parentInterestedTopicsReadingComprehension,
      parentInterestedTopicsLiterature,
      parentInterestedTopicsOthersBox,
      parentInterestedTopicsOthersField,
      parentDateOfRenewal,

      studentBackground,
      profile_pic,
      desc,
      subject,
      year_of_exp,
      track_record,

      navigation
    } = this.state

    const toolbarGroups = [
      { name: 'basicstyles', groups: ['basicstyles', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'align', 'bidi', 'links'] }
    ]

    const CkConfig = {
      toolbarGroups
    }

    return (
      <Dialog disableEscapeKeyDown disableEnforceFocus open={isOpen} onClose={toggle} fullScreen={true}>
        <AppBar className="position-relative">
          <div className="container-toolbar">
            <div>
              <Toolbar>
                <IconButton onClick={toggle} aria-label="Close" className="text-white">
                  <CloseIcon />
                </IconButton>
                <Typography
                  type="title"
                  color="inherit"
                  style={{
                    flex: 1
                  }}>
                  {userData ? 'Edit User' : 'Add New Tutor'}
                </Typography>
              </Toolbar>
            </div>
            {userData?._type === 'Student' && (
              <div>
                <Button onClick={this.handleNavigation} style={{ color: 'white' }}>
                  {' '}
                  {navigation ? `Hide Navigation` : `Show Navigation`}{' '}
                </Button>
              </div>
            )}
          </div>
        </AppBar>
        <DialogContent style={{ padding: '24px' }}>
          <div className="row">
            <div className="col-md">
              <h1>
                {userData?._type === 'Student' ? 'Student' : 'Teacher'} Information (CTRL + F to look for specific
                keyword)
              </h1>

              {userData?._type === 'Student' && navigation && (
                <>
                  <Link smooth to="#basic-information" className="basic-information">
                    <span className="basic-information-text"> Basic Information </span>
                  </Link>
                  {isKruA && (
                    <>
                      {' '}
                      <Link smooth to="#school-academic" className="school-academic">
                        <span className="school-academic-text"> School & Academic</span>
                      </Link>
                      <Link smooth to="#academic-career" className="academic-career">
                        <span className="academic-career-text"> Academic & Career</span>
                      </Link>
                      <Link smooth to="#personality-preference" className="personality-preference">
                        Personality & Preference
                      </Link>
                      <Link smooth to="#consultation" className="consultation">
                        <span className="consultation-text"> Consultation</span>
                      </Link>
                      <Link smooth to="#additional-notes" className="additional-notes">
                        <span className="additional-notes-text"> Additional Notes</span>
                      </Link>
                      <Link smooth to="#parent-info" className="parent-info">
                        <span className="parent-info-text"> Parent's Info</span>
                      </Link>
                    </>
                  )}
                  {!isKruA && (
                    <>
                      <Link smooth to="#school-academic" className="school-academic-admin">
                        <span className="school-academic-text"> Schools & Academic</span>
                      </Link>
                      <Link smooth to="#academic-career" className="academic-career-admin">
                        <span className="academic-career-text"> Academic & Career Goal</span>
                      </Link>
                      <Link smooth to="#personality-preference" className="personality-preference-admin">
                        <span className="personality-preference-text"> Personality & Preference</span>
                      </Link>
                      <Link smooth to="#parent-info" className="parent-info-admin">
                        <span className="parent-info-text"> Parent's Info</span>
                      </Link>
                    </>
                  )}
                </>
              )}
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h1 id="basic-information">Basic Information</h1>
                <FormControl className={'mb-3 w-100'}>
                  <div className="row">
                    <div className="col-3 col-md-3">
                      <InputText
                        name="name"
                        label="Name"
                        defaultValue={name}
                        onBlur={this.handleBlurInput.bind(this)}
                      />
                    </div>
                    <div className="col-3 col-md-3">
                      <InputText
                        name="nickname"
                        label="Nickname"
                        defaultValue={nickname}
                        onBlur={this.handleBlurInput.bind(this)}
                      />
                    </div>
                    <div className="col-3 mt-3 col-md-3 mt-md-0">
                      <InputText
                        name="email"
                        label="Email"
                        defaultValue={email}
                        onBlur={this.handleBlurInput.bind(this)}
                      />
                    </div>
                    <div className="col-3 col-md-3">
                      <InputText
                        name="password"
                        label="Password"
                        defaultValue={password}
                        onBlur={this.handleBlurInput.bind(this)}
                      />
                      <FormHelperText>(To reset if necessary)</FormHelperText>
                    </div>
                  </div>
                </FormControl>
                <FormControl className={'mb-3 w-100'}>
                  <div className="row">
                    <div className="col-auto mb-2">
                      <FormControl>
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup row aria-label="gender" name="gender" value={gender} onChange={this.handleChange}>
                          <FormControlLabel value="f" control={<Radio color="primary" />} label="Female" />
                          <FormControlLabel value="m" control={<Radio color="primary" />} label="Male" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {userData?._type === 'Student' && (
                      <>
                        <div className="col-auto mb-2">
                          <FormLabel component="legend">Date of birth</FormLabel>
                          <InputText
                            name="dateOfBirth"
                            type="date"
                            defaultValue={dateOfBirth}
                            onBlur={this.handleBlurInput.bind(this)}
                          />
                        </div>

                        <div className="col-auto mb-2">
                          <FormLabel component="legend">Phone Country Code</FormLabel>
                          <InputText
                            name="phoneCountryCode"
                            type="tel"
                            defaultValue={phoneCountryCode}
                            onBlur={this.handleBlurInput.bind(this)}
                          />
                        </div>
                        <div className="col-auto mb-2">
                          <FormLabel component="legend">Phone</FormLabel>
                          <InputText
                            name="phone"
                            type="tel"
                            defaultValue={phone}
                            onBlur={this.handleBlurInput.bind(this)}
                          />
                        </div>

                        <div className="col-2 mb-2">
                          <FormLabel component="legend">Customer service</FormLabel>
                          <Autocomplete
                            id="combo-box-demo"
                            style={{ marginTop: '13px' }}
                            name="csnameOfStaff"
                            value={csnameOfStaff}
                            disabled={isLoading}
                            options={csList.map(options => options.name)}
                            onChange={(event, value) => {
                              if (value !== null) this.handleAutoCompleteCSStaff(event, value)
                            }}
                            // getOptionLabel={option => option?.name || ''}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={params => <TextField {...params} />}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-auto mb-2">
                      <FormLabel component="legend">EPA Database ID</FormLabel>
                      <InputText
                        name="dbid"
                        type="text"
                        defaultValue={dbid}
                        onBlur={this.handleBlurInput.bind(this)}
                        disabled={dbid}
                        helperText={'For Internal use only'}
                      />
                    </div>
                  </div>
                </FormControl>
                {userData?._type === 'Student' && (
                  <FormControl className={'mb-3 w-100'}>
                    <FormLabel component="legend">Contact Preference</FormLabel>
                    <div className="row">
                      <div className="col-3 mb-3">
                        <div className="row">
                          <div className="col-auto">
                            <RadioGroup
                              row
                              aria-label="contactPreference"
                              name="contactPreference"
                              value={contactPreference}
                              onChange={this.handleChange}>
                              <FormControlLabel value="whatsapp" control={<Radio color="primary" />} label="Whatsapp" />
                              <FormControlLabel value="line" control={<Radio color="primary" />} label="Line" />
                              <FormControlLabel value="other" control={<Radio color="primary" />} label="Other" />
                            </RadioGroup>
                            {contactPreference === 'other' && (
                              <InputText
                                name="contactPreferenceOther"
                                placeholder="e.g. phone call, SMS, e-mail"
                                defaultValue={contactPreferenceOther}
                                onBlur={this.handleBlurInput.bind(this)}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="row">
                          <FormLabel component="legend">Facebook URL</FormLabel>

                          <InputText
                            name="facebook"
                            label="Facebook"
                            placeholder="facebook.com/zuck"
                            defaultValue={facebook}
                            onBlur={this.handleBlurInput.bind(this)}
                          />
                        </div>
                      </div>

                      <div className="ml-md-5 col-auto">
                        <div className="row">
                          <FormLabel component="legend">LINE ID</FormLabel>

                          <InputText
                            name="line"
                            label="Line"
                            defaultValue={line}
                            onBlur={this.handleBlurInput.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </FormControl>
                )}
              </Paper>
            </div>
            {!isKruA && (
              <>
                <Paper elevation={2} className="app-wrapper my-3 mx-2">
                  <div className="row col-12">
                    <FormControl className="w-100">
                      <div className="mb-2">
                        <FormLabel component="legend">Date of consultation</FormLabel>
                        <InputText
                          name="dateOfConsultation"
                          type="date"
                          defaultValue={dateOfConsultation}
                          onBlur={this.handleBlurInput.bind(this)}
                        />
                      </div>
                    </FormControl>

                    <InputText
                      name="consultationNotes"
                      label="Consultation notes/comments"
                      placeholder="Consultation notes/comments"
                      defaultValue={consultationNotes}
                      multiline
                      minRows={4}
                      onBlur={this.handleBlurInput.bind(this)}
                    />
                  </div>
                </Paper>
              </>
            )}

            {userData?._type === 'Student' && (
              <div className="col-12">
                <div className="row">
                  <div className="col-md">
                    <SchoolsAcademic
                      userData={userData}
                      handleChange={this.handleChange}
                      handleCheckboxChange={this.handleCheckboxChange}
                      handleAutoCompleteChange={this.handleAutoCompleteChange}
                      handleBlurInput={this.handleBlurInput.bind(this)}
                      level={level}
                      othersLevel={othersLevel}
                      showOthersLevel={showOthersLevel}
                      othersSchool={othersSchool}
                      showOthersSchool={showOthersSchool}
                      school={school}
                      typeOfSchool={typeOfSchool}
                      othersTypeOfSchool={othersTypeOfSchool}
                      showOthersTypeOfSchool={showOthersTypeOfSchool}
                      studyMediumEng={studyMediumEng}
                      studyMediumThai={studyMediumThai}
                      studyingAtOtherSchool={studyingAtOtherSchool}
                      studyingAtOtherSchoolName={studyingAtOtherSchoolName}
                      gpaEngBox={gpaEngBox}
                      gpaThaiBox={gpaThaiBox}
                      gpaMathsBox={gpaMathsBox}
                      gpaPhysBox={gpaPhysBox}
                      gpaChemBox={gpaChemBox}
                      gpaBioBox={gpaBioBox}
                      gpaGeoBox={gpaGeoBox}
                      gpaHistBox={gpaHistBox}
                      gpaLitBox={gpaLitBox}
                      gpaSSBox={gpaSSBox}
                      gpaPolBox={gpaPolBox}
                      gpaEcoBox={gpaEcoBox}
                      gpaArtBox={gpaArtBox}
                      gpaFLBox={gpaFLBox}
                      gpaOthersBox={gpaOthersBox}
                      gpaEng={gpaEng}
                      gpaThai={gpaThai}
                      gpaMaths={gpaMaths}
                      gpaPhys={gpaPhys}
                      gpaChem={gpaChem}
                      gpaBio={gpaBio}
                      gpaGeo={gpaGeo}
                      gpaHist={gpaHist}
                      gpaLit={gpaLit}
                      gpaSS={gpaSS}
                      gpaPol={gpaPol}
                      gpaEco={gpaEco}
                      gpaArt={gpaArt}
                      gpaFL={gpaFL}
                      gpaOthersField={gpaOthersField}
                      favSubjEngBox={favSubjEngBox}
                      favSubjThaiBox={favSubjThaiBox}
                      favSubjMathsBox={favSubjMathsBox}
                      favSubjPhysBox={favSubjPhysBox}
                      favSubjChemBox={favSubjChemBox}
                      favSubjBioBox={favSubjBioBox}
                      favSubjGeoBox={favSubjGeoBox}
                      favSubjHistBox={favSubjHistBox}
                      favSubjLitBox={favSubjLitBox}
                      favSubjSSBox={favSubjSSBox}
                      favSubjPolBox={favSubjPolBox}
                      favSubjEcoBox={favSubjEcoBox}
                      favSubjArtBox={favSubjArtBox}
                      favSubjFLBox={favSubjFLBox}
                      favSubjOthersBox={favSubjOthersBox}
                      favSubjOthersBoxField={favSubjOthersBoxField}
                      weakestSubjEngBox={weakestSubjEngBox}
                      weakestSubjThaiBox={weakestSubjThaiBox}
                      weakestSubjMathsBox={weakestSubjMathsBox}
                      weakestSubjPhysBox={weakestSubjPhysBox}
                      weakestSubjChemBox={weakestSubjChemBox}
                      weakestSubjBioBox={weakestSubjBioBox}
                      weakestSubjGeoBox={weakestSubjGeoBox}
                      weakestSubjHistBox={weakestSubjHistBox}
                      weakestSubjLitBox={weakestSubjLitBox}
                      weakestSubjSSBox={weakestSubjSSBox}
                      weakestSubjPolBox={weakestSubjPolBox}
                      weakestSubjEcoBox={weakestSubjEcoBox}
                      weakestSubjArtBox={weakestSubjArtBox}
                      weakestSubjFLBox={weakestSubjFLBox}
                      weakestSubjOthersBox={weakestSubjOthersBox}
                      weakestSubjOthersBoxField={weakestSubjOthersBoxField}
                      extExamSAT={extExamSAT}
                      extExamGRE={extExamGRE}
                      extExamIELTS={extExamIELTS}
                      extExamTOEFL={extExamTOEFL}
                      extExamGMAT={extExamGMAT}
                      extExamOthers={extExamOthers}
                      extExamOthersField={extExamOthersField}
                      otherTCcourseSAT={otherTCcourseSAT}
                      otherTCcourseGRE={otherTCcourseGRE}
                      otherTCcourseIELTS={otherTCcourseIELTS}
                      otherTCcourseTOEFL={otherTCcourseTOEFL}
                      otherTCcourseGMAT={otherTCcourseGMAT}
                      otherTCcourseEnglish={otherTCcourseEnglish}
                      otherTCcourseThai={otherTCcourseThai}
                      otherTCcourseMath={otherTCcourseMath}
                      otherTCcoursePhysics={otherTCcoursePhysics}
                      otherTCcourseBiology={otherTCcourseBiology}
                      otherTCcourseChemistry={otherTCcourseChemistry}
                      otherTCcourseGeography={otherTCcourseGeography}
                      otherTCcourseHistory={otherTCcourseHistory}
                      otherTCcourseLiterature={otherTCcourseLiterature}
                      otherTCcourseSocialStudies={otherTCcourseSocialStudies}
                      otherTCcoursePolitics={otherTCcoursePolitics}
                      otherTCcourseEconomics={otherTCcourseEconomics}
                      otherTCcourseArt={otherTCcourseArt}
                      otherTCcourseForeignLanguage={otherTCcourseForeignLanguage}
                      otherTCcourseOthers={otherTCcourseOthers}
                      otherTCcourseOthersField={otherTCcourseOthersField}
                      studyingAtEPA={studyingAtEPA}
                      coursesTakenAtEpaSAT={coursesTakenAtEpaSAT}
                      coursesTakenAtEpaGRE={coursesTakenAtEpaGRE}
                      coursesTakenAtEpaIELTS={coursesTakenAtEpaIELTS}
                      coursesTakenAtEpaTOEFL={coursesTakenAtEpaTOEFL}
                      coursesTakenAtEpaGMAT={coursesTakenAtEpaGMAT}
                      coursesTakenAtEpaEnglish={coursesTakenAtEpaEnglish}
                      coursesTakenAtEpaThai={coursesTakenAtEpaThai}
                      coursesTakenAtEpaMath={coursesTakenAtEpaMath}
                      coursesTakenAtEpaPhysics={coursesTakenAtEpaPhysics}
                      coursesTakenAtEpaBiology={coursesTakenAtEpaBiology}
                      coursesTakenAtEpaGeography={coursesTakenAtEpaGeography}
                      coursesTakenAtEpaChemistry={coursesTakenAtEpaChemistry}
                      coursesTakenAtEpaHistory={coursesTakenAtEpaHistory}
                      coursesTakenAtEpaLiterature={coursesTakenAtEpaLiterature}
                      coursesTakenAtEpaSocialStudies={coursesTakenAtEpaSocialStudies}
                      coursesTakenAtEpaPolitics={coursesTakenAtEpaPolitics}
                      coursesTakenAtEpaEconomics={coursesTakenAtEpaEconomics}
                      coursesTakenAtEpaArt={coursesTakenAtEpaArt}
                      coursesTakenAtEpaForeignLanguage={coursesTakenAtEpaForeignLanguage}
                      coursesTakenAtEpaOthers={coursesTakenAtEpaOthers}
                      coursesTakenAtEpaOthersField={coursesTakenAtEpaOthersField}
                      gradeLevel={gradeLevel}
                      yearLevel={yearLevel}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <AcademicCareerGoal
                      isKruA={isKruA}
                      userData={userData}
                      handleChange={this.handleChange}
                      handleCheckboxChange={this.handleCheckboxChange}
                      handleBlurInput={this.handleBlurInput.bind(this)}
                      handleAutoCompleteStudyAreaChange={this.handleAutoCompleteStudyAreaChange}
                      handleAutoCompleteStudyAreaSpecificChange={this.handleAutoCompleteStudyAreaSpecificChange}
                      handleAutoCompleteStudyAreaChange1={this.handleAutoCompleteStudyAreaChange1}
                      handleAutoCompleteStudyAreaSpecificChange1={this.handleAutoCompleteStudyAreaSpecificChange1}
                      handleAutoCompleteStudyAreaChange2={this.handleAutoCompleteStudyAreaChange2}
                      handleAutoCompleteStudyAreaSpecificChange2={this.handleAutoCompleteStudyAreaSpecificChange2}
                      handleAutoCompleteStudyAreaChange3={this.handleAutoCompleteStudyAreaChange3}
                      handleAutoCompleteStudyAreaSpecificChange3={this.handleAutoCompleteStudyAreaSpecificChange3}
                      handleAutoCompleteStudyAreaChange4={this.handleAutoCompleteStudyAreaChange4}
                      handleAutoCompleteStudyAreaSpecificChange4={this.handleAutoCompleteStudyAreaSpecificChange4}
                      handleAutoCompleteParentStudyAreaChange={this.handleAutoCompleteParentStudyAreaChange}
                      handleAutoCompleteParentStudyAreaSpecificChange={
                        this.handleAutoCompleteParentStudyAreaSpecificChange
                      }
                      handleAutoCompleteParentStudyAreaChange1={this.handleAutoCompleteParentStudyAreaChange1}
                      handleAutoCompleteParentStudyAreaSpecificChange1={
                        this.handleAutoCompleteParentStudyAreaSpecificChange1
                      }
                      handleAutoCompleteParentStudyAreaChange2={this.handleAutoCompleteParentStudyAreaChange2}
                      handleAutoCompleteParentStudyAreaSpecificChange2={
                        this.handleAutoCompleteParentStudyAreaSpecificChange2
                      }
                      handleAutoCompleteParentStudyAreaChange3={this.handleAutoCompleteParentStudyAreaChange3}
                      handleAutoCompleteParentStudyAreaSpecificChange3={
                        this.handleAutoCompleteParentStudyAreaSpecificChange3
                      }
                      handleAutoCompleteParentStudyAreaChange4={this.handleAutoCompleteParentStudyAreaChange4}
                      handleAutoCompleteParentStudyAreaSpecificChange4={
                        this.handleAutoCompleteParentStudyAreaSpecificChange4
                      }
                      careerGoalsStudentPerspective={careerGoalsStudentPerspective}
                      careerGoalsParentPerspective={careerGoalsParentPerspective}
                      careerGoalsCounsellorPerspective={careerGoalsCounsellorPerspective}
                      areaOfStudy={areaOfStudy}
                      areaOfStudySpecific={areaOfStudySpecific}
                      areaOfStudy1={areaOfStudy1}
                      areaOfStudySpecific1={areaOfStudySpecific1}
                      areaOfStudy2={areaOfStudy2}
                      areaOfStudySpecific2={areaOfStudySpecific2}
                      areaOfStudy3={areaOfStudy3}
                      areaOfStudySpecific3={areaOfStudySpecific3}
                      areaOfStudy4={areaOfStudy4}
                      areaOfStudySpecific4={areaOfStudySpecific4}
                      areaOfStudyParent={areaOfStudyParent}
                      areaOfStudyParentSpecific={areaOfStudyParentSpecific}
                      areaOfStudyParent1={areaOfStudyParent1}
                      areaOfStudyParentSpecific1={areaOfStudyParentSpecific1}
                      areaOfStudyParent2={areaOfStudyParent2}
                      areaOfStudyParentSpecific2={areaOfStudyParentSpecific2}
                      areaOfStudyParent3={areaOfStudyParent3}
                      areaOfStudyParentSpecific3={areaOfStudyParentSpecific3}
                      areaOfStudyParent4={areaOfStudyParent4}
                      areaOfStudyParentSpecific4={areaOfStudyParentSpecific4}
                      studyGoalsStudentPerspectiveEntranceExamBox={studyGoalsStudentPerspectiveEntranceExamBox}
                      studyGoalsStudentPerspectiveEntranceExamField={studyGoalsStudentPerspectiveEntranceExamField}
                      studyGoalsStudentPerspectiveSchoolExamBox={studyGoalsStudentPerspectiveSchoolExamBox}
                      studyGoalsStudentPerspectiveSATBox={studyGoalsStudentPerspectiveSATBox}
                      studyGoalsStudentPerspectiveGREBox={studyGoalsStudentPerspectiveGREBox}
                      studyGoalsStudentPerspectiveIELTSBox={studyGoalsStudentPerspectiveIELTSBox}
                      studyGoalsStudentPerspectiveTOEFLBox={studyGoalsStudentPerspectiveTOEFLBox}
                      studyGoalsStudentPerspectiveGMATBox={studyGoalsStudentPerspectiveGMATBox}
                      studyGoalsStudentPerspectiveOthersBox={studyGoalsStudentPerspectiveOthersBox}
                      studyGoalsStudentPerspectiveOthersField={studyGoalsStudentPerspectiveOthersField}
                      studyGoalsParentPerspectiveEntranceExamBox={studyGoalsParentPerspectiveEntranceExamBox}
                      studyGoalsParentPerspectiveEntranceExamField={studyGoalsParentPerspectiveEntranceExamField}
                      studyGoalsParentPerspectiveSchoolExamBox={studyGoalsParentPerspectiveSchoolExamBox}
                      studyGoalsParentPerspectiveSATBox={studyGoalsParentPerspectiveSATBox}
                      studyGoalsParentPerspectiveGREBox={studyGoalsParentPerspectiveGREBox}
                      studyGoalsParentPerspectiveIELTSBox={studyGoalsParentPerspectiveIELTSBox}
                      studyGoalsParentPerspectiveTOEFLBox={studyGoalsParentPerspectiveTOEFLBox}
                      studyGoalsParentPerspectiveGMATBox={studyGoalsParentPerspectiveGMATBox}
                      studyGoalsParentPerspectiveOthersBox={studyGoalsParentPerspectiveOthersBox}
                      studyGoalsParentPerspectiveOthersField={studyGoalsParentPerspectiveOthersField}
                      studyGoalsCounsellorPerspectiveEntranceExamBox={studyGoalsCounsellorPerspectiveEntranceExamBox}
                      studyGoalsCounsellorPerspectiveEntranceExamField={
                        studyGoalsCounsellorPerspectiveEntranceExamField
                      }
                      studyGoalsCounsellorPerspectiveSchoolExamBox={studyGoalsCounsellorPerspectiveSchoolExamBox}
                      studyGoalsCounsellorPerspectiveSATBox={studyGoalsCounsellorPerspectiveSATBox}
                      studyGoalsCounsellorPerspectiveGREBox={studyGoalsCounsellorPerspectiveGREBox}
                      studyGoalsCounsellorPerspectiveIELTSBox={studyGoalsCounsellorPerspectiveIELTSBox}
                      studyGoalsCounsellorPerspectiveTOEFLBox={studyGoalsCounsellorPerspectiveTOEFLBox}
                      studyGoalsCounsellorPerspectiveGMATBox={studyGoalsCounsellorPerspectiveGMATBox}
                      studyGoalsCounsellorPerspectiveOthersBox={studyGoalsCounsellorPerspectiveOthersBox}
                      studyGoalsCounsellorPerspectiveOthersField={studyGoalsCounsellorPerspectiveOthersField}
                      targetCountryStudentUS={targetCountryStudentUS}
                      targetCountryStudentCanada={targetCountryStudentCanada}
                      targetCountryStudentUK={targetCountryStudentUK}
                      targetCountryStudentOthersBox={targetCountryStudentOthersBox}
                      targetCountryStudentOthersField={targetCountryStudentOthersField}
                      targetCountryParentUS={targetCountryParentUS}
                      targetCountryParentCanada={targetCountryParentCanada}
                      targetCountryParentUK={targetCountryParentUK}
                      targetCountryParentOthersBox={targetCountryParentOthersBox}
                      targetCountryParentOthersField={targetCountryParentOthersField}
                      specialRequests={specialRequests}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <PersonalityPreference
                      isKruA={isKruA}
                      userData={userData}
                      handleChange={this.handleChange}
                      handleCheckboxChange={this.handleCheckboxChange}
                      handleBlurInput={this.handleBlurInput.bind(this)}
                      learningTypeVisual={learningTypeVisual}
                      learningTypeKinaesthetic={learningTypeKinaesthetic}
                      learningTypeAural={learningTypeAural}
                      learningTypeSocial={learningTypeSocial}
                      learningTypeSolitary={learningTypeSolitary}
                      learningTypeVerbal={learningTypeVerbal}
                      learningTypeLogical={learningTypeLogical}
                      learningTypeOthersBox={learningTypeOthersBox}
                      learningTypeOthersField={learningTypeOthersField}
                      personalityTestCheck={personalityTestCheck}
                      personalityTestResult={personalityTestResult}
                      personalityTestResultField={personalityTestResultField}
                      scDominanceDirect={scDominanceDirect}
                      scDominanceResultsOriented={scDominanceResultsOriented}
                      scDominanceFirm={scDominanceFirm}
                      scDominanceStrongWilled={scDominanceStrongWilled}
                      scDominanceForceful={scDominanceForceful}
                      scInfluenceOutgoing={scInfluenceOutgoing}
                      scInfluenceEnthusiastic={scInfluenceEnthusiastic}
                      scInfluenceOptimistic={scInfluenceOptimistic}
                      scInfluenceHighSpirited={scInfluenceHighSpirited}
                      scInfluenceLively={scInfluenceLively}
                      scConscientiousnessAnalytical={scConscientiousnessAnalytical}
                      scConscientiousnessReserved={scConscientiousnessReserved}
                      scConscientiousnessPrecise={scConscientiousnessPrecise}
                      scConscientiousnessPrivate={scConscientiousnessPrivate}
                      scConscientiousnessSystematic={scConscientiousnessSystematic}
                      scSteadinessEvenTempered={scSteadinessEvenTempered}
                      scSteadinessAccommodating={scSteadinessAccommodating}
                      scSteadinessPatient={scSteadinessPatient}
                      scSteadinessHumble={scSteadinessHumble}
                      scSteadinessTactful={scSteadinessTactful}
                      healthConditionNA={healthConditionNA}
                      healthConditionADHD={healthConditionADHD}
                      healthConditionAutism={healthConditionAutism}
                      healthConditionOthersBox={healthConditionOthersBox}
                      healthConditionOthersField={healthConditionOthersField}
                      preferredTeacherStudentPerspectiveMale={preferredTeacherStudentPerspectiveMale}
                      preferredTeacherStudentPerspectiveFemale={preferredTeacherStudentPerspectiveFemale}
                      preferredTeacherStudentPerspectiveNameBox={preferredTeacherStudentPerspectiveNameBox}
                      preferredTeacherStudentPerspectiveNameField={preferredTeacherStudentPerspectiveNameField}
                      preferredTeacherStudentPerspectiveAuthority={preferredTeacherStudentPerspectiveAuthority}
                      preferredTeacherStudentPerspectiveDelegator={preferredTeacherStudentPerspectiveDelegator}
                      preferredTeacherStudentPerspectiveFacilitator={preferredTeacherStudentPerspectiveFacilitator}
                      preferredTeacherStudentPerspectiveDemonstrator={preferredTeacherStudentPerspectiveDemonstrator}
                      preferredTeacherStudentPerspectiveHybrid={preferredTeacherStudentPerspectiveHybrid}
                      preferredTeacherStudentPerspectiveOthersBox={preferredTeacherStudentPerspectiveOthersBox}
                      preferredTeacherStudentPerspectiveOthersField={preferredTeacherStudentPerspectiveOthersField}
                      preferredTeacherParentPerspectiveMale={preferredTeacherParentPerspectiveMale}
                      preferredTeacherParentPerspectiveFemale={preferredTeacherParentPerspectiveFemale}
                      preferredTeacherParentPerspectiveNameBox={preferredTeacherParentPerspectiveNameBox}
                      preferredTeacherParentPerspectiveNameField={preferredTeacherParentPerspectiveNameField}
                      preferredTeacherParentPerspectiveAuthority={preferredTeacherParentPerspectiveAuthority}
                      preferredTeacherParentPerspectiveDelegator={preferredTeacherParentPerspectiveDelegator}
                      preferredTeacherParentPerspectiveFacilitator={preferredTeacherParentPerspectiveFacilitator}
                      preferredTeacherParentPerspectiveDemonstrator={preferredTeacherParentPerspectiveDemonstrator}
                      preferredTeacherParentPerspectiveHybrid={preferredTeacherParentPerspectiveHybrid}
                      preferredTeacherParentPerspectiveOthersBox={preferredTeacherParentPerspectiveOthersBox}
                      preferredTeacherParentPerspectiveOthersField={preferredTeacherParentPerspectiveOthersField}
                      interestedTopicsPublicSpeaking={interestedTopicsPublicSpeaking}
                      interestedTopicsDebate={interestedTopicsDebate}
                      interestedTopicsCoding={interestedTopicsCoding}
                      interestedTopicsLeadership={interestedTopicsLeadership}
                      interestedTopicsWriting={interestedTopicsWriting}
                      interestedTopicsCreativeWriting={interestedTopicsCreativeWriting}
                      interestedTopicsFormalWriting={interestedTopicsFormalWriting}
                      interestedTopicsReadingComprehension={interestedTopicsReadingComprehension}
                      interestedTopicsLiterature={interestedTopicsLiterature}
                      interestedTopicsOthersBox={interestedTopicsOthersBox}
                      interestedTopicsOthersField={interestedTopicsOthersField}
                    />
                  </div>
                </div>
                {isKruA && (
                  <>
                    {userData?._type === 'Student' && (
                      <>
                        <h1 id="consultation" className="mt-1">
                          Consultation
                        </h1>
                        <Paper elevation={2} className="app-wrapper mb-3">
                          <FormControl className="w-100">
                            <div className="mb-2">
                              <FormLabel component="legend">Date of consultation</FormLabel>
                              <InputText
                                name="dateOfConsultation"
                                type="date"
                                defaultValue={dateOfConsultation}
                                onBlur={this.handleBlurInput.bind(this)}
                              />
                            </div>
                          </FormControl>

                          <InputText
                            name="actionPlans"
                            label="Consultation Action plans"
                            placeholder="Consultation Action plans"
                            defaultValue={actionPlans}
                            multiline
                            minRows={4}
                            onBlur={this.handleBlurInput.bind(this)}
                          />
                          <div className="my-2"></div>
                          <InputText
                            name="consultationNotes"
                            label="Consultation notes/comments by admin"
                            placeholder="Consultation notes/comments by admin"
                            defaultValue={consultationNotes}
                            multiline
                            minRows={4}
                            onBlur={this.handleBlurInput.bind(this)}
                          />
                        </Paper>
                      </>
                    )}

                    <h1 id="additional-notes" className="mt-1">
                      Additional Notes
                    </h1>
                    <Paper elevation={2} className="app-wrapper mb-3">
                      <FormControl className="w-100">
                        {/* <CKEditor
                      name="studentBackground"
                      content={studentBackground}
                      config={CkConfig}
                      events={{
                        change: this.handleCKEditorChange('studentBackground')
                      }}
                    /> */}
                        <InputText
                          name="studentBackground"
                          defaultValue={studentBackground}
                          onBlur={this.handleBlurInput.bind(this)}
                          multiline
                          label="Notes"
                          minRows={4}
                        />
                      </FormControl>
                    </Paper>
                  </>
                )}

                <ParentInfo
                  isKruA={isKruA}
                  userData={userData}
                  handleChange={this.handleChange}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleBlurInput={this.handleBlurInput.bind(this)}
                  handleAutoCompleteOccupationChange={this.handleAutoCompleteOccupationChange}
                  handleAutoCompleteOccupationSpecificChange={this.handleAutoCompleteOccupationSpecificChange}
                  handleAutoCompleteSAStaff={this.handleAutoCompleteSAStaff}
                  parentPassword={parentPassword}
                  parentName={parentName}
                  parentEmail={parentEmail}
                  parentPhoneCountryCode={parentPhoneCountryCode}
                  parentPhone={parentPhone}
                  isLoading={isLoading}
                  saList={saList}
                  sanameOfStaff={sanameOfStaff}
                  parentDOB={parentDOB}
                  parentGender={parentGender}
                  parentContactPreference={parentContactPreference}
                  parentContactPreferenceOther={parentContactPreferenceOther}
                  parentFacebook={parentFacebook}
                  parentLine={parentLine}
                  parentOccupation={parentOccupation}
                  parentOccupationSpecific={parentOccupationSpecific}
                  parentFamilyBackground={parentFamilyBackground}
                  parentNetworkingFB={parentNetworkingFB}
                  parentNetworkingWebsite={parentNetworkingWebsite}
                  parentNetworkingFriends={parentNetworkingFriends}
                  parentNetworkingOthersBox={parentNetworkingOthersBox}
                  parentNetworkingOthersField={parentNetworkingOthersField}
                  pcDominanceDirect={pcDominanceDirect}
                  pcDominanceResultsOriented={pcDominanceResultsOriented}
                  pcDominanceFirm={pcDominanceFirm}
                  pcDominanceStrongWilled={pcDominanceStrongWilled}
                  pcDominanceForceful={pcDominanceForceful}
                  pcInfluenceOutgoing={pcInfluenceOutgoing}
                  pcInfluenceEnthusiastic={pcInfluenceEnthusiastic}
                  pcInfluenceOptimistic={pcInfluenceOptimistic}
                  pcInfluenceHighSpirited={pcInfluenceHighSpirited}
                  pcInfluenceLively={pcInfluenceLively}
                  pcConscientiousnessAnalytical={pcConscientiousnessAnalytical}
                  pcConscientiousnessReserved={pcConscientiousnessReserved}
                  pcConscientiousnessPrecise={pcConscientiousnessPrecise}
                  pcConscientiousnessPrivate={pcConscientiousnessPrivate}
                  pcConscientiousnessSystematic={pcConscientiousnessSystematic}
                  pcSteadinessEvenTempered={pcSteadinessEvenTempered}
                  pcSteadinessAccommodating={pcSteadinessAccommodating}
                  pcSteadinessPatient={pcSteadinessPatient}
                  pcSteadinessHumble={pcSteadinessHumble}
                  pcSteadinessTactful={pcSteadinessTactful}
                  parentConflict={parentConflict}
                  parentDecisionFather={parentDecisionFather}
                  parentDecisionMother={parentDecisionMother}
                  paymentMode={paymentMode}
                  parentInterestedTopicsPublicSpeaking={parentInterestedTopicsPublicSpeaking}
                  parentInterestedTopicsDebate={parentInterestedTopicsDebate}
                  parentInterestedTopicsCoding={parentInterestedTopicsCoding}
                  parentInterestedTopicsLeadership={parentInterestedTopicsLeadership}
                  parentInterestedTopicsWriting={parentInterestedTopicsWriting}
                  parentInterestedTopicsCreativeWriting={parentInterestedTopicsCreativeWriting}
                  parentInterestedTopicsFormalWriting={parentInterestedTopicsFormalWriting}
                  parentInterestedTopicsReadingComprehension={parentInterestedTopicsReadingComprehension}
                  parentInterestedTopicsLiterature={parentInterestedTopicsLiterature}
                  parentInterestedTopicsOthersBox={parentInterestedTopicsOthersBox}
                  parentInterestedTopicsOthersField={parentInterestedTopicsOthersField}
                  parentDateOfRenewal={parentDateOfRenewal}
                />
              </div>
            )}
            {/* {(!userData || userData._type === 'Tutor') && (
              <div className="col-md">
                <Paper elevation={2} className={'app-wrapper mb-3'}>
                  <div className="row">
                    <div className="col">
                      <FormControl className={'mb-3 w-100'}>
                        <TextField
                          label={'Profile Picture'}
                          name={'profile_pic'}
                          value={profile_pic}
                          onChange={this.handleChange}
                          helperText={'Upload the image to S3 first and then paste the HTTPS link here.'}
                        />
                      </FormControl>
                      <FormControl className="mb-3 w-100">
                        <TextField
                          label={'Subjects taught'}
                          name={'subject'}
                          value={subject}
                          onChange={this.handleChange}
                          helperText={'Separate multiple subjects with commas e.g. English, Mathematics'}
                        />
                      </FormControl>
                      <FormControl className="mb-3 w-100">
                        <TextField
                          label={'Years of experience'}
                          name={'year_of_exp'}
                          value={year_of_exp}
                          onChange={this.handleChange}
                        />
                      </FormControl>
                      <FormControl className="mb-3 w-100">
                        <TextField
                          label={'Credentials'}
                          name={'track_record'}
                          value={track_record}
                          onChange={this.handleChange}
                          helperText={'What makes this tutor qualified? Comma separated.'}
                        />
                      </FormControl>
                    </div>
                    {profile_pic && (
                      <div className="col-4">
                        <img src={profile_pic} alt="profile" className="img-fluid" style={{ borderRadius: '50%' }} />
                      </div>
                    )}
                    <div className="col-12">
                      <FormControl className="mb-3 w-100">
                        <CKEditor
                          name="description"
                          content={desc}
                          config={CkConfig}
                          events={{
                            change: this.handleCKEditorChange('desc')
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </Paper>
              </div>
            )} */}
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button variant={'text'} color={'default'} onClick={toggle}>
            Cancel
          </Button>
          <div style={{flex: '1 0 0'}} /> */}
          {!name && (
            <Link smooth to="#basic-information">
              Name cannot be empty!
            </Link>
          )}

          {userData?._type === 'Student' && !parentEmail && (
            <Link smooth to="#parent-info">
              Parent email cannot be empty!
            </Link>
          )}
          {userData?._type === 'Student' && error && (
            <Link smooth to="#parent-info">
              Parent email duplicated!
            </Link>
          )}
          <Button
            disabled={!name || !email || isLoading || (userData?._type === 'Student' && !parentEmail)}
            variant="contained"
            color="primary"
            onClick={this.saveChanges}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogUserEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  userData: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogUserEdit)
