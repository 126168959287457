import React from 'react'
import moment from 'moment'

const AnnouncementTemplate = props => {
  const { user, subject, content } = props

  return (
    <div className="gmail_quote">
      <div>
        <div className="gmail_quote">
          <div>
            <div>
              <div>
                <div>
                  <table
                    className="gmail-body-wrap"
                    style={{
                      margin: '0px',
                      padding: '0px',
                      fontSize: '100%',
                      fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                      lineHeight: '1.65',
                      height: '100%',
                      background: '#efefef',
                      width: '100%'
                    }}>
                    <tbody>
                      <tr
                        style={{
                          margin: '0px',
                          padding: '0px',
                          fontSize: '100%',
                          fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                          lineHeight: '1.65'
                        }}>
                        <td
                          className="gmail-container"
                          style={{
                            padding: '0px',
                            fontSize: '100%',
                            fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                            lineHeight: '1.65',
                            margin: '0px auto',
                            display: 'block',
                            clear: 'both',
                            maxWidth: '580px'
                          }}>
                          <table
                            style={{
                              margin: '0px',
                              padding: '0px',
                              fontSize: '100%',
                              fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                              lineHeight: '1.65',
                              borderCollapse: 'collapse',
                              width: '100%'
                            }}>
                            <tbody>
                              <tr
                                style={{
                                  margin: '0px',
                                  padding: '0px',
                                  fontSize: '100%',
                                  fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                                  lineHeight: '1.65'
                                }}>
                                <td
                                  className="gmail-masthead"
                                  style={{
                                    margin: '0px',
                                    padding: '80px 0px',
                                    fontSize: '100%',
                                    fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                                    lineHeight: '1.65',
                                    background: 'rgb(253, 101, 2)',
                                    color: 'white'
                                  }}
                                  align="center">
                                  <h1
                                    style={{
                                      padding: '0px',
                                      fontSize: '32px',
                                      fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                                      lineHeight: '1.25',
                                      maxWidth: '90%',
                                      textTransform: 'uppercase',
                                      margin: '0px auto'
                                    }}>
                                    <img
                                      style={{ marginRight: '0px' }}
                                      src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/EPA+Extended+Logo+Dark+Background+(Transparent).png"
                                      width="225"
                                      height="64"
                                      alt="epa orange logo"
                                    />
                                    <br />
                                    <br />
                                    Announcement
                                  </h1>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  margin: '0px',
                                  padding: '0px',
                                  fontSize: '100%',
                                  fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                                  lineHeight: '1.65'
                                }}>
                                <td
                                  className="gmail-content"
                                  style={{
                                    margin: '0px',
                                    padding: '30px 35px',
                                    fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                                    lineHeight: '1.65',
                                    background: 'white'
                                  }}>
                                  <h2>{subject}</h2>
                                  <p>Hi {!!user ? user.name : '[Name]'},</p>
                                  <p dangerouslySetInnerHTML={{ __html: content }}></p>
                                  <br />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr
                        style={{
                          margin: '0px',
                          padding: '0px',
                          fontSize: '100%',
                          fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                          lineHeight: '1.65'
                        }}>
                        <td
                          className="gmail-container"
                          style={{
                            padding: '0px',
                            fontSize: '100%',
                            fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                            lineHeight: '1.65',
                            margin: '0px auto',
                            display: 'block',
                            clear: 'both',
                            maxWidth: '580px'
                          }}>
                          <table
                            style={{
                              margin: '0px',
                              padding: '0px',
                              fontSize: '100%',
                              fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                              lineHeight: '1.65',
                              borderCollapse: 'collapse',
                              width: '100%'
                            }}>
                            <tbody>
                              <tr
                                style={{
                                  margin: '0px',
                                  padding: '0px',
                                  fontSize: '100%',
                                  fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                                  lineHeight: '1.65'
                                }}>
                                <td
                                  className="gmail-content m_-6337355445745940795gmail-footer"
                                  style={{
                                    margin: '0px',
                                    padding: '30px 35px',
                                    fontSize: '90%',
                                    fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif",
                                    lineHeight: '1.65',
                                    background: 'none'
                                  }}
                                  align="center">
                                  <br />
                                  <br /> © Copyright {moment().format('YYYY')} EPA Pte Ltd.
                                  <br />
                                  <span className="HOEnZb">
                                    <span style={{ color: '#888888' }}>
                                      <span className="HOEnZb">
                                        <span style={{ color: '#888888' }}>
                                          <br />{' '}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnouncementTemplate
