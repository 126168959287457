import React from 'react'
import { connect } from 'react-redux'
import * as Type from 'constants/actionTypes'
import agent from 'agent'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import AppModuleHeader from 'components/AppModuleHeader'
import ClassList from 'pages/CourseManagement/ClassList'
import AddClass from 'pages/CourseManagement/AddClass'
import CircularProgress from '@material-ui/core/CircularProgress'

import { ROUTE_COURSES } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'
import Pagination from 'components/Pagination'

import $ from 'jquery'
window.jQuery = $

const mapStateToProps = state => ({
  ...state.classroom,
  width: state.common.windowWidth,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  getClasses: filter => dispatch({ type: Type.GET_CLASSES, payload: agent.Classroom.getClasses(filter, 10, 0) }),
  createClass: classRoom => dispatch({ type: Type.CREATE_CLASS, payload: agent.Classroom.createClass(classRoom) }),
  editClass: classRoom => dispatch({ type: Type.EDIT_CLASS, payload: agent.Classroom.editClass(classRoom) })
})

class CourseManagement extends React.Component {
  constructor() {
    super()

    this.changeSearch = e => {
      let value = e.target.value
      this.setState({ searchTerm: value })
    }

    this.searchClasses = e => {
      e.preventDefault()
      let { searchTerm } = this.state
      let formattedSearchTerm = searchTerm.replaceAll('(', '\\(').replaceAll(')', '\\)')
      let filter = { name: { $regex: formattedSearchTerm, $options: 'i' } }
      this.props.getClasses(filter)
      this.setState({ activeFilter: filter })
    }

    this.state = {
      addClassState: false,
      drawerState: false,
      searchTerm: '',
      activeFilter: ''
    }
  }

  ClassSideBar = () => {
    return (
      <div className="module-side">
        <div className="module-side-header">
          <div className="module-logo">
            <i className="zmdi zmdi-file mr-4" />
            <span>Class</span>
          </div>
        </div>

        <div className="module-side-content">
          <div className="module-side-scroll">
            <div className="module-add-task">
              {checkActionPermitted(permissions, this.props.userRole, ROUTE_COURSES, 'canManage') && (
                <Button
                  disabled={this.props.creatingClass}
                  className="btn-block jr-btn"
                  variant="contained"
                  color="primary"
                  aria-label="add"
                  onClick={this.toggleAddClass}>
                  <i className="zmdi zmdi-file-plus zmdi-hc-fw" />
                  <span>Add New Class</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  toggleAddClass = () => {
    this.setState({
      addClassState: !this.state.addClassState
    })
  }

  onSaveClass = data => {
    if (!!data._id) {
      this.props.editClass(data)
    } else {
      this.props.createClass(data)
    }
  }

  onDeleteClass = data => {
    this.props.editClass({ _id: data._id, is_active: !data.is_active })
  }

  handleRequestClose = () => {}

  onToggleDrawer = () => {
    this.setState({
      drawerState: !this.state.drawerState
    })
  }

  componentDidMount() {
    this.props.getClasses({}, 0)
  }

  componentDidUpdate(prevProps, prevState) {
    if ((!prevProps.classCreated && this.props.classCreated) || (!prevProps.classEdited && this.props.classEdited)) {
      this.props.getClasses({}, 0)
    }
  }

  render() {
    const { classList, classCount, currentPage, alertMessage, showMessage, noContentFoundMessage, loader } = this.props
    const { drawerState, addClassState, searchTerm, activeFilter } = this.state

    return (
      <div className="app-wrapper">
        <div className="app-module animated slideInUpTiny animation-duration-3">
          <div className="d-block d-xl-none">
            <Drawer open={drawerState} onClose={this.onToggleDrawer.bind(this)}>
              {this.ClassSideBar()}
            </Drawer>
          </div>
          <div className="app-module-sidenav d-none d-xl-flex">{this.ClassSideBar()}</div>

          <div className="module-box">
            <div className="module-box-header">
              <IconButton
                className="drawer-btn d-block d-xl-none"
                aria-label="Menu"
                onClick={this.onToggleDrawer.bind(this)}>
                <i className="zmdi zmdi-menu" />
              </IconButton>
              <AppModuleHeader
                value={searchTerm}
                onChange={this.changeSearch}
                onSearch={this.searchClasses}
                placeholder="Search by class name"
              />
            </div>
            <div className="module-box-content">
              <div className="module-list-scroll">
                {loader ? (
                  <div className="loader-view-block h-100">
                    <div className="loader-view">
                      <CircularProgress />
                    </div>
                  </div>
                ) : classList.length === 0 ? (
                  <div className="h-100 d-flex align-items-center justify-content-center">{noContentFoundMessage}</div>
                ) : (
                  <ClassList
                    classList={classList}
                    onDeleteClass={this.onDeleteClass.bind(this)}
                    onSaveClass={this.onSaveClass.bind(this)}
                  />
                )}
              </div>

              {classCount > 0 && (
                <div className="row justify-content-center p-2" style={{ backgroundColor: '#f5f5f5' }}>
                  <div className="col-auto">
                    <Pagination
                      itemType="Classes"
                      itemFilter={activeFilter}
                      itemsCount={classCount}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {addClassState && (
          <AddClass
            open={addClassState}
            tClass={{
              name: '',
              subject: '',
              class_type: 'Workshop',
              selected: false
            }}
            onSaveClass={this.onSaveClass}
            onClassClose={this.toggleAddClass}
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showMessage}
          autoHideDuration={3000}
          onClose={this.handleRequestClose}
          SnackbarContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{alertMessage}</span>}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseManagement)
