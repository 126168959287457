import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Pluralize from 'react-pluralize'
import agent from 'agent'
import { CREATE_ORDER, GET_CLASSES } from 'constants/actionTypes'
import { ROUTE_ORDERS } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'

const mapStateToProps = state => ({
  classList: state.classroom.classList,
  isLoadingClasses: state.classroom.isLoadingClasses,
  creatingOrder: state.orders.creatingOrder,
  createError: state.orders.error,
  agentList: state.user.agentList,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  getClasses: filter => dispatch({ type: GET_CLASSES, payload: agent.Classroom.getClasses(filter) }),
  createOrder: req => dispatch({ type: CREATE_ORDER, payload: agent.Orders.createOrder(req) })
})

class DialogAddLesson extends React.Component {
  state = {
    type: 'PAYMENT',
    email: '',
    phone_country_code: 66,
    phone: '',
    selectedClass: null,
    selectedLessons: [],
    lessonRange: [],
    remark: '', // REMARK is a required field in adding order manually because backend use it to check whether to process or not. TODO: review and change logic later
    className: '',
    selectClassDialogOpen: false,
    selectLessonDialogOpen: false,
    discount_code: '',
    agent_id: '',
    paymentChannel: 'offline',
    paymentProvider: '',
    paymentCompletedOn: moment().format('YYYY-MM-DDTHH:mm'),
    isRenewal: false,
    errors: {}
  }

  componentDidMount() {
    this.canCreateNonTrial = checkActionPermitted(permissions, this.props.userRole, ROUTE_ORDERS, 'canCreateNormal')
    if (this.canCreateNonTrial) {
      this.setState({ type: 'PAYMENT' })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoadingClasses && !this.props.isLoadingClasses) {
      this.setState({ selectClassDialogOpen: true })
    }

    if (!_.isEqual(prevState.selectedLessons, this.state.selectedLessons)) {
      const { selectedLessons, selectedClass } = this.state

      if (selectedLessons.length === 0) {
        this.setState({ lessonRange: [] })
      } else {
        let firstLesson = selectedLessons[0]
        let lastLesson = selectedLessons[selectedLessons.length - 1]
        let allLessons = selectedClass.lesson

        let range = _.filter(allLessons, lesson => {
          return (
            moment(lesson.scheduled_on) >= moment(firstLesson.scheduled_on) &&
            moment(lesson.scheduled_on) <= moment(lastLesson.scheduled_on)
          )
        })
        this.setState({ lessonRange: range })
      }
    }

    if (prevProps.creatingOrder && !this.props.creatingOrder && !this.props.createError) {
      this.props.onSave()
    }
  }

  handleChange = e => {
    let target = e.target.name
    let value = e.target.value

    this.validateInput(target, value)
    this.setState({ [target]: value })
  }

  onChangeDiscountCode = e => {
    this.setState({ discount_code: e.target.value.toUpperCase() })
  }

  handleCheckboxChange = event => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  validateInput = (target, value) => {
    if (target === 'email') {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value.toLowerCase()
        ) ||
        !value
      ) {
        this.setState(prevState => {
          let errors = { ...prevState.errors }
          delete errors[target]

          return { errors }
        })
      } else {
        this.setState(prevState => {
          let errors = { ...prevState.errors }
          errors[target] = 'You have entered an invalid email'

          return { errors }
        })
      }
    }
  }

  searchClass = e => {
    e.preventDefault()
    let formattedSearchTerm = this.state.className.replaceAll('(', '\\(').replaceAll(')', '\\)')
    let filter = {
      name: {
        $regex: formattedSearchTerm,
        $options: 'i'
      }
    }

    this.props.getClasses(filter)
  }

  selectClass = classObj => () => {
    this.setState({ selectedClass: classObj, selectedLessons: [] })
  }

  selectLesson = lessonObj => () => {
    const { selectedLessons } = this.state

    let toModify = _.cloneDeep(selectedLessons)

    let indexOfLesson = _.findIndex(toModify, lesson => {
      return lesson._id === lessonObj._id
    })

    if (indexOfLesson === -1) {
      toModify.push(lessonObj)
    } else {
      _.remove(toModify, lesson => {
        return lesson._id === lessonObj._id
      })
    }

    toModify = _.orderBy(toModify, ['scheduled_on'], ['asc'])
    this.setState({ selectedLessons: toModify })
  }

  toggleSelectClassDialog = () => {
    this.setState({ selectClassDialogOpen: !this.state.selectClassDialogOpen })
  }

  toggleSelectLessonDialog = () => {
    this.setState({ selectLessonDialogOpen: !this.state.selectLessonDialogOpen })
  }

  submitOrder = () => {
    const {
      type,
      email,
      phone_country_code,
      phone,
      selectedClass,
      lessonRange,
      remark,
      discount_code,
      agent_id,
      errors,
      paymentChannel,
      paymentProvider,
      paymentCompletedOn,
      isRenewal
    } = this.state

    if (!_.isEmpty(errors)) {
      return false
    }

    let sortedLessonList = _.orderBy(lessonRange, ['scheduled_on'], ['asc'])
    let start_date = moment(sortedLessonList[0].scheduled_on).format('YYYY-MM-DD')
    let end_date = moment(sortedLessonList[sortedLessonList.length - 1].scheduled_on).format('YYYY-MM-DD')
    let paymentInfo = {
      channel: paymentChannel,
      provider: paymentProvider,
      completed_on: moment(paymentCompletedOn).toISOString()
    }

    if (paymentChannel === 'online') {
      if (paymentProvider === 'stripe') {
        paymentInfo['platform'] = 'credit-card'
      } else if (paymentProvider === 'hitpay') {
        paymentInfo['platform'] = 'paynow'
      }
    }

    const reqPayload = {
      type,
      email,
      phone_country_code,
      phone,
      class: selectedClass._id,
      start_date,
      end_date,
      remark,
      discount_code: discount_code.trim(),
      agent_id: agent_id,
      payment: paymentInfo,
      meta: {
        is_renewal: isRenewal
      }
    }
    this.props.createOrder(reqPayload)
  }

  render() {
    const { isOpen, toggle, classList, isLoadingClasses, agentList, creatingOrder } = this.props
    const {
      type,
      email,
      phone_country_code,
      phone,
      selectedClass,
      selectedLessons,
      remark,
      className,
      selectClassDialogOpen,
      selectLessonDialogOpen,
      lessonRange,
      discount_code,
      errors,
      agent_id,
      paymentChannel,
      paymentProvider,
      paymentCompletedOn,
      isRenewal
    } = this.state
    let lessonList = []
    if (!!selectedClass) {
      lessonList = _.orderBy(selectedClass.lesson, ['scheduled_on'], ['asc'])
    }

    return (
      <Dialog disableEnforceFocus open={isOpen} onClose={toggle} fullScreen={true}>
        <AppBar className="position-relative">
          <Toolbar>
            <IconButton onClick={toggle} aria-label="Close" className="text-white">
              <CloseIcon />
            </IconButton>
            <h3 className="mb-0">Create New Allocation</h3>
          </Toolbar>
        </AppBar>
        <DialogContent className="p-4">
          <Paper elevation={2} className="app-wrapper mb-3 d-none">
            <FormControl className="w-100 mb-2">
              <Select name="type" value={'PAYMENT'} onChange={this.handleChange} input={<Input id="type" />}>
                <MenuItem value="PAYMENT">Payment</MenuItem>
              </Select>
            </FormControl>
          </Paper>

          <div className="row">
            <div className="col">
              <Paper elevation={2} className="app-wrapper mb-3">
                <FormControl className="w-100 mb-3">
                  <TextField
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    label="Student Email"
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </FormControl>
                <FormControl className="w-100 mb-3">
                  <div className="row">
                    <div className="col-3">
                      <TextField
                        type="tel"
                        name="phone_country_code"
                        value={phone_country_code}
                        onChange={this.handleChange}
                        label="Country Code"
                        error={!!errors.phone_country_code}
                        helperText={errors.phone_country_code}
                      />
                    </div>
                    <div className="col-9">
                      <TextField
                        type="tel"
                        name="phone"
                        value={phone}
                        onChange={this.handleChange}
                        label="Phone Number"
                        error={!!errors.phone}
                        helperText={errors.phone}
                        fullWidth
                      />
                    </div>
                  </div>
                </FormControl>
                <div className="row">
                  <div className="col-9">
                    If phone number is not known, leave it blank.
                  </div>
                </div>
              </Paper>
              <Paper elevation={2} className="app-wrapper mb-3">
                <FormControl className="w-100">
                  
                  <TextField
                    name="remark"
                    value={remark}
                    onChange={this.handleChange}
                    label="Remarks"
                    multiline
                    helperText={`*REQUIRED: Put 'NEW' if new student or 'EXISTING' if student is already registered`}
                    className="mt-2"
                  />
                </FormControl>
              </Paper>

              
            </div>
            <div className="col">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h3 className="font-weight-semibold">Which class is this allocation for?</h3>
                <form onSubmit={this.searchClass}>
                  <div className="row">
                    <div className="col">
                      <TextField
                        name={'className'}
                        label={'Class Name'}
                        value={className}
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </div>
                    <div className="col-auto">
                      <Button disabled={isLoadingClasses} type={'submit'} variant={'contained'} color={'secondary'}>
                        Search {isLoadingClasses && <CircularProgress color={'primary'} size={16} className={'ml-3'} />}
                      </Button>
                    </div>
                  </div>
                </form>
                {!!selectedClass && (
                  <Paper elevation={2} className={'app-wrapper mt-2'}>
                    <small className={'text-muted'}>Selected Class</small>
                    <h4 className="font-weight-semibold m-0">{selectedClass.name}</h4>
                  </Paper>
                )}
                {!!selectedClass && (
                  <Paper elevation={2} className={'app-wrapper mt-2'}>
                    <small className={'text-muted'}>Selected Lessons</small>
                    <div>
                      <div className="row">
                        <div className="col">
                          <h4 className="font-weight-semibold m-0">
                            <Pluralize count={lessonRange.length} singular={'lesson'} />
                          </h4>
                        </div>
                        <div className="col-auto">
                          <Button variant={'contained'} color={'secondary'} onClick={this.toggleSelectLessonDialog}>
                            Select Lessons
                          </Button>
                        </div>
                      </div>
                      <Dialog open={selectLessonDialogOpen} fullWidth>
                        <DialogTitle>Select lessons</DialogTitle>
                        <DialogContent>
                          <h4 className="font-weight-semibold text-danger">
                            Selection will be ALL lessons from earliest to latest lesson chosen
                          </h4>
                          <List disablePadding>
                            {lessonList.map(item => {
                              return (
                                <ListItem
                                  key={item._id}
                                  button
                                  divider
                                  onClick={this.selectLesson(item)}
                                  className={
                                    _.findIndex(lessonRange, i => {
                                      return i._id === item._id
                                    }) !== -1
                                      ? 'bg-green lighten-5'
                                      : ''
                                  }>
                                  <ListItemText>
                                    <div>
                                      {_.findIndex(selectedLessons, i => {
                                        return i._id === item._id
                                      }) !== -1 && <i className="zmdi zmdi-check text-success mr-3" />}
                                      {item.name}
                                      {moment(item.ended_on) < moment() && (
                                        <span className="d-inline-block ml-3 border border-danger text-danger rounded py-1 px-3">
                                          Ended
                                        </span>
                                      )}
                                    </div>
                                    <div className="text-muted">
                                      <small>{moment(item.scheduled_on).format('ddd, D MMM YYYY, h:mma')}</small>
                                    </div>
                                  </ListItemText>
                                </ListItem>
                              )
                            })}
                          </List>
                        </DialogContent>
                        <DialogActions>
                          <Button color={'primary'} onClick={this.toggleSelectLessonDialog}>
                            Done
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Paper>
                )}
                {selectClassDialogOpen && (
                  <Dialog open={selectClassDialogOpen} fullWidth>
                    <DialogTitle>Select a class</DialogTitle>
                    <DialogContent>
                      <FormControl className={'w-100 app-wrapper'}>
                        <form onSubmit={this.searchClass}>
                          <div className="row">
                            <div className="col">
                              <TextField
                                name={'className'}
                                label={'Class Name'}
                                value={className}
                                onChange={this.handleChange}
                                fullWidth
                              />
                            </div>
                            <div className="col-auto">
                              <Button
                                disabled={isLoadingClasses}
                                type={'submit'}
                                variant={'contained'}
                                color={'secondary'}>
                                Search{' '}
                                {isLoadingClasses && (
                                  <CircularProgress color={'primary'} size={16} className={'ml-3'} />
                                )}
                              </Button>
                            </div>
                          </div>
                        </form>
                      </FormControl>
                      <Paper elevation={2} className={'mt-3'}>
                        <h3 className={'border-bottom mb-0 font-weight-semibold app-wrapper py-3'}>
                          Results for '{className}'
                        </h3>
                        <List disablePadding>
                          {isLoadingClasses ? (
                            <ListItem>
                              <ListItemText disableTypography>
                                <CircularProgress color={'primary'} size={20} />
                              </ListItemText>
                            </ListItem>
                          ) : classList.length > 0 ? (
                            classList.map(item => {
                              return (
                                <ListItem key={item._id} button divider onClick={this.selectClass(item)}>
                                  <ListItemText>
                                    {!!selectedClass && selectedClass._id === item._id && (
                                      <i className="zmdi zmdi-check text-success mr-3" />
                                    )}
                                    {item.name}
                                  </ListItemText>
                                </ListItem>
                              )
                            })
                          ) : (
                            <ListItem>
                              <ListItemText>No classes found</ListItemText>
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                    </DialogContent>
                    <DialogActions>
                      <Button color={'primary'} onClick={this.toggleSelectClassDialog}>
                        Done
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </Paper>
              {/* <div>
                    For new users, search for Welcome to EPA! Select the lesson with the same name.
              </div> */}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!selectedClass || !email || !remark || lessonRange.length === 0 || creatingOrder}
            variant="contained"
            color="primary"
            onClick={this.submitOrder}>
            Create Allocation {creatingOrder && <CircularProgress size={15} className="ml-2" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogAddLesson.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSave: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddLesson)
