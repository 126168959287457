// only specify permissions for user roles with limited access
// unspecified user roles have access to all pages
// TBD: get permissions object from

import {
  ROUTE_NUMBER_DASHBOARD,
  ROUTE_ORDERS,
  ROUTE_USER_MANAGEMENT,
  ROUTE_LESSON_MANAGEMENT,
  ROUTE_LESSON_DETAIL_ZOOM,
} from 'routes/paths'

export const checkActionPermitted = function (permissions, userRole, path, action) {
  if (Object.keys(permissions).includes(userRole)) {
    const allowedPaths = permissions[userRole].allowedPaths
    if (Object.keys(allowedPaths).includes(path)) {
      const allowedActions = allowedPaths[path]
      if (Object.keys(allowedActions).includes(action)) {
        return allowedActions[action]
      }
    }
  }
  return true
}

export default {
  DataAdmin: {
    allowedPaths: {
      [ROUTE_NUMBER_DASHBOARD]: {}
    }
  },
  SalesAdmin: {
    allowedPaths: {
      [ROUTE_NUMBER_DASHBOARD]: {},
    }
  },
  Partner: {
    allowedPaths: {
      [ROUTE_ORDERS]: {
        canCreateOrder: true,
        allowedOrderType: ['TRIAL'],
        canCreateOrderByCSV: false,
        canCreateNormal: false,
        canCreateTrial: true,
        canCancelTrial: true,
        canCancelPurchase: false,
        canUpdateOrder: false
      },
      [ROUTE_USER_MANAGEMENT]: {
        canManage: false,
        canManageCredit: false
      },
      [ROUTE_LESSON_MANAGEMENT]: { canManage: false, canSendNotification: false },
      [ROUTE_LESSON_DETAIL_ZOOM]: { canManagePoll: false },
    }
  },
  Agent: {
    allowedPaths: {
      [ROUTE_ORDERS]: {
        canCreateOrder: false,
        canViewAllOrders: false,
        canCancelTrial: true,
        canCancelPurchase: false,
        canUpdateOrder: false
      }
    }
  },
  CustService: {
    allowedPaths: {
      [ROUTE_NUMBER_DASHBOARD]: {},
    }
  },
}
