import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Checkbox
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'

import studyArea from 'assets/area-of-study.json'
import studyAreaSpecific from 'assets/area-of-study-specific.json'
import { OthersField } from 'components/Fields/others'
import { InputText } from 'components/Fields/inputText'

class ParentInfo extends React.Component {
  render() {
    const {
      isKruA,
      handleChange,
      handleCheckboxChange,
      handleBlurInput,
      handleAutoCompleteOccupationChange,
      handleAutoCompleteOccupationSpecificChange,
      parentName,
      parentPassword,
      parentEmail,
      parentPhoneCountryCode,
      parentPhone,
      parentDOB,
      sanameOfStaff,
      isLoading,
      saList,
      handleAutoCompleteSAStaff,
      parentGender,
      parentContactPreference,
      parentContactPreferenceOther,
      parentFacebook,
      parentLine,
      parentOccupation,
      parentOccupationSpecific,
      parentFamilyBackground,
      parentNetworkingFB,
      parentNetworkingWebsite,
      parentNetworkingFriends,
      parentNetworkingOthersBox,
      parentNetworkingOthersField,
      pcDominanceDirect,
      pcDominanceResultsOriented,
      pcDominanceFirm,
      pcDominanceStrongWilled,
      pcDominanceForceful,
      pcInfluenceOutgoing,
      pcInfluenceEnthusiastic,
      pcInfluenceOptimistic,
      pcInfluenceHighSpirited,
      pcInfluenceLively,
      pcConscientiousnessAnalytical,
      pcConscientiousnessReserved,
      pcConscientiousnessPrecise,
      pcConscientiousnessPrivate,
      pcConscientiousnessSystematic,
      pcSteadinessEvenTempered,
      pcSteadinessAccommodating,
      pcSteadinessPatient,
      pcSteadinessHumble,
      pcSteadinessTactful,
      parentConflict,
      parentDecisionFather,
      parentDecisionMother,
      paymentMode,
      parentInterestedTopicsPublicSpeaking,
      parentInterestedTopicsDebate,
      parentInterestedTopicsCoding,
      parentInterestedTopicsCreativeWriting,
      parentInterestedTopicsFormalWriting,
      parentInterestedTopicsReadingComprehension,
      parentInterestedTopicsLiterature,
      parentInterestedTopicsOthersBox,
      parentInterestedTopicsOthersField,
      parentDateOfRenewal
    } = this.props

    const networkingCircle = [
      { check: parentNetworkingFB, name: 'parentNetworkingFB', label: 'Facebook' },
      { check: parentNetworkingWebsite, name: 'parentNetworkingWebsite', label: 'Website' },
      { check: parentNetworkingFriends, name: 'parentNetworkingFriends', label: 'Friends' }
    ]

    const pcDominance = [
      { check: pcDominanceDirect, nameBox: 'pcDominanceDirect', labelBox: 'Direct' },
      { check: pcDominanceResultsOriented, nameBox: 'pcDominanceResultsOriented', labelBox: 'Results-Oriented' },
      { check: pcDominanceFirm, nameBox: 'pcDominanceFirm', labelBox: 'Firm' },
      { check: pcDominanceStrongWilled, nameBox: 'pcDominanceStrongWilled', labelBox: 'Strong-Willed' },
      { check: pcDominanceForceful, nameBox: 'pcDominanceForceful', labelBox: 'Forceful' }
    ]

    const pcInfluence = [
      { check: pcInfluenceOutgoing, nameBox: 'pcInfluenceOutgoing', labelBox: 'Outgoing' },
      { check: pcInfluenceEnthusiastic, nameBox: 'pcInfluenceEnthusiastic', labelBox: 'Enthusiastic' },
      { check: pcInfluenceOptimistic, nameBox: 'pcInfluenceOptimistic', labelBox: 'Optimistic' },
      { check: pcInfluenceHighSpirited, nameBox: 'pcInfluenceHighSpirited', labelBox: 'High-Spirited' },
      { check: pcInfluenceLively, nameBox: 'pcInfluenceLively', labelBox: 'Lively' }
    ]

    const pcConscientiousness = [
      { check: pcConscientiousnessAnalytical, nameBox: 'pcConscientiousnessAnalytical', labelBox: 'Analytical' },
      { check: pcConscientiousnessReserved, nameBox: 'pcConscientiousnessReserved', labelBox: 'Reserved' },
      { check: pcConscientiousnessPrecise, nameBox: 'pcConscientiousnessPrecise', labelBox: 'Precise' },
      { check: pcConscientiousnessPrivate, nameBox: 'pcConscientiousnessPrivate', labelBox: 'Private' },
      { check: pcConscientiousnessSystematic, nameBox: 'pcConscientiousnessSystematic', labelBox: 'Systematic' }
    ]

    const pcSteadiness = [
      { check: pcSteadinessEvenTempered, nameBox: 'pcSteadinessEvenTempered', labelBox: 'Even-Tempered' },
      { check: pcSteadinessAccommodating, nameBox: 'pcSteadinessAccommodating', labelBox: 'Accommodating' },
      { check: pcSteadinessPatient, nameBox: 'pcSteadinessPatient', labelBox: 'Patient' },
      { check: pcSteadinessHumble, nameBox: 'pcSteadinessHumble', labelBox: 'Humble' },
      { check: pcSteadinessTactful, nameBox: 'pcSteadinessTactful', labelBox: 'Tactful' }
    ]

    const decisionMaker = [
      { check: parentDecisionFather, name: 'parentDecisionFather', label: 'Father' },
      { check: parentDecisionMother, name: 'parentDecisionMother', label: 'Mother' }
    ]

    const parentInterestedTopicsLifeSkills = [
      {
        check: parentInterestedTopicsPublicSpeaking,
        name: 'parentInterestedTopicsPublicSpeaking',
        label: 'Public Speaking'
      },
      { check: parentInterestedTopicsDebate, name: 'parentInterestedTopicsDebate', label: 'Debate' },
      { check: parentInterestedTopicsCoding, name: 'parentInterestedTopicsCoding', label: 'Coding' }
    ]

    const parentInterestedTopicsAcademicSkills = [
      {
        check: parentInterestedTopicsCreativeWriting,
        name: 'parentInterestedTopicsCreativeWriting',
        label: 'Creative Writing'
      },
      {
        check: parentInterestedTopicsFormalWriting,
        name: 'parentInterestedTopicsFormalWriting',
        label: 'Formal Writing'
      },
      {
        check: parentInterestedTopicsReadingComprehension,
        name: 'parentInterestedTopicsReadingComprehension',
        label: 'Reading Comprehension'
      },
      {
        check: parentInterestedTopicsLiterature,
        name: 'parentInterestedTopicsLiterature',
        label: 'Literature'
      }
    ]

    return (
      <>
        <h1 id="parent-info" className="mt-5">
          Parent's Information
        </h1>
        <Paper elevation={2} className="app-wrapper mb-3">
          <h3>Basic Information</h3>
          <FormControl className={'mb-3 w-100'}>
            <div className="row">
              <div className="col-12 col-md-4">
                <InputText
                  name="parentName"
                  label="Name"
                  defaultValue={parentName}
                  onBlur={handleBlurInput.bind(this)}
                />
              </div>
              <div className="col-12 mt-3 col-md-4 mt-md-0">
                <InputText
                  name="parentEmail"
                  label="Email"
                  required
                  defaultValue={parentEmail}
                  onBlur={handleBlurInput.bind(this)}
                />
              </div>
              <div className="col-12 col-md-4">
                <InputText
                  name="parentPassword"
                  label="Password"
                  defaultValue={parentPassword}
                  onBlur={handleBlurInput.bind(this)}
                />
                <FormHelperText>(To reset if necessary)</FormHelperText>
              </div>
            </div>
          </FormControl>
          <FormControl className={'my-3 w-100'}>
            <div className="row">
              <div className="col-auto mb-2">
                <FormControl>
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-label="parentGender"
                    name="parentGender"
                    value={parentGender}
                    onChange={handleChange}>
                    <FormControlLabel value="f" control={<Radio color="primary" />} label="Female" />
                    <FormControlLabel value="m" control={<Radio color="primary" />} label="Male" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-auto mb-2">
                <FormLabel component="legend">Date of birth</FormLabel>
                <InputText name="parentDOB" type="date" defaultValue={parentDOB} onBlur={handleBlurInput.bind(this)} />
              </div>
              <div className="col-auto mb-2">
                <FormLabel component="legend">Phone Country Code</FormLabel>
                <InputText
                  name="parentPhoneCountryCode"
                  type="tel"
                  defaultValue={parentPhoneCountryCode}
                  onBlur={handleBlurInput.bind(this)}
                />
              </div>
              <div className="col-auto mb-2">
                <FormLabel component="legend">Phone</FormLabel>
                <InputText
                  name="parentPhone"
                  type="tel"
                  defaultValue={parentPhone}
                  onBlur={handleBlurInput.bind(this)}
                />
              </div>

              <div className="col-2 mb-2">
                <FormLabel component="legend">Sales Admin</FormLabel>
                <Autocomplete
                  id="combo-box-demo"
                  style={{ marginTop: '13px' }}
                  name="sanameOfStaff"
                  disabled={isLoading}
                  value={sanameOfStaff}
                  options={saList.map(p => p.name)}
                  onChange={(event, value) => {
                    if (value !== null) handleAutoCompleteSAStaff(event, value)
                  }}
                  // getOptionLabel={option => option?.name || ''}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={params => <TextField {...params} />}
                />
              </div>
            </div>
          </FormControl>
          <FormControl className={'mb-3 w-100'}>
            <FormLabel component="legend">Contact Preference</FormLabel>
            <div className="row">
              <div className="col-3 mb-3">
                <div className="row">
                  <div className="col-auto">
                    <RadioGroup
                      row
                      aria-label="parentContactPreference"
                      name="parentContactPreference"
                      value={parentContactPreference}
                      onChange={handleChange}>
                      <FormControlLabel value="whatsapp" control={<Radio color="primary" />} label="Whatsapp" />
                      <FormControlLabel value="line" control={<Radio color="primary" />} label="Line" />
                      <FormControlLabel value="other" control={<Radio color="primary" />} label="Other" />
                    </RadioGroup>
                    {parentContactPreference === 'other' && (
                      <InputText
                        name="parentContactPreferenceOther"
                        placeholder="e.g. phone call, SMS, e-mail"
                        defaultValue={parentContactPreferenceOther}
                        onBlur={handleBlurInput.bind(this)}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row">
                  <FormLabel component="legend">Facebook URL</FormLabel>

                  <InputText
                    name="parentFacebook"
                    label="Facebook"
                    placeholder="facebook.com/zuck"
                    defaultValue={parentFacebook}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>

              <div className="ml-md-5 col-auto">
                <div className="row">
                  <FormLabel component="legend">LINE ID</FormLabel>

                  <InputText
                    name="parentLine"
                    label="Line"
                    defaultValue={parentLine}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>
            </div>
          </FormControl>

          <FormLabel legend="component" className="mt-4">
            Occupation (Optional)
          </FormLabel>
          <div className="row">
            <div className="row col-6">
              <div className="col-12 col-md-12 mb-3 mt-1">
                <Autocomplete
                  id="combo-box-area"
                  name="parentOccupation"
                  options={studyArea.map(options => options.name)}
                  value={parentOccupation}
                  onChange={(event, value) => {
                    if (value !== null) handleAutoCompleteOccupationChange(event, value)
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={params => <TextField {...params} label="Choose an occupation" />}
                />
              </div>
            </div>

            {parentOccupation && (
              <div className="row col-6">
                <div className="col-12 col-md-12 mb-3 mt-1">
                  <Autocomplete
                    id="combo-box--specific-area"
                    name="parentOccupationSpecific"
                    value={parentOccupationSpecific}
                    options={studyAreaSpecific
                      .filter(options => options.nameAS === parentOccupation)
                      .map(options => options.name)}
                    onChange={(event, value) => {
                      if (value !== null) handleAutoCompleteOccupationSpecificChange(event, value)
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={params => <TextField {...params} label="Choose a specific occupation" />}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="col-12">
            <div className="row">
              <InputText
                name="parentFamilyBackground"
                label="Family Background"
                defaultValue={parentFamilyBackground}
                onBlur={handleBlurInput.bind(this)}
              />
            </div>
          </div>

          <div className="row">
            <FormLabel className="ml-3 mt-4" component="legend">
              Networking Circle
            </FormLabel>
            <div className="col-md-12 col-6 mt-2">
              <div className="row">
                {networkingCircle.map((type, index) => (
                  <Paper key={index} className="m-1">
                    <div className="col-auto">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={type.check}
                              name={type.name}
                              color="primary"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={`${type.label}`}
                        />
                      </FormControl>
                    </div>

                    <div className="col-auto" style={{ marginBottom: '-30px' }}>
                      <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                    </div>
                  </Paper>
                ))}
                <OthersField
                  check={parentNetworkingOthersBox}
                  nameBox={'parentNetworkingOthersBox'}
                  placeholderField={'Please specify other favorite subject'}
                  nameField={'parentNetworkingOthersField'}
                  valueField={parentNetworkingOthersField}
                  checkBoxChange={handleCheckboxChange}
                  onBlur={handleBlurInput.bind(this)}
                />
              </div>
            </div>
          </div>

          {isKruA && (
            <>
              <h3 id="parent-personality-parenting" className="mt-5">
                Personality & Parenting Style
              </h3>
              <div className="row">
                <FormLabel className="ml-2" component="legend">
                  Parent's Character (DISC) - Counsellor's Perspective
                </FormLabel>
                <div className="col-md-2 col-6 mt-2">
                  <div className="row">
                    <FormLabel className="ml-2" component="legend">
                      Dominance
                    </FormLabel>
                    {pcDominance.map((type, index) => (
                      <Paper key={index} className="m-1 box-counsellor" style={{ backgroundColor: '#90EE90' }}>
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={type.check}
                                  name={type.nameBox}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={`${type.labelBox}`}
                            />
                          </FormControl>
                        </div>

                        <div className="col-auto" style={{ marginBottom: '-30px' }}>
                          <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                        </div>
                      </Paper>
                    ))}
                  </div>
                </div>

                <div className="col-md-2 col-6 mt-2">
                  <div className="row">
                    <FormLabel className="ml-2" component="legend">
                      Influence
                    </FormLabel>

                    {pcInfluence.map((type, index) => (
                      <Paper key={index} className="m-1 box-counsellor" style={{ backgroundColor: '#FFCCCB' }}>
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={type.check}
                                  name={type.nameBox}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={`${type.labelBox}`}
                            />
                          </FormControl>
                        </div>
                        <div className="col-auto" style={{ marginBottom: '-30px' }}>
                          <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                        </div>
                      </Paper>
                    ))}
                  </div>
                </div>
                <div className="col-md-2 col-6 mt-2">
                  <div className="row">
                    <FormLabel className="ml-2" component="legend">
                      Steadiness
                    </FormLabel>

                    {pcSteadiness.map((type, index) => (
                      <Paper key={index} className="m-1 box-counsellor" style={{ backgroundColor: '#D7E5F0' }}>
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={type.check}
                                  name={type.nameBox}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={`${type.labelBox}`}
                            />
                          </FormControl>
                        </div>
                        <div className="col-auto" style={{ marginBottom: '-30px' }}>
                          <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                        </div>
                      </Paper>
                    ))}
                  </div>
                </div>

                <div className="col-md-2 col-6 mt-2">
                  <div className="row">
                    <FormLabel className="ml-2" component="legend">
                      Conscientiousness
                    </FormLabel>

                    {pcConscientiousness.map((type, index) => (
                      <Paper key={index} className="m-1 box-counsellor" style={{ backgroundColor: '#FDFD96' }}>
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={type.check}
                                  name={type.nameBox}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={`${type.labelBox}`}
                            />
                          </FormControl>
                        </div>
                        <div className="col-auto" style={{ marginBottom: '-30px' }}>
                          <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                        </div>
                      </Paper>
                    ))}
                  </div>
                </div>
                <div className="col-md-1 col-6 mt-2 d-md-block d-none"></div>

                <div className="col-md-2 col-6 mt-2">
                  <div className="row">
                    <FormLabel style={{ justifyContent: 'start', display: 'flex' }} component="legend">
                      Decision Maker
                    </FormLabel>

                    {decisionMaker.map((type, index) => (
                      <Paper key={index} className="m-1 box-counsellor">
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={type.check}
                                  name={type.name}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={`${type.label}`}
                            />
                          </FormControl>
                        </div>
                        <div className="col-auto" style={{ marginBottom: '-30px' }}>
                          <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                        </div>
                      </Paper>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

          {!isKruA && (
            <div className="row">
              <FormLabel className="ml-3 mt-4" component="legend">
                Decision Maker
              </FormLabel>
              <div className="col-md-12 col-6 mt-2">
                <div className="row">
                  {decisionMaker.map((type, index) => (
                    <Paper key={index} className="m-1 box-counsellor">
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={type.check}
                                name={type.name}
                                color="primary"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={`${type.label}`}
                          />
                        </FormControl>
                      </div>
                      <div className="col-auto" style={{ marginBottom: '-30px' }}>
                        <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                      </div>
                    </Paper>
                  ))}
                </div>{' '}
              </div>
            </div>
          )}
          {isKruA && (
            <>
              <div className="col-12 mt-3">
                <div className="row">
                  <InputText
                    name="parentConflict"
                    label="Conflicts in Family"
                    defaultValue={parentConflict}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>
            </>
          )}

          <h3 id="parent-preference-payment" className="mt-5">
            {isKruA ? 'Preference & Payment' : 'Parent Preferences'}
          </h3>
          <div className="row">
            {isKruA && (
              <div className="col-sm-auto">
                <div className="row">
                  <div className="col-auto">
                    <FormLabel className="mt-3" component="legend">
                      Preferred mode of payment
                    </FormLabel>

                    <RadioGroup aria-label="paymentMode" name="paymentMode" value={paymentMode} onChange={handleChange}>
                      <FormControlLabel value="cash" control={<Radio color="primary" />} label="Cash" />
                      <FormControlLabel value="credit" control={<Radio color="primary" />} label="Credit Card" />
                      <FormControlLabel value="bank" control={<Radio color="primary" />} label="Bank Transfer" />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            )}

            <div className="col-sm-auto">
              <div className={isKruA ? "row ml-md-2" : "row"}>
                <FormLabel className="ml-md-3 mt-3" component="legend">
                  Interested Topics / Events / Courses
                </FormLabel>
                <div className="row col-md-12 mt-2">
                  <div className="col-auto col-md-4">
                    <FormLabel className="ml-md-2" component="legend">
                      Life Skills
                    </FormLabel>
                    {parentInterestedTopicsLifeSkills.map((lifeSkills, index) => (
                      <Paper key={index} className="m-1">
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={lifeSkills.check}
                                  name={lifeSkills.name}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={`${lifeSkills.label}`}
                            />
                          </FormControl>
                        </div>
                      </Paper>
                    ))}
                  </div>

                  <div className="col-auto col-md-4">
                    <FormLabel className="ml-md-2" component="legend">
                      Academic Skills
                    </FormLabel>
                    {parentInterestedTopicsAcademicSkills.map((academicSkills, index) => (
                      <Paper key={index} className="m-1">
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={academicSkills.check}
                                  name={academicSkills.name}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={`${academicSkills.label}`}
                            />
                          </FormControl>
                        </div>
                      </Paper>
                    ))}
                  </div>

                  <div className="col-auto col-md-4">
                    <FormLabel className="ml-md-2" component="legend">
                      Other Interested Skills
                    </FormLabel>
                    <OthersField
                      check={parentInterestedTopicsOthersBox}
                      removeField={true}
                      nameBox={'parentInterestedTopicsOthersBox'}
                      placeholderField={'Please specify others'}
                      nameField={'parentInterestedTopicsOthersField'}
                      valueField={parentInterestedTopicsOthersField}
                      checkBoxChange={handleCheckboxChange}
                      onBlur={handleBlurInput.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-auto ml-md-4">
              <FormLabel component="legend">Date of renewal</FormLabel>
              <InputText
                name="parentDateOfRenewal"
                type="date"
                defaultValue={parentDateOfRenewal}
                onBlur={handleBlurInput.bind(this)}
              />
            </div>
          </div>
        </Paper>
      </>
    )
  }
}

export default ParentInfo
