import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import {
  AppBar,
  Dialog,
  Card,
  CardContent,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Tab,
  Tabs,
  Divider,
  Radio,
  RadioGroup
} from '@material-ui/core'
import CsvParse from '@vtex/react-csv-parse'
import _ from 'lodash'
import moment from 'moment'
import { CREATE_ORDER, GET_CLASSES, IMPORT_CLASSES } from 'constants/actionTypes'
import agent from 'agent'
import { Close } from '@material-ui/icons'
import Pluralize from 'react-pluralize'

const mapStateToProps = state => ({
  classList: state.classroom.classList,
  isLoadingClasses: state.classroom.isLoadingClasses,
  creatingOrder: state.orders.creatingOrder,
  createError: state.orders.error,
  agentList: state.user.agentList,
  inProgress: state.classroom.inProgress
})

const mapDispatchToProps = dispatch => ({
  getClasses: filter => dispatch({ type: GET_CLASSES, payload: agent.Classroom.getClasses(filter) }),
  createOrder: req => dispatch({ type: CREATE_ORDER, payload: agent.Orders.createOrder(req) }),
  importClass: classes => dispatch({ type: IMPORT_CLASSES, payload: agent.Classroom.importClass(classes) })
})

class DialogOrderCSV extends React.Component {
  constructor() {
    super()

    this.state = {
      data: null,
      dataProcessed: false,
      processedData: [],
      orderedData: [],
      rejectedData: [],
      skippedData: [],
      discount_code: '',
      no_discount_code: false,
      handlingIndex: null,
      handlingObject: null,
      selectedClass: null,
      selectedLessons: [],
      allDone: false,
      class_name: '',
      selectClassDialogOpen: false,
      selectLessonDialogOpen: false,
      selectedTab: 'ordered',
      agent_id: '',
      paymentChannel: 'offline',
      paymentProvider: '',
      paymentCompletedOn: moment().format('YYYY-MM-DDTHH:mm'),
      isRenewal: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // when clicked on process, csvparse will process the data. the data is stored in array. how many rows of data is returned is based on email in list
    // length is more than 0, handlingIndex is set to 0
    if (this.state.processedData.length > 0 && !prevState.dataProcessed && this.state.dataProcessed) {
      this.setState({ handlingIndex: 0 })
    }

    // when clicked on create order,
    if (prevProps.creatingOrder && !this.props.creatingOrder && !this.props.createError) {
      let orderedData = [...this.state.orderedData]
      orderedData.push(this.state.handlingObject)
      // ordered data is pushed with the values.

      if (prevState.handlingIndex < this.state.processedData.length - 1) {
        this.setState(prevState => ({ handlingIndex: prevState.handlingIndex + 1, orderedData }))
      } else {
        this.setState({ allDone: true, orderedData })
        // same as orderedData: orderedData
        // https://stackoverflow.com/questions/42181873/react-js-setstate-call-with-key-but-without-value
        // https://ariya.io/2013/02/es6-and-object-literal-property-value-shorthand
      }
    }

    if (this.state.handlingIndex !== null && prevState.handlingIndex !== this.state.handlingIndex) {
      //if index is 0, 1, 2 so on...
      let object = { ...this.state.processedData[this.state.handlingIndex] }

      if (
        (!!prevState.handlingObject && prevState.handlingObject.class !== object.class) ||
        !prevState.handlingObject
      ) {
        this.setState({ class_name: object.class })
        this.searchClass(object.class)
      }

      this.setState({ handlingObject: object })
    }

    if (prevProps.isLoadingClasses && !this.props.isLoadingClasses) {
      if (this.props.classList.length === 1) {
        let classObject = this.props.classList[0]

        this.setState({ selectedClass: classObject, selectClassDialogOpen: false })
      } else {
        this.setState({ selectedClass: null, selectClassDialogOpen: true })
      }
    }

    if (
      !!this.state.selectedClass &&
      (!_.isEqual(prevState.selectedClass, this.state.selectedClass) ||
        (!!prevState.handlingObject &&
          !!this.state.handlingObject &&
          (prevState.handlingObject.for_month !== this.state.handlingObject.for_month ||
            prevState.handlingObject.lessons !== this.state.handlingObject.lessons)))
    ) {
      let classObject = this.state.selectedClass
      let object = this.state.handlingObject

      let selectedLessons = this.getLessons(classObject.lesson, object.lessons, object.for_month)

      this.setState({ selectedLessons })
    }
  }

  manualSearch = e => {
    e.preventDefault()

    this.searchClass(this.state.class_name)
  }

  searchClass = class_name => {
    const { handlingObject } = this.state
    let className = null

    if (!!handlingObject) {
      className = handlingObject.class
    }

    if (!class_name && !className) {
      return null
    }

    let filter = {
      name: {
        $regex: className,
        $options: 'i'
      }
    }

    if (!!class_name) {
      filter['name']['$regex'] = class_name
    }

    this.props.getClasses(filter)
  }

  selectClass = classObject => () => {
    this.setState({ selectedClass: classObject })
  }

  toggleSelectClassDialog = () => {
    this.setState(prevState => ({ selectClassDialogOpen: !prevState.selectClassDialogOpen }))
  }

  toggleSelectLessonDialog = () => {
    this.setState(prevState => ({ selectLessonDialogOpen: !prevState.selectLessonDialogOpen }))
  }

  selectLesson = lessonObj => () => {
    const { selectedLessons, selectedClass } = this.state

    let availableLessons = selectedClass.lesson

    let toModify = _.cloneDeep(selectedLessons)

    let indexOfLesson = _.findIndex(toModify, lesson => {
      return lesson._id === lessonObj._id
    })

    if (indexOfLesson === -1) {
      //not found, add lesson
      toModify.push(lessonObj)
    } else {
      //found, remove from array
      _.remove(toModify, lesson => {
        return lesson._id === lessonObj._id
      })
    }

    toModify = _.orderBy(toModify, ['scheduled_on'], ['asc'])
    availableLessons = _.orderBy(availableLessons, ['scheduled_on'], ['asc'])

    let lessonRange = []
    if (toModify.length > 0) {
      let indexFirst = _.findIndex(availableLessons, i => i._id === toModify[0]._id)
      let indexLast = _.findIndex(availableLessons, i => i._id === toModify[toModify.length - 1]._id)

      lessonRange = availableLessons.slice(indexFirst, indexLast + 1)
    }

    this.setState({ selectedLessons: lessonRange })
  }

  getLessons = (lessonList, numOfLessons, for_month) => {
    let monthLessons = _.filter(lessonList, l => moment(l.scheduled_on).format('MMMM') === for_month)
    let futureLessons = _.filter(monthLessons, fl => {
      return moment(fl.scheduled_on).diff(moment(), 'hours', true) > 0
    })
    let sortedLessons = _.orderBy(futureLessons, ['scheduled_on'], ['asc'])
    numOfLessons = parseInt(numOfLessons)

    return sortedLessons.slice(0, numOfLessons)
  }

  handleChange = e => {
    let name = e.target.name
    let value = e.target.value.trim()

    if (name === 'discount_code') {
      value = value.toUpperCase()
    }

    this.setState({ [name]: value })
  }

  handleCheckboxChange = event => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  handleObjectData = e => {
    let modified = { ...this.state.handlingObject }
    console.log('🚀 alex: ~ file: DialogOrderCSV.js:260 ~ modified', modified)

    let name = e.target.name
    let value = e.target.value

    if (name === 'discount_code') {
      value = value.toUpperCase()
    }

    modified[name] = value

    this.setState({ handlingObject: modified })
  }

  handleData = data => {
    this.setState({ data, dataProcessed: false })
  }

  handleError = (err, file, inputElem, reason) => {
    // eslint-disable-next-line no-console
    console.log(err, file, inputElem, reason)
  }

  processCSV = () => {
    const { data, discount_code, no_discount_code, agent_id } = this.state

    let processedData = []
    let rejectedData = []

    for (let i = 0; i <= data.length; i++) {
      let current = data[i]

      if (!current) {
        continue
      } else if (!current.student_id) {
        rejectedData.push(current)
        continue
      }

      let tempOrder = {
        id: current.id,
        student_id: current.student_id,
        level_id: current.level_id,
        subject_id: current.subject_id,
        date: current.date,
        //class name
        name: `${current.student_id}_${current.level_title}_${current.subject_title}`, //class name
        lesson_name: `${current.student_id}_${current.subject_title}_${
          current.start_datetime.length >= 13 && current.start_datetime.length <= 16
            ? current.start_datetime
            : current.start_datetime.slice(0, -3)
        }`, //1st lesson name for class, all other lessons will have own
        duplicate_lesson_check: `${current.start_datetime}_${current.teacher_id}_${current.level_id}_${current.subject_id}`,
        teacher_id: current.teacher_id,
        duration: current.duration,
        start_datetime: current.start_datetime, //if changed, update start_datetime
        modified: current.modified
      }

      processedData.push(tempOrder)
    }

    this.props.importClass(processedData)

    this.setState({
      processedData,
      rejectedData,
      skippedData: [],
      orderedData: [],
      dataProcessed: true,
      allDone: false
    })
  }
  submitOrder = () => {
    const {
      handlingObject,
      selectedClass,
      selectedLessons,
      discount_code,
      agent_id,
      paymentChannel,
      paymentProvider,
      paymentCompletedOn,
      isRenewal
    } = this.state

    let request = {
      type: handlingObject.type,
      email: handlingObject.email,
      class: selectedClass._id,
      remark: handlingObject.remark
    }

    let first = selectedLessons[0]
    let last = selectedLessons[selectedLessons.length - 1]

    let start_date = moment(first.scheduled_on).format('YYYY-MM-DD')
    let end_date = moment(last.scheduled_on).format('YYYY-MM-DD')

    request.start_date = start_date
    request.end_date = end_date

    if (!!discount_code.trim()) {
      request.discount_code = discount_code.trim()
    }
    if (agent_id) {
      request.agent_id = agent_id
    }

    let paymentInfo = {
      channel: paymentChannel,
      provider: paymentProvider,
      completed_on: moment(paymentCompletedOn).toISOString()
    }

    if (paymentChannel === 'online') {
      if (paymentProvider === 'stripe') {
        paymentInfo['platform'] = 'credit-card'
      } else if (paymentProvider === 'hitpay') {
        paymentInfo['platform'] = 'paynow'
      }
    }
    request['payment'] = paymentInfo
    request['meta'] = {
      is_renewal: isRenewal
    }
    this.props.createOrder(request)
  }

  skipData = () => {
    let skippedData = [...this.state.skippedData]
    skippedData.push(this.state.handlingObject)
    this.setState(prevState => ({ skippedData, handlingIndex: prevState.handlingIndex + 1 }))
  }

  finalClose = () => {
    this.props.toggle()
  }

  changeTab = (e, value) => {
    this.setState({ selectedTab: value })
  }

  render() {
    const { isOpen, toggle, inProgress } = this.props
    const {
      data,
      discount_code,
      no_discount_code,
      dataProcessed,
      processedData,
      handlingIndex,
      handlingObject,
      selectedClass,
    } = this.state

    let lessonList = []
    if (!!selectedClass) {
      lessonList = _.orderBy(selectedClass.lesson, ['scheduled_on'], ['asc'])
    }

    let keys = [
      'id',
      'student_id',
      'date',
      'start_datetime',
      'duration',
      'created',
      'modified',
      'teacher_id',
      'level_id',
      'level_title',
      'subject_id',
      'subject_title'
    ]

    let sampleData = []

    if (!!data && data.length > 0) {
      // data.slice(0, 8) previously to limit to 8
      sampleData = data
    }

    return (
      <React.Fragment>
        <Modal centered isOpen={isOpen && !handlingObject} toggle={toggle}>
          <ModalBody>
            <div className="row justify-content-between">
              <h1>CSV Parser</h1> <i className="zmdi zmdi-close zmdi-hc-2x mr-2" onClick={toggle} />
            </div>
            <CsvParse
              keys={keys}
              onDataUploaded={this.handleData}
              onError={this.handleError}
              render={onChange => <input type="file" className="form-control" onChange={onChange} />}
            />
            {!!data && (
              <Card className={'mt-3'}>
                <CardContent>
                  <div className="row">
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={'no_discount_code'}
                            checked={no_discount_code}
                            onChange={this.handleCheckboxChange}
                          />
                        }
                        label="Confirm and proceed"
                      />
                    </div>
                    <div className="col-6 justify-content-end align-items-center">
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        disabled={(!discount_code && !no_discount_code) || dataProcessed}
                        onClick={this.processCSV}>
                        {!dataProcessed ? 'Proceed' : 'Data Processed'}
                      </Button>
                    </div>
                  </div>
                  <hr />
                  <h3 className="text-center">Data Preview ({data.length} entries)</h3>
                  <Divider />
                  <div className="overflow-card">
                    {sampleData.map((s, index) => (
                      <Card key={index} className="py-2 mb-2">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-4">
                              <h4>{s.name}</h4>
                              <div className="text-muted">{s.student_id}</div>
                            </div>
                            <div className="col-md-8">
                              <h3>
         
                                {s.start_datetime.length >= 13 && s.start_datetime.length <= 16
                                  ? s.start_datetime
                                  : s.start_datetime.slice(0, -3)}
                              </h3>
                              <div className="text-muted">{s.level_title}</div>
                              <div className="text-muted">{s.subject_title}</div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </CardContent>
              </Card>
            )}
          </ModalBody>
        </Modal>
        {!!inProgress && (
          <Dialog disableEnforceFocus open={!!inProgress}>
            <AppBar className="position-relative"></AppBar>
            Please wait...
          </Dialog>
        )}
      </React.Fragment>
    )
  }
}

DialogOrderCSV.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogOrderCSV)
