import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from 'components/Pagination/Pagination'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import Checkbox from '@material-ui/core/Checkbox'
import { GET_FEEDBACK } from 'constants/actionTypes'
import * as Type from 'constants/actionTypes'
import FeedbackDisplay from 'pages/Feedback/FeedbackItem'

const mapStateToProps = state => ({
  ...state.feedback,
  classList: state.classroom.classList,
  classCount: state.classroom.classCount,
  classLoading: state.classroom.isLoadingClasses
})

const mapDispatchToProps = dispatch => ({
  getFeedback: (filter, perPage, page) =>
    dispatch({ type: GET_FEEDBACK, payload: agent.UserInput.getFeedback(filter, perPage, page) }),
  getClasses: filter => dispatch({ type: Type.GET_CLASSES, payload: agent.Classroom.getClasses(filter, 0) })
})

class Feedback extends React.Component {
  constructor(props) {
    super()

    this.toggleSelectClassDialog = () => {
      this.setState({ selectClassDialogOpen: !this.state.selectClassDialogOpen })
    }

    this.state = {
      ratingDropdownOpen: false,
      selectedRating: ['1', '2', '3', '4', '5'],
      comments: false,
      perPage: 10,
      page: 1,
      classText: '',
      selectedClass: null,
      selectedLesson: '',
      selectClassDialogOpen: false
    }
  }

  componentDidMount() {
    this.getFeedback()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getFeedback()
    }

    if (prevState.selectedClass !== this.state.selectedClass) {
      this.getFeedback()
    }

    if (prevState.selectedLesson !== this.state.selectedLesson) {
      this.getFeedback()
    }

    if (prevState.selectedRating !== this.state.selectedRating) {
      this.getFeedback()
    }

    if (prevState.comments !== this.state.comments) {
      this.getFeedback()
    }
  }

  getFeedback = () => {
    const { selectedRating, perPage, page, selectedClass, selectedLesson, comments } = this.state

    let filter = {}

    if (selectedRating.length !== 0) {
      filter.rating = { $in: selectedRating }
    }

    if (!!selectedClass && !selectedLesson) {
      filter.feedback_target_id = {
        $in: selectedClass.lesson.map(l => l._id)
      }
    }

    if (!!selectedLesson) {
      filter.feedback_target_id = selectedLesson
    }

    if (comments) {
      filter.content = { $ne: '' }
    }

    this.props.getFeedback(filter, perPage, page)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckbox = e => {
    const { selectedRating } = this.state

    let toChange = [...selectedRating]
    let value = e.target.value
    let checked = e.target.checked

    if (checked) {
      toChange.push(value)
    } else {
      _.remove(toChange, v => {
        return v === value
      })
    }

    toChange = _.sortBy(toChange)

    this.setState({ selectedRating: toChange })
  }

  toggleRatingDropdown = () => {
    this.setState({ ratingDropdownOpen: !this.state.ratingDropdownOpen })
  }

  onChangePage = page => {
    this.setState({ page })
  }

  searchClasses = e => {
    e.preventDefault()
    const { classText } = this.state

    let filter = {
      name: {
        $regex: classText,
        $options: 'i'
      }
    }

    this.props.getClasses(filter)
  }

  selectClass = selectedClass => () => {
    this.setState({ selectedClass })
  }

  clearClass = () => {
    this.setState({ selectedClass: null, selectedLesson: '' })
  }

  render() {
    const { feedbackList, feedbackCount, classList, classLoading } = this.props
    const {
      ratingDropdownOpen,
      selectedRating,
      perPage,
      page,
      classText,
      selectedClass,
      selectedLesson,
      selectClassDialogOpen,
      comments
    } = this.state

    let appliedFilters = []

    if (!!selectedClass) {
      appliedFilters.push('class')
    }

    if (!!selectedLesson) {
      appliedFilters.push('lesson')
    }

    if (selectedRating.length !== 5 && selectedRating.length > 0) {
      appliedFilters.push('rating')
    }

    return (
      <div className={'app-wrapper'}>
        <h1>Feedback</h1>
        <div className="row mb-3">
          <div className="col-auto d-flex align-items-center">
            <span>Filter By</span>
          </div>
          <div className={'col-auto'}>
            {selectedClass ? (
              <Button variant={'contained'} color={'primary'} onClick={this.toggleSelectClassDialog}>
                {selectedClass.name}
              </Button>
            ) : (
              <Button variant={'contained'} color={'primary'} onClick={this.toggleSelectClassDialog}>
                Select a class
              </Button>
            )}
            <Dialog open={selectClassDialogOpen} onClose={this.toggleSelectClassDialog}>
              <DialogContent>
                <form onSubmit={this.searchClasses}>
                  <div className="row">
                    <div className="col">
                      <TextField
                        label={'Class Name'}
                        name={'classText'}
                        value={classText}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-auto d-flex align-items-end">
                      <Button type={'submit'} disabled={!classText} variant={'contained'} color={'secondary'}>
                        Search
                      </Button>
                    </div>
                  </div>
                </form>
              </DialogContent>
              {classLoading && (
                <div className="my-5 text-center">
                  <CircularProgress size={30} color={'primary'} />
                </div>
              )}
              {!!classList ? (
                classList.length > 0 ? (
                  <List>
                    {classList.map(c => {
                      return (
                        <ListItem key={c._id} button divider onClick={this.selectClass(c)}>
                          {!!selectedClass && selectedClass._id === c._id ? (
                            <div>
                              <i className="zmdi zmdi-check text-success mr-2" />
                              {c.name}
                            </div>
                          ) : (
                            c.name
                          )}
                        </ListItem>
                      )
                    })}
                  </List>
                ) : (
                  <DialogContent>
                    <div>Search for a class</div>
                  </DialogContent>
                )
              ) : (
                <DialogContent>
                  <div>
                    No classes found matching "<strong>{classText}</strong>"
                  </div>
                </DialogContent>
              )}
              <DialogActions>
                <Button onClick={this.toggleSelectClassDialog}>Done</Button>
              </DialogActions>
            </Dialog>
          </div>
          {!!selectedClass && (
            <div className="col">
              <select
                name="selectedLesson"
                value={selectedLesson}
                onChange={this.handleChange}
                className="form-control">
                <option value="">All</option>
                {selectedClass.lesson.map(l => {
                  return (
                    <option key={l._id} value={l._id}>
                      {l.name}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          <div className="col-auto">
            <ButtonDropdown isOpen={ratingDropdownOpen} toggle={this.toggleRatingDropdown} className={'d-block'}>
              <DropdownToggle
                className={'border bg-white text-black form-control text-left d-flex align-items-center'}
                style={{ height: 'calc(2.25rem + 2px)' }}
                caret>
                <span className="d-inline-block mr-auto">
                  {selectedRating.length === 5 ? 'All ratings' : `${selectedRating.join(', ')} stars`}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <FormGroup>
                  <FormControlLabel
                    className={'ml-0'}
                    checked={selectedRating.includes('1')}
                    control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'1'} />}
                    label={'1 star'}
                  />
                  <FormControlLabel
                    className={'ml-0'}
                    checked={selectedRating.includes('2')}
                    control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'2'} />}
                    label={'2 star'}
                  />
                  <FormControlLabel
                    className={'ml-0'}
                    checked={selectedRating.includes('3')}
                    control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'3'} />}
                    label={'3 star'}
                  />
                  <FormControlLabel
                    className={'ml-0'}
                    checked={selectedRating.includes('4')}
                    control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'4'} />}
                    label={'4 star'}
                  />
                  <FormControlLabel
                    className={'ml-0'}
                    checked={selectedRating.includes('5')}
                    control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'5'} />}
                    label={'5 star'}
                  />
                </FormGroup>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <div className="col-auto">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={'comments'}
                  checked={comments}
                  onChange={(e, checked) => {
                    this.setState({ comments: checked })
                  }}
                />
              }
              label="Only commented"
            />
          </div>
          {!!selectedClass && (
            <div className="col-auto">
              <Button variant={'contained'} color={'secondary'} onClick={this.clearClass}>
                Clear
              </Button>
            </div>
          )}
        </div>
        <div className={'mb-3'}>
          {appliedFilters.length > 0 && <strong>Filtering by {appliedFilters.join(', ')}.</strong>}
          {!!feedbackCount && <div>{feedbackCount} results found.</div>}
        </div>
        <div className="feedback-list">
          {!!feedbackList ? (
            feedbackList.length === 0 ? (
              <h3 className="font-weight-semibold">No feedback was found</h3>
            ) : (
              feedbackList.map(f => {
                return <FeedbackDisplay key={f._id} feedback={f} />
              })
            )
          ) : (
            <div className="my-5 text-center">
              <CircularProgress color={'primary'} size={40} />
            </div>
          )}
        </div>
        {!!feedbackCount && (
          <Pagination
            itemCount={feedbackCount}
            onChangePage={this.onChangePage}
            pageSize={perPage}
            currentPage={page}
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
