import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import Button from '@material-ui/core/Button'
import permissions from 'permissions'

class SidebarNavItem extends Component {
  render() {
    const { location, to, userRole, children, className, onClick } = this.props
    let isActive = false
    if (to === location.pathname) {
      isActive = true
    }

    if (Object.keys(permissions).includes(userRole)) {
      const { allowedPaths } = permissions[userRole]
      if (Object.keys(allowedPaths).includes(to)) {
        return (
          <li className={isActive ? 'menu no-arrow open' : 'menu no-arrow'} onClick={onClick}>
            <NavLink to={to} className={className}>
              {children}
            </NavLink>
          </li>
        )
      } else {
        return null
      }
    }

    return (
      <li className={isActive ? 'menu no-arrow open' : 'menu no-arrow'} onClick={onClick}>
        <NavLink to={to} className={className}>
          {children}
        </NavLink>
      </li>
    )
  }
}

export const NavItem = withRouter(SidebarNavItem)

class SidebarNavSubmenuItem extends Component {
  render() {
    const { location, to, children, className } = this.props
    let isActive = false
    if (to === location.pathname) {
      isActive = true
    }

    return (
      <li className={isActive ? 'active' : ''}>
        <NavLink to={to} className={className}>
          {children}
        </NavLink>
      </li>
    )
  }
}

export const NavSubmenuItem = withRouter(SidebarNavSubmenuItem)

export class NavSubMenu extends React.Component {
  state = {
    isOpen: false,
    parentClass: 'menu',
    current: ''
  }

  componentDidMount() {
    this.setState({ current: this.props.keyword })
    if (this.props.location.includes(this.props.keyword)) {
      this.expandSubmenu()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const { keyword } = this.props
      const { current } = this.state
      if (keyword === current) {
        if (this.props.location.includes(keyword)) {
          this.expandSubmenu()
        } else {
          this.collapseSubmenu()
        }
      }
    }
  }

  expandSubmenu = () => {
    this.setState({ isOpen: true, parentClass: 'menu open active' })
  }

  collapseSubmenu = () => {
    this.setState({ isOpen: false, parentClass: 'menu' })
  }

  exited = () => {
    this.setState({ isOpen: false, parentClass: 'menu' })
  }

  render() {
    const { title, icon, children, to, userRole } = this.props

    if (Object.keys(permissions).includes(userRole)) {
      const { allowedPaths } = permissions[userRole]
      if (Object.keys(allowedPaths).includes(to)) {
        return (
          <li className={this.state.parentClass}>
            <Button
              onClick={() => {
                this.expandSubmenu()
                this.props.goToPage()
              }}
              style={{
                color: '#a1a1a1',
                display: 'block',
                fontSize: '14px',
                padding: '16px 20px',
                fontWeight: 400,
                textTransform: 'capitalize',
                textDecoration: 'none',
                borderRadius: 0,
                width: '100%',
                textAlign: 'left',
                borderLeft: '2px solid transparent'
              }}>
              {typeof icon === 'string' ? <i className={icon}></i> : icon}
              <span className="nav-text">{title}</span>
            </Button>
            <Collapse isOpen={this.state.isOpen} tag={'ul'} className={'sub-menu'} onExited={this.exited}>
              {children}
            </Collapse>
          </li>
        )
      } else {
        return null
      }
    }

    return (
      <li className={this.state.parentClass}>
        <Button
          onClick={() => {
            this.expandSubmenu()
            this.props.goToPage()
          }}
          style={{
            color: '#a1a1a1',
            display: 'block',
            fontSize: '14px',
            padding: '16px 20px',
            fontWeight: 400,
            textTransform: 'capitalize',
            textDecoration: 'none',
            borderRadius: 0,
            width: '100%',
            textAlign: 'left',
            borderLeft: '2px solid transparent'
          }}>
          {typeof icon === 'string' ? <i className={icon}></i> : icon}
          <span className="nav-text">{title}</span>
        </Button>
        <Collapse isOpen={this.state.isOpen} tag={'ul'} className={'sub-menu'} onExited={this.exited}>
          {children}
        </Collapse>
      </li>
    )
  }
}
