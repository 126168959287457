import React, { useState } from 'react'
import { FormControl, FormControlLabel, Checkbox, Paper, TextField } from '@material-ui/core'
export function OthersField(props) {
  const [value, setValue] = useState('')

  const { style, removeField, check, label, nameBox, placeholderField, nameField, valueField, checkBoxChange, onBlur } =
    props
  return (
    <Paper className={`m-1 ${style && style}`}>
      <div className="col-auto">
        <FormControl>
          <FormControlLabel
            control={<Checkbox checked={check} name={nameBox} color="primary" onChange={checkBoxChange} />}
            label={label ?? 'Others'}
          />
        </FormControl>
      </div>
      <div className="col-auto">
        {check && (
          <TextField
            fullWidth
            placeholder={placeholderField}
            name={nameField}
            label={label}
            onBlur={e => onBlur(e)}
            defaultValue={valueField}
            onChange={e => setValue(e.target.value)}
          />
        )}
      </div>
      {!removeField && (
        <div className="col-auto" style={{ marginBottom: '-30px' }}>
          <TextField disabled={true} InputProps={{ disableUnderline: true }} />
        </div>
      )}
    </Paper>
  )
}
